import ApiClient from "./ApiClient";
import {
  Event,
  ListEvent,
  Contactpersoon,
  EventGuest,
  EventLeadSubmit,
  EventAttendSubmit,
} from "../types/model";
import moment from "moment";

const endpoint = "event";

export default class EventApi {
  public static async getEvents(): Promise<ListEvent[]> {
    var events = await ApiClient.doHttpRequest<ListEvent[]>(
      "get",
      `api/${endpoint}`
    );
    return events;
  }

  public static async getEventsForUser(): Promise<ListEvent[]> {
    var events = await ApiClient.doHttpRequest<ListEvent[]>(
      "get",
      `api/${endpoint}/GetEventsForUser`
    );
    return events;
  }

  public static async getAankomendeEvents(): Promise<ListEvent[]> {
    var events = await ApiClient.doHttpRequest<ListEvent[]>(
      "get",
      `api/${endpoint}/Latest`
    );
    return events;
  }

  public static async getEvent(id: string): Promise<Event> {
    var event = await ApiClient.doHttpRequest<Event>(
      "get",
      `api/${endpoint}/${id}`
    );
    return event;
  }

  public static async getAttendees(id: string): Promise<Contactpersoon[]> {
    var attendees = await ApiClient.doHttpRequest<Contactpersoon[]>(
      "get",
      `api/${endpoint}/${id}/Attendees`
    );
    return attendees;
  }

  public static async archiveEventAttendees(
    eventId: string,
    attendees: string[]
  ): Promise<void> {
    await ApiClient.doHttpRequest<void[]>(
      "post",
      `api/${endpoint}/${eventId}/ToggleArchiveAttendees`,
      attendees
    );
  }

  public static async upsertEventGuest(eventGuest: EventGuest): Promise<void> {
    await ApiClient.doHttpRequest<void[]>(
      "post",
      `api/${endpoint}/EventGuest`,
      eventGuest
    );
  }

  public static async upsertEventLead(
    eventLead: EventLeadSubmit
  ): Promise<void> {
    await ApiClient.doHttpRequest<void[]>(
      "post",
      `api/${endpoint}/EventLead`,
      eventLead
    );
  }

  public static async upsertEventContactpersoon(
    eventId: string,
    eventContactpersoon: EventAttendSubmit
  ): Promise<void> {
    await ApiClient.doFileRequest<void>(
      "post",
      `api/${endpoint}/${eventId}/Attending`,
      eventContactpersoon
    );
  }

  public static async deleteEvent(id: string): Promise<void> {
    await ApiClient.doHttpRequest<void>("delete", `api/${endpoint}/${id}`);
  }

  public static async createEvent(event: Event): Promise<void> {
    await ApiClient.doHttpRequest<void>("post", `api/${endpoint}`, event);
  }

  public static async sendEventReminder(id: string): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "put",
      `api/${endpoint}/${id}/SendReminder`
    );
  }

  public static async updateEvent(event: Event): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "put",
      `api/${endpoint}/${event.id}`,
      event
    );
  }

  public static async updateEventForUser(event: Event): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "put",
      `api/${endpoint}/UpdateEventForUser`,
      event
    );
  }

  public static async archiveSelectedEvents(
    selectedEvents: string[]
  ): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `api/${endpoint}/ArchiveSelectedEvents`,
      selectedEvents
    );
  }

  public static async exportSelectedEvents(
    selectedEvents: string[]
  ): Promise<void> {
    await ApiClient.doFileRequest(
      "post",
      `api/${endpoint}/ExportSelectedEvents`,
      selectedEvents,
      false,
      true,
      { responseType: "blob" }
    ).then((response) => {
      var blobUrl = window.URL.createObjectURL(response.data);
      var tempLink = document.createElement("a");
      tempLink.href = blobUrl;
      tempLink.setAttribute("download", "export.csv");
      tempLink.click();
    });
  }

  public static async sendCalendarInviteSelectedEvents(
    selectedEvents: string[]
  ): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `api/${endpoint}/SendCalendarInviteSelectedEvents`,
      selectedEvents
    );
  }

  public static async sendReminderSelectedEvents(
    selectedEvents: string[]
  ): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `api/${endpoint}/SendReminderSelectedEvents`,
      selectedEvents
    );
  }

  public static async sendInvitationSelectedEvents(
    selectedEvents: string[]
  ): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `api/${endpoint}/SendInvitationSelectedEvents`,
      selectedEvents
    );
  }

  public static async deleteEventBijlage(id: string): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "delete",
      `api/${endpoint}/EventBijlage/${id}`
    );
  }
}
