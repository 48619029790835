import * as React from "react";
import { FormCheckbox } from "../FormCheckbox";
import { connect } from "react-redux";
import { FormTextInput } from "../FormTextInput";
import required from "../../validators/required";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { mdiConsoleNetwork } from "@mdi/js";
import email from "../../validators/email";
import { Bedrijf, Contactpersoon, EventAttendee } from "../../types/model";
import { PersonType } from "../../constants/enums";
import { FormDropdown } from "../FormDropdown";

interface OwnProps {
  onCancel: () => void;
  onSubmit: (formValues: any) => void;
  formValues: any;
  attendee: EventAttendee;
  bedrijven: Bedrijf[];
  contactpersonen: Contactpersoon[];
  attendees: Contactpersoon[];
}

class EventAttendeeComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>
> {
  render() {
    const {
      handleSubmit,
      onSubmit,
      formValues,
      bedrijven,
      contactpersonen,
      attendees,
    } = this.props;

    let selectBedrijven = bedrijven.map(function (bedrijf) {
      return { label: bedrijf.name, value: bedrijf.id.toString() };
    });

    let selectContactPersonen = contactpersonen.map(function (contactpersoon) {
      return {
        label: contactpersoon.firstname + " " + contactpersoon.surname,
        value: contactpersoon.id.toString(),
      };
    });

    return (
      <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
        <div className="content" style={{ minHeight: 473 }}>
          <div className="columns contentPanel is-multiline is-mobile">
            <Field
              component={FormCheckbox}
              showSideBySide={true}
              label="Gast"
              readOnly={false}
              name="isGuest"
              className="checkbox"
            />

            {this.props.formValues && this.props.formValues.isGuest && (
              <>
                <Field
                  component={FormCheckbox}
                  showSideBySide={true}
                  label="Is lead"
                  readOnly={formValues && formValues.attendee.id != null}
                  name="attendee.isLead"
                  className="checkbox"
                />
                <Field
                  component={FormTextInput}
                  label="Voornaam"
                  name="attendee.firstname"
                  readOnly={false}
                  showSideBySide={true}
                  validate={required}
                />
                <Field
                  component={FormTextInput}
                  label="Achternaam"
                  name="attendee.surname"
                  readOnly={false}
                  showSideBySide={true}
                  validate={required}
                />
                <Field
                  component={FormTextInput}
                  label="Bedrijfsnaam"
                  name="attendee.companyName"
                  readOnly={false}
                  showSideBySide={true}
                  validate={required}
                />
                <Field
                  component={FormTextInput}
                  label="Emailadres"
                  name="attendee.email"
                  readOnly={false}
                  showSideBySide={true}
                  validate={
                    formValues &&
                    (formValues.attendee.isLead ||
                      formValues.attendee.email !== "")
                      ? [required, email]
                      : null
                  }
                />
                <Field
                  component={FormTextInput}
                  label="Telefoon nummer"
                  name="attendee.phoneNumber"
                  readOnly={false}
                  showSideBySide={true}
                  validate={null}
                />
                {formValues && formValues.attendee.isLead && (
                  <Field
                    component={FormDropdown}
                    label="Aangedragen door"
                    name="attendee.invitedById"
                    showSideBySide={true}
                    validate={required}
                    required={true}
                    options={selectBedrijven}
                  />
                )}
              </>
            )}
            {this.props.formValues && !this.props.formValues.isGuest && (
              <>
                <Field
                  component={FormDropdown}
                  label="Contactpersoon"
                  name="contactpersoonId"
                  showSideBySide={true}
                  validate={required}
                  required={true}
                  options={selectContactPersonen}
                />
              </>
            )}
          </div>
        </div>
      </Form>
    );
  }
}

const EventAttendeeReduxForm = reduxForm<{}, OwnProps>({
  form: "EventAttendeeForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventAttendeeComponent);

export default connect((state: any, props: any) => {
  return {
    initialValues: {
      isGuest: props.selectedAttendee ? true : false,
      attendee: {
        id: props.selectedAttendee ? props.selectedAttendee.id : null,
        isLead: props.selectedAttendee
          ? props.selectedAttendee.type === PersonType.Lead
          : false,
        firstname: props.selectedAttendee
          ? props.selectedAttendee.firstname
          : "",
        surname: props.selectedAttendee ? props.selectedAttendee.surname : "",
        email: props.selectedAttendee
          ? props.selectedAttendee.emailAddress
          : "",
        companyName: props.selectedAttendee
          ? props.selectedAttendee.companyName
          : "",
        phoneNumber: props.selectedAttendee
          ? props.selectedAttendee.phoneNumber
          : "",
        invitedById: props.selectedAttendee
          ? props.selectedAttendee.invitedBy
          : null,
      },
    },
    formValues: getFormValues("EventAttendeeForm")(state),
  };
})(EventAttendeeReduxForm);
