import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { EventAttendee, Bedrijf } from "../../types/model";
import { CSVLink } from "react-csv";
import { Modal } from "../Modal";
import EventAttendeeComponent from "../EventAttendeeComponent/EventAttendeeComponent";

interface OwnProps {
  columns: Array<Column>;
  attendees: EventAttendee[];
  bedrijven: Bedrijf[];
  onClick: (id: string, formValue: any) => void;
}

export default class EventAttendeesComponent extends React.Component<OwnProps> {
  render() {
    const { columns, attendees } = this.props;

    return <Gridview id="AttendeesList" onRowClick={this.props.onClick} columns={columns} data={attendees} addEditColumn={false} />;
  }
}
