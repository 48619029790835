import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bulma/css/bulma.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import userManager from "./utils/userManager";
import { store } from "./utils/store";

import resizeIcon from "./images/resize-expand-corner.png";
import { ContextProviders } from "./contexts/ContextProviders";
declare global {
  interface Window {
    store: any;
    editor: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

window.store = store;

fetch("/settings.json", { method: "GET" })
  .then((result) => result.json())
  .then(async (json) => {
    await localStorage.setItem("apiUrl", json.apiUrl);
    await localStorage.setItem("identityServerUrl", json.identityServerUrl);

    if (!userManager.settings.authority) {
      userManager.settings.authority = json.identityServerUrl;
    }

    ReactDOM.render(
      <ContextProviders>
        <Provider store={store}>
          <div className="mobile-error">
            <img src={resizeIcon} />
            <p>Dit schermformaat is te klein om de app te gebruiken.</p>
          </div>
          <OidcProvider store={store} userManager={userManager}>
            <App />
          </OidcProvider>
        </Provider>
      </ContextProviders>,
      document.getElementById("root")
    );
  })
  .catch((error) => console.error(error, "Failed to parse json"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
