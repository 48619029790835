import { Action } from "redux";
import { Filter, Sorting } from "../types/model";

export const SET_FILTER = "SET_FILTER";
export const SET_SORTING = "SET_SORTING";

export interface SetFilterAction extends Action<string> {
    gridviewId: string;
    filter: Filter;
}

export interface SetSortingAction extends Action<string> {
    gridviewId: string;
    sorting: Sorting;
}
