import React from "react";
import { ChartModel, Permissions } from "../../types/model";
import { Bar, Pie } from "react-chartjs-2";
import Gridview from "../Gridview/Gridview";
import { ShowPage } from "../../helpers/PermissionHelper";

export interface Props {
  sponsordealSoortStatistieken: ChartModel;
  sponsordealTotaalBedragStatistieken: ChartModel;
  genemuidenStatistieken: ChartModel;
  contactpersoonStatistics: ChartModel;
}

export default class StatistiekenComponent extends React.Component<Props> {
  render() {
    const {
      sponsordealSoortStatistieken,
      sponsordealTotaalBedragStatistieken,
      genemuidenStatistieken,
      contactpersoonStatistics,
    } = this.props;

    const colors: string[] = [
      "#00B0A6",
      "#007D73",
      "#00596D",
      "#6C7698",
      "#009BE3",
      "#0067AB",
      "#FFDD82",
      "#D46600",
      "#F63C50",
      "#739A77",
    ];

    const sponsordealData = {
      labels: sponsordealSoortStatistieken.labels,
      datasets: [
        {
          data: sponsordealSoortStatistieken.data,
          backgroundColor: colors,
          hoverBackgroundColor: colors,
        },
      ],
    };

    const sponsordealTotaalBedragData = {
      labels: sponsordealTotaalBedragStatistieken.labels,
      datasets: [
        {
          data: sponsordealTotaalBedragStatistieken.data,
          backgroundColor: colors,
          hoverBackgroundColor: colors,
        },
      ],
    };

    const genemuidenData = {
      labels: genemuidenStatistieken.labels,
      datasets: [
        {
          data: genemuidenStatistieken.data,
          backgroundColor: colors,
          hoverBackgroundColor: colors,
        },
      ],
    };

    const contactpersoonData = {
      labels: contactpersoonStatistics.labels,
      datasets: [
        {
          data: contactpersoonStatistics.data,
          backgroundColor: colors,
          hoverBackgroundColor: colors,
        },
      ],
    };

    return (
      <div className="content dashboard-content">
        <div className="dashboard-column-chart">
          <div className="chart-block chart-halve">
            <h3>Totaalbedrag per seizoen</h3>
            <Bar
              data={sponsordealTotaalBedragData}
              width={300}
              height={200}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
              legend={null}
            />
          </div>
        </div>

        <div className="dashboard-column-chart">
          <div className="chart-block chart-halve">
            <h3>Aantal sponsoren per soort</h3>
            <Pie
              data={sponsordealData}
              width={300}
              height={200}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem: any, data: any) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var total = dataset.data.reduce(function (
                        previousValue: any,
                        currentValue: any
                      ) {
                        return previousValue + currentValue;
                      },
                      0);
                      var currentValue = dataset.data[tooltipItem.index];
                      var percentage = Math.floor(
                        (currentValue / total) * 100 + 0.5
                      );
                      return currentValue + " (" + percentage + "%)";
                    },
                  },
                },
              }}
              legend={{
                position: "left",
              }}
            />
          </div>
        </div>

        <div className="dashboard-column-chart">
          <div className="chart-block chart-halve">
            <h3>Aantal sponsoren in/buiten Genemuiden</h3>
            <Pie
              data={genemuidenData}
              width={300}
              height={200}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem: any, data: any) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var total = dataset.data.reduce(function (
                        previousValue: any,
                        currentValue: any
                      ) {
                        return previousValue + currentValue;
                      },
                      0);
                      var currentValue = dataset.data[tooltipItem.index];
                      var percentage = Math.floor(
                        (currentValue / total) * 100 + 0.5
                      );
                      return currentValue + " (" + percentage + "%)";
                    },
                  },
                },
              }}
              legend={{
                position: "left",
              }}
            />
          </div>
        </div>

        <div className="dashboard-column-chart">
          <div className="chart-block chart-halve">
            <h3>Aantal (hoofd)contactpersonen/leads</h3>
            <Pie
              data={contactpersoonData}
              width={300}
              height={200}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem: any, data: any) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var total = dataset.data.reduce(function (
                        previousValue: any,
                        currentValue: any
                      ) {
                        return previousValue + currentValue;
                      },
                      0);
                      var currentValue = dataset.data[tooltipItem.index];
                      var percentage = Math.floor(
                        (currentValue / total) * 100 + 0.5
                      );
                      return currentValue + " (" + percentage + "%)";
                    },
                  },
                },
              }}
              legend={{
                position: "left",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
