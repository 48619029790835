import * as React from "react";

import { Bedrijf, Permissions } from "../../types/model";

import { CSVLink } from "react-csv";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openArchief: () => void;
  openModal: () => void;
  archiveToggleSelectedCompanies: () => void;
  exportSelectedCompanies: () => void;
  columns: Array<Column>;
  bedrijven: Bedrijf[];
  archiefButtonText: string;
  archiverenButtonText: string;
}

const isReadOnly = IsReadOnly(Permissions.ManageCompanies);
export default class BedrijvenComponent extends React.Component<OwnProps> {
  render() {
    const {
      onRowClick,
      openModal,
      openArchief,
      archiveToggleSelectedCompanies,
      exportSelectedCompanies,
      archiverenButtonText,
      archiefButtonText,
      columns,
      bedrijven,
    } = this.props;

    return (
      <>
        <div className="content">
          <div className="columns contentPanel">
            <div className="column is-full">
              <div className="buttons is-pulled-left">
                <button className="button is-success archive-button" onClick={openArchief}>
                  {archiefButtonText}
                </button>
              </div>
              <div className="buttons is-pulled-right">
                {!isReadOnly && (
                  <>
                    <button className="button is-success" onClick={archiveToggleSelectedCompanies}>
                      {archiverenButtonText}
                    </button>
                    <button className="button is-success" onClick={exportSelectedCompanies}>
                      Exporteren
                    </button>
                    <button className="button is-success add-button" onClick={openModal}>
                      Toevoegen
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="contentPanel">
            <Gridview id="BedrijvenList" onRowClick={onRowClick} columns={columns} data={bedrijven} />
          </div>
        </div>
      </>
    );
  }
}
