import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Permissions, Person } from "../../types/model";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openModal: () => void;
  generateIdentity: () => void;
  columns: Array<Column>;
  gebruikers: Array<Person>;
}

const isReadOnly = IsReadOnly(Permissions.ManageUsers);
export default class GebruikersComponent extends React.Component<OwnProps> {
  render() {
    const { onRowClick, openModal, columns, gebruikers, generateIdentity } = this.props;
    return (
      <>
        <div className="content">
          <div className="columns contentPanel">
            <div className="column is-full">
              {!isReadOnly && (
                <div className="buttons is-pulled-right">
                  <button className="button is-success add-button" onClick={openModal}>
                    Toevoegen
                  </button>
                  <button className="button is-success add-button" onClick={generateIdentity}>
                    Genereer logins
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="contentPanel">
            <Gridview id="GebruikersList" onRowClick={onRowClick} columns={columns} data={gebruikers} />
          </div>
        </div>
      </>
    );
  }
}
