export enum UserRol {
    Admin = "Admin",
    Beheerder = "Beheerder",
    Bestuurslid = "BESTUURSLID",
    Hoofdcontactpersoon = "HOOFDCONTACTPERSOON",
    Contactpersoon = "CONTACTPERSOON",
    ExternePartij = "EXTERNEPARTIJ"
}

export enum PersonType {
    Hoofdcontactpersoon,
    Contactpersoon,
    Partner,
    Gast,
    SysteemGebruiker,
    Lead
}
