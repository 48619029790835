import ApiClient from './ApiClient';
import { Nieuwsbericht } from "../types/model";
import { Guid } from 'guid-typescript';

const endpoint = 'api/news';

export default class NieuwsberichtApi {
    public static async getNieuwsberichten(): Promise<Nieuwsbericht[]> {
        var nieuwsberichten = await ApiClient.doHttpRequest<Nieuwsbericht[]>('get', `${endpoint}`);
        return nieuwsberichten;
    }

    public static async getNieuwsbericht(id: string): Promise<Nieuwsbericht> {
        var nieuwsbericht = await ApiClient.doHttpRequest<Nieuwsbericht>('get', `${endpoint}/${id}`);
        return nieuwsbericht;
    }

    public static async createNieuwsbericht(nieuwsbericht: Nieuwsbericht): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}`, nieuwsbericht);
    }

    public static async archiveNieuwsbericht(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('patch', `${endpoint}/${id}/archive`);
    }

    public static async updateNieuwsbericht(id:string | Guid, nieuwsbericht: Nieuwsbericht): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}/${id}`, nieuwsbericht);
    }

    public static async archiveSelectedNieuwsberichten(selectedNieuwsberichten: string[]): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/archiveSelectedNieuwsberichten`, selectedNieuwsberichten);
    }

    public static async deleteHoofdFotoBijlage(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `${endpoint}/attachment/${id}`);
    }

    public static async deleteNieuwsberichtFotoBijlage(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `${endpoint}/attachment/${id}`);
    }
}