import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import userManager from "../../utils/userManager";
import { WaitScreen } from "../WaitScreen";

const CallbackComponent: React.FC<RouteComponentProps> = ({ history }: RouteComponentProps) => {
  useEffect(() => {
    userManager
      .signinRedirectCallback()
      .then((user) => {
        history.push("/");
      })
      .catch((error) => console.log(error));
  }, [history]);

  return <WaitScreen />;
};

export default CallbackComponent;
