import React, { Fragment } from "react";
import Cropper from "react-easy-crop";
import Dropzone from "react-dropzone";

interface Props {
  disabled?: boolean;
  aspectRatio: number;
  quality: number; // between 0 and 1
  onSaveCrop: (fileName: string, file: string) => void;
}

interface State {
  fileName: string;
  imageToCrop: any;

  changedButNotSaved: boolean;
  saveCropButtonText: string;
  label: string;
  crop: { x: number; y: number };
  zoom: number;
  aspectRatio: number;
  croppedAreaPixels?: any;
  imageContentBase64: string;
  isCropping: boolean;
  quality: number;
}

export default class ImageCropper extends React.Component<Props, State> {
  state = {
    label: "Upload een afbeelding",
    saveCropButtonText: "Opslaan",
    imageToCrop: "",
    crop: { x: 0, y: 0 },
    zoom: 1,
    quality: this.props.quality ? this.props.quality : 1,
    aspectRatio: this.props.aspectRatio ? this.props.aspectRatio : 19 / 10,
    croppedAreaPixels: 0,
    imageContentBase64: "",
    isCropping: false,
    changedButNotSaved: true,
    fileName: "",
  };

  onCropChange = (crop: any) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  onZoomChange = (zoom: any) => {
    this.setState({ zoom });
  };

  onMediaLoaded = () => {
    this.setState({ changedButNotSaved: true });
  };

  onInteractionStart = () => {
    this.setState({ changedButNotSaved: true });
  };

  saveCrop = async () => {
    try {
      this.setState({
        isCropping: true,
      });
      const imgDataUrl = await this.getCroppedImg(this.state.imageToCrop, this.state.croppedAreaPixels);
      this.setState({
        //imageToCrop: imgDataUrl.split(","[1]),
        //zoom: 1,
        isCropping: false,
        changedButNotSaved: false,
      });
      this.props.onSaveCrop(this.state.fileName, imgDataUrl);
    } catch (e) {
      console.error(e);
      this.setState({
        isCropping: false,
      });
    }
  };

  onClose = async () => {
    this.setState({
      imageContentBase64: "",
    });
  };

  onDrop = (files: File[], rejectedFiles: File[]) => {
    if (files && files.length) {
      let label = "Upload een afbeelding";

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.setState({
            imageToCrop: event.target.result,
            fileName: file.name,
            crop: { x: 0, y: 0 },
            zoom: 1,
          });
        };
        reader.readAsDataURL(file);
      });
    }
  };

  createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
  };

  getCroppedImg = async (imageSrc: string, pixelCrop: any, rotation = 0): Promise<string> => {
    const image = (await this.createImage(imageSrc)) as any;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx != null) {
      const safeArea = Math.max(image.width, image.height) * 2;

      // set each dimensions to double largest dimension to allow for a safe area for the
      // image to rotate in without being clipped by canvas context
      canvas.width = safeArea;
      canvas.height = safeArea;

      // translate canvas context to a central location on image to allow rotating around the center.
      ctx.translate(safeArea / 2, safeArea / 2);
      ctx.rotate(this.getRadianAngle(rotation));
      ctx.translate(-safeArea / 2, -safeArea / 2);

      // draw rotated image and store data.
      ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
      const data = ctx.getImageData(0, 0, safeArea, safeArea);

      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      // paste generated rotate image with correct offsets for x,y crop values.
      ctx.putImageData(data, 0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x, 0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y);

      // As Base64 string
      return canvas.toDataURL("image/jpeg", this.state.quality);
    }
    return "";
  };

  render() {
    return (
      <div className="imageCropper">
        <div className="dropzone">
          <Dropzone multiple={false} disabled={false} onDrop={this.onDrop} maxSize={5000000}>
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone-label">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>
                    <span>{this.state.label}</span>
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        {this.state.imageToCrop && (
          <Fragment>
            <div className="crop-container">
              <Cropper
                image={this.state.imageToCrop}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspectRatio}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                onMediaLoaded={this.onMediaLoaded}
                onInteractionStart={this.onInteractionStart}
              />
            </div>
            <div className="controls">
              {/* <Slider
                            value={this.state.zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => this.onZoomChange(zoom)}
                            classes={{ container: 'slider' }}
                        /> */}
            </div>
            <br />
            {this.state.changedButNotSaved && (
              <button className="button is-success" onClick={this.saveCrop} disabled={this.state.isCropping}>
                {this.state.saveCropButtonText}
              </button>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}
