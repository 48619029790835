import * as actionTypes from "../constants/nieuwsberichtActionTypes";
import NieuwsberichtApi from '../api/NieuwsberichtApi';
import { GetNieuwsberichtenAction, GetNieuwsberichtAction } from "../constants/nieuwsberichtActionTypes";
import { AsyncAction } from ".";

export const getNieuwsberichten = (): AsyncAction<GetNieuwsberichtenAction> => {
    return async (dispatch) => {
        const nieuwsberichten = await NieuwsberichtApi.getNieuwsberichten();
        nieuwsberichten.sort((a,b) => a.date < b.date ? 1:-1)

        return dispatch({ type: actionTypes.GET_NIEUWSBERICHTEN, nieuwsberichten });
    };
}

export const getNieuwsbericht = (id: string): AsyncAction<GetNieuwsberichtAction> => {
    return async (dispatch) => {
        const nieuwsbericht = await NieuwsberichtApi.getNieuwsbericht(id);

        return dispatch({ type: actionTypes.GET_NIEUWSBERICHT, nieuwsbericht });
    };
}
