import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { TextArea } from "../TextArea";
import { FormDropdown } from "../FormDropdown";
import {
  Bedrijf,
  Verantwoordelijke,
  Address,
  Permissions,
} from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import email from "../../validators/email";
import { FormCheckbox } from "../FormCheckbox";
import integer from "../../validators/integer";
import length8 from "../../validators/length8";
import lengthmax6 from "../../validators/lengthmax6";
import { RootState } from "../../types/state";
import { CompanyTypeComponent } from "../CompanyTypeComponent/CompanyTypeComponent";
import postalcode from "../../validators/postalcode";
import postalcodeabroad from "../../validators/postalcodeabroad";
import { IsReadOnly } from "../../helpers/PermissionHelper";

export interface OwnProps {
  company?: Bedrijf;
  companyType: number;
  invoiceAddressPresent: boolean;
  verantwoordelijken: Verantwoordelijke[];
  bedrijfStatussen: [{ label: string; value: string }];
  onSubmit: (formValues: any) => void;
  onCancel: () => void;
  archiveBedrijf: (id: string) => void;
  isEdit?: boolean;
  formValues: any;
  searchParams: URLSearchParams;
}

export interface State {
  company?: Bedrijf;
  invoiceAddressPresentState?: boolean;
  searchParams: URLSearchParams;
}

const isReadOnly = IsReadOnly(Permissions.ManageCompanies);
class BedrijfComponent extends React.Component<
  OwnProps & State & InjectedFormProps<{}, OwnProps>,
  State
> {
  constructor(props: OwnProps & State & InjectedFormProps<{}, OwnProps>) {
    super(props);
    this.state = {
      searchParams: new URLSearchParams(document.location.search),
    };
  }
  invoiceAddressPresentToo = this.props.invoiceAddressPresent;

  onChangeSelected = (value: number) => {
    if (this.props.company) {
      this.props.company.type = value;
    }
  };

  onHasInvoiceAddressChanged = (value: boolean) => {
    if (value == true) {
      this.props.company!.invoiceAddress = this.props.company!.address;
    }
  };

  render() {
    const {
      handleSubmit,
      company,
      verantwoordelijken,
      archiveBedrijf,
      onCancel,
      isEdit = false,
      bedrijfStatussen,
      onSubmit,
      submitting,
    } = this.props;

    let title: any = "";
    let buttons: any = "";
    let factuurAdres: any = "";
    let verantwoordelijke: any = "";
    let status: any = "";
    let opmerking: any = "";

    let selectVerantwoordelijken = verantwoordelijken.map(function (
      verantwoordelijke
    ) {
      return {
        label: verantwoordelijke.fullName,
        value: verantwoordelijke.id.toString(),
      };
    });

    verantwoordelijke = (
      <Field
        component={FormDropdown}
        label="Verantwoordelijke"
        name="responsibleId"
        options={selectVerantwoordelijken}
        showSideBySide={true}
        validate={required}
        readOnly={isReadOnly}
        required={true}
      />
    );

    status = (
      <Field
        component={FormDropdown}
        label="Status"
        name="status"
        readOnly={isReadOnly}
        showSideBySide={true}
        options={bedrijfStatussen}
      />
    );

    opmerking = (
      <Field
        component={TextArea}
        label="Opmerking"
        readOnly={isReadOnly}
        name="comment"
        showSideBySide={true}
      />
    );

    if (isEdit) {
      title = (
        <div className="column subHeader is-full">
          <h1>Bedrijf</h1>
        </div>
      );

      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              {!isReadOnly && (
                <button
                  type="button"
                  className="button is-success delete-button"
                  onClick={() => archiveBedrijf(company!.id)}
                >
                  {this.state.searchParams.get("isArchived") === "true"
                    ? "Dearchiveren "
                    : "Archiveren"}
                </button>
              )}
              <button type="button" className="button" onClick={onCancel}>
                Annuleren
              </button>
              {!isReadOnly && (
                <button
                  type="submit"
                  disabled={
                    submitting ||
                    (this.props.company && this.props.company.isArchived)
                  }
                  className="button is-success"
                >
                  Opslaan
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (this.props.formValues && this.props.formValues.hasInvoiceAddress) {
      factuurAdres = (
        <>
          <Field
            component={FormTextInput}
            label="Naam"
            name="companyInvoiceSettings.name"
            showSideBySide={true}
            readOnly={isReadOnly}
            required={true}
            validate={required}
          />

          <Field
            component={FormTextInput}
            label="Emailadres"
            readOnly={isReadOnly}
            name="companyInvoiceSettings.emailAddress"
            showSideBySide={true}
            required={true}
            validate={[required, email]}
          />

          <Field
            component={FormTextInput}
            label="Straat"
            name="invoiceAddress.street"
            showSideBySide={true}
            required={true}
            validate={required}
          />

          <Field
            component={FormTextInput}
            label="Huisnr"
            readOnly={isReadOnly}
            name="invoiceAddress.houseNumber"
            showSideBySide={true}
            required={true}
            validate={[required, lengthmax6]}
          />

          <Field
            component={FormTextInput}
            label="Postcode"
            readOnly={isReadOnly}
            name="invoiceAddress.postalCode"
            showSideBySide={true}
            required={true}
            validate={[required, postalcode]}
          />

          <Field
            component={FormTextInput}
            label="Plaats"
            readOnly={isReadOnly}
            name="invoiceAddress.city"
            showSideBySide={true}
            required={true}
            validate={required}
          />
        </>
      );
    }

    return (
      <Form onSubmit={handleSubmit(onSubmit)} className="singleCompany">
        <div className="content">
          <div className="columns contentPanel is-multiline is-mobile">
            {title}

            <Field
              component={FormTextInput}
              label="Naam"
              name="name"
              readOnly={isReadOnly}
              showSideBySide={true}
              required={true}
              validate={[required]}
            />

            {status}

            <CompanyTypeComponent
              readonly={isReadOnly}
              company={company}
              onCompanyTypeChange={(value: number) =>
                this.onChangeSelected(value)
              }
            />

            <Field
              component={FormTextInput}
              label="Emailadres"
              readOnly={isReadOnly}
              name="emailAddress"
              showSideBySide={true}
              required={true}
              validate={[required, email]}
            />

            <Field
              component={FormTextInput}
              label="Telefoon"
              name="phoneNumber"
              readOnly={isReadOnly}
              showSideBySide={true}
              required={true}
              validate={[required]}
            />

            <Field
              component={FormTextInput}
              label="Straat"
              name="address.street"
              showSideBySide={true}
              required={true}
              validate={[required]}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Huisnr"
              name="address.houseNumber"
              showSideBySide={true}
              required={true}
              validate={[required, lengthmax6]}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Postcode"
              name="address.postalCode"
              showSideBySide={true}
              required={true}
              readOnly={isReadOnly}
              validate={[required, postalcodeabroad]}
            />

            <Field
              component={FormTextInput}
              label="Plaats"
              name="address.city"
              showSideBySide={true}
              required={true}
              validate={[required]}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Land"
              name="address.country"
              showSideBySide={true}
              required={true}
              validate={[required]}
              readOnly={isReadOnly}
            />

            {verantwoordelijke}
          </div>
          <div className="columns contentPanel is-multiline is-mobile">
            {/* At some point this checkbox needs to be redone */}
            <span className="checkboxWrapper">
              <Field
                component={FormCheckbox}
                label="Factuuradres ongelijk aan adres"
                name="hasInvoiceAddress"
                className="checkbox"
                onValueChanged={this.onHasInvoiceAddressChanged}
                readOnly={isReadOnly}
              />
            </span>

            <div className="border-spacer" />

            {factuurAdres}

            <Field
              component={FormTextInput}
              label="Website"
              name="website"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              type="string"
              label="Debiteurnr"
              name="debitNumber"
              required={false}
              validate={[integer]}
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              type="number"
              label="Kvk"
              name="kvkNumber"
              required={false}
              validate={[length8, integer]}
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            {opmerking}
          </div>
          <div className="floating-btn-bar">{buttons}</div>
        </div>
      </Form>
    );
  }
}

const BedrijfReduxForm = reduxForm<{}, OwnProps>({
  form: "BedrijfForm",
  enableReinitialize: true,
})(BedrijfComponent);

export default connect((state: State, props: any) => ({
  initialValues: {
    id: props.isEdit ? state.company && state.company.id : "",
    name: props.isEdit ? state.company && state.company.name : "",
    status: props.isEdit ? state.company && state.company.status : "0",
    type:
      props.isEdit && state.company && state.company.type
        ? state.company.type
        : (0 as number),
    emailAddress: props.isEdit
      ? state.company && state.company.emailAddress
      : "",
    phoneNumber: props.isEdit ? state.company && state.company.phoneNumber : "",
    responsibleId: props.isEdit
      ? state.company && state.company.responsibleId
      : props.verantwoordelijken.length > 0
      ? props.verantwoordelijken[0].id
      : "",
    website: props.isEdit ? state.company && state.company.website : "",
    debitNumber: props.isEdit
      ? state.company && state.company.debitNumber
      : null,
    kvkNumber: props.isEdit ? state.company && state.company.kvkNumber : null,
    comment: props.isEdit ? state.company && state.company.comment : "",

    addressId: props.isEdit ? state.company && state.company.addressId : null,
    address: {
      id: props.isEdit ? state.company && state.company.addressId : null,
      street: props.isEdit
        ? state.company && state.company.address
          ? state.company && state.company.address.street
          : ""
        : "",
      houseNumber: props.isEdit
        ? state.company && state.company.address
          ? state.company.address.houseNumber
          : ""
        : "",
      postalCode: props.isEdit
        ? state.company && state.company.address
          ? state.company.address.postalCode
          : ""
        : "",
      city: props.isEdit
        ? state.company && state.company.address
          ? state.company.address.city
          : ""
        : "",
      country: props.isEdit
        ? state.company && state.company.address
          ? state.company.address.country
          : ""
        : "",
    },
    hasInvoiceAddress: props.isEdit
      ? state.company && state.company.companyInvoiceSettings
        ? true
        : false
      : false,
    invoiceId: props.isEdit ? state.company && state.company.invoiceId : null,
    companyInvoiceSettingsId: props.isEdit
      ? state.company && state.company.companyInvoiceSettingsId
      : null,
    companyInvoiceSettings: {
      id: props.isEdit
        ? state.company && state.company.companyInvoiceSettingsId
        : null,
      name: props.isEdit
        ? state.company &&
          state.company.companyInvoiceSettings &&
          state.company.companyInvoiceSettings.name
        : "",
      emailAddress: props.isEdit
        ? state.company &&
          state.company.companyInvoiceSettings &&
          state.company.companyInvoiceSettings.emailAddress
        : "",
    },
    invoiceAddress: {
      id: props.isEdit ? state.company && state.company.invoiceId : null,
      street: props.isEdit
        ? state.company && state.company.invoiceAddress
          ? state.company.invoiceAddress.street
          : ""
        : "",
      houseNumber: props.isEdit
        ? state.company && state.company.invoiceAddress
          ? state.company.invoiceAddress.houseNumber
          : ""
        : "",
      postalCode: props.isEdit
        ? state.company && state.company.invoiceAddress
          ? state.company.invoiceAddress.postalCode
          : ""
        : "",
      city: props.isEdit
        ? state.company && state.company.invoiceAddress
          ? state.company.invoiceAddress.city
          : ""
        : "",
      country: "Nederland",
    },
    hoofdcontactpersoon: props.isEdit
      ? state.company && state.company.hoofdcontactpersoon
      : "",
    contactpersonen: props.isEdit
      ? state.company && state.company.contactpersonen
      : "",
  },
  formValues: getFormValues("BedrijfForm")(state),
  companyType:
    props.isEdit && state.company && state.company.type
      ? state.company.type
      : (0 as number),
}))(BedrijfReduxForm);
