import * as React from "react";
import { Form, Field, reduxForm, InjectedFormProps } from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { Person } from "../../types/model";
import confirmpassword from "../../validators/confirmpassword";
import { connect } from "react-redux";
import length6 from "../../validators/length6";
import containsnumber from "../../validators/containsnumber";

interface OwnProps {
  gebruiker?: Person;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isEdit?: boolean;
}

class WachtwoordWijzigenComponent extends React.Component<
  OwnProps & InjectedFormProps<{}, OwnProps>
> {
  render() {
    const { submitting, onSubmit, handleSubmit, onCancel } = this.props;

    return (
      <div className="content">
        <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
          <div className="columns contentPanel is-multiline is-mobile">
            <div className="column subHeader is-full">
              <h1>Wachtwoord wijzigen</h1>
            </div>

            <Field
              component={FormTextInput}
              label="Oud wachtwoord"
              name="gebruiker.oudwachtwoord"
              showSideBySide={true}
              validate={[length6, containsnumber, confirmpassword]}
              type="password"
            />

            <Field
              component={FormTextInput}
              label="Nieuw wachtwoord"
              name="gebruiker.wachtwoord"
              showSideBySide={true}
              validate={[length6, containsnumber, confirmpassword]}
              type="password"
            />

            <Field
              component={FormTextInput}
              label="Herhaal nieuw wachtwoord"
              name="gebruiker.wachtwoord2"
              showSideBySide={true}
              validate={[length6, containsnumber, confirmpassword]}
              type="password"
            />
          </div>

          <div className="floating-btn-bar">
            <div className="columns contentPanel">
              <div className="column is-full">
                <div className="buttons is-pulled-right">
                  <button type="button" className="button " onClick={onCancel}>
                    Annuleren
                  </button>
                  <button
                    type="submit"
                    disabled={submitting}
                    className="button is-success"
                  >
                    Opslaan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const WachtwoordWijzigenReduxForm = reduxForm<{}, OwnProps>({
  form: "WachtwoordWijzigenForm",
  enableReinitialize: true,
})(WachtwoordWijzigenComponent);

export default connect((state: any, props: any) => ({
  initialValues: {
    gebruiker: {
      wachtwoord: "",
      wachtwoord2: "",
      oudwachtwoord: "",
    },
  },
}))(WachtwoordWijzigenReduxForm);
