import React, { ChangeEvent } from "react";
import { SponsordealList, ListEvent, Permissions } from "../../types/model";
import Gridview from "../Gridview/Gridview";
import { Column, RowInfo } from "react-table";
import { ShowPage } from "../../helpers/PermissionHelper";

export interface Props {
  onSponsordealRowClick: (event: ChangeEvent, rowInfo: RowInfo) => void;
  onEventRowClick: (event: ChangeEvent, rowInfo: RowInfo) => void;
  onOverzichtRowClick: (event: ChangeEvent, rowInfo: RowInfo) => void;
  overzichtColumns: Array<Column>;
  aflopendeContractenColumns: Array<Column>;
  aankomendeEventsColumns: Array<Column>;
  sponsordeallist: SponsordealList[];
  events: ListEvent[];
  overzichtViewNames: { naam: string }[];
}

export default class DashboardComponent extends React.Component<Props> {
  render() {
    const {
      onSponsordealRowClick,
      onEventRowClick,
      onOverzichtRowClick,
      aflopendeContractenColumns,
      aankomendeEventsColumns,
      sponsordeallist,
      events,
      overzichtViewNames,
      overzichtColumns,
    } = this.props;

    return (
      <div className="content dashboard-content">
        {ShowPage([Permissions.ReadEvents, Permissions.ManageEvents]) && (
          <div className="dashboard-column">
            <div className="dashboard-grid-view">
              <h3>Aankomende events</h3>
              <Gridview
                id="AankomendeEventsList"
                onRowClick={onEventRowClick}
                columns={aankomendeEventsColumns}
                data={events}
              />
            </div>
          </div>
        )}
        {ShowPage([
          Permissions.ReadSponsorDeals,
          Permissions.ManageSponsorDeals,
        ]) && (
          <div className="dashboard-column">
            <div className="dashboard-grid-view">
              <h3>Aflopende contracten</h3>
              <Gridview
                id="AflopendeContractenList"
                onRowClick={onSponsordealRowClick}
                columns={aflopendeContractenColumns}
                data={sponsordeallist}
              />
            </div>
          </div>
        )}

        <div className="dashboard-column">
          <div className="dashboard-grid-view">
            <h3>Download Overzichten</h3>
            <Gridview
              id="OverzichtenList"
              onRowClick={onOverzichtRowClick}
              columns={overzichtColumns}
              data={overzichtViewNames}
            />
          </div>
        </div>
      </div>
    );
  }
}
