import { Bedrijf, Contactpersoon } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import React, { ChangeEvent } from "react";
import { change, getFormValues, submit } from "redux-form";
import { getPartners, getPartnersForHoofdcontactpersoon } from "../actions/contactpersoonActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import ContactpersoonApi from "../api/ContactpersoonApi";
import { Modal } from "../components/Modal";
import PartnerComponent from "../components/PartnerComponent/PartnerComponent";
import PartnersComponent from "../components/PartnersComponent/PartnersComponent";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getBedrijven } from "../actions/bedrijfActions";
import history from '../components/history';
import matchSorter from "match-sorter";
import { toast } from "react-toastify";

interface DispatchProps {
    getPartners: (companyId: string, archived: boolean) => any;
    getPartnersForHoofdcontactpersoon: () => any;
    submitForm: (form: string) => any;
    getBedrijven: () => any;
    updateFormValue: (field: string, value: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    partners: Contactpersoon[];
    bedrijven: Bedrijf[];
    contactpersoonSoorten: [{ label: string; value: string }];
    formValues: Contactpersoon
}

interface OwnProps {
    bedrijfId?: string;
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
    showModal: boolean
}

const searchParams = new URLSearchParams(document.location.search)
class PartnersContainer extends React.Component<Props, State> {
    state: State = {
        showModal: false
    }

    componentDidMount() {
        this.props.getPartners(this.props.bedrijfId ? this.props.bedrijfId : '', searchParams.get("isArchived") === 'true');
        this.props.getBedrijven();
    }

    onRowClick(event: ChangeEvent, rowInfo: RowInfo) {
        history.push("/Partners/" + rowInfo.original.id);
    }

    openModal = () => {
        this.setState({ showModal: true });
    }

    createPartner = (formValues: Contactpersoon) => {
        var partner = formValues;
        const userRol = localStorage.getItem("userRol");

        this.props.showLoading();
        ContactpersoonApi.createPartner(partner)
            .then(() => this.props.getPartners(this.props.bedrijfId ? this.props.bedrijfId : '', searchParams.get("isArchived") === 'true') && toast.success("Het opslaan is succesvol"))
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => this.props.hideLoading());
        this.setState({ showModal: false });
    }

    updateFormValue = (field: string, value: string) => {
        this.props.updateFormValue(field, value);
    }

    onClose = () => {
        this.setState({ showModal: false });
    }

    render() {
        const { partners, bedrijven, contactpersoonSoorten, formValues, bedrijfId } = this.props;

        let columns: Array<Column> = [
            {
                Header: "Voornaam",
                accessor: "firstname",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Achternaam",
                accessor: "surname",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Bedrijf",
                accessor: "companyName",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Telefoonnummer",
                accessor: "phoneNumber",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Straat",
                accessor: "address.street",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Huisnummer",
                accessor: "address.houseNumber",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Postcode",
                accessor: "address.postalCode",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Plaats",
                accessor: "address.city",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]

        let partnersList = partners;
        if (bedrijfId)
            partnersList = partnersList.filter(p => p.companyId == bedrijfId);

        return (
            <>
                <PartnersComponent onRowClick={this.onRowClick} openModal={this.openModal} columns={columns} partners={partnersList} />
                {
                    this.state.showModal
                        ? (<Modal
                            title="Nieuwe partner"
                            content={
                                <PartnerComponent contactpersoonSoorten={contactpersoonSoorten} bedrijven={bedrijven} companyId={bedrijfId} formValues={formValues} updateFormValue={this.updateFormValue} onSubmit={this.createPartner} />
                            }
                            onSubmit={() => this.props.submitForm("PartnerForm")}
                            onClose={this.onClose} />
                        )
                        : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    partners: state.partners,
    bedrijven: state.bedrijven,
    contactpersoonSoorten: state.contactpersoonSoorten,
    formValues: getFormValues("PartnerForm")(state) as Contactpersoon
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getPartners: (companyId: string, archived: boolean) => dispatch(getPartners(companyId, archived)),
    getPartnersForHoofdcontactpersoon: () => dispatch(getPartnersForHoofdcontactpersoon()),
    submitForm: (form: string) => dispatch(submit(form)),
    getBedrijven: () => dispatch(getBedrijven()),
    updateFormValue: (field: string, value: string) => dispatch(change("PartnerForm", field, value)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersContainer);
