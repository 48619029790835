import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormDropdown } from "../FormDropdown";
import { connect } from "react-redux";
import required from "../../validators/required";
import { LabelInput } from "../LabelInput";
import { RootState } from "../../types/state";
import { FormTextInput } from "../FormTextInput";
import { HiddenInput } from "../HiddenInput";

export interface OwnProps {
  onSubmit: (formValues: any) => void;
  isModal?: boolean;
  seizoenen: [{ label: string; value: number }];
  ids: string[];
  formValues: any;
}

class SponsordealsFacturerenComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>
> {
  render() {
    const { handleSubmit, onSubmit, formValues, submitting, seizoenen, ids } =
      this.props;

    return (
      <div>
        <Field
          component={FormDropdown}
          label="Seizoen"
          name="season"
          options={seizoenen}
          required={true}
          validate={required}
        />

        <Field component={FormTextInput} label="Kenmerk" name="distinction" />
      </div>
    );
  }
}

const SponsordealReduxForm = reduxForm<{}, OwnProps>({
  form: "SponsordealsFacturerenForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SponsordealsFacturerenComponent);

export default connect((state: any, props: any) => ({
  formValues: getFormValues("SponsordealsFacturerenForm")(state),
}))(SponsordealReduxForm);
