import * as React from "react";
import { NavLink } from "react-router-dom";
import { ShowPage } from "../helpers/PermissionHelper";
import { Permissions } from "../types/model";

interface State {
  showMenu: boolean;
}

export default class FoldableSettingsMenu extends React.Component<{}, State> {
  state = {
    showMenu: false,
  };

  onClickMenu = (event: React.MouseEvent) => {
    this.setState({ showMenu: this.state.showMenu ? false : true });
  };

  render() {
    const isVisibleSponsorDealKinds = ShowPage([Permissions.ReadSponsorDealKinds, Permissions.ManageSponsorDealKinds]);
    const isVisibleProducts = ShowPage([Permissions.ReadProducts, Permissions.ManageProducts]);
    const isVisibleUsers = ShowPage([Permissions.ReadUsers, Permissions.ManageUsers]);
    const isVisibleRoles = ShowPage([Permissions.ReadRoles, Permissions.ManageRoles]);

    return (
      (isVisibleSponsorDealKinds || isVisibleProducts || isVisibleUsers || isVisibleRoles) && (
        <div className="menu-list">
          <a onClick={this.onClickMenu}>Instellingen</a>
          {this.state.showMenu && (
            <ul>
              {/* {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid, UserRol.Hoofdcontactpersoon, UserRol.Contactpersoon]) &&
                            <NavLink className="navbar-item" activeClassName="is-active" to="/WachtwoordWijzigen">
                                Wachtwoord wijzigen
                            </NavLink>
                        } */}
              {/* {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder]) &&
                            <NavLink className="navbar-item" activeClassName="is-active" to="/Settings">
                                Beheerder settings
                            </NavLink>
                        } */}
              {isVisibleSponsorDealKinds && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Sponsordealsoorten">
                  Beheer sponsordealsoorten
                </NavLink>
              )}
              {isVisibleProducts && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Producten">
                  Beheer producten
                </NavLink>
              )}
              {isVisibleUsers && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Gebruikers">
                  Beheer gebruikers
                </NavLink>
              )}
              {isVisibleRoles && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Rollen">
                  Beheer rollen
                </NavLink>
              )}
            </ul>
          )}
        </div>
      )
    );
  }
}
