import React, { useState } from "react";

export const ApplicationContext = React.createContext({
  loading: false,
  setLoading: (loading: boolean) => {},
});

export const ApplicationContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const contextValue = {
    loading,
    setLoading,
  };

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
    </ApplicationContext.Provider>
  );
};
