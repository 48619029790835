import GebruikerComponent from "../components/GebruikerComponent/GebruikerComponent";
import React from "react";
import { Person } from "../types/model";
import { connect } from "react-redux";
import GebruikerApi from '../api/GebruikerApi';
import { AnyAction } from "redux";
import { RootState } from '../types/state';
import { Role } from '../types/model';
import { ThunkDispatch } from "redux-thunk";
import history from "../components/history";
import { toast } from "react-toastify";
import { getContactPersoonByEmail, getGebruiker, getRollen } from "../actions/gebruikerActions";
import { showLoading, hideLoading } from "../actions/loadingActions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { change } from "redux-form";

interface DispatchProps {
    getGebruiker: (id: string) => any;
    getRollen: () => any;
    updateFormValue: (field: string, value: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    gebruiker: Person;
    rollen: Role[];
}

interface MatchParams {
    id: string
}

class GebruikerContainer extends React.Component<DispatchProps & StateProps & RouteComponentProps<MatchParams>> {
    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getGebruiker(this.props.match.params.id);
            this.props.getRollen();
        }
    }

    updateGebruiker = async (formValues: any) => {
        var gebruiker = formValues.gebruiker as Person;
        this.props.showLoading();
        await GebruikerApi.updateGebruiker(gebruiker)
            .then(() => toast.success("Het opslaan is succesvol"))
            .catch(err => toast.error(err.response.data))
            .finally(() => this.props.hideLoading());
        history.goBack();
    }

    searchPersoonByEmail = async (emailAddress: string) => {
        var returnValue = false;
        await GebruikerApi.getContactPersoonByEmail(emailAddress).then((person) => {
            this.props.updateFormValue("gebruiker.firstname", person.firstname);
            this.props.updateFormValue("gebruiker.surname", person.surname);
            this.props.updateFormValue("gebruiker.jobTitle", person.jobTitle);
            this.props.updateFormValue("gebruiker.type", person.type);
            returnValue = true;
        })
            .catch(err => { });
        return returnValue;
    }

    onCancel = () => {
        history.goBack();
    }

    render() {
        const { gebruiker, rollen, updateFormValue } = this.props;

        return (
            <GebruikerComponent rollen={rollen} searchPersonByEmail={this.searchPersoonByEmail} updateFormValue={updateFormValue} gebruiker={gebruiker} isEdit={true} onCancel={this.onCancel} onSubmit={this.updateGebruiker} />
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    gebruiker: state.gebruiker as Person,
    rollen: state.rollen as Role[],
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getGebruiker: (id: string) => dispatch(getGebruiker(id)),
    getRollen: () => dispatch(getRollen()),
    updateFormValue: (field: string, value: string) => dispatch(change("GebruikerForm", field, value)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GebruikerContainer));
