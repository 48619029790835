import { Action } from "redux";
import { Notation } from "../types/model";

export const GET_NOTULEN = "GET_NOTULEN";
export const GET_NOTATION = "GET_NOTATION";

export interface GetNotulenAction extends Action<string> {
    notulen: Notation[];
}

export interface GetNotuleAction extends Action<string> {
    notule: Notation;
}
