import React from "react";
import SettingsComponent from "../components/SettingsComponent/SettingsComponent";
import { Settings, Person, Verantwoordelijke } from "../types/model";
import { getSettings } from "../actions/settingsActions";
import { getVerantwoordelijken } from "../actions/gebruikerActions";
import history from "../components/history";
import { AnyAction } from "redux";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import { submit } from "redux-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SettingsApi from "../api/SettingsApi";

interface DispatchProps {
  getSettings: () => any;
  getVerantwoordelijken: () => any;
  submitForm: (form: string) => any;
}

interface StateProps {
  settings: Settings;
  verantwoordelijken: Verantwoordelijke[];
}

class SettingsContainer extends React.Component<DispatchProps & StateProps> {
  componentDidMount() {
    this.props.getSettings();
    this.props.getVerantwoordelijken();
  }

  onDeleteLogo = (id: number) => {
    SettingsApi.deleteLogo().then(() => {
      this.props.getSettings();

      toast.success("Het verwijderen is succesvol");
    });
  };

  updateSettings = (formValues: Settings) => {
    var settings = formValues;

    SettingsApi.updateSettings(settings)
      .then(() => {
        this.props.getSettings();
        toast.success("Het opslaan is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => {
        history.goBack();
      });
  };

  resendUserActivation = () => {
    if (
      !window.confirm(
        "Weet u zeker dat u alle activatie emails opnieuw wilt versturen?"
      )
    )
      return;

    SettingsApi.ResendActionEmails()
      .then(() =>
        toast.success("De activatie emails zijn successvol verzonden")
      )
      .catch(() => toast.error("Er is een fout opgetreden"));
  };

  render() {
    const { settings, verantwoordelijken } = this.props;

    return (
      <SettingsComponent
        verantwoordelijken={verantwoordelijken}
        settings={settings}
        onDeleteLogo={this.onDeleteLogo}
        onSubmit={this.updateSettings}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  settings: state.settings,
  verantwoordelijken: state.verantwoordelijken,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getSettings: () => dispatch(getSettings()),
  getVerantwoordelijken: () => dispatch(getVerantwoordelijken()),
  submitForm: (form: string) => dispatch(submit(form)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
