import React from "react";
import { ChartModel } from "../../types/model";
import StatisticsApi from "../../api/StatistiekenApi";

export const useSatistics = () => {
  const [sponsorDealTypeStatistics, setSponsorDealTypeStatistics] =
    React.useState<ChartModel>({ data: [], labels: [] });
  const [
    sponsorDealTotalAmountStatistics,
    setSponsorDealTotalAmountStatistics,
  ] = React.useState<ChartModel>({ data: [], labels: [] });
  const [localStatistics, setLocalStatistics] = React.useState<ChartModel>({
    data: [],
    labels: [],
  });
  const [contactPersonStatistics, setContactPersonStatistics] =
    React.useState<ChartModel>({ data: [], labels: [] });

  const getSponsorDealTypeStatistics = async (force = false) => {
    if (!force && sponsorDealTypeStatistics.data.length > 0)
      return sponsorDealTypeStatistics;
    const data = await StatisticsApi.getSponsordealSoortStatistieken();
    setSponsorDealTypeStatistics(data);
    return data;
  };

  const getSponsorDealTotalAmountStatistics = async (force = false) => {
    if (!force && sponsorDealTotalAmountStatistics.data.length > 0)
      return sponsorDealTotalAmountStatistics;
    const data = await StatisticsApi.getSponsordealTotaalBedragStatistieken();
    setSponsorDealTotalAmountStatistics(data);
    return data;
  };

  const getLocalStatistics = async (force = false) => {
    if (!force && localStatistics.data.length > 0) return localStatistics;
    const data = await StatisticsApi.getGenemuidenStatistieken();
    setLocalStatistics(data);
    return data;
  };

  const getContactPersonStatistics = async (force = false) => {
    if (!force && contactPersonStatistics.data.length > 0)
      return contactPersonStatistics;
    const data = await StatisticsApi.getContactpersoonStatistics();
    setContactPersonStatistics(data);
    return data;
  };

  return {
    getSponsorDealTotalAmountStatistics,
    getSponsorDealTypeStatistics,
    getLocalStatistics,
    getContactPersonStatistics,
  };
};
