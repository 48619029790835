import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

interface Props {
  disabled?: boolean;
  accept: string;
  multiple?: boolean;
  onChangeUpload: (fileName: string, file: string) => void;
}

interface State {
  label: string;
  imgContent: string;
}

export default class FileUpload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      label: "Upload een bestand",
      imgContent: "",
    };
  }

  onDrop = (files: File[], rejectedFiles: File[]) => {
    if (rejectedFiles.length > 0) {
      toast.error("Bestandstype niet correct of limiet van 5MB overschreden.");
    }

    if (files && files.length) {
      let label = "Upload een bestand";

      files.forEach((file) => {
        const reader = new FileReader();
        label = file.name;
        reader.onload = (event: any) => {
          this.props.onChangeUpload(file.name, event.target.result.split(",")[1]);
          if (files.length == 1 && file.type.includes("image")) {
            this.setState({ label, imgContent: event.target.result });
          } else {
            this.setState({ label, imgContent: "" });
          }
        };
        reader.readAsDataURL(file);
      });

      if (files.length > 1) {
        label = files.length + " bestanden geselecteerd";
      }
    }
  };

  render() {
    const { accept, multiple = true, disabled = false } = this.props;

    return (
      <div className="dropzone">
        <Dropzone multiple={multiple} disabled={disabled} onDrop={this.onDrop} maxSize={5000000} accept={accept}>
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone-label">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {this.state.imgContent != "" && <img src={this.state.imgContent} className="file-upload-img" />}
                {this.state.imgContent == "" && (
                  <p>
                    <span>{this.state.label}</span>
                  </p>
                )}
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}
