import { ActionCreator } from "redux";
import { loadUser } from "redux-oidc";
import { ThunkAction } from "redux-thunk";
import { store } from "../utils/store";
import userManager from "../utils/userManager";
import { User } from "oidc-client";
import * as actionTypes from "../constants/applicationActionTypes";
import { RootState } from "../types/state";
import { InitializeAction } from "../constants/applicationActionTypes";

export const initialize: ActionCreator<
  ThunkAction<Promise<any>, RootState, {}, InitializeAction>
> = () => async (dispatch) => {
  // Get the user from local storage.
  let user: User | undefined;

  try {
    user = await loadUser(store, userManager);
  } catch (err) {
    // Failed to retrieve a user from local storage.
    userManager.signinRedirect();
    return;
  }

  if (!user) {
    // No existing user found, redirect to login page.
    userManager.signinRedirect();
    return;
  }

  if (user.expired) {
    // User found but the token is expired. Attempt a silent login.
    try {
      await userManager.signinSilent();
    } catch (err) {
      // Silent log in failed, redirect to login page.
      userManager.signinRedirect();
      return;
    }
  }

  if (user.profile.permissions)
    localStorage.setItem(
      "permissions",
      JSON.stringify(
        user.profile.permissions.split(",").map((e: string) => parseInt(e))
      )
    );
  localStorage.setItem("token", user.access_token);
  localStorage.setItem("naam", user && user.profile && user.profile.name[1]);
  localStorage.setItem("userRol", user.profile.role);

  return dispatch({ type: actionTypes.SET_INITIALIZED, initialized: true });
};
