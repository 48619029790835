import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
  change,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import {
  Nieuwsbericht,
  BijlageUrl,
  Bijlage,
  Permissions,
} from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import * as BtwTarief from "../../constants/BtwTarief";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import TextEditor from "../TextEditor";
import { FormCheckbox } from "../FormCheckbox";
import FormDatePicker from "../FormDatePicker";
import {
  DocumentListHelper,
  DocumentListHelperTwo,
} from "../../helpers/DocumentListHelper";
import FileUpload from "../FileUpload";
import { RootState } from "../../types/state";
import { HiddenInput } from "../HiddenInput";
import ImageCropper from "../ImageCropper";
import { IsReadOnly } from "../../helpers/PermissionHelper";
import moment from "moment";
import openImage from "../../images/bin.svg";

export interface OwnProps {
  nieuwsbericht?: Nieuwsbericht;
  formValues: Nieuwsbericht;
  onDeleteHoofdFotoBijlage: (id: string) => void;
  onDeleteNieuwsberichtFotoBijlage: (id: string) => void;
  onCancel: () => void;
  onSubmit: (formValues: Nieuwsbericht, editorState: EditorState) => void;
  isEdit?: boolean;
}

interface State {
  editorState: EditorState;
}

interface DispatchProps {
  dispatch: any;
  updateFormValue: (field: string, value: any) => any;
}

type Props = OwnProps & DispatchProps;

const isReadOnly = IsReadOnly(Permissions.ManageNews);
class NieuwsberichtComponent extends React.Component<
  Props & InjectedFormProps<{}, any>,
  State
> {
  constructor(props: Props & InjectedFormProps<{}, any>) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidUpdate = (prevProps: any) => {
    if (prevProps != this.props && this.props.formValues && this.props.isEdit) {
      if (
        this.props.formValues.item &&
        this.props.formValues.item.description != undefined
      ) {
        const blocksFromHtml = htmlToDraft(
          this.props.formValues.item.description
        );
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);

        this.setState({ editorState });
      }
    }
  };

  onSubmitForm = (formValues: Nieuwsbericht, editorState: EditorState) => {
    this.props.onSubmit(formValues, editorState);
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  createDocumentList = (
    url: string,
    id: string | undefined,
    filename?: string
  ) => {
    if (!filename) {
      var filename = url ? url.split("/").pop() : undefined;
      if (filename == undefined) filename = "";
    }

    return (
      <div className="custom-docs event-docs">
        <a href={url} target="_blank">
          {decodeURIComponent(filename)}
        </a>
        {id && (
          <a
            className="gridIcon"
            onClick={() => this.props.onDeleteHoofdFotoBijlage(id)}
          >
            <img src={openImage} alt="Delete" />
          </a>
        )}
      </div>
    );
  };

  onChangeUploadNieuwsberichtFotoBijlagen = (
    fileName: string,
    file: string
  ) => {
    var existsInNieuwsberichtFotoBijlageUrls =
      this.props.formValues.item.attachments.find(
        (attachment: Bijlage) =>
          decodeURI(attachment.content.split("/").pop()!) === fileName
      );
    var existsInNieuwsberichtFotoBijlagen =
      this.props.formValues.item.attachments.find(
        (bijlage: Bijlage) => bijlage.title === fileName
      );

    const attachmentProps = this.props.formValues.item.attachments;

    if (existsInNieuwsberichtFotoBijlageUrls) {
      if (
        window.confirm(
          "Er bestaat al een bijlage met deze bestandsnaam, weet u zeker dat u deze wilt overschrijven?"
        )
      ) {
        attachmentProps.push({
          id: undefined,
          type: 2,
          title: fileName,
          content: file,
          overwrite: true,
          url: "",
          isArchived: false,
        });
        this.props.updateFormValue("item.attachments", attachmentProps);
      }
    }
    if (!existsInNieuwsberichtFotoBijlagen) {
      attachmentProps.push({
        id: undefined,
        type: 2,
        title: fileName,
        content: file,
        overwrite: false,
        url: "",
        isArchived: false,
      });
      this.props.updateFormValue("item.attachments", attachmentProps);
    }
  };

  onHoofdFotoSave = (fileName: string, fileDataURL: string) => {
    let file = fileDataURL.split(",")[1];

    let hoofdFotoCollection = this.props.formValues.item.attachments.filter(
      (value) => value.type === 1
    );
    let hoofdFoto =
      hoofdFotoCollection.length > 0 ? hoofdFotoCollection[0] : undefined;

    const attachmentProps = this.props.formValues.item.attachments;
    if (hoofdFoto && hoofdFoto.content.split("/").pop() === fileName) {
      attachmentProps.push({
        id: undefined,
        type: 1,
        title: fileName,
        content: file,
        overwrite: true,
        url: "",
        isArchived: false,
      });
      this.props.updateFormValue("item.attachments", attachmentProps);
    } else {
      attachmentProps.push({
        id: undefined,
        type: 1,
        title: fileName,
        content: file,
        overwrite: false,
        url: "",
        isArchived: false,
      });
      this.props.updateFormValue("item.attachments", attachmentProps);
    }
  };

  render() {
    const {
      handleSubmit,
      nieuwsbericht,
      formValues,
      isEdit = false,
      submitting,
      onDeleteHoofdFotoBijlage,
      onDeleteNieuwsberichtFotoBijlage,
      onCancel,
      onSubmit,
    } = this.props;

    let nieuwsberichtFotoBijlageUrls: any = "";
    let hoofdFotoUrl: any = "";

    if (
      formValues &&
      formValues.item &&
      formValues.item.attachments &&
      formValues.item.attachments.length > 0
    ) {
      let attachments = formValues.item.attachments;

      nieuwsberichtFotoBijlageUrls = attachments
        .filter((value) => value.type === 2)
        .map((nieuwsberichtFotoBijlage: Bijlage) => {
          return DocumentListHelperTwo({
            bijlage: nieuwsberichtFotoBijlage,
            onDeleteBijlage: onDeleteNieuwsberichtFotoBijlage,
            showDelete: true,
          });
        });

      let hoofdFoto =
        attachments.length > 0 &&
        attachments.filter((value) => value.type === 1).length > 0
          ? attachments.filter((value) => value.type === 1)[0]
          : undefined;
      hoofdFotoUrl =
        hoofdFoto &&
        hoofdFoto.id &&
        this.createDocumentList(
          hoofdFoto.content,
          hoofdFoto.id.toString(),
          hoofdFoto.title
        );
    }

    return (
      <div className="content notulen">
        <Form
          className="singleCompany"
          onSubmit={handleSubmit((values: any) =>
            onSubmit(values, this.state.editorState)
          )}
        >
          <div className="columns contentPanel is-multiline is-mobile">
            <Field component={HiddenInput} type="hidden" name="id" />
            <Field component={HiddenInput} type="hidden" name="itemId" />
            <Field component={HiddenInput} type="hidden" name="item.id" />
            <Field
              component={FormTextInput}
              label="Titel"
              name="item.title"
              showSideBySide={true}
              required={true}
              readOnly={isReadOnly}
              validate={required}
            />

            <Field
              component={FormDatePicker}
              label="Datum"
              name="date"
              showSideBySide={true}
              showTimeFormat={true}
              required={true}
              readOnly={isReadOnly}
              validate={required}
            />

            <Field
              component={FormTextInput}
              label="Auteur"
              name="author"
              showSideBySide={true}
              required={true}
              readOnly={isReadOnly}
              validate={required}
            />

            <div className="checkboxNieuwsbericht">
              <Field
                component={FormCheckbox}
                label="Topbericht"
                name="topBericht"
                className="checkbox"
                readOnly={isReadOnly}
                showSideBySide={true}
              />
            </div>

            <div className="checkboxNieuwsbericht">
              <Field
                component={FormCheckbox}
                label="Gesponsord bericht"
                name="gesponsordBericht"
                className="checkbox"
                readOnly={isReadOnly}
                showSideBySide={true}
              />
            </div>
            <div className="checkboxNieuwsbericht">
              <Field
                component={FormCheckbox}
                label="Mogelijkheid tot reageren"
                name="possibilityToRespond"
                className="checkbox"
                readOnly={isReadOnly}
                showSideBySide={true}
              />
            </div>

            <div className="wysiwyg-edit">
              <Field
                component={TextEditor}
                label="Content"
                name="item.description"
                readOnly={isReadOnly}
                editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
              />
            </div>

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-uploaded-files">
              <div className="field-label">
                <label className="label bcwLabel">Hoofdfoto</label>
              </div>

              {hoofdFotoUrl}
            </div>

            {!isReadOnly && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-uploaded-files">
                <div className="field-label">
                  <label className="label bcwLabel ">Upload hoofdfoto</label>
                </div>
                <Field
                  component={ImageCropper}
                  label="Upload hoofdfoto"
                  name="item.attachments"
                  onSaveCrop={this.onHoofdFotoSave}
                  aspectRatio={19 / 10}
                  showSideBySide={true}
                  quality={0.2}
                />
              </div>
            )}
            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-uploaded-files">
              <div className="field-label">
                <label className="label bcwLabel">Overige foto's</label>
              </div>
              <div className="custom-docs event-docs">
                {nieuwsberichtFotoBijlageUrls}
              </div>
            </div>

            {!isReadOnly && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-uploaded-files">
                <div className="field-label">
                  <label className="label bcwLabel ">
                    Upload overige foto's
                  </label>
                </div>

                <Field
                  component={FileUpload}
                  label="Bijlagen"
                  name="nieuwsberichtFotoBijlagen"
                  onChangeUpload={this.onChangeUploadNieuwsberichtFotoBijlagen}
                  accept="image/*"
                />
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const NieuwsberichtReduxForm = reduxForm<{}, Props>({
  form: "NieuwsberichtForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(NieuwsberichtComponent);

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  dispatch,
  updateFormValue: (field: string, value: string) =>
    dispatch(change("NieuwsberichtForm", field, value)),
});

export default connect(
  (state: RootState, props: any) => ({
    initialValues: {
      id: props.isEdit ? state.nieuwsbericht.id : "",
      itemId: props.isEdit ? state.nieuwsbericht.itemId : "",
      item: {
        id: props.isEdit ? state.nieuwsbericht.itemId : "",
        description:
          props.isEdit && state.nieuwsbericht.item
            ? state.nieuwsbericht.item.description
            : "",
        title:
          props.isEdit && state.nieuwsbericht.item
            ? state.nieuwsbericht.item.title
            : "",
        socialSettingsId:
          props.isEdit && state.nieuwsbericht.item
            ? state.nieuwsbericht.item.socialSettingsId
            : "",
        socialSettings: {},
        attachments:
          props.isEdit && state.nieuwsbericht.item
            ? state.nieuwsbericht.item.attachments
            : [],
      },
      date: props.isEdit
        ? state.nieuwsbericht.date
        : moment().format("YYYY-MM-DDTHH:00").toString(),
      author: props.isEdit ? state.nieuwsbericht.author : "",
      possibilityToRespond: props.isEdit
        ? state.nieuwsbericht.possibilityToRespond
        : false,
      topBericht: props.isEdit ? state.nieuwsbericht.topBericht : false,
      gesponsordBericht: props.isEdit
        ? state.nieuwsbericht.gesponsordBericht
        : false,
    },
    formValues: getFormValues("NieuwsberichtForm")(state) as Nieuwsbericht,
  }),
  mapDispatchToProps
)(NieuwsberichtReduxForm);
