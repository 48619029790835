import * as actionTypes from "../constants/notuleActionTypes";
import NotuleApi from "../api/NotuleApi";
import { GetNotulenAction, GetNotuleAction } from "../constants/notuleActionTypes";
import { AsyncAction } from ".";

export const getNotations = (): AsyncAction<GetNotulenAction> => {
    return async (dispatch) => {
        const notulen = await NotuleApi.getNotulen();

        return dispatch({ type: actionTypes.GET_NOTULEN, notulen });
    };
}

export const getNotation = (id: string): AsyncAction<GetNotuleAction> => {
    return async (dispatch) => {
        const notule = await NotuleApi.getNotation(id);

        return dispatch({ type: actionTypes.GET_NOTATION, notule });
    };
}
