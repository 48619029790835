import { Bedrijf, Lead, Permissions } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import React, { ChangeEvent } from "react";
import { getLead, getLeads } from "../actions/leadActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import { IsReadOnly } from '../helpers/PermissionHelper';
import LeadApi from "../api/LeadApi";
import LeadComponent from "../components/LeadComponent/LeadComponent";
import LeadsComponent from "../components/LeadsComponent/LeadsComponent";
import { Modal } from "../components/Modal";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getBedrijven } from "../actions/bedrijfActions";
import matchSorter from "match-sorter";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
    getLeads: () => any;
    getBedrijven: () => any;
    getLead: (id: string) => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    leads: Lead[];
    bedrijven: Bedrijf[];
    lead: Lead;
}

interface OwnProps {
    companyId?: string;
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
    showCreateModal: boolean;
    showEditModal: boolean;
    selectedLeads: string[];
    archief: boolean;
}
const isReadOnly = IsReadOnly(Permissions.ManageLeads);
class LeadsContainer extends React.Component<Props, State> {
    state: State = {
        showCreateModal: false,
        showEditModal: false,
        selectedLeads: [],
        archief: false
    }

    componentDidMount() {
        this.props.getLeads();
        this.props.getBedrijven();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        this.props.getLead(rowInfo.original.id);
        this.setState({ showEditModal: true });
    }

    openCreateLeadModal = () => {
        this.setState({ showCreateModal: true });
    }

    createLead = (formValues: Lead) => {
        var lead = formValues;

        LeadApi.createLead(lead)
            .then(() => {
                this.props.getLeads();
                toast.success("Het opslaan is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.onClose();
            });
    }

    openArchief = () => {
        this.setState({ archief: this.state.archief ? false : true });
    }

    onClose = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false
        });
    }

    updateLead = (formValues: Lead) => {
        var lead = formValues;

        LeadApi.updateLead(lead)
            .then(() => {
                this.props.getLeads();
                toast.success("Het opslaan is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.onClose();
            });
    }

    onDelete = () => {
        if (this.props.lead && this.props.lead.id)
            LeadApi.deleteLead(this.props.lead.id)
                .then(() => {
                    this.props.getLeads();
                    toast.success("Het verwijderen is succesvol")
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => {
                    this.onClose();
                });
    }

    selectLead = (event: any) => {
        const leadId = event.target.value;

        if (event.target.checked) {
            if (this.state.selectedLeads.find(item => item === leadId) == null) {
                const selectedLeads = [...this.state.selectedLeads, leadId];
                this.setState({ selectedLeads: selectedLeads });
            }
        }
        else {
            var index = this.state.selectedLeads.indexOf(leadId);
            const selectedLeads = [
                ...this.state.selectedLeads.slice(0, index),
                ...this.state.selectedLeads.slice(index + 1)
            ]
            this.setState({ selectedLeads: selectedLeads });
        }
    }

    selectAlleLeads = (event: any) => {
        if (event.target.checked) {
            const selectedLeads = this.state.selectedLeads;
            this.props.leads.forEach(lead => {
                if (this.state.selectedLeads.find(item => item === lead.id) == null) {
                    selectedLeads.push(lead.id);
                }
            });
            this.setState({ selectedLeads: selectedLeads });
        }
        else {
            this.setState({ selectedLeads: [] });
        }
    }

    render() {
        const { leads, lead, bedrijven, companyId } = this.props;

        let columns: Array<Column> = [
            // {
            //     id: "checkbox",
            //     accessor: "",
            //     Cell: ({ original }) => {
            //         return (
            //             <input
            //                 type="checkbox"
            //                 className="checkbox"
            //                 checked={this.state.selectedLeads.some(item => item.toString() === original.id.toString())}
            //                 value={original.id}
            //                 onChange={this.selectLead}
            //             />
            //         );
            //     },
            //     Header: x => {
            //         return (
            //             <input
            //                 type="checkbox"
            //                 className="checkbox"
            //                 checked={this.state.selectedLeads.length === this.props.leads.length}
            //                 onChange={this.selectAlleLeads}
            //             />
            //         );
            //     },
            //     sortable: false,
            //     filterable: false,
            //     width: 55
            // },
            {
                Header: "Voornaam",
                accessor: "firstName",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Achternaam",
                accessor: "surName",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Emailadres",
                accessor: "emailAddress",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Bedrijfsnaam",
                accessor: "companyName",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Aangedragen door",
                accessor: "company.name",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]

        let leadsList = leads;
        if (leadsList.length > 0) {
            if (companyId)
                leadsList = leadsList.filter(l => l.companyId == companyId);

            if (this.state.archief)
                leadsList = leadsList.filter(l => l.isArchived == true);
            else
                leadsList = leadsList.filter(l => l.isArchived == false);
        }

        return (
            <>
                <LeadsComponent onRowClick={this.onRowClick}
                    openModal={this.openCreateLeadModal}
                    openArchief={this.openArchief}
                    archiefButtonText={this.state.archief ? "Terug" : "Archief"}
                    archiverenButtonText={this.state.archief ? "Dearchiveren" : "Archiveren"}
                    columns={columns} leads={leadsList} />

                {this.state.showCreateModal
                    ? (<Modal
                        title="Nieuwe lead"
                        content={
                            <LeadComponent bedrijven={bedrijven} companyId={companyId} onSubmit={this.createLead} />
                        }
                        onSubmit={() => this.props.submitForm("LeadForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
                {this.state.showEditModal
                    ? (<Modal
                        title="Aanpassen lead"
                        readOnly={isReadOnly}
                        content={
                            <LeadComponent bedrijven={bedrijven} companyId={companyId} lead={lead} onSubmit={this.updateLead} isEdit={true} />
                        }
                        onSubmit={() => this.props.submitForm("LeadForm")}
                        onArchive={this.onDelete}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    leads: state.leads,
    bedrijven: state.bedrijven,
    lead: state.lead
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getLeads: () => dispatch(getLeads()),
    getBedrijven: () => dispatch(getBedrijven()),
    getLead: (id: string) => dispatch(getLead(id)),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadsContainer);
