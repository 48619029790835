import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { Actie, ActieProduct, Product, BaseItem } from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import FormDatePicker from "../FormDatePicker";
import TextEditor from "../TextEditor";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import FileUpload from "../FileUpload";
import { FormDropdown } from "../FormDropdown";
import { ActieStatus } from "../../constants/enums";
import { LabelInput } from "../LabelInput";
import moment from "moment";
import { RootState } from "../../types/state";

import openImage from "../../images/bin.svg";

export interface OwnProps {
  actie?: Actie;
  producten: Product[];
  formValues: Actie;
  onSubmit: (formValues: Actie, editorState: EditorState) => void;
  onDeleteFlyer: () => void;
  onCancel: () => void;
  isEdit?: boolean;
}

interface State {
  editorState: EditorState;
}

class ActieComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<{} & any, OwnProps>) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidUpdate = (prevProps: any) => {
    // if (this.props.initialValues.actionProducts !== prevProps.initialValues.actionProducts && this.props.isEdit)
    //     this.props.initialize(this.props.initialValues);

    if (prevProps != this.props && this.props.formValues && this.props.isEdit) {
      if (this.props.formValues.item.description != undefined) {
        const blocksFromHtml = htmlToDraft(
          this.props.formValues.item.description
        );
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    }
  };

  onChangeUpload = (fileName: string, file: string) => {
    if (
      this.props.formValues.item.attachments &&
      this.props.formValues.item.attachments.length > 0 &&
      this.props.formValues.item.attachments[0].title === fileName
    ) {
      if (
        window.confirm(
          "Er bestaat al een bijlage met deze bestandsnaam, weet u zeker dat u deze wilt overschrijven?"
        )
      )
        this.props.formValues.item.attachments[0] = {
          id: undefined,
          title: fileName,
          content: file,
          overwrite: false,
          url: "",
          isArchived: false,
        };
    } else {
      this.props.formValues.item.attachments = [
        {
          id: undefined,
          title: fileName,
          content: file,
          overwrite: false,
          url: "",
          isArchived: false,
        },
      ];
    }
  };

  createDocumentList = (url: string) => {
    var filename = url.split("/").pop();
    if (filename == undefined) filename = "";

    return (
      <>
        <a href={url} target="_blank">
          {decodeURIComponent(filename)}
        </a>
        {!this.isReadOnly() && (
          <a className="gridIcon" onClick={() => this.props.onDeleteFlyer()}>
            <img src={openImage} alt="Delete" />
          </a>
        )}
      </>
    );
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  createActieProduct = (index: number): JSX.Element => {
    let selectProducten = this.props.producten.map(function (product) {
      return { label: product.name, value: product.id.toString() };
    });

    return (
      <div key={index} className="actieProductenRow">
        <Field
          component={FormDropdown}
          label="Product"
          name={`actionProducts[${index}].product.id`}
          showSideBySide={true}
          options={selectProducten}
          readOnly={this.isReadOnly()}
        />

        {!this.isReadOnly() && (
          <a
            className="button is-success delete-btn"
            onClick={() => this.deleteActieProduct(index)}
          >
            Delete
          </a>
        )}
      </div>
    );
  };

  addEmptyActieProduct = () => {
    var actionProducts = this.props.formValues.actionProducts;
    actionProducts.push({
      product: {
        id: 0,
        name: "",
        unitPrice: 0,
        stock: 0,
        vatRate: 0,
        isArchived: false,
      },
      isDeleted: false,
    });
    this.props.initialize(this.props.formValues);
  };

  deleteActieProduct = (index: number) => {
    if (this.props.formValues.actionProducts.length > 1) {
      this.props.formValues.actionProducts[index].isDeleted = true;
      this.props.initialize(this.props.formValues);
    } else {
      this.props.formValues.actionProducts[0].isDeleted = true;
      this.props.initialize(this.props.formValues);
    }
  };

  isReadOnly = () => {
    if (this.props.actie && this.props.actie.status == ActieStatus.Definitief)
      return true;
    else return false;
  };

  render() {
    const {
      handleSubmit,
      actie,
      formValues,
      isEdit = false,
      submitting,
      onCancel,
      onSubmit,
    } = this.props;

    let title: any = "";
    let buttons: any = "";
    let flyerUrl: any = "";
    flyerUrl =
      this.props.formValues &&
      this.props.formValues.item &&
      this.props.formValues.item.attachments &&
      this.props.formValues.item.attachments.length > 0
        ? this.createDocumentList(
            this.props.formValues.item.attachments[0].content
          )
        : "";
    let actionProducts: JSX.Element[] = [];

    if (isEdit) {
      title = (
        <div className="column subHeader is-full">
          <h1>Actie</h1>
        </div>
      );

      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              <button type="button" className="button" onClick={onCancel}>
                Annuleren
              </button>
              {!this.isReadOnly() && (
                <button
                  type="submit"
                  disabled={submitting}
                  className="button is-success"
                >
                  Opslaan
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (
      formValues &&
      formValues.actionProducts &&
      formValues.actionProducts.length > 0
    ) {
      formValues.actionProducts.forEach(
        (actieProduct: ActieProduct, index: number) => {
          if (!actieProduct.isDeleted) {
            const element = this.createActieProduct(index);
            actionProducts.push(element);
          }
        }
      );
    } else {
      const element = this.createActieProduct(0);
      actionProducts.push(element);
    }

    return (
      <div className="content notulen">
        <Form
          className="singleCompany"
          onSubmit={handleSubmit((values: any) =>
            onSubmit(values, this.state.editorState)
          )}
        >
          <div className="columns contentPanel is-multiline is-mobile">
            {title}

            <Field
              component={FormTextInput}
              label="Titel"
              name="item.title"
              showSideBySide={true}
              required={true}
              validate={required}
              readOnly={this.isReadOnly()}
            />

            {!isEdit && (
              <Field
                component={LabelInput}
                label="Status"
                name="status"
                showSideBySide={true}
                text={actie && ActieStatus[actie.status]}
              />
            )}

            <div className="field column radio-label is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop">
              <div className="field-label">
                <label className="label bcwLabel is-pulled-left">
                  Afleversoort
                </label>
              </div>
              <div className="radio-wrapper">
                <label className="firstRadioActie">
                  <Field
                    name="deliveryType"
                    component="input"
                    type="radio"
                    value="0"
                    disabled={this.isReadOnly()}
                  />
                  Afhalen
                </label>
                <label>
                  <Field
                    name="deliveryType"
                    component="input"
                    type="radio"
                    value="1"
                    disabled={this.isReadOnly()}
                  />
                  Thuisbezorgd
                </label>
              </div>
            </div>
            <Field
              component={FormDatePicker}
              label="Openingsdatum"
              name="item.openingDateTime"
              showSideBySide={true}
              required={true}
              validate={required}
              readOnly={this.isReadOnly()}
            />

            <Field
              component={FormDatePicker}
              label="Sluitingsdatum"
              name="item.closingDateTime"
              showSideBySide={true}
              required={true}
              validate={required}
              readOnly={this.isReadOnly()}
            />

            <div className="wysiwyg-edit">
              <Field
                component={TextEditor}
                label="Omschrijving"
                name="item.description"
                editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
                readOnly={this.isReadOnly()}
              />
            </div>

            {isEdit && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop ">
                <div className="field-label">
                  <label className="label bcwLabel">Flyer</label>
                </div>
                <div className="custom-docs event-docs">{flyerUrl}</div>

                {actie &&
                  actie.item &&
                  actie.item.attachments &&
                  actie.item.attachments.length > 0 && (
                    <div>
                      <img
                        className="eventUitnodigingImage"
                        src={actie.item.attachments[0].url}
                      ></img>
                    </div>
                  )}
              </div>
            )}

            {!this.isReadOnly() && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop ">
                <div className="field-label">
                  <label className="label is-pulled-left">Upload flyer</label>
                </div>
                <Field
                  component={FileUpload}
                  label="Flyer"
                  name="flyer"
                  onChangeUpload={this.onChangeUpload}
                  multiple={false}
                />
              </div>
            )}

            <div className="actieProductenRowWrapper">
              <h4 className="column">Producten</h4>
              {actionProducts}
              {!this.isReadOnly() && (
                <a
                  className="button is-success"
                  onClick={() => this.addEmptyActieProduct()}
                >
                  Toevoegen
                </a>
              )}
            </div>
          </div>
          {buttons}
        </Form>
      </div>
    );
  }
}

const ActieReduxForm = reduxForm<{}, OwnProps>({
  form: "ActieForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ActieComponent);

export default connect((state: RootState, props: any) => {
  let actie = state.actie && (state.actie as Actie);
  let item = actie && (actie.item as BaseItem | undefined);

  return {
    initialValues: {
      id: props.isEdit ? state.actie.id : null,
      item: {
        id:
          props.isEdit && state.actie && state.actie.item
            ? state.actie.item.id
            : null,
        title: props.isEdit && item ? actie.item.title : "",
        description:
          props.isEdit && state.actie.item ? state.actie.item.description : "",
        openingDateTime:
          props.isEdit && state.actie.item && state.actie.item.openingDateTime
            ? state.actie.item.openingDateTime
            : new Date().toDateString(),
        openingTime:
          props.isEdit && state.actie.item && state.actie.item.openingDateTime
            ? moment(state.actie.item.openingDateTime).format("HH:mm")
            : moment().format("HH:mm"),
        closingDateTime:
          props.isEdit && state.actie.item && state.actie.item.closingDateTime
            ? state.actie.item.closingDateTime
            : new Date().toDateString(),
        closingTime:
          props.isEdit && state.actie.item && state.actie.item.closingDateTime
            ? moment(state.actie.item.closingDateTime).format("HH:mm")
            : moment().format("HH:mm"),
        startDateTime:
          props.isEdit && state.actie.item && state.actie.item.startDateTime
            ? state.actie.item.startDateTime
            : new Date().toDateString(),
        endDateTime:
          props.isEdit && state.actie.item && state.actie.item.endDateTime
            ? state.actie.item.endDateTime
            : new Date().toDateString(),
        publishDateTime:
          props.isEdit && state.actie.item
            ? state.actie.item.publishDateTime
            : new Date().toDateString(),
        archiveDateTime:
          props.isEdit && state.actie.item
            ? state.actie.item.archiveDateTime
            : new Date().toDateString(),
        socialSettingsId:
          props.isEdit && state.actie.item
            ? state.actie.item.socialSettingsId
            : undefined,
        socialSettings: {
          id:
            props.isEdit && state.actie.item
              ? state.actie.item.socialSettingsId
              : undefined,
        },
        attachments:
          props.isEdit && state.actie.item ? state.actie.item.attachments : [],
      },
      status: props.isEdit ? state.actie.status : 0,
      deliveryType:
        props.isEdit && state.actie && state.actie.deliveryType
          ? state.actie.deliveryType.toString()
          : "0",
      actionProducts:
        props.isEdit &&
        state.actie.actionProducts &&
        state.actie.actionProducts.length > 0
          ? state.actie.actionProducts
          : [],
      isArchived: props.isEdit ? state.actie.isArchived : 0,
    },
    formValues: getFormValues("ActieForm")(state) as Actie,
  };
})(ActieReduxForm);
