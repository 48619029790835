import ActieComponent from "../components/ActieComponent/ActieComponent";
import React from "react";
import { Actie, Product } from "../types/model";
import { connect } from "react-redux";
import { getActie } from "../actions/actieActions";
import { AnyAction } from "redux";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import history from "../components/history";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "../actions/loadingActions";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { submit } from "redux-form";
import UserRolHelper from "../helpers/UserRolHelper";
import { UserRol, ActieStatus, AfleverSoort } from "../constants/enums";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import ActieApi from "../api/ActieApi";
import { getProducten, getProductenForUser } from "../actions/productActions";

interface DispatchProps {
    getActie: (id: string) => any;
    getProducten: () => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    actie: Actie;
    producten: Product[];
}

interface MatchParams {
    id: string
}

class ActieContainer extends React.Component<DispatchProps & StateProps & RouteComponentProps<MatchParams>> {
    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getActie(this.props.match.params.id);
            this.props.getProducten();
        }
        else
            history.push("/Error");
    }

    updateActie = async (formValues: Actie, editorState: EditorState) => {
        var actie = formValues;
       
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const html = draftToHtml(
            rawContentState
        );
        actie.item.description = html;

        var actieArray = actie.actionProducts.filter(actieProduct => actieProduct.isDeleted == false).map(actieProduct => actieProduct.product.id);
        var set = new Set(actieArray);

        if (actieArray.length != set.size) {
            toast.error("Er is een dubbel product geprobeerd toe te voegen")
        }
        else {
            this.props.showLoading();
            await ActieApi.updateActie(actie)
                .then(() => toast.success("Het opslaan is succesvol"))
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => this.props.hideLoading());
            history.goBack();
        }
    }

    onDeleteFlyer = (id: number) => {
        ActieApi.deleteActieFlyer(parseInt(this.props.match.params.id))
            .then(() => {
                const userRol = localStorage.getItem("userRol");
                if (userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]))
                    this.props.getActie(this.props.match.params.id);

                toast.success("Het verwijderen is succesvol");
            });
    }

    onCancel = () => {
        history.goBack();
    }

    render() {
        const { actie, producten } = this.props;

        if (actie && actie.status && actie.status != ActieStatus.Definitief)
            history.push("/Error");

        return (
            <ActieComponent actie={actie} producten={producten} isEdit={true}
                onDeleteFlyer={this.onDeleteFlyer} onCancel={this.onCancel} onSubmit={this.updateActie} />
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    actie: state.actie,
    producten: state.producten
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getActie: (id: string) => dispatch(getActie(id)),
    getProducten: () => dispatch(getProducten()),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActieContainer));
