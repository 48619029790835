import React from "react";
import RouterWrapper from "../RouterWrapper";
import CallbackComponent from "../CallbackComponent/CallbackComponent";
import { Router, Route, Switch } from "react-router";
import history from "../history";

export class Template extends React.Component {
  render() {
    return (
      <>
        <Router history={history}>
          <Switch>
            <Route exact path="/callback" component={CallbackComponent} />
            <Route path="/" component={RouterWrapper} />
          </Switch>
        </Router>
      </>
    );
  }
}
