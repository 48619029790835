import * as React from "react";

import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { IsReadOnly } from "../../helpers/PermissionHelper";
import { Permissions } from "../../types/model";
import { Taak } from "../../types/model";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  onSetRowColor: (rowInfo: any) => string;
  openArchief: () => void;
  openModal: () => void;
  archiveToggleSelectedTasks: () => void;
  columns: Array<Column>;
  taken: Taak[];
  archiefButtonText: string;
  archiverenButtonText: string;
  bedrijfId?: number;
}

export default class TakenComponent extends React.Component<OwnProps> {
  render() {
    const isReadOnly = IsReadOnly(Permissions.ManageTasks);
    const { onRowClick, onSetRowColor, openModal, openArchief, archiveToggleSelectedTasks, archiefButtonText, archiverenButtonText, columns, taken } =
      this.props;

    return (
      <>
        <div className="content">
          <div className="contentPanel">
            <div className="columns contentPanel">
              <div className="column is-full">
                <div className="buttons is-pulled-left">
                  <button className="button is-success archive-button" onClick={openArchief}>
                    {archiefButtonText}
                  </button>
                </div>
                <div className="buttons is-pulled-right">
                  {!isReadOnly && (
                    <>
                      <button className="button is-success" onClick={archiveToggleSelectedTasks}>
                        {archiverenButtonText}
                      </button>
                      <button className="button is-success add-button" onClick={openModal}>
                        Toevoegen
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Gridview id="TakenList" addEditColumn={false} onRowClick={onRowClick} onSetRowColor={onSetRowColor} columns={columns} data={taken} />
          </div>
        </div>
      </>
    );
  }
}
