import {
  Bedrijf,
  Contactpersoon,
  Permissions,
  SocialNotificationSettings,
} from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import { ContactpersoonSoort, UserRol } from "../constants/enums";
import React, { ChangeEvent } from "react";
import { change, getFormValues, submit } from "redux-form";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import ContactpersonenComponent from "../components/ContactpersonenComponent/ContactpersonenComponent";
import ContactpersoonApi from "../api/ContactpersoonApi";
import ContactpersoonComponent from "../components/ContactpersoonComponent/ContactpersoonComponent";
import { IsReadOnly } from "../helpers/PermissionHelper";
import { Modal } from "../components/Modal";
import { RootState } from "../types/state";
import SocialSettingsApi from "../api/SocialSettingsApi";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getBedrijven } from "../actions/bedrijfActions";
import { getContactpersonen } from "../actions/contactpersoonActions";
import history from "../components/history";
import matchSorter from "match-sorter";
import moment from "moment";
import { toast } from "react-toastify";

interface DispatchProps {
  getContactpersonen: (
    companyId: string | undefined,
    inclArchived: boolean,
    inclPartners: boolean
  ) => any;
  submitForm: (form: string) => any;
  getBedrijven: () => any;
  updateFormValue: (field: string, value: string) => any;
  showLoading: () => any;
  hideLoading: () => any;
}

interface StateProps {
  contactpersonen: Contactpersoon[];
  bedrijven: Bedrijf[];
  contactpersoonSoorten: [{ label: string; value: string }];
  formValues: Contactpersoon;
}

interface OwnProps {
  bedrijfId?: string;
  readOnly?: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  showModal: boolean;
}

const searchParams = new URLSearchParams(document.location.search);
const isReadOnly = IsReadOnly(Permissions.ManageContactPersons);
class ContactpersonenContainer extends React.Component<Props, State> {
  state: State = {
    showModal: false,
  };

  componentDidMount() {
    this.props.getContactpersonen(
      this.props.bedrijfId,
      searchParams.get("isArchived") === "true",
      false
    );
    this.props.getBedrijven();
  }

  onRowClick(event: ChangeEvent, rowInfo: RowInfo) {
    history.push("/Contactpersonen/" + rowInfo.original.id);
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  createContactpersoon = async (formValues: Contactpersoon) => {
    var contactpersoon = formValues;
    contactpersoon.birthDate = moment(contactpersoon.birthDate)
      .startOf("day")
      .format("YYYY-MM-DD hh:mm:ss");
    if (contactpersoon.partner) {
      contactpersoon.partner.birthDate = moment(
        contactpersoon.partner.birthDate
      )
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss");
    }
    const userRol = localStorage.getItem("userRol");
    var returnperson = {} as Contactpersoon;

    this.props.showLoading();
    await ContactpersoonApi.createContactpersoon(contactpersoon)
      .then((person) => {
        this.props.getContactpersonen(
          this.props.bedrijfId,
          searchParams.get("isArchived") === "true",
          false
        );
        toast.success("Het opslaan is succesvol");
        returnperson = person;
      })
      .catch((err) => {
        toast.error("Er is iets mis gegaan");
      });

    var socialValues = formValues.socialSettings as SocialNotificationSettings;
    socialValues.personId = returnperson.id;
    if (socialValues) {
      await SocialSettingsApi.updateSocialSettings(socialValues)
        .then(() => {
          toast.success("Het opslaan van de notificaties is succesvol");
        })
        .catch((err) => {
          return toast.error(
            "Kan geen notificaties opslaan " + err.response.data
          );
        });
    }
    this.props.hideLoading();

    this.setState({ showModal: false });
  };

  updateFormValue = (field: string, value: string) => {
    this.props.updateFormValue(field, value);
  };

  onClose = () => {
    this.setState({ showModal: false });
  };

  exportSelectedContactpersons = () => {
    this.props.showLoading();
    ContactpersoonApi.exportContactpersonen(
      [],
      searchParams.get("isArchived") === "true",
      false
    )
      .then(() => {
        toast.success("Het exporteren is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => {
        this.props.hideLoading();
      });
  };

  render() {
    const {
      contactpersonen,
      bedrijven,
      contactpersoonSoorten,
      formValues,
      bedrijfId,
    } = this.props;

    let columns: Array<Column> = [
      {
        Header: "Voornaam",
        accessor: "firstname",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Achternaam",
        accessor: "surname",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Bedrijf",
        accessor: "companyName",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Soort",
        accessor: "type",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) => {
          const soort = contactpersoonSoorten.find(
            (c: { value: string; label: string }) =>
              c.value === props.value.toString()
          );
          return soort ? soort.label : null;
        },
        filterMethod: (filter: Filter, rows: any) => {
          const id = filter.id;
          return rows[id] !== undefined
            ? String(ContactpersoonSoort[rows[id]].toLowerCase()).startsWith(
                filter.value.toLowerCase()
              )
            : true;
        },
      },
      {
        Header: "Email-adres",
        accessor: "emailAddress",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Telefoonnummer",
        accessor: "phoneNumber",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        id: "loggedInOn",
        Header: "Laatst actief",
        accessor: (d) => {
          return moment(d.loggedInOn).local().format("DD-MM-YYYY");
        },
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
    ];

    let contactpersonenList = contactpersonen;

    if (bedrijfId)
      contactpersonenList = contactpersonenList.filter(
        (c) => c.companyId == bedrijfId.toString()
      );

    return (
      <>
        <ContactpersonenComponent
          onRowClick={this.onRowClick}
          openModal={this.openModal}
          exportSelectedContactpersons={this.exportSelectedContactpersons}
          columns={columns}
          contactpersonen={contactpersonenList}
        />
        {this.state.showModal ? (
          <Modal
            title="Nieuwe contactpersoon"
            content={
              <ContactpersoonComponent
                useCompanyAddress={
                  this.props.formValues &&
                  this.props.formValues.useCompanyAddress
                }
                hasPartner={
                  this.props.formValues && this.props.formValues.hasPartner
                }
                hasSeperatePartnerAddress={
                  this.props.formValues &&
                  this.props.formValues.hasSeperatePartnerAddress
                }
                contactpersoonSoorten={contactpersoonSoorten}
                bedrijven={bedrijven}
                companyId={bedrijfId}
                formValues={formValues}
                updateFormValue={this.updateFormValue}
                onSubmit={this.createContactpersoon}
                socialSettings={{} as SocialNotificationSettings}
              />
            }
            onSubmit={() => this.props.submitForm("ContactpersoonForm")}
            onClose={this.onClose}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  contactpersonen: state.contactpersonen,
  bedrijven: state.bedrijven,
  contactpersoonSoorten: state.contactpersoonSoorten,
  formValues: getFormValues("ContactpersoonForm")(state) as Contactpersoon,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getContactpersonen: (
    companyId: string | undefined,
    archive: boolean,
    inclPartners: boolean
  ) => dispatch(getContactpersonen(companyId, archive, inclPartners)),
  submitForm: (form: string) => dispatch(submit(form)),
  getBedrijven: () => dispatch(getBedrijven()),
  updateFormValue: (field: string, value: string) =>
    dispatch(change("ContactpersoonForm", field, value)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactpersonenContainer);
