import * as types from "../constants/statistiekenActionTypes";
import { ChartModel, CsvViewModel } from "../types/model";

const initialGenemuidenStatistiekenState = { labels: [], data: [] } as ChartModel
const initialSponsordealTotaalBedragStatistiekenState = { labels: [], data: [] } as ChartModel
const initialSponsordealSoortStatistiekenState = { labels: [], data: [] } as ChartModel
const initialFactuurStatistiekenState = { labels: [], data: [] } as ChartModel
const initialContactpersoonStatisticsState = { labels: [], data: [] } as ChartModel
const initialCsvViewNamesState = { viewNames: [] } as CsvViewModel

type GenemuidenStatistiekenState = typeof initialGenemuidenStatistiekenState
type SponsordealTotaalBedragStatistiekeState = typeof initialSponsordealTotaalBedragStatistiekenState
type SponsordealSoortStatistiekenState = typeof initialSponsordealSoortStatistiekenState
type FactuurStatistiekenState = typeof initialFactuurStatistiekenState
type ContactpersoonStatisticsState = typeof initialContactpersoonStatisticsState
type CsvViewNamesState = typeof initialCsvViewNamesState

export const sponsordealSoortStatistiekenReducer = (
    state: SponsordealSoortStatistiekenState = initialSponsordealSoortStatistiekenState,
    action: types.GetSponsordealSoortStatistiekenAction
) => {
    if (action.type === types.GET_SPONSORDEALSOORT_STATISTIEKEN)
        return action.sponsordealSoortStatistieken;

    return state;
};

export const sponsordealTotaalBedragStatistiekenReducer = (
    state: SponsordealTotaalBedragStatistiekeState = initialSponsordealTotaalBedragStatistiekenState,
    action: types.GetSponsordealTotaalBedragStatistiekenAction
) => {
    if (action.type === types.GET_SPONSORDEALTOTAALBEDRAG_STATISTIEKEN)
        return action.sponsordealTotaalBedragStatistieken;

    return state;
};

export const genemuidenStatistiekenReducer = (
    state: GenemuidenStatistiekenState = initialGenemuidenStatistiekenState,
    action: types.GetGenemuidenStatistiekenAction
) => {
    if (action.type === types.GET_GENEMUIDEN_STATISTIEKEN)
        return action.genemuidenStatistieken;

    return state;
};

export const factuurStatistiekenReducer = (
    state: FactuurStatistiekenState = initialFactuurStatistiekenState,
    action: types.GetFactuurStatistiekenAction
) => {
    if (action.type === types.GET_FACTUUR_STATISTIEKEN)
        return action.factuurStatistieken;

    return state;
};

export const contactpersoonStatisticsReducer = (
    state: ContactpersoonStatisticsState = initialContactpersoonStatisticsState,
    action: types.GetContactpersoonStatisticsAction
) => {
    if (action.type === types.GET_CONTACTPERSOON_STATISTICS)
        return action.contactpersoonStatistics;

    return state;
};

export const csvViewNamesReducer = (
    state: CsvViewNamesState = initialCsvViewNamesState,
    action: types.GetViewNamesAction
) => {
    if (action.type === types.GET_CSV_VIEWNAMES)
    {
        return action.viewNames;
    }

    return state;
};
