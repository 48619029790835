import * as types from "../constants/eventActionTypes";
import { Event, ListEvent } from "../types/model";
import { Contactpersoon } from "../types/model";

const initialEventsState: Array<ListEvent> = [];
const initialEventState = {} as Event
const initialEventTabIndexState = 0
const initialAttendeesState: Array<Contactpersoon> = []

type EventsState = typeof initialEventsState
type EventState = typeof initialEventState
type EventTabIndexState = typeof initialEventTabIndexState
type AttendeesState = typeof initialAttendeesState

export const eventsReducer = (
    state: EventsState = initialEventsState,
    action: types.GetEventsAction
) => {
    if (action.type === types.GET_EVENTS)
        return action.events;

    return state;
};

export const eventReducer = (
    state: EventState = initialEventState,
    action: types.GetEventAction
) => {
    if (action.type === types.GET_EVENT) {
        return action.event;
    }

    return state;
};

export const attendeesReducer = (
    state: AttendeesState = initialAttendeesState,
    action: types.GetAttendeesAction
) => {
    if (action.type === types.GET_ATTENDEES) {
        return action.attendees;
    }

    return state;
};

export const eventTabIndexReducer = (
    state: EventTabIndexState = initialEventTabIndexState,
    action: types.SetEventTabIndexAction
) => {
    if (action.type === types.SET_EVENTTABINDEX)
        return action.eventTabIndex;

    return state;
};
