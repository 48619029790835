import * as types from "../constants/agendaActionTypes";
import { AgendaItem } from "../types/model";

const initialAgendaItemsState: Array<AgendaItem> = [];
const initialAgendaItemState = {} as AgendaItem

type AgendaItemsState = typeof initialAgendaItemsState
type AgendaItemState = typeof initialAgendaItemState

export const agendaItemsReducer = (
    state: AgendaItemsState = initialAgendaItemsState,
    action: types.GetAgendaItemsAction
) => {
    if (action.type === types.GET_AGENDA_ITEMS)
        return action.agendaItems;

    return state;
};

export const agendaItemReducer = (
    state: AgendaItemState = initialAgendaItemState,
    action: types.GetAgendaItemAction
) => {
    if (action.type === types.GET_AGENDA_ITEM)
        return action.agendaItem;

    return state;
};
