import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { Settings, Person, Verantwoordelijke } from "../../types/model";
import { connect } from "react-redux";
import { FormDropdown } from "../FormDropdown";
import { ColorPicker } from "../ColorPicker";
import FileUpload from "../FileUpload";

import openImage from "../../images/bin.svg";

export interface OwnProps {
  settings?: Settings;
  verantwoordelijken: Verantwoordelijke[];
  formValues: Settings;
  onCancel: () => void;
  resendUserActivation: () => void;
  onDeleteLogo: () => void;
  onSubmit: (formValues: Settings) => void;
  isEdit?: boolean;
}

class SettingsComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>
> {
  constructor(props: OwnProps & InjectedFormProps<{} & any, OwnProps>) {
    super(props);
  }

  createDocumentList = (url: string) => {
    var filename = url.split("/").pop();
    if (filename == undefined) filename = "";

    return (
      <>
        <a href={url} target="_blank">
          {decodeURIComponent(filename)}
        </a>
        {
          <a className="gridIcon" onClick={() => this.props.onDeleteLogo()}>
            <img src={openImage} alt="Delete" />
          </a>
        }
      </>
    );
  };

  onChangeUpload = (fileName: string, file: string) => {
    if (
      this.props.formValues.logoBlobUrl &&
      this.props.formValues.logoBlobUrl.split("/").pop() === fileName
    ) {
      if (
        window.confirm(
          "Er bestaat al een bijlage met deze bestandsnaam, weet u zeker dat u deze wilt overschrijven?"
        )
      )
        this.props.formValues.logo = {
          id: undefined,
          title: fileName,
          content: file,
          overwrite: false,
          url: "",
          isArchived: false,
        };
    } else
      this.props.formValues.logo = {
        id: undefined,
        title: fileName,
        content: file,
        overwrite: false,
        url: "",
        isArchived: false,
      };
  };

  render() {
    const {
      handleSubmit,
      settings,
      verantwoordelijken,
      resendUserActivation,
      isEdit = false,
      submitting,
      onCancel,
      onSubmit,
    } = this.props;

    let selectVerantwoordelijken = verantwoordelijken.map(function (
      verantwoordelijke
    ) {
      return {
        label: verantwoordelijke.fullName,
        value: verantwoordelijke.id.toString(),
      };
    });

    let logo: any = "";
    logo =
      this.props.formValues &&
      this.props.formValues.logoBlobUrl &&
      this.createDocumentList(this.props.formValues.logoBlobUrl);

    return (
      <div className="content">
        <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
          <div className="columns contentPanel is-multiline is-mobile">
            <div className="column subHeader is-full">
              <h1>Settings</h1>
            </div>

            <Field
              component={FormDropdown}
              label="Social media verantwoordelijke"
              name="socialMediaVerantwoordelijkeId"
              showSideBySide={true}
              options={selectVerantwoordelijken}
              clearable={true}
            />

            <Field
              component={FormDropdown}
              label="Reclamebord verantwoordelijke"
              name="reclamebordVerantwoordelijkeId"
              showSideBySide={true}
              options={selectVerantwoordelijken}
              clearable={true}
            />

            <Field
              component={ColorPicker}
              label="Primaire kleur"
              name="primaireKleur"
              showSideBySide={true}
            />

            <Field
              component={ColorPicker}
              label="Secundaire kleur"
              name="secundaireKleur"
              showSideBySide={true}
            />

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop uploaded-files settings-block">
              <div className="field-label">
                <label className="label bcwLabel">Logo</label>
              </div>
              <div className="custom-docs event-docs">{logo}</div>
            </div>

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop uploaded-files settings-block">
              <div className="field-label">
                <label className="label bcwLabel is-pulled-left">
                  Alle account activatie-emails opnieuw verzenden
                </label>
              </div>
              <button
                type="button"
                onClick={resendUserActivation}
                disabled={submitting}
                className="button is-danger is-pulled-left"
              >
                Verstuur
              </button>
            </div>

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop uploaded-files settings-block">
              <div className="field-label">
                <label className="label bcwLabel is-pulled-left">
                  Upload logo
                </label>
              </div>
              <Field
                component={FileUpload}
                label="Logo"
                name="logoBlobUrl"
                onChangeUpload={this.onChangeUpload}
                multiple={false}
              />
            </div>
          </div>

          <div className="columns contentPanel">
            <div className="column is-full">
              <div className="buttons is-pulled-right">
                <button type="button" className="button" onClick={onCancel}>
                  Annuleren
                </button>
                <button
                  type="submit"
                  disabled={submitting}
                  className="button is-success"
                >
                  Opslaan
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const SettingsReduxForm = reduxForm<{}, OwnProps>({
  form: "SettingsForm",
  enableReinitialize: true,
})(SettingsComponent);

export default connect((state: any, props: any) => ({
  initialValues: {
    id: state.settings.id,
    socialMediaVerantwoordelijkeId:
      state.settings.socialMediaVerantwoordelijkeId,
    reclamebordVerantwoordelijkeId:
      state.settings.reclamebordVerantwoordelijkeId,
    primaireKleur: state.settings.primaireKleur,
    secundaireKleur: state.settings.secundaireKleur,
    logoBlobUrl: state.settings.logoBlobUrl,
    logo: {},
  },
  formValues: getFormValues("SettingsForm")(state) as Settings,
}))(SettingsReduxForm);
