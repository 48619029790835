import React from "react";

export default class PrintMarkdown extends React.Component {
  render() {
    var url2 = new URL(window.location.href);
    var test = new URLSearchParams(url2.search);
    var querystring = test.get("md");
    var markdown = atob(querystring!);

    window.print();
    return <div dangerouslySetInnerHTML={{ __html: markdown }} />;
  }
}
