import * as actionTypes from "../constants/sponsordealActionTypes";
import SponsordealApi from '../api/SponsordealApi';
import { GetSponsordealsAction, GetSponsordealAction, GetSponsordealListAction } from "../constants/sponsordealActionTypes";
import { AsyncAction } from ".";

export const getSponsordeals = (): AsyncAction<GetSponsordealsAction> => {
    return async (dispatch) => {
        const sponsordeals = await SponsordealApi.getSponsordeals();

        return dispatch({ type: actionTypes.GET_SPONSORDEALS, sponsordeals });
    };
}

export const getSponsordealList = (): AsyncAction<GetSponsordealListAction> => {
    return async (dispatch) => {
        const sponsordeals = await SponsordealApi.getSponsordealList();

        return dispatch({ type: actionTypes.GET_SPONSORDEALLIST, sponsordeals });
    };
}

export const getSponsordeal = (id: number): AsyncAction<GetSponsordealAction> => {
    return async (dispatch) => {
        const sponsordeal = await SponsordealApi.getSponsordeal(id);

        return dispatch({ type: actionTypes.GET_SPONSORDEAL, sponsordeal });
    };
}