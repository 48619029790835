import PartnerComponent from "../components/PartnerComponent/PartnerComponent";
import React from "react";
import { Contactpersoon, Bedrijf } from "../types/model";
import { connect } from "react-redux";
import {
  getPartnerForHoofdcontactpersoon,
  getPartner,
} from "../actions/contactpersoonActions";
import ContactpersoonApi from "../api/ContactpersoonApi";
import { AnyAction, compose } from "redux";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import history from "../components/history";
import { toast } from "react-toastify";
import { getBedrijven } from "../actions/bedrijfActions";
import { change, getFormValues, reset } from "redux-form";
import { showLoading, hideLoading } from "../actions/loadingActions";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface DispatchProps {
  getPartner: (id: number) => any;
  getBedrijven: () => any;
  getPartnerForHoofdcontactpersoon: (id: number) => any;
  updateFormValue: (field: string, value: string) => any;
  showLoading: () => any;
  hideLoading: () => any;
  resetForm: () => void;
}

interface StateProps {
  partner: Contactpersoon;
  contactpersoonSoorten: [{ label: string; value: string }];
  bedrijven: Bedrijf[];
  formValues: Contactpersoon;
}

interface OwnProps {
  bedrijfId?: number;
}

interface MatchParams {
  id: string;
}

type Props = DispatchProps &
  StateProps &
  OwnProps &
  RouteComponentProps<MatchParams>;

class PartnerContainer extends React.Component<Props> {
  componentDidMount = () => {
    this.props.getPartner(this.props.match.params.id as unknown as number);
    this.props.getBedrijven();
  };

  updatePartner = async (formValues: Contactpersoon) => {
    var partner = formValues;

    this.props.showLoading();
    await ContactpersoonApi.updatePartner(partner)
      .then(() => toast.success("Het opslaan is succesvol"))
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => {
        this.props.hideLoading();
        history.goBack();
      });

    this.setState({ partner: null });
    this.props.resetForm();
  };

  deletePartner = (id: string) => {
    if (window.confirm("Weet u zeker dat deze partner wilt verwijderen?")) {
      this.props.showLoading();
      ContactpersoonApi.deletePartner(id)
        .then(() => toast.success("Het opslaan is succesvol"))
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => {
          this.props.hideLoading();
          history.goBack();
        });
    }
  };

  updateFormValue = (field: string, value: string) => {
    this.props.updateFormValue(field, value);
  };

  onCancel = () => {
    history.goBack();
  };

  render() {
    const { partner, bedrijven, formValues, contactpersoonSoorten } =
      this.props;

    return (
      <PartnerComponent
        useCompanyAddress={
          this.props.formValues && this.props.formValues.useCompanyAddress
        }
        partner={partner}
        deletePartner={this.deletePartner}
        contactpersoonSoorten={contactpersoonSoorten}
        bedrijven={bedrijven}
        isEdit={true}
        onCancel={this.onCancel}
        formValues={formValues}
        updateFormValue={this.updateFormValue}
        onSubmit={this.updatePartner}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  partner: state.partner,
  contactpersoonSoorten: state.contactpersoonSoorten,
  bedrijven: state.bedrijven,
  formValues: getFormValues("PartnerForm")(state) as Contactpersoon,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getPartner: (id: number) => dispatch(getPartner(id)),
  getBedrijven: () => dispatch(getBedrijven()),
  getPartnerForHoofdcontactpersoon: (id: number) =>
    dispatch(getPartnerForHoofdcontactpersoon(id)),
  updateFormValue: (field: string, value: string) =>
    dispatch(change("PartnerForm", field, value)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  resetForm: () => dispatch(reset("PartnerForm")),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PartnerContainer)
);
