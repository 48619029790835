import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";

interface Props {
  label?: string;
  readOnly?: boolean;
  hidden?: boolean;
  showSideBySide?: boolean;
  displayInline?: boolean;
  onValueChanged?: (value: any) => void;
  onClick?: (value: any) => void;
  customWrapperClass?: string;
}

export type CheckboxProps = WrappedFieldProps & Props;

interface CheckboxState {
  isChecked: boolean;
}

export class FormCheckbox extends React.Component<
  CheckboxProps,
  CheckboxState
> {
  constructor(props: CheckboxProps) {
    super(props);

    this.state = { isChecked: props.input.value };
  }

  componentWillReceiveProps(nextProps: Readonly<CheckboxProps>) {
    this.setState({ isChecked: nextProps.input.value });
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onValueChanged } = this.props;

    const newValue = !this.state.isChecked;

    onValueChanged && onValueChanged(newValue);
    this.setState({ isChecked: newValue });

    event.target.focus();
    event.target.blur();
  };

  render() {
    const {
      input,
      label,
      meta: { touched, error },
      showSideBySide,
      readOnly,
      displayInline,
      customWrapperClass,
      hidden,
    } = this.props;

    let style = {
      display: displayInline ? "inline-block" : "block",
      paddingLeft: displayInline ? "10px" : "0px",
    };
    let wrapperClass =
      "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

    if (showSideBySide)
      wrapperClass = classnames(
        wrapperClass,
        "is-half-desktop",
        displayInline ? "" : "checkbox-wrapper"
      );
    else wrapperClass = classnames(wrapperClass, "is-full-desktop");

    const labelComponent = label && (
      <label className="label" htmlFor={input.name} style={style}>
        {label}
      </label>
    );

    return (
      !hidden && (
        <div className={customWrapperClass ? customWrapperClass : wrapperClass}>
          <div
            className="field-body"
            style={{ marginLeft: displayInline ? "10px" : "0px" }}
          >
            <label className="checkbox">
              {label && !displayInline && labelComponent}

              <input
                {...input}
                type="checkbox"
                disabled={readOnly}
                hidden={hidden}
                onChange={(event) => this.onChange(event)}
                checked={this.state.isChecked}
              />

              {label && displayInline && labelComponent}
            </label>
            {touched && error && <p className="help is-danger">{error}</p>}
          </div>
        </div>
      )
    );
  }
}
