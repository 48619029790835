import { Factuur } from "../../../types/model";

export const validateInvoice = (invoice: Factuur): boolean => {
  if (invoice.invoiceLines.length < 1) return false;
  for (let i = 0; i < invoice.invoiceLines.length; i++) {
    const line = invoice.invoiceLines[i];
    if (!line.isDeleted) {
      if (!line.description) return false;
      if (!line.amount) return false;
      if (!line.price) return false;
    }
  }
  if (!invoice.companyId) return false;
  if (!invoice.email) return false;
  return true;
};
