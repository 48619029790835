import ApiClient from './ApiClient';
import { Taak } from "../types/model";

const endpoint = 'api/tasks';

export default class TaskApi {
    public static async getTasks(): Promise<Taak[]> {
        var taken = await ApiClient.doHttpRequest<Taak[]>('get', `${endpoint}`);
        return taken;
    }

    public static async getTask(id: string): Promise<Taak> {
        var taak = await ApiClient.doHttpRequest<Taak>('get', `${endpoint}/${id}`);
        return taak;
    }

    public static async updateTask(taak: Taak): Promise<Taak> {
        var taak = await ApiClient.doHttpRequest<Taak>('put', `${endpoint}/${taak.id}`, taak);
        return taak;
    }

    public static async createTask(taak: Taak): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}`, taak);
    }

    public static async toggleTask(id: number): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}/${id}/toggle`);
    }
    
    public static async archiveSelectedTaks(ids: string[]): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/archive`, ids);
    }
}
