import * as types from "../constants/customPagesActionTypes";
import { CustomPage } from "../types/model";

const initialCustomPageState = {} as CustomPage
const initialCustomPagesState : Array<CustomPage> = [];

type CustomPagesState = typeof initialCustomPagesState
type CustomPageState = typeof initialCustomPageState

export const customPageReducer = (
    state: CustomPageState = initialCustomPageState,
    action: types.GetCustomPageAction
) => {
    if (action.type === types.GET_CUSTOMPAGE)
        return action.customPage;

    return state;
};

export const customPagesReducer = (
    state: CustomPagesState = initialCustomPagesState,
    action: types.GetCustomPagesAction
) => {
    if (action.type === types.GET_CUSTOMPAGES)
        return action.customPages;

    return state;
};
