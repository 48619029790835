import { Actie, Product } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import { EditorState, convertToRaw } from "draft-js";
import React, { ChangeEvent } from "react";
import { getProducten, getProductenForUser } from "../actions/productActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import ActieApi from "../api/ActieApi";
import ActieComponent from "../components/ActieComponent/ActieComponent";
import { ActieStatus } from "../constants/enums";
import ActiesComponent from "../components/ActiesComponent/ActiesComponent";
import { AnyAction } from "redux";
import { Modal } from "../components/Modal";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import draftToHtml from 'draftjs-to-html';
import { getActies } from "../actions/actieActions";
import history from '../components/history';
import matchSorter from "match-sorter";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
    getActies: () => any;
    getProducten: () => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    acties: Actie[];
    producten: Product[];
}

interface OwnProps {
    bedrijfId?: number;
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
    showModal: boolean;
    selectedActies: string[];
    archief: boolean;
}

class ActiesContainer extends React.Component<Props, State> {
    state: State = {
        showModal: false,
        selectedActies: [],
        archief: false
    }

    componentDidMount() {
        this.props.getActies();
        this.props.getProducten();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        history.push("/Acties/" + rowInfo.original.id);
    }

    openModal = () => {
        this.setState({ showModal: true });
    }

    createActie = (formValues: Actie, editorState: EditorState) => {
        var actie = formValues;

        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const html = draftToHtml(
            rawContentState
        );
        actie.item.description = html;

        var actieArray = actie.actionProducts.filter(actieProduct => actieProduct.isDeleted == false).map(actieProduct => actieProduct.product.id);
        var set = new Set(actieArray);

        if (actieArray.length != set.size) {
            toast.error("Er is een dubbel product geprobeerd toe te voegen")
        }
        else {
            ActieApi.createActie(actie)
                .then(() => {
                    this.props.getActies();
                    toast.success("Het opslaan is succesvol")
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => {
                    this.onClose();
                });
        }
    }

    openArchief = () => {
        this.setState({ archief: this.state.archief ? false : true });
    }

    onClose = () => {
        this.setState({
            showModal: false
        });
    }

    // archiveSelectedActies = () => {
    //     if (this.state.selectedActies.length > 0) {
    //         this.props.showLoading();
    //         ActieApi.archiveSelectedActies(this.state.selectedActies)
    //             .then(() => {
    //                 this.props.getActies();
    //                 this.setState({ selectedActies: [] });
    //                 toast.success("Het archiveren is succesvol");
    //             })
    //             .catch(() => toast.error("Er is een fout opgetreden"))
    //             .finally(() => {
    //                 this.props.hideLoading();
    //             });
    //     }
    // }

    // exportSelectedActies = () => {
    //     if (this.state.selectedActies.length > 0) {
    //         this.props.showLoading();
    //         ActieApi.exportSelectedActies(this.state.selectedActies)
    //             .then(() => {
    //                 this.props.getActies();
    //                 this.setState({ selectedActies: [] });
    //                 toast.success("Het exporteren is succesvol");
    //             })
    //             .catch(() => toast.error("Er is een fout opgetreden"))
    //             .finally(() => {
    //                 this.props.hideLoading();
    //             });
    //     }
    // }

    // sendActieEmailInvitations = () => {
    //     if (this.state.selectedActies.length > 0) {
    //         toast.success("De emails worden verstuurd");
    //         ActieApi.sendActieEmailInvitations(this.state.selectedActies)
    //             .then(() => {
    //                 this.props.getActies();
    //                 this.setState({ selectedActies: [] });
    //                 toast.success("Het versturen is succesvol");
    //             })
    //             .catch(() => toast.error("Er is een fout opgetreden"))
    //             .finally(() => {
    //                 this.props.hideLoading();
    //             });
    //     }
    // }

    selectActie = (event: any) => {
        const actieId = event.target.value;

        if (event.target.checked) {
            if (this.state.selectedActies.find(item => item === actieId) == null) {
                const selectedActies = [...this.state.selectedActies, actieId];
                this.setState({ selectedActies: selectedActies });
            }
        }
        else {
            var index = this.state.selectedActies.indexOf(actieId);
            const selectedActies = [
                ...this.state.selectedActies.slice(0, index),
                ...this.state.selectedActies.slice(index + 1)
            ]
            this.setState({ selectedActies: selectedActies });
        }
    }

    selectAlleActies = (event: any) => {
        if (event.target.checked) {
            const selectedActies = this.state.selectedActies;
            this.props.acties.forEach(actie => {
                if (this.state.selectedActies.find(item => item === actie.id) == null) {
                    selectedActies.push(actie.id);
                }
            });
            this.setState({ selectedActies: selectedActies });
        }
        else {
            this.setState({ selectedActies: [] });
        }
    }

    render() {
        const { acties, producten } = this.props;

        let columns: Array<Column> = [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedActies.some(item => item.toString() === original.id.toString())}
                            value={original.id}
                            onChange={this.selectActie}
                        />
                    );
                },
                Header: x => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedActies.length === acties.length}
                            onChange={this.selectAlleActies}
                        />
                    );
                },
                sortable: false,
                filterable: false,
                width: 55
            },
            {
                Header: "Titel",
                accessor: "item.title",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Openingsdatum",
                accessor: "item.openingDateTime",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Sluitingsdatum",
                accessor: "item.closingDateTime",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]
        
        columns.push({
            Header: "Status",
            accessor: "status",
            headerStyle: {
                textAlign: "left"
            },
            Cell: props => {
                var status = ActieStatus[props.value];
                return status;
            },
            filterMethod: (filter: Filter, rows: any) => {
                const id = filter.id;
                return rows[id] !== undefined ? String((ActieStatus[rows[id]]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
            }
        });

        let actiesList = acties;
        if (this.state.archief)
            actiesList = actiesList.filter(a => a.isArchived == true);
        else
            actiesList = actiesList.filter(a => a.isArchived == false);

        return (
            <>
                <ActiesComponent onRowClick={this.onRowClick}
                    openModal={this.openModal} openArchief={this.openArchief}
                    archiefButtonText={this.state.archief ? "Terug" : "Archief"} archiverenButtonText={this.state.archief ? "Dearchiveren" : "Archiveren"}
                    //archiveSelectedActies={this.archiveSelectedActies} exportSelectedActies={this.exportSelectedActies} sendActieEmailInvitations={this.sendActieEmailInvitations}
                    columns={columns} acties={actiesList} hideExport={this.state.selectedActies.length <= 1} />
                {this.state.showModal
                    ? (<Modal
                        title="Nieuwe actie"
                        content={
                            <ActieComponent producten={producten} onSubmit={this.createActie} />
                        }
                        onSubmit={() => this.props.submitForm("ActieForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    acties: state.acties,
    producten: state.producten
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getActies: () => dispatch(getActies()),
    getProducten: () => dispatch(getProducten()),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiesContainer);
