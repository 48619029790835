import {
  Address,
  Bedrijf,
  Contactpersoon,
  Permissions,
  SocialNotificationSettings,
} from "../../types/model";
import {
  Field,
  Form,
  formValues,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import React, { ChangeEvent } from "react";

import { FormCheckbox } from "../FormCheckbox";
import { ContactpersoonSoort } from "../../constants/enums";
import FormDatePicker from "../FormDatePicker";
import { FormDropdown } from "../FormDropdown";
import { HiddenInput } from "../HiddenInput";
import { IsReadOnly } from "../../helpers/PermissionHelper";
import { FormTextInput } from "../FormTextInput";
import { connect } from "react-redux";
import email from "../../validators/email";
import lengthmax6 from "../../validators/lengthmax6";
import postalcode from "../../validators/postalcode";
import required from "../../validators/required";

export interface OwnProps {
  gebruiker?: Contactpersoon;
  contactpersoonSoorten: Array<{ label: string; value: string }>;
  bedrijven: Bedrijf[];
  formValues: Contactpersoon;
  onCancel?: () => void;
  updateFormValue: (field: string, value: any) => void;
  archiveContactpersoon?: (id: string) => void;
  onSubmit: (formValues: Contactpersoon) => void;
  isEdit?: boolean;
  isModal?: boolean;
  companyId?: string | undefined;
  hasPartner?: boolean;
  useCompanyAddress?: boolean;
  hasSeperatePartnerAddress?: boolean;
  socialSettings: SocialNotificationSettings;
  resendActivationMail?: (id: string) => void;
}

export interface State {
  localContactpersoonSoorten: Array<{ label: string; value: string }>;
  company?: Bedrijf;
  gebruiker?: Contactpersoon;
  contactpersoonSoorten: Array<{ label: string; value: string }>;
  socialSettings: SocialNotificationSettings;
}
const isReadOnly = IsReadOnly(Permissions.ManageContactPersons);
class ContactpersoonComponent extends React.Component<
  OwnProps & InjectedFormProps<Contactpersoon, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<Contactpersoon, OwnProps>) {
    super(props);
    this.state = {
      company: props.companyId
        ? props.bedrijven.find((b) => b.id == props.companyId)
        : props.bedrijven[0],
      localContactpersoonSoorten: this.setLocalState(),
      gebruiker: props.gebruiker,
      contactpersoonSoorten: props.contactpersoonSoorten,
      socialSettings: props.socialSettings,
    };
  }

  onFormSubmit = (formValues: Contactpersoon) => {
    this.props.onSubmit(formValues);
    this.props.reset();
  };

  //TODO: zorg dat auto-fill werkt van bedrijf
  // componentDidMount = () => {
  //     var bedrijf = this.state && this.state.company;

  //     if (bedrijf) {
  //         this.props.updateFormValue("straat", bedrijf.straat);
  //         this.props.updateFormValue("huisnummer", bedrijf.huisnummer);
  //         this.props.updateFormValue("postcode", bedrijf.postcode);
  //         this.props.updateFormValue("plaats", bedrijf.plaats);
  //     }
  // }

  componentDidUpdate = (prevProps: OwnProps, prevState: State) => {
    if (
      this.props.gebruiker &&
      prevProps.gebruiker &&
      this.props.gebruiker != prevProps.gebruiker
    ) {
      // this.props.updateFormValue("hasPartner", this.props.gebruiker.partner ? true : false);
      // this.props.updateFormValue("useCompanyAddress", this.props.gebruiker.addressId == (this.props.gebruiker.company ? this.props.gebruiker.company.addressId : '') ? true : false);
      // this.props.updateFormValue("hasSeperatePartnerAddress", this.props.gebruiker.partner && this.props.gebruiker.partner.address && (this.props.gebruiker.partner.addressId == this.props.gebruiker.addressId) ? false : true);
    }

    if (this.props !== prevProps) {
      this.setState({
        company: this.props.companyId
          ? this.props.bedrijven.find((b) => b.id == this.props.companyId)
          : this.props.bedrijven[0],
        localContactpersoonSoorten: this.setLocalState(),
      });
    }
  };

  setLocalState = () => {
    let contactPersoonSoorten: Array<{ label: string; value: string }> =
      this.props.contactpersoonSoorten.filter((c) => c.label !== "Gast");

    if (this.props.isEdit) {
      if (
        this.props.formValues &&
        this.props.gebruiker &&
        this.props.formValues.type !== undefined &&
        this.props.bedrijven.find(
          (b) => b.id == this.props.formValues.companyId
        ) &&
        this.props.bedrijven.find(
          (b) => b.id == this.props.formValues.companyId
        )!.hasHoofdContactpersoon &&
        !(
          this.props.formValues.companyId == this.props.gebruiker.companyId &&
          this.props.formValues.type.toString() ===
            ContactpersoonSoort.Hoofdcontactpersoon.toString()
        )
      ) {
        contactPersoonSoorten = contactPersoonSoorten.filter(
          (c) => c.label !== "Hoofdcontactpersoon"
        );
      }
      if (
        this.props.gebruiker &&
        this.props.gebruiker.type !=
          ContactpersoonSoort.Partner.valueOf().toString()
      ) {
        contactPersoonSoorten = contactPersoonSoorten.filter(
          (c) => c.label !== "Partner"
        );
      }
      return contactPersoonSoorten;
    } else {
      contactPersoonSoorten = contactPersoonSoorten.filter(
        (c) => c.label !== "Partner"
      );
      var bedrijf = this.state && this.state.company;

      if (bedrijf && bedrijf.hasHoofdContactpersoon) {
        contactPersoonSoorten = contactPersoonSoorten.filter(
          (c) => c.label !== "Hoofdcontactpersoon"
        );
      }

      return contactPersoonSoorten;
    }
  };

  onChangeBedrijf = (value: any) => {
    var bedrijf = this.props.bedrijven.find(
      (b) => b.id.toString() === value.toString()
    );

    // TODO: this.props.bedrijven bevat niet de adres gegevens van een Company
    //if (bedrijf) {
    //    this.props.updateFormValue("address.street", bedrijf.address.street);
    //    this.props.updateFormValue("address.houseNumber", bedrijf.address.houseNumber);
    //    this.props.updateFormValue("address.postalCode", bedrijf.address.postalCode);
    //    this.props.updateFormValue("address.city", bedrijf.address.city);
    //}

    if (
      bedrijf &&
      bedrijf.hasHoofdContactpersoon &&
      this.props.gebruiker &&
      this.props.gebruiker.companyId == bedrijf.id &&
      this.props.gebruiker.type.toString() !==
        ContactpersoonSoort.Hoofdcontactpersoon.toString()
    ) {
      this.setState({
        localContactpersoonSoorten: this.props.contactpersoonSoorten.filter(
          (c) => c.label !== "Hoofdcontactpersoon"
        ),
      });
      this.props.updateFormValue(
        "soort",
        this.state.localContactpersoonSoorten[0].value
      );
    } else {
      this.setState({
        localContactpersoonSoorten: this.props.contactpersoonSoorten,
        company: bedrijf,
      });
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      gebruiker,
      archiveContactpersoon,
      resendActivationMail,
      bedrijven,
      isEdit = false,
      isModal = false,
      onSubmit,
      onCancel,
      formValues,
      hasPartner,
      useCompanyAddress,
      hasSeperatePartnerAddress,
      socialSettings,
    } = this.props;

    let buttons: JSX.Element = <></>;
    let title: JSX.Element = <></>;
    let voorkeuren: JSX.Element[] = [];
    let emailadres: JSX.Element;
    let soort: JSX.Element = <></>;
    let company: JSX.Element = <></>;
    let aanmeldenEvents: JSX.Element = <></>;
    let bestellenActies: JSX.Element = <></>;

    let selectBedrijven = bedrijven.map(function (bedrijf) {
      return { label: bedrijf.name, value: bedrijf.id.toString() };
    });

    const selectedPersoonRol = gebruiker
      ? this.state.localContactpersoonSoorten.find(
          (p) => p.value == gebruiker.type
        )
      : "";
    const readOnlyForPartner =
      selectedPersoonRol &&
      selectedPersoonRol.value ==
        ContactpersoonSoort.Partner.valueOf().toString();
    let dateFormat = readOnlyForPartner ? "dd-MM" : "dd-MM-yyyy";

    var partnerVisible = formValues ? formValues.type == "0" : true;

    soort = (
      <Field
        component={FormDropdown}
        label="Soort"
        name="type"
        showSideBySide={true}
        required={true}
        readOnly={isReadOnly}
        options={this.state.localContactpersoonSoorten}
      />
    );

    company = (
      <Field
        component={FormDropdown}
        label="Bedrijf"
        name="companyId"
        showSideBySide={true}
        required={true}
        validate={required}
        options={selectBedrijven}
        readOnly={isReadOnly}
        {...{
          onValueChanged: (value: any) => {
            this.onChangeBedrijf(value);
          },
        }}
      />
    );

    aanmeldenEvents = (
      <Field
        component={FormCheckbox}
        label="Mag andere contactpersonen/introducees aanmelden voor events"
        name="aanmeldenEvents"
        showSideBySide={true}
      />
    );

    bestellenActies = (
      <Field
        component={FormCheckbox}
        label="Mag bestellingen doen bij acties"
        name="bestellenActies"
        showSideBySide={true}
      />
    );

    if (isEdit && isModal == false) {
      title = (
        <div className="column subHeader is-full">
          <h1>Contactpersoon</h1>
        </div>
      );

      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              {!isReadOnly && (
                <button
                  type="button"
                  className="button is-success delete-button"
                  onClick={() => archiveContactpersoon!(gebruiker!.id)}
                >
                  Archiveren
                </button>
              )}
              {!isReadOnly && (
                <button
                  type="button"
                  className="button"
                  onClick={() => resendActivationMail!(gebruiker!.id)}
                >
                  Verstuur activatiemail
                </button>
              )}
              <button type="button" className="button" onClick={onCancel}>
                Annuleren
              </button>
              {!isReadOnly && (
                <button
                  type="submit"
                  disabled={submitting}
                  className="button is-success"
                >
                  Opslaan
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="content">
        <Form
          className="singleCompany"
          onSubmit={handleSubmit(this.onFormSubmit)}
        >
          <div className="columns contentPanel is-multiline is-mobile">
            {title}

            <Field
              component={FormTextInput}
              label="Voornaam"
              name="firstname"
              showSideBySide={true}
              required={true}
              validate={[required]}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Achternaam"
              name="surname"
              showSideBySide={true}
              required={true}
              validate={[required]}
              readOnly={isReadOnly}
            />

            {soort}

            <Field
              component={FormTextInput}
              label="Emailadres"
              name="emailAddress"
              showSideBySide={true}
              required={true}
              validate={[required, email]}
              readOnly={isReadOnly}
            />

            {company}

            <Field
              component={FormTextInput}
              label="Telefoon"
              hidden={readOnlyForPartner}
              name="phoneNumber"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormDatePicker}
              label="Geboortedatum"
              name="birthDate"
              showSideBySide={true}
              dateFormat={dateFormat}
              readOnly={isReadOnly}
            />
            <Field
              component={FormCheckbox}
              label="App gebruik geblokkeerd"
              name="isBlockedFromApp"
              showSideBySide={true}
            />
            <Field
              component={FormCheckbox}
              label="Is tekenbevoegd"
              name="isQualified"
              showSideBySide={true}
            />

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop" />

            {partnerVisible && (
              <Field
                component={FormCheckbox}
                showSideBySide={true}
                label="Partner"
                readOnly={isReadOnly}
                name="hasPartner"
                className="checkbox"
              />
            )}
            <Field component={HiddenInput} name="partnerId" />

            {partnerVisible && hasPartner && (
              <>
                <h3
                  className="marginless-spacer-title"
                  style={{ marginTop: 0, marginBottom: 10 }}
                >
                  Partner
                </h3>
                <Field
                  component={FormTextInput}
                  label="Voornaam"
                  name="partner.firstname"
                  showSideBySide={true}
                  required={true}
                  validate={[required]}
                  readOnly={isReadOnly}
                />

                <Field
                  component={FormTextInput}
                  label="Achternaam"
                  name="partner.surname"
                  showSideBySide={true}
                  required={true}
                  validate={[required]}
                  readOnly={isReadOnly}
                />

                <Field
                  component={FormDatePicker}
                  label="Geboortedatum"
                  name="partner.birthDate"
                  showSideBySide={true}
                  required={true}
                  validate={[required]}
                  dateFormat="dd-MM"
                  readOnly={isReadOnly}
                />

                {/* <span className="checkboxWrapper"> */}
                <Field
                  component={FormCheckbox}
                  showSideBySide={false}
                  label="Apart adres"
                  name="hasSeperatePartnerAddress"
                  readOnly={isReadOnly}
                  className="checkbox"
                />
                {/* </span> */}

                {hasSeperatePartnerAddress && (
                  <>
                    <Field
                      component={FormTextInput}
                      label="Straat"
                      name="partner.address.street"
                      showSideBySide={true}
                      required={true}
                      readOnly={isReadOnly}
                      validate={[required]}
                    />

                    <Field
                      component={FormTextInput}
                      label="Huisnr"
                      name="partner.address.houseNumber"
                      showSideBySide={true}
                      required={true}
                      validate={[required, lengthmax6]}
                      readOnly={isReadOnly}
                    />

                    <Field
                      component={FormTextInput}
                      label="Postcode"
                      name="partner.address.postalCode"
                      showSideBySide={true}
                      required={true}
                      validate={[required, postalcode]}
                      readOnly={isReadOnly}
                    />

                    <Field
                      component={FormTextInput}
                      label="Plaats"
                      name="partner.address.city"
                      showSideBySide={true}
                      required={true}
                      validate={[required]}
                      readOnly={isReadOnly}
                    />
                  </>
                )}
              </>
            )}

            {socialSettings && (
              <>
                <h3
                  className="marginless-spacer-title"
                  style={{ marginTop: 0, marginBottom: 10 }}
                >
                  Notificaties
                </h3>
                <Field
                  component={FormCheckbox}
                  label="Event Email"
                  name="socialSettings.eventEmail"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                />
                <Field
                  component={FormCheckbox}
                  label="Event Pushnotificaties"
                  name="socialSettings.eventPushNotification"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                />
                <Field
                  component={FormCheckbox}
                  label="Acties Email"
                  name="socialSettings.actionsEmail"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                />
                <Field
                  component={FormCheckbox}
                  label="Acties Pushnotification"
                  name="socialSettings.actionsPushNotification"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                />
                <Field
                  component={FormCheckbox}
                  label="Nieuws Email"
                  name="socialSettings.newsEmail"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                />
                <Field
                  component={FormCheckbox}
                  label="Nieuws Pushnotification"
                  name="socialSettings.newsPushNotification"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                />
                <Field
                  component={FormCheckbox}
                  label="Netwerk Email"
                  name="socialSettings.networkEmail"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                />
                <Field
                  component={FormCheckbox}
                  label="Netwerk Pushnotification"
                  name="socialSettings.networkPushNotification"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                />
              </>
            )}

            {/* {aanmeldenEvents} */}
            {/* {bestellenActies} */}
          </div>

          <div className="border-spacer margin-spacer" />

          {/* <div className="column subHeader is-full">
                        <h1>Emailvoorkeuren</h1>
                    </div> */}

          <div className="floating-btn-bar">{buttons}</div>
        </Form>
      </div>
    );
  }
}

const ContactpersoonReduxForm = reduxForm<Contactpersoon, OwnProps>({
  form: "ContactpersoonForm",
  enableReinitialize: true,
})(ContactpersoonComponent);

export default connect((state: State, props: OwnProps) => {
  let address =
    state &&
    state.gebruiker &&
    (state.gebruiker.address as Address | undefined);
  let partner =
    state &&
    state.gebruiker &&
    (state.gebruiker.partner as Contactpersoon | undefined);
  let company =
    state &&
    state.gebruiker &&
    (state.gebruiker.company as Bedrijf | undefined);
  let socialSettings =
    state && (state.socialSettings as SocialNotificationSettings | undefined);
  return {
    initialValues: {
      id: props.isEdit && state.gebruiker ? state.gebruiker.id : "",
      firstname:
        props.isEdit && state.gebruiker ? state.gebruiker.firstname : "",
      surname: props.isEdit && state.gebruiker ? state.gebruiker.surname : "",
      type:
        props.isEdit && state.gebruiker
          ? state.gebruiker.type && state.gebruiker.type
          : state.contactpersoonSoorten[1].value &&
            state.contactpersoonSoorten[1].value,
      name: props.isEdit && state.gebruiker ? state.gebruiker.name : "",
      emailAddress:
        props.isEdit && state.gebruiker ? state.gebruiker.emailAddress : "",
      phoneNumber:
        props.isEdit && state.gebruiker ? state.gebruiker.phoneNumber : "",
      birthDate:
        props.isEdit && state.gebruiker
          ? state.gebruiker.birthDate
          : new Date().toDateString(),
      addressId:
        props.isEdit && state.gebruiker ? state.gebruiker.addressId : undefined,
      partnerId:
        props.isEdit && state.gebruiker ? state.gebruiker.partnerId : undefined,
      isBlockedFromApp:
        props.isEdit && state.gebruiker
          ? state.gebruiker.isBlockedFromApp
          : undefined,
      isQualified:
        props.isEdit && state.gebruiker ? state.gebruiker.isQualified : false,
      hasPartner:
        props.isEdit && state.gebruiker
          ? state.gebruiker.partner !== null
          : false,
      useCompanyAddress:
        props.isEdit && state.gebruiker
          ? state.gebruiker.addressId ==
            (state.gebruiker.company ? state.gebruiker.company.addressId : "")
            ? true
            : false
          : false,
      hasSeperatePartnerAddress:
        props.isEdit && state.gebruiker
          ? state.gebruiker.partner &&
            state.gebruiker.partner.address &&
            state.gebruiker.partner.addressId == state.gebruiker.addressId
            ? false
            : true
          : false,

      address: props.isEdit && address ? address : undefined,
      partner: props.isEdit && partner ? partner : undefined,
      company: props.isEdit && company ? company : undefined,
      socialSettings:
        props.isEdit && socialSettings
          ? socialSettings
          : ({
              actionsEmail: true,
              actionsPushNotification: true,
              eventEmail: true,
              eventPushNotification: true,
              networkEmail: true,
              networkPushNotification: true,
              newsEmail: true,
              newsPushNotification: true,
            } as SocialNotificationSettings),

      aanmeldenEvents:
        props.isEdit && state.gebruiker
          ? state.gebruiker.aanmeldenEvents
          : false,
      bestellenActies:
        props.isEdit && state.gebruiker
          ? state.gebruiker.bestellenActies
          : false,
      companyName:
        props.isEdit && state.gebruiker && state.gebruiker.company
          ? state.gebruiker.company.name
          : "",
      companyId:
        props.isEdit && state.gebruiker
          ? state.gebruiker.companyId
          : props.companyId
          ? props.companyId
          : props.bedrijven.length > 0
          ? props.bedrijven[0].id
          : "",
    },
  };
})(ContactpersoonReduxForm);
