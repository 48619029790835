import * as actionTypes from "../constants/gebruikerActionTypes";
import { AsyncAction } from ".";
import { GetGebruikersAction, GetGebruikerAction, GetRollenAction, GetRolAction, GetVerantwoordelijkenAction, GetContactpersoonByEmailAction } from "../constants/gebruikerActionTypes";
import GebruikerApi from "../api/GebruikerApi";

export const getGebruikers = (): AsyncAction<GetGebruikersAction> => {
    return async (dispatch) => {
        const gebruikers = await GebruikerApi.getGebruikers();
        return dispatch({ type: actionTypes.GET_GEBRUIKERS, gebruikers });
    };
}

export const getRollen = (): AsyncAction<GetRollenAction> => {
    return async (dispatch) => {
        const rollen = await GebruikerApi.getAllRoles();
        return dispatch({ type: actionTypes.GET_ROLLEN, rollen });
    };
}

export const getRol = (id : string): AsyncAction<GetRolAction> => {
    return async (dispatch) => {
        const rol = await GebruikerApi.getRole(id);
        return dispatch({ type: actionTypes.GET_ROL, rol });
    };
}

export const getContactpersoon = (id: string): AsyncAction<GetGebruikerAction> => {
    return async (dispatch) => {
        const gebruiker = await GebruikerApi.getContactPersoon(id);

        return dispatch({ type: actionTypes.GET_GEBRUIKER, gebruiker });
    };
}

export const getVerantwoordelijken = (): AsyncAction<GetVerantwoordelijkenAction> => {
    return async (dispatch) => {
        const verantwoordelijken = await GebruikerApi.getVerantwoordelijken();
        return dispatch({ type: actionTypes.GET_VERANTWOORDELIJKEN, verantwoordelijken });
    };
}

export const getGebruiker = (id: string): AsyncAction<GetGebruikerAction> => {
    return async (dispatch) => {
        const gebruiker = await GebruikerApi.getGebruiker(id);
        return dispatch({ type: actionTypes.GET_GEBRUIKER, gebruiker });
    };
}

export const getContactPersoonByEmail = (emailAdres: string): AsyncAction<GetContactpersoonByEmailAction> => {
    return async (dispatch) => {
        const contactPersoon = await GebruikerApi.getContactPersoonByEmail(emailAdres);
        return dispatch({ type: actionTypes.GET_PERSOON_BY_EMAIL, contactPersoon });
    };
}