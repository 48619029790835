import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";

interface Props {
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  showSideBySide?: boolean;
}

export const TextArea = (props: WrappedFieldProps & Props) => {
  const {
    input,
    label,
    meta: { touched, error },
    placeholder,
    showSideBySide,
    readOnly,
  } = props;

  let style = {};

  let wrapperClass = "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

  if (showSideBySide) wrapperClass = classnames(wrapperClass, "is-half-desktop");
  else wrapperClass = classnames(wrapperClass, "is-full-desktop");

  //style = { paddingTop: '.5rem', paddingRight: '2rem' };

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label">
          <label className="label bcwLabel is-pulled-left" htmlFor={input.name} style={style}>
            {label}
          </label>
        </div>
      )}
      <div className="field-body">
        <textarea
          {...input}
          className={classnames("textarea", touched && error && "is-danger")}
          placeholder={placeholder}
          rows={4}
          cols={50}
          readOnly={readOnly}
        />
        {touched && error && <p className="help is-danger">{error}</p>}
      </div>
    </div>
  );
};
