import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";

interface Props {
  placeholder?: string;
  label?: string;
  hidden?: boolean;
  readOnly?: boolean;
  type?: string;
  showSideBySide?: boolean;
  required?: boolean;
}

export class FormTextInput extends React.Component<WrappedFieldProps & Props> {
  render() {
    const {
      input,
      label,
      type = "text",
      required,
      meta: { touched, error },
      placeholder,
      showSideBySide,
      readOnly,
      hidden,
    } = this.props;

    let style = {};

    let wrapperClass =
      "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

    if (showSideBySide)
      wrapperClass = classnames(wrapperClass, "is-half-desktop");
    else wrapperClass = classnames(wrapperClass, "is-full-desktop");

    return (
      <div className={wrapperClass}>
        {!hidden && (
          <>
            {label && (
              <div className="field-label is-normal">
                <label
                  className="label is-pulled-left"
                  htmlFor={input.name}
                  style={style}
                >
                  {label} {required && <span style={{ color: "red" }}>*</span>}
                </label>
              </div>
            )}
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    {...input}
                    type={type}
                    className={classnames(
                      "input",
                      touched && error && "is-danger",
                      readOnly && "is-readonly"
                    )}
                    placeholder={placeholder}
                    readOnly={readOnly}
                  />
                  {touched && error && (
                    <p className="help is-danger">{error}</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
