import * as React from 'react';
import GebruikersComponent from '../components/GebruikersComponent/GebruikersComponent';
import { connect } from 'react-redux';
import { Person, Role } from '../types/model';
import { Modal } from '../components/Modal';
import { Column, Filter, RowInfo } from 'react-table';
import matchSorter from 'match-sorter';
import history from '../components/history';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { submit, change } from 'redux-form';
import RolComponent from '../components/RolComponent/RolComponent';
import RollenComponent from '../components/RollenComponent/RollenComponent';
import { getRollen } from '../actions/gebruikerActions';
import { toast } from 'react-toastify';
import GebruikerApi from '../api/GebruikerApi';
import { showLoading, hideLoading } from '../actions/loadingActions';
import { RootState } from "../types/state";
import { PersonType } from '../constants/enums';

interface State {
    showEditModal: boolean;
}

export type Props = OwnProps & StateProps & DispatchProps;

export interface OwnProps {
}

export interface StateProps {
    rollen: Array<Role>;
}

export interface DispatchProps {
    updateFormValue: (field: string, value: number[]) => any;
    submitForm: (form: string) => any;
    getRollen: () => any;
    showLoading: () => any;
    hideLoading: () => any;
}

class RollenContainer extends React.Component<Props, State> {
    state: State = {
        showEditModal: false
    };

    componentDidMount() {
        this.props.getRollen();
    }

    onRowClick(event: React.ChangeEvent, rowInfo: RowInfo) {
        history.push("/Rol/" + rowInfo.original.id);
    }

    createRole = async (formValues: any) => {
        const role = {
            id : formValues.id,
            name : formValues.rolName,
            permissions: formValues.permissions
        } as Role
        this.props.showLoading();

        await GebruikerApi.upsertRole(role)
            .then(() => {
                this.props.getRollen();
                toast.success("Het opslaan is succesvol");
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => this.props.hideLoading());
    }

    openModal = () => {
        this.setState({ showEditModal: true });
    }

    updateFormValue = (field: string, value: number[]) => {
        this.props.updateFormValue(field, value);
    }

    closeModal = () => {
        this.setState({ showEditModal: false });
    }

    rollen = () => ([
        {
            Header: "Naam",
            accessor: "name",
            headerStyle: {
                textAlign: "left"
            },
            filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [filter.id] }),
            filterAll: true
        }
    ] as Array<Column>)

    render() {
        const { rollen, submitForm } = this.props;

        return (
            <>
                <RollenComponent onRowClick={this.onRowClick} columns={this.rollen()} openModal={this.openModal} rollen={rollen} />
                {
                    this.state.showEditModal &&
                    <Modal
                        title={"Rol toevoegen"}
                        content={<RolComponent updateFormValue={this.updateFormValue} onSubmit={this.createRole} />}
                        onSubmit={() => submitForm("RolForm")}
                        onClose={this.closeModal}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({ rollen: [...state.rollen] })

}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    submitForm: (form: string) => dispatch(submit(form)),
    updateFormValue: (field: string, value: number[]) => dispatch(change("RolForm", field, value)),
    getRollen: () => dispatch(getRollen()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
})

export default connect(mapStateToProps, mapDispatchToProps)(RollenContainer)

