import { Person, Verantwoordelijke, Role } from "../types/model";
import * as types from '../constants/gebruikerActionTypes';

const initialGebruikersState: Array<Person> = [];
const initialVerantwoordelijkeState : Array<Verantwoordelijke> = [];
const initialRollenState : Array<Role> = [];
const initialRolState = {} as Role;
const initialGebruikerState = {} as Person;
const initialContactPersoonState = {} as Person;

type GebruikersState = typeof initialGebruikersState;
type VerantwoordelijkeState = typeof initialVerantwoordelijkeState;
type GebruikerState = typeof initialGebruikerState;
type RollenState = typeof initialRollenState;
type RolState = typeof initialRolState;
type ContactpersoonState = typeof initialContactPersoonState;

export const gebruikersReducer = (
    state: GebruikersState = initialGebruikersState,
    action: types.GetGebruikersAction
) => {
    if (action.type === types.GET_GEBRUIKERS)
        return action.gebruikers;

    return state;
};

export const rollenReducer = (
    state: RollenState = initialRollenState,
    action: types.GetRollenAction
) => {
    if (action.type === types.GET_ROLLEN)
        return action.rollen;

    return state;
};

export const rolReducer = (
    state: RolState = initialRolState,
    action: types.GetRolAction
) => {
    if (action.type === types.GET_ROL)
        return action.rol;

    return state;
};

export const verantwoordelijkenReducer = (
    state: VerantwoordelijkeState = initialVerantwoordelijkeState,
    action: types.GetVerantwoordelijkenAction
) => {
    if (action.type === types.GET_VERANTWOORDELIJKEN)
        return action.verantwoordelijken;

    return state;
};

export const gebruikerReducer = (
    state: GebruikerState = initialGebruikerState,
    action: types.GetGebruikerAction
) => {
    if (action.type === types.GET_GEBRUIKER)
        return action.gebruiker;

    return state;
};

export const contactpersoonByEmailReducer = (
    state: ContactpersoonState = initialContactPersoonState,
    action: types.GetContactpersoonByEmailAction
) => {
    if (action.type === types.GET_PERSOON_BY_EMAIL)
        return action.contactPersoon;

    return state;
};
