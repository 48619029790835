import React from "react";
import { Field, InjectedFormProps } from "redux-form";
import { Bedrijf } from "../../types/model";

export interface OwnProps {
  company?: Bedrijf;
  onCompanyTypeChange: (companyType: number) => void;
  readonly?: boolean;
}

export interface State {
  companyType: number;
}

export class CompanyTypeComponent extends React.Component<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props);

    this.state = { companyType: 0 };
  }

  onChangeSelected = (value: number) => {
    this.setState({ companyType: value });
    this.props.onCompanyTypeChange(value);
  };

  render() {
    return (
      <div className="field column is-horizontal is-full-mobile is-full-tablet is-half-desktop">
        <div className="field-label">
          <label className="label is-pulled-left">Soort</label>
        </div>
        <div className="control">
          <label className="radio">
            <Field
              onClick={() => this.onChangeSelected(0)}
              disabled={this.props.readonly}
              checked={this.props.company ? this.props.company.type == 0 : this.state.companyType == 0}
              name="type"
              component="input"
              type="radio"
              value="0"
            />{" "}
            B.V.
          </label>
          <label className="radio">
            <Field
              onClick={() => this.onChangeSelected(1)}
              disabled={this.props.readonly}
              checked={this.props.company ? this.props.company.type == 1 : this.state.companyType == 1}
              name="type"
              component="input"
              type="radio"
              value="1"
            />{" "}
            V.OF
          </label>
          <label className="radio">
            <Field
              onClick={() => this.onChangeSelected(2)}
              disabled={this.props.readonly}
              checked={this.props.company ? this.props.company.type == 2 : this.state.companyType == 2}
              name="type"
              component="input"
              type="radio"
              value="2"
            />{" "}
            Eenmanszaak
          </label>
        </div>
      </div>
    );
  }
}
