import { Column, Filter, RowInfo } from "react-table";
import { EditorState, convertToRaw } from "draft-js";
import { Event, ListEvent, SponsorDealType } from "../types/model";
import { EventStatus, UserRol } from "../constants/enums";
import React, { ChangeEvent } from "react";
import { getEvents, getEventsForUser } from "../actions/eventActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import EventApi from "../api/EventApi";
import EventComponent from "../components/EventComponent/EventComponent";
import EventsComponent from "../components/EventsComponent/EventsComponent";
import { Modal } from "../components/Modal";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import { combineDateAndTime } from "../helpers/Moment";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { getSponsordealSoorten } from "../actions/sponsordealSoortActions";
import history from "../components/history";
import matchSorter from "match-sorter";
import moment from "moment";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
  getEvents: () => any;
  getEventsForUser: () => any;
  getSponsordealSoorten: () => any;
  submitForm: (form: string) => any;
  showLoading: () => any;
  hideLoading: () => any;
}

interface StateProps {
  events: ListEvent[];
  sponsorDealTypes: SponsorDealType[];
}

type Props = StateProps & DispatchProps;

interface State {
  showCreateModal: boolean;
  selectedEvents: string[];
  archief: boolean;
}

class EventsContainer extends React.Component<Props, State> {
  state: State = {
    showCreateModal: false,
    selectedEvents: [],
    archief: false,
  };

  componentDidMount() {
    this.props.getEvents();
    this.props.getSponsordealSoorten();
  }

  onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
    history.push("/Events/" + rowInfo.original.id);
  };

  openCreateEventModal = () => {
    this.setState({ showCreateModal: true });
  };

  createEvent = (formValues: Event, editorState: EditorState) => {
    var event = formValues;

    event.item.startDateTime = combineDateAndTime(
      event.item.startDateTime,
      event.item.startTime
    );
    event.item.endDateTime = combineDateAndTime(
      event.item.endDateTime,
      event.item.endTime
    );

    // by adding a new event we dont need sponsordealtypes to be submitted as deleted/archived
    if (!event.id) {
      event.eventSponsorDealTypes = event.eventSponsorDealTypes.filter(
        (i) => i.isDeleted == false
      );
    }

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(rawContentState);
    if (event) event.item.description = html || "";

    EventApi.createEvent(event)
      .then(() => {
        this.props.getEvents();
        toast.success("Het opslaan is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => {
        this.onClose();
      });
  };

  openArchief = () => {
    this.setState({ archief: this.state.archief ? false : true });
  };

  onClose = () => {
    this.setState({ showCreateModal: false });
  };

  archiveSelectedEvents = () => {
    if (this.state.selectedEvents.length > 0) {
      this.props.showLoading();
      EventApi.archiveSelectedEvents(this.state.selectedEvents)
        .then(() => {
          this.props.getEvents();
          this.setState({ selectedEvents: [] });
          toast.success("Het archiveren is succesvol");
        })
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => {
          this.props.hideLoading();
        });
    }
  };

  exportSelectedEvents = () => {
    if (this.state.selectedEvents.length > 0) {
      this.props.showLoading();
      EventApi.exportSelectedEvents(this.state.selectedEvents)
        .then(() => {
          this.props.getEvents();
          this.setState({ selectedEvents: [] });
          toast.success("Het exporteren is succesvol");
        })
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => {
          this.props.hideLoading();
        });
    }
  };

  sendCalendarInviteSelectedEvents = () => {
    if (this.state.selectedEvents.length > 0) {
      toast.success("De emails worden verstuurd");
      EventApi.sendCalendarInviteSelectedEvents(this.state.selectedEvents)
        .then(() => {
          this.props.getEvents();
          this.setState({ selectedEvents: [] });
          toast.success("Het versturen is succesvol");
        })
        .catch(() => toast.error("Er is een fout opgetreden"));
    }
  };

  sendReminderSelectedEvents = () => {
    if (this.state.selectedEvents.length > 0) {
      toast.success("De emails worden verstuurd");
      EventApi.sendReminderSelectedEvents(this.state.selectedEvents)
        .then(() => {
          this.props.getEvents();
          this.setState({ selectedEvents: [] });
          toast.success("Het versturen is succesvol");
        })
        .catch(() => toast.error("Er is een fout opgetreden"));
    }
  };

  sendInvitationSelectedEvents = () => {
    if (this.state.selectedEvents.length > 0) {
      toast.success("De emails worden verstuurd");
      EventApi.sendInvitationSelectedEvents(this.state.selectedEvents)
        .then(() => {
          this.props.getEvents();
          this.setState({ selectedEvents: [] });
          toast.success("Het versturen is succesvol");
        })
        .catch(() => toast.error("Er is een fout opgetreden"));
    }
  };

  selectEvent = (event: any) => {
    const eventId = event.target.value;

    if (event.target.checked) {
      if (this.state.selectedEvents.find((item) => item === eventId) == null) {
        const selectedEvents = [...this.state.selectedEvents, eventId];
        this.setState({ selectedEvents: selectedEvents });
      }
    } else {
      var index = this.state.selectedEvents.indexOf(eventId);
      const selectedEvents = [
        ...this.state.selectedEvents.slice(0, index),
        ...this.state.selectedEvents.slice(index + 1),
      ];
      this.setState({ selectedEvents: selectedEvents });
    }
  };

  selectAlleEvents = (event: any) => {
    if (event.target.checked) {
      const selectedEvents = this.state.selectedEvents;
      this.props.events.forEach((event) => {
        if (
          this.state.selectedEvents.find((item) => item === event.id) == null
        ) {
          selectedEvents.push(event.id);
        }
      });
      this.setState({ selectedEvents: selectedEvents });
    } else {
      this.setState({ selectedEvents: [] });
    }
  };

  render() {
    const { events, sponsorDealTypes } = this.props;

    const userRol = localStorage.getItem("userRol");

    let columns: Array<Column> = [
      {
        Header: "Titel",
        accessor: "eventName",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Datum",
        accessor: "startDate",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) =>
          props && props.value ? moment(props.value).format("DD-MM-YYYY") : "",
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Status",
        accessor: "status",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) => {
          var status = EventStatus[props.value];
          return status;
        },
        filterMethod: (filter: Filter, rows: any) => {
          const id = filter.id;
          return rows[id] !== undefined
            ? String(EventStatus[rows[id]].toLowerCase()).startsWith(
                filter.value.toLowerCase()
              )
            : true;
        },
      },
    ];

    let eventList = events;

    // if (this.state.archief)
    //     eventList = eventList.filter(e => e.isGearchiveerd == true);
    // else
    //     eventList = eventList.filter(e => e.isGearchiveerd == false);

    //checkbox column en status column weghalen voor users
    // userRol && UserRolHelper(userRol, [UserRol.Hoofdcontactpersoon, UserRol.Contactpersoon]) && columns.shift();
    // userRol && UserRolHelper(userRol, [UserRol.Hoofdcontactpersoon, UserRol.Contactpersoon]) && columns.pop();

    return (
      <>
        <EventsComponent
          onRowClick={this.onRowClick}
          openModal={this.openCreateEventModal}
          openArchief={this.openArchief}
          exportSelectedEvents={this.exportSelectedEvents}
          sendCalendarInviteSelectedEvents={
            this.sendCalendarInviteSelectedEvents
          }
          sendReminderSelectedEvents={this.sendReminderSelectedEvents}
          sendInvitationSelectedEvents={this.sendInvitationSelectedEvents}
          hideExport={this.state.selectedEvents.length <= 1}
          archiefButtonText={this.state.archief ? "Terug" : "Archief"}
          archiverenButtonText={
            this.state.archief ? "Dearchiveren" : "Archiveren"
          }
          archiveSelectedEvents={this.archiveSelectedEvents}
          columns={columns}
          events={eventList}
        />
        {this.state.showCreateModal ? (
          <Modal
            title="Nieuw event"
            content={
              <EventComponent
                isNew={true}
                sponsorDealTypes={sponsorDealTypes}
                onSubmit={this.createEvent}
              />
            }
            onSubmit={() => this.props.submitForm("EventForm")}
            onClose={this.onClose}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  events: state.events,
  sponsorDealTypes: state.sponsorDealTypes,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getEvents: () => dispatch(getEvents()),
  getEventsForUser: () => dispatch(getEventsForUser()),
  getSponsordealSoorten: () => dispatch(getSponsordealSoorten()),
  submitForm: (form: string) => dispatch(submit(form)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsContainer);
