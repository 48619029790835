import ApiClient from './ApiClient';

import { SponsorDealType } from "../types/model";

const endpoint = 'sponsordeal/type';

export default class SponsorDealApi {
    public static async getSponsorDealTypes(): Promise<SponsorDealType[]> {
        var sponsorDealTypes = await ApiClient.doHttpRequest<SponsorDealType[]>('get', `api/${endpoint}`);
        return sponsorDealTypes;
    }

    public static async getSponsorDealType(id: number): Promise<SponsorDealType> {
        var sponsorDealType = await ApiClient.doHttpRequest<SponsorDealType>('get', `api/${endpoint}/${id}`);
        return sponsorDealType;
    }

    public static async createSponsorDealType(sponsordealSoort: SponsorDealType): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `api/${endpoint}`, sponsordealSoort);
    }

    public static async updateSponsorDealType(sponsordealSoort: SponsorDealType): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `api/${endpoint}`, sponsordealSoort);
    }
}