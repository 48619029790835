import "react-datepicker/dist/react-datepicker.css";

import ReactDatePicker, { registerLocale } from "react-datepicker";

import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";
import moment from "moment";
import nl from "date-fns/locale/nl";

registerLocale("nl", nl);

interface Props {
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  type?: string;
  showSideBySide?: boolean;
  required?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  showTimeFormat?: boolean;
}

export default class FormDatePicker extends React.Component<
  WrappedFieldProps & Props
> {
  onChange = (newDate: Date) => {
    this.props.input.onChange(
      newDate ? moment(newDate).format("YYYY-MM-DDTHH:mm").toString() : ""
    );
  };

  render() {
    const {
      input,
      label,
      required,
      meta: { touched, error },
      showSideBySide,
      dateFormat,
      timeFormat,
      showTimeFormat,
      readOnly,
    } = this.props;
    var format = dateFormat || "dd-MM-yyyy";
    const timeformat = timeFormat || "HH:mm";

    if (showTimeFormat) format += " " + timeformat;

    let style = {};

    let wrapperClass =
      "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

    if (showSideBySide)
      wrapperClass = classnames(wrapperClass, "is-half-desktop");
    else wrapperClass = classnames(wrapperClass, "is-full-desktop");

    //style = { paddingTop: '.5rem', paddingRight: '2rem' };
    return (
      <div className={wrapperClass}>
        {label && (
          <div className="field-label">
            <label
              className="label bcwLabel is-pulled-left"
              htmlFor={input.name}
              style={style}
            >
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </label>
          </div>
        )}
        <div className="field-body">
          <ReactDatePicker
            selected={input.value ? new Date(input.value) : null}
            name={input.name}
            className={classnames("input", touched && error && "is-danger")}
            dateFormat={format}
            dateFormatCalendar={format}
            showTimeSelect={showTimeFormat}
            timeFormat={timeformat}
            timeCaption="Tijdstip"
            timeIntervals={15}
            onChange={this.onChange}
            strictParsing={true}
            disabled={readOnly}
            todayButton={"Vandaag"}
          />
          {touched && error && <p className="help is-danger">{error}</p>}
        </div>
      </div>
    );
  }
}
