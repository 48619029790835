const MESSAGE_INCORRECT = "Onjuiste nederlandse postcode, wijzig land veld eerst.";
const MESSAGE_SPACE = "Postcode mag geen spaties bevatten.";
const MESSAGE_TO_LONG = "Postcode mag niet langer dan 10 karakters zijn"
const INLAND = "Nederland";

export default (value: string, formValues: any) => {
    if (INLAND === formValues.address.country) {
        if (value && !/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i.test(value)) {
            return MESSAGE_INCORRECT
        } else if (value && value.includes(" ")) {
            return MESSAGE_SPACE
        }
        return undefined
    }
    if(value && value.length > 14)
        return MESSAGE_TO_LONG;
    return undefined
}
