import HeaderComponent from "../components/HeaderComponent/HeaderComponent";
import React from "react";
import history from "../components/history";
import { connect } from "react-redux";
import { getBedrijfForHoofdcontactpersoon } from "../actions/bedrijfActions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Bedrijf, Contactpersoon, Settings } from "../types/model";
import { RootState } from "../types/state";
import userManager from "../utils/userManager";
import { getSettings } from "../actions/settingsActions";


interface DispatchProps {
    getBedrijfForHoofdcontactpersoon: () => any;
    getSettings: () => any;
}

interface StateProps {
    company: Bedrijf;
    contactpersoon: Contactpersoon;
    settings: Settings;
}

class HeaderContainer extends React.Component<DispatchProps & StateProps> {
    onLogout = () => {
        userManager.signoutRedirect();
    }

    render() {
        const { contactpersoon, company, settings } = this.props;

        var aanmeldenEvents = false;
        var bestellenActies = false;
        const userRol = localStorage.getItem("userRol");
        var logoUrl = settings.logoBlobUrl != undefined ? settings.logoBlobUrl : 'https://bcwpstg01.blob.core.windows.net/bijlagen/Settings/1/logobcwwwww2.png';
        var primaireKleur = settings.primaireKleur;
        var secundaireKleur = settings.secundaireKleur;

        aanmeldenEvents = true;
        bestellenActies = true;

        // else if (userRol && UserRolHelper(userRol, [UserRol.Contactpersoon])) {
        //     if (contactpersoon.aanmeldenEvents == true)
        //         aanmeldenEvents = true;
        //     if (contactpersoon.bestellenActies == true)
        //         bestellenActies = true;
        // }

        return (
            <HeaderComponent onLogout={this.onLogout} bedrijfId={company != null ? company.id : ''} aanmeldenEvents={aanmeldenEvents} bestellenActies={bestellenActies} logoUrl={logoUrl} primaireKleur={primaireKleur} secundaireKleur={secundaireKleur} />
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    company: state.company,
    contactpersoon: state.contactpersoon,
    settings: state.settings
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getBedrijfForHoofdcontactpersoon: () => dispatch(getBedrijfForHoofdcontactpersoon()),
    // getContactpersoonForUser: () => dispatch(getContactpersoonForUser()),
    getSettings: () => dispatch(getSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
