import { Column, Filter, RowInfo } from "react-table";
import { Permissions, Product } from "../types/model";
import React, { ChangeEvent } from "react";
import { getProduct, getProducten } from "../actions/productActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import { IsReadOnly } from "../helpers/PermissionHelper";
import { Modal } from "../components/Modal";
import ProductApi from "../api/ProductApi";
import ProductComponent from "../components/ProductComponent/ProductComponent";
import ProductenComponent from "../components/ProductenComponent/ProductenComponent";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import matchSorter from "match-sorter";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
    getProducten: () => any;
    getProduct: (id: number) => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    producten: Product[];
    product: Product;
}

interface OwnProps {
    bedrijfId?: number;
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
    showCreateModal: boolean;
    showEditModal: boolean;
    selectedProducten: number[];
    archief: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageProducts);
class ProductenContainer extends React.Component<Props, State> {
    state: State = {
        showCreateModal: false,
        showEditModal: false,
        selectedProducten: [],
        archief: false
    }

    componentDidMount() {
        this.props.getProducten();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        this.props.getProduct(rowInfo.original.id);
        this.setState({ showEditModal: true });
    }

    openCreateProductModal = () => {
        this.setState({ showCreateModal: true });
    }

    createProduct = (formValues: Product) => {
        var product = formValues;

        ProductApi.createProduct(product)
            .then(() => {
                this.props.getProducten();
                toast.success("Het opslaan is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.onClose();
            });
    }

    openArchief = () => {
        this.setState({ archief: this.state.archief ? false : true });
    }

    onClose = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false
        });
    }

    updateProduct = (formValues: Product) => {
        var product = formValues;

        ProductApi.updateProduct(product)
            .then(() => {
                this.props.getProducten();
                toast.success("Het opslaan is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.onClose();
            });
    }

    archiveSelectedProducten = () => {
        this.props.showLoading();
        ProductApi.archiveSelectedProducten(this.state.selectedProducten)
            .then(() => {
                this.props.getProducten();
                this.setState({ selectedProducten: [] });
                toast.success("Het archiveren is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.props.hideLoading();
            });
    }

    selectProduct = (event: any) => {
        const productId = event.target.value;

        if (event.target.checked) {
            if (this.state.selectedProducten.find(item => item === productId) == null) {
                const selectedProducten = [...this.state.selectedProducten, productId];
                this.setState({ selectedProducten: selectedProducten });
            }
        }
        else {
            var index = this.state.selectedProducten.indexOf(productId);
            const selectedProducten = [
                ...this.state.selectedProducten.slice(0, index),
                ...this.state.selectedProducten.slice(index + 1)
            ]
            this.setState({ selectedProducten: selectedProducten });
        }
    }

    selectAlleProducten = (event: any) => {
        if (event.target.checked) {
            const selectedProducten = this.state.selectedProducten;
            this.props.producten.forEach(product => {
                if (this.state.selectedProducten.find(item => item === product.id) == null) {
                    selectedProducten.push(product.id);
                }
            });
            this.setState({ selectedProducten: selectedProducten });
        }
        else {
            this.setState({ selectedProducten: [] });
        }
    }

    render() {
        const { producten, product, bedrijfId } = this.props;
        let columns: Array<Column> = [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedProducten.some(item => item.toString() === original.id.toString())}
                            value={original.id}
                            onChange={this.selectProduct}
                        />
                    );
                },
                Header: x => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedProducten.length === this.props.producten.length}
                            onChange={this.selectAlleProducten}
                        />
                    );
                },
                sortable: false,
                filterable: false,
                width: 55
            },
            {
                Header: "Naam",
                accessor: "name",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Stuksprijs",
                accessor: "unitPrice",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Btw-tarief",
                accessor: "vatRate",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => (props.value * 100) + "%",
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Voorraad",
                accessor: "stock",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]

        let productenList = producten;
        /*if (this.state.archief)
            productenList = productenList.filter(p => p.isArchived == true);
        else
            productenList = productenList.filter(p => p.isArchived == false);
        */
        return (
            <>
                <ProductenComponent onRowClick={this.onRowClick}
                    openModal={this.openCreateProductModal} openArchief={this.openArchief}
                    archiefButtonText={this.state.archief ? "Terug" : "Archief"} archiverenButtonText={this.state.archief ? "Dearchiveren" : "Archiveren"}
                    archiveSelectedProducten={this.archiveSelectedProducten} columns={columns} producten={productenList} />
                {this.state.showCreateModal
                    ? (<Modal
                        title="Nieuw product"
                        content={
                            <ProductComponent onSubmit={this.createProduct} />
                        }
                        onSubmit={() => this.props.submitForm("ProductForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
                {this.state.showEditModal
                    ? (<Modal
                        title="Aanpassen product"
                        content={
                            <ProductComponent product={product} onSubmit={this.updateProduct} isEdit={true} />
                        }
                        readOnly={isReadOnly}
                        onSubmit={() => this.props.submitForm("ProductForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    producten: state.producten,
    product: state.product
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getProducten: () => dispatch(getProducten()),
    getProduct: (id: number) => dispatch(getProduct(id)),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductenContainer);
