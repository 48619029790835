import "react-tabs/style/react-tabs.css";

import { Filter, Permissions } from "../../types/model";
import { ParseLocalStoragePermissionsToArray, ShowPage } from '../../helpers/PermissionHelper';
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { AnyAction } from "redux";
import BedrijfContainer from "../../containers/BedrijfContainer";
import BestandenContainer from "../../containers/BestandenContainer";
import ContactpersonenContainer from "../../containers/ContactpersonenContainer";
import FacturenContainer from "../../containers/FacturenContainer";
import { IsReadOnly } from '../../helpers/PermissionHelper';
import LeadsContainer from "../../containers/LeadsContainer";
import PartnersContainer from "../../containers/PartnersContainer";
import React from "react";
import { RootState } from "../../types/state";
import SponsordealsContainer from "../../containers/SponsordealsContainer";
import TakenContainer from "../../containers/TakenContainer";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { setBedrijfTabIndex } from "../../actions/bedrijfActions";
import { setFilter } from "../../actions/gridviewActions";

interface DispatchProps {
    setBedrijfTabIndex: (bedrijfTabIndex: number) => any;
    setFilter: (gridviewId: string, filter: Filter) => void;
}

interface StateProps {
    bedrijfTabIndex: number;
    gridviews: any
}

interface MatchParams {
    id: string;
    bedrijfTabIndex: string;
}


type Props = StateProps & DispatchProps

const isReadOnly = IsReadOnly(Permissions.ManageCompanies);
class Bedrijf extends React.Component<Props & RouteComponentProps<MatchParams>> {
    componentDidMount = () => {
        if (this.props.match.params.bedrijfTabIndex) {
            this.props.setBedrijfTabIndex(parseInt(this.props.match.params.bedrijfTabIndex));
        }
    }

    resetFilters = () => {
        const { gridviews, setFilter } = this.props;
        for (var i = 0; i < gridviews.length; i++) {
            for (var k = 0; k < gridviews[i].filters.length; k++) {
                setFilter(gridviews[i].id, ({ id: gridviews[i].filters[k].id, value: "" }));
            }
        }
    }

    render() {
        const { match, bedrijfTabIndex, setBedrijfTabIndex } = this.props;

        const userRol = localStorage.getItem("userRol");

        return (
            <Tabs selectedIndex={bedrijfTabIndex} onSelect={tabIndex => { setBedrijfTabIndex(tabIndex); this.resetFilters() }}>
                <TabList>
                    <Tab>Bedrijf</Tab>
                    {/* {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]) && <Tab>Leads</Tab>} */}
                    {ShowPage([Permissions.ReadContactPersons, Permissions.ManageContactPersons]) && <Tab>Contactpersonen</Tab>}
                    <Tab>Partners</Tab>
                    {ShowPage([Permissions.ReadSponsorDeals, Permissions.ManageSponsorDeals]) && <Tab>Sponsordeals</Tab>}
                    {/* {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]) && <Tab>Taken</Tab>} */}
                    {/* <Tab>Bestanden</Tab>
                    {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]) && <Tab>Facturen</Tab>} */}
                </TabList>
                <TabPanel>
                    <BedrijfContainer />
                </TabPanel>
                {/* {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]) && <TabPanel>
                    <LeadsContainer companyId={match.params.id} />
                </TabPanel>} */}
                {ShowPage([Permissions.ReadContactPersons, Permissions.ManageContactPersons]) && <TabPanel>
                    <ContactpersonenContainer readOnly={isReadOnly} bedrijfId={match.params.id as unknown as string} />
                </TabPanel>}
                <TabPanel>
                    <PartnersContainer bedrijfId={match.params.id as unknown as string} />
                </TabPanel>
                {ShowPage([Permissions.ReadSponsorDeals, Permissions.ManageSponsorDeals]) &&
                    <TabPanel>
                        <SponsordealsContainer bedrijfId={match.params.id as unknown as string} />
                    </TabPanel>}
                {/* {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]) && <TabPanel>
                    <TakenContainer companyId={match.params.id} />
                </TabPanel>} */}
                {/* {<TabPanel>
                    <BestandenContainer bedrijfId={match.params.id as unknown as number} />
                </TabPanel>}
                {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]) && <TabPanel>
                    <FacturenContainer bedrijfId={match.params.id as unknown as number} />
                </TabPanel>} */}
            </Tabs>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    bedrijfTabIndex: state.bedrijfTabIndex,
    gridviews: state.gridviews
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    setBedrijfTabIndex: (bedrijfTabIndex: number) => dispatch(setBedrijfTabIndex(bedrijfTabIndex)),
    setFilter: (gridviewId: string, filter: Filter) => dispatch(setFilter(gridviewId, filter)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Bedrijf));
