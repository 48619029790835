import React, { Component, useContext } from "react";
import "./App.css";
import { RootState, LoginState } from "./types/state";
import { Template } from "./components/Template";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { WaitScreen } from "./components/WaitScreen";
import "react-toastify/dist/ReactToastify.css";
import { ApplicationContext } from "./contexts/application/ApplicationContext";

export interface StateProps {
  LoginState: LoginState;
  isLoading: boolean;
}

class App extends Component<StateProps> {
  render() {
    const { isLoading } = this.props;

    return (
      <>
        <Template />
        <ApplicationContext.Consumer>
          {(context) => (context.loading || isLoading) && <WaitScreen />}
        </ApplicationContext.Consumer>
        <ToastContainer position="top-right" autoClose={3000} closeOnClick />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    LoginState: state.login,
    isLoading: state.isLoading,
  };
};

export default connect(mapStateToProps, null, null, { pure: false })(App);
