export enum TaakSoort {
    "Afspraak contractverlenging" = 0,
    "Afspraak voor event" = 1,
    "Contact opnemen over reclame uitingen" = 2,
    "Handmatige herinnering factuur (bellen / mailen)" = 3,
    "Hoofdcontactpersoon wijzigen" = 4,
    "Contactpersoon wijzigen" = 5,
    "Contact- of adresgegevens wijzigen" = 6,
    "Anders" = 7,
    "Notulen" = 8
}
