export enum ButtonType {
  success,
}

type Props = {
  title: string;
  type: ButtonType;
  disabled?: boolean;
  onClick: () => void;
};

export const Button: React.FC<Props> = ({ title, type, disabled, onClick }) => {
  return (
    <button
      className={`button ${getClassNameByType(type)}`}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

const getClassNameByType = (type: ButtonType) => {
  switch (type) {
    case ButtonType.success:
      return "is-success";
    default:
      return "";
  }
};
