import React, { useEffect } from "react";
import { ChartModel, CsvViewModel } from "../../types/model";
import StatisticsApi from "../../api/StatistiekenApi";
import { useSatistics } from "./useStatistics";

export const StatisticsContext = React.createContext({
  viewNames: [] as string[],
  getSponsorDealTypeStatistics: async (force = false) => {
    return {} as ChartModel;
  },
  getSponsorDealTotalAmountStatistics: async (force = false) => {
    return {} as ChartModel;
  },
  getLocalStatistics: async (force = false) => {
    return {} as ChartModel;
  },
  getContactPersonStatistics: async (force = false) => {
    return {} as ChartModel;
  },
});

export const StatisticsContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [viewNames, setViewNames] = React.useState<string[]>([]);

  const {
    getContactPersonStatistics,
    getLocalStatistics,
    getSponsorDealTotalAmountStatistics,
    getSponsorDealTypeStatistics,
  } = useSatistics();

  useEffect(() => {
    StatisticsApi.getViewNames().then((data) => {
      setViewNames(data.viewNames);
    });
  }, []);

  const contextValue = {
    viewNames,
    getContactPersonStatistics,
    getLocalStatistics,
    getSponsorDealTotalAmountStatistics,
    getSponsorDealTypeStatistics,
  };

  return (
    <StatisticsContext.Provider value={contextValue}>
      {children}
    </StatisticsContext.Provider>
  );
};
