import BedrijfComponent from "../components/BedrijfComponent/BedrijfComponent";
import React from "react";
import {
  Bedrijf,
  Contactpersoon,
  Sponsordeal,
  Verantwoordelijke,
} from "../types/model";
import { connect } from "react-redux";
import {
  getBedrijf,
  getBedrijfForHoofdcontactpersoon,
} from "../actions/bedrijfActions";
import BedrijfApi from "../api/BedrijfApi";
import { AnyAction } from "redux";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import history from "../components/history";
import ContactpersoonApi from "../api/ContactpersoonApi";
import { submit, change } from "redux-form";
import { toast } from "react-toastify";
import SponsordealApi from "../api/SponsordealApi";
import { getVerantwoordelijken } from "../actions/gebruikerActions";
import { showLoading, hideLoading } from "../actions/loadingActions";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Guid } from "guid-typescript";

interface DispatchProps {
  getBedrijf: (id: number) => any;
  getBedrijfForHoofdcontactpersoon: () => any;
  submitForm: (form: string) => any;
  getVerantwoordelijken: () => any;
  updateFormValue: (field: string, value: string) => any;
  showLoading: () => any;
  hideLoading: () => any;
}

interface StateProps {
  company: Bedrijf;
  bedrijven: Bedrijf[];
  verantwoordelijken: Verantwoordelijke[];
  bedrijfStatussen: [{ label: string; value: string }];
}

interface MatchParams {
  id: string;
}

interface State {
  showContactpersoonModal: boolean;
  showSponsordealModal: boolean;
}

class BedrijfContainer extends React.Component<
  DispatchProps & StateProps & RouteComponentProps<MatchParams>,
  State
> {
  state: State = {
    showContactpersoonModal: false,
    showSponsordealModal: false,
  };

  componentDidMount() {
    this.props.getBedrijf(this.props.match.params.id as unknown as number);
    this.props.getVerantwoordelijken();
  }

  updateBedrijf = (formValues: any) => {
    var bedrijf = formValues as Bedrijf;

    if (!bedrijf.hasInvoiceAddress) {
      bedrijf.invoiceAddress = null;
      bedrijf.invoiceId = null;
      bedrijf.companyInvoiceSettings = null;
      bedrijf.companyInvoiceSettingsId = null;
    } else if (bedrijf.invoiceAddress) {
      bedrijf.invoiceAddress.country = "Nederland";
    }
    if (
      bedrijf.companyInvoiceSettings &&
      bedrijf.companyInvoiceSettings.id == null
    ) {
      bedrijf.companyInvoiceSettings.id = Guid.createEmpty().toString();
    }

    const userRol = localStorage.getItem("userRol");
    this.props.showLoading();

    BedrijfApi.updateBedrijf(bedrijf)
      .then(() => toast.success("Het opslaan is succesvol"))
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());

    history.goBack();
  };

  updateFormValue = (field: any, value: any) => {
    this.props.updateFormValue(field, value);
  };

  archiveBedrijf = (id: string) => {
    if (window.confirm("Weet u zeker dat dit bedrijf wilt archiveren?")) {
      this.props.showLoading();
      BedrijfApi.archiveBedrijf(id)
        .then(() => toast.success("Archiveren is succesvol"))
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => {
          history.goBack();
          this.props.hideLoading();
        });
    }
  };

  updateContactpersoon = (formValues: any) => {
    var contactpersoon = formValues.contactpersoon as Contactpersoon;
    ContactpersoonApi.updateContactpersoon(contactpersoon)
      .then(
        () =>
          this.props.getBedrijf(
            this.props.match.params.id as unknown as number
          ) && toast.success("Het opslaan is succesvol")
      )
      .catch(() => toast.error("Er is een fout opgetreden"));
    this.setState({ showContactpersoonModal: false });
  };

  updateSponsordeal = (formValues: any) => {
    var sponsordeal = formValues.sponsordeal as Sponsordeal;
    SponsordealApi.updateSponsordeal(sponsordeal)
      .then(
        () =>
          this.props.getBedrijf(
            this.props.match.params.id as unknown as number
          ) && toast.success("Het opslaan is succesvol")
      )
      .catch(() => toast.error("Er is een fout opgetreden"));
    this.setState({ showSponsordealModal: false });
  };

  onCancel = () => {
    history.goBack();
  };

  render() {
    const { company, verantwoordelijken, bedrijfStatussen } = this.props;

    return (
      <BedrijfComponent
        company={company}
        bedrijfStatussen={bedrijfStatussen}
        verantwoordelijken={verantwoordelijken}
        archiveBedrijf={this.archiveBedrijf}
        isEdit={true}
        onSubmit={this.updateBedrijf}
        onCancel={this.onCancel}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  company: state.company,
  bedrijven: state.bedrijven,
  bedrijfStatussen: state.bedrijfStatussen,
  verantwoordelijken: state.verantwoordelijken,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getBedrijf: (id: number) => dispatch(getBedrijf(id)),
  getBedrijfForHoofdcontactpersoon: () =>
    dispatch(getBedrijfForHoofdcontactpersoon()),
  submitForm: (form: string) => dispatch(submit(form)),
  getVerantwoordelijken: () => dispatch(getVerantwoordelijken()),
  updateFormValue: (field: string, value: string) =>
    dispatch(change("ContactpersoonForm", field, value)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BedrijfContainer)
);
