import React, { useState } from "react";
import classnames from "classnames";
import Select from "react-select";
import { OptionsType } from "react-select/lib/types";
import { InputErrorMessage } from "../ErrorMessage";

type Props = {
  name: string;
  options: OptionsType<{ label: string; value: string }>;
  label?: string;
  readOnly?: boolean;
  showSideBySide?: boolean;
  clearable: boolean;
  onValueChanged?: (value: string) => void;
  required: boolean;
  value: string;
  validators?: ((value: string) => string | undefined)[];
};

export const Dropdown: React.FC<Props> = ({
  name,
  options,
  label,
  required,
  clearable = false,
  showSideBySide,
  readOnly,
  onValueChanged,
  value,
}) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  let wrapperClass =
    "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

  if (showSideBySide)
    wrapperClass = classnames(wrapperClass, "is-half-desktop");
  else wrapperClass = classnames(wrapperClass, "is-full-desktop");

  const onBlur = () => {
    setTouched(true);
    if (required && !value) {
      setError("Dit veld is verplicht");
    } else {
      setError("");
    }
  };

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label is-normal">
          <label className="label is-pulled-left" htmlFor={name}>
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </label>
        </div>
      )}
      <div className="field-body">
        <div className="field">
          <div className="control">
            <Select
              className="field"
              options={options}
              isDisabled={readOnly}
              isSearchable={true}
              value={options.find(
                (o) => o.value.toString() == value.toString()
              )}
              onBlur={onBlur}
              defaultValue={
                options && options.length == 1
                  ? options[0]
                  : { label: "", value: "" }
              }
              isClearable={clearable}
              onChange={(option: any) => {
                if (onValueChanged) onValueChanged(option ? option.value : "");
              }}
            />
            <InputErrorMessage error={error} touched={touched} />
          </div>
        </div>
      </div>
    </div>
  );
};
