import "react-tabs/style/react-tabs.css";

import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { AnyAction } from "redux";
import ContactpersonenContainer from "../../containers/ContactpersonenContainer";
import EventAttendeesContainer from "../../containers/EventAttendeesContainer";
import EventContainer from "../../containers/EventContainer";
import { Filter } from "../../types/model";
import React from "react";
import { RootState } from "../../types/state";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { setEventTabIndex } from "../../actions/eventActions";
import { setFilter } from "../../actions/gridviewActions";

interface DispatchProps {
    setEventTabIndex: (eventTabIndex: number) => any;
    setFilter: (gridviewId: string, filter: Filter) => void;
}

interface StateProps {
    eventTabIndex: number;
    gridviews: any;
}

interface MatchParams {
    id: string;
    eventTabIndex: string;
}

type Props = StateProps & DispatchProps

class Event extends React.Component<Props & RouteComponentProps<MatchParams>> {
    componentDidMount = () => {
        if (this.props.match.params.eventTabIndex) {
            this.props.setEventTabIndex(parseInt(this.props.match.params.eventTabIndex));
        }
    }

    resetFilters = () => {
        const { gridviews, setFilter } = this.props;
        for (var i = 0; i < gridviews.length; i++) {
            for (var k = 0; k < gridviews[i].filters.length; k++) {
                setFilter(gridviews[i].id, ({ id: gridviews[i].filters[k].id, value: "" }));
            }
        }
    }

    render() {
        const { match, eventTabIndex, setEventTabIndex } = this.props;

        const userRol = localStorage.getItem("userRol");

        return (
            <Tabs selectedIndex={eventTabIndex} onSelect={tabIndex => { setEventTabIndex(tabIndex); this.resetFilters(); }}>
                <TabList>
                    <Tab>Event</Tab>
                    <Tab>Aanmeldingen</Tab>
                </TabList>
                <TabPanel>
                    <EventContainer />
                </TabPanel>
                <TabPanel>
                    <EventAttendeesContainer eventId={match.params.id as unknown as string} />
                </TabPanel>
            </Tabs>
        );
    }

}

const mapStateToProps = (state: RootState): StateProps => ({
    eventTabIndex: state.eventTabIndex,
    gridviews: state.gridviews

});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    setEventTabIndex: (eventTabIndex: number) => dispatch(setEventTabIndex(eventTabIndex)),
    setFilter: (gridviewId: string, filter: Filter) => dispatch(setFilter(gridviewId, filter)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Event));