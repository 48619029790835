import { useEffect, useState } from "react";
import { FinancialValue } from "../../../../components/FinancialValue";
import { Factuur } from "../../../../types/model";
import { VATRates } from "../../../../constants/BtwTarief";

type Props = {
  invoice: Factuur;
};

export const CreateInvoiceSummary: React.FC<Props> = ({ invoice }) => {
  const [stats, setStats] = useState<{
    exclVat: number;
    low: number;
    high: number;
    inclVat: number;
  }>();

  useEffect(() => {
    var exclVat = 0;
    var low = 0;
    var high = 0;

    invoice.invoiceLines.forEach((x) => {
      if (!x.isDeleted) {
        const price =
          Number.parseFloat(x.amount || "0") *
          Number.parseFloat(x.price || "0");
        exclVat += price;

        if (x.vatRate === VATRates.Low) {
          low += price * VATRates.Low;
        } else if (x.vatRate === VATRates.High) {
          high += price * VATRates.High;
        }
      }
    });

    setStats({
      exclVat,
      low: low,
      high: high,
      inclVat: exclVat + low + high,
    });
  }, [invoice]);

  return stats ? (
    <div className="pricing">
      <div className="pricing-row">
        <div className="pricing-row-col">
          <b>Subtotaal: </b>
        </div>
        <div className="pricing-row-col">
          <FinancialValue value={stats.exclVat} />
        </div>
      </div>
      <div className="pricing-row">
        <div className="pricing-row-col">
          <b>{"BTW 9%"}: </b>
        </div>
        <div className="pricing-row-col">
          <FinancialValue value={stats.low} />
        </div>
      </div>
      <div className="pricing-row">
        <div className="pricing-row-col">
          <b>{"BTW 21%"}: </b>
        </div>
        <div className="pricing-row-col">
          <FinancialValue value={stats.high} />
        </div>
      </div>
      <div className="pricing-row">
        <div className="pricing-row-col">
          <b>Totaal: </b>
        </div>
        <div className="pricing-row-col">
          <FinancialValue value={stats.inclVat} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
