import {
  FactuurStatus,
  SponsordealAttachmentEnum,
  SponsordealStatus,
} from "../constants/enums";

import { Guid } from "guid-typescript";
import { Moment } from "moment";

export interface TokenResponse {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
}

export interface EventGuest {
  id: string;
  eventId: string;
  firstname: string;
  surname: string;
  companyName: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface Role {
  id?: string;
  name: string;
  normalizedName?: string;
  concurrencyStamp?: string;
  permissions: number[];
}

export interface Bedrijf {
  id: string;
  name: string;
  status: number;
  type: number;
  emailAddress: string;
  phoneNumber: string;
  isArchived: boolean;
  numberOfActiveSponsorDeals: number;
  invoiceToTheAttentionOf: string;
  addressId: string | null | Guid;
  address: Address;

  hasInvoiceAddress: boolean;
  invoiceId: string | null;
  invoiceAddress: Address | null;
  companyInvoiceSettingsId: string | null | Guid;
  companyInvoiceSettings: BedrijfInvoiceSettings | null;
  hasHoofdContactpersoon: boolean;

  responsibleId: string;
  logoUrl: string;
  website: string;
  debitNumber?: string;
  kvkNumber?: number;
  comment: string;
  employees: Person[];
  hoofdcontactpersoon: Contactpersoon;
  contactpersonen: Contactpersoon[];
  partners: Contactpersoon[];
  sponsordeals: Sponsordeal[];
  bedrijfLogoBijlageUrls: BijlageUrl[];
  bedrijfLogoBijlagen: Bijlage[];
  advertentieUrl: string;
  advertentieBijlage: Bijlage;
  reclamebordUrl: string;
  reclamebordBijlage: Bijlage;
  defaultProviderSpecifications: string[];
  invoiceEmailAddress: string;
}

export interface BedrijfInvoiceSettings {
  id: string | null | Guid | undefined;
  name: string;
  emailAddress: string;
}

export interface Address {
  id: string | null | undefined | Guid;
  street: string;
  houseNumber: string;
  city: string;
  country: string;
  postalCode: string;
}

export interface EventLeadSubmit {
  id: string | null;
  eventId: string;
  firstname: string;
  surname: string;
  companyName: string;
  emailAddress: string;
  phoneNumber: string;
  invitedById: string;
}

export interface EventAttendSubmit {
  personId: string;
  introduceeName: string;
  introduceeEmail: string;
}

export interface Contactpersoon {
  id: string;
  companyId?: string | undefined;
  company: Bedrijf;
  companyName: string;
  firstname: string;
  surname: string;
  name: string;
  type: string;
  emailAddress: string;
  phoneNumber: string;
  birthDate: string;
  loggedInOn: Date;
  isQualified: boolean;
  isEditable: boolean;
  isBlockedFromApp: boolean;
  isArchived: boolean;

  useCompanyAddress: boolean;
  addressId: string | undefined;
  address: Address;

  hasPartner: boolean;
  hasSeperatePartnerAddress: boolean;
  partnerId: Guid | undefined;
  partner: Contactpersoon;
  roleName: string;

  aanmeldenEvents: boolean;
  bestellenActies: boolean;
  emailvoorkeuren: { id: string; soort: number; voorkeur: boolean }[];
  socialSettings?: SocialNotificationSettings;
}

// class bedoeld voor CSV export, kolomnamen zijn nederlands om de kolommen in CSV nederlands te krijgen. Sorry, kon even niet anders.
export interface EventAttendee {
  Id: string;
  Voornaam: string;
  Achternaam: string;
  Bedrijfsnaam: string;
  Type: string;
  Emailadres: string;
  Telefoonnummer: string;
  Geboortedatum: string;
  IsTeWijzigen: boolean;
}

export interface InvoiceSponsorDeals {
  ids: string[];
  season: number;
  distinction: string;
}

export interface Sponsordeal {
  id: string;
  companyId: string;
  company: Bedrijf;
  sponsorDealTypeId: string;
  sponsorDealType: SponsorDealType;
  status: SponsordealStatus;
  leadTime: number;
  remark: string;
  seasonAgreements: SeasonAgreement[];
  seperateSeasonalAgreements: boolean;
  signatureId: string;
  attachments: SponsordealAttachment[];
  cancelledDate?: Date;
  cancelledDescription: string;
  cancelledReason: string;
  isArchived: boolean;

  hasPartner: boolean;
  from: string;
  to: string;
  bestaandBedrijf: boolean;

  documenten: string[];

  verantwoordelijke: string;
  verantwoordelijkeId: number;
  tegenprestaties: Tegenprestatie[];
  sponsordealBijlagenUrls: BijlageUrl[];
  sponsordealBijlagen: Bijlage[];
}

export interface SponsordealAttachment {
  id: string;
  fileUrl: string;
  fileName: string;
  content?: string;
  attachmentType: SponsordealAttachmentEnum;
}

export interface SponsordealList {
  id: string;
  companyName: string;
  companyId: string;
  from: string;
  to: string;
  status: SponsordealStatus;
  amountOfSeasons: number;
  sponsorDealTypeName: string;
  sponsorDealTypeDefaultPrice: number;
  totalValue: number;
  isArchived: boolean;
}

export interface NewSponsorDeal {
  id: string;
  sponsorDealTypeId: string;
  sponsorDealType: SponsorDealType;
  status: SponsordealStatus;
  leadTime: number;

  tegenprestaties: Tegenprestatie[];

  companyId: string;
  company: Bedrijf;
  contactPerson: Contactpersoon;
  partnerContactPerson?: Contactpersoon;
}

export interface Seizoensafspraak {
  season: number;
  prijs: number;
  inNatura: string;
  tegenprestaties: Tegenprestatie[];
  hidden: boolean;
}

export interface SeasonAgreement {
  season: number;
  price: number;
  inKind: string;
  seasonAgreementCompensations: Tegenprestatie[];
  hidden: boolean;
}

export interface SponsorDealType {
  id: number;
  name: string;
  defaultPrice: number;
  displayName: string;
  numUsed: number;
  numCompensation: number;
  defaultMaxAttendees: number;
  sponsorDealTypeCompensations: StandaardTegenprestatie[];
  deletedStandaardTegenprestaties: StandaardTegenprestatie[];
  defaultProviderSpecifications: string;
  allowAppAccess: boolean;
  mailChimpTag: string;
}

export interface SponsordealSignature {
  id: string;
  sponsorDealId: string;
  location: string;
  signature: string;
}

export interface Document {
  entityId: number;
  isContract: boolean;
  documentnaam: string;
  document: string;
}

export interface Taak {
  id: string;
  companyId: string;
  company: Bedrijf;
  responsibleId: string;
  responsibleName: string;
  description: string;
  taskType: number;
  expirationDate: string;
  completed: boolean;
  taakId: number;
  isArchived: boolean;
}

export interface ListFactuur {
  id: string;
  companyId: string;
  companyName: string;
  distinction: string;
  invoiceNumber?: number;
  numberOfTerms: number;
  payedAmountIncVAT: number;
  remainingAmountIncVAT: number;
  TotalAmountIncVAT: number;
  isArchived: boolean;
  unit4Message: string;
  status: FactuurStatus;
  lastReminder: string;
  invoicePayments: FactuurBetaaltermijn[];
  orderDate: Date;
  fiscalYear: string;
}

export interface Factuur {
  id: string;
  companyId: string;
  invoiceNumber?: number;
  distinction: string;
  status: FactuurStatus;
  orderDate: Date;
  payInTerms: boolean;
  isArchived: boolean;
  invoiceLines: FactuurRegel[];
  invoiceTermPayments: FactuurBetaaltermijn[];
  email: string;
  toTheAttentionOf: string;
  isVerstuurdNaarUnit4: boolean;
}

export interface FactuurRegel {
  id: string;
  amount: string;
  description: string;
  vatRate: number;
  price: string;
  isPayed: boolean;
  isDeleted: boolean;
  isIncludingVat: boolean;
}

export interface FactuurBetaaltermijn {
  id: number;
  amount: number;
  dueDate: string;
  isDeleted: boolean;
}

export interface Tegenprestatie {
  id?: string;
  SponsorDealTypeCompensationId?: string;
  description: string;
}

export interface StandaardTegenprestatie {
  id: string;
  description: string;
}

export interface Lead {
  id: string;
  company: Bedrijf;
  companyId: string;
  firstName: string;
  surName: string;
  emailAddress: string;
  phoneNumber: string;
  remarks: string;
  function: string;
  isArchived: boolean;
  eventLeads: EventLead[];
}

export interface Notation {
  id: string;
  title: string;
  date: string;
  schedule: string;
  notations: string;
  attending: string;
  isArchived: boolean;
  tasks: Taak[];
  attachments: Attachment[];
}

export interface Attachment {
  id: string;
  fileUrl: string;
  fileName: string;
  content?: string;
  attachmentType: SponsordealAttachmentEnum;
}

export interface NotuleTaak {
  id: number;
  notuleId: number;
  taakId: number;
  bedrijfId?: number;
  verantwoordelijkeId?: string;
  omschrijving: string;
  vervaldatum: string;
  isDeleted: boolean;
}

export interface ListEvent {
  id: string;
  eventName: string;
  status: number;
  startDate: string;
  endDate: string;
  companyName: string;
  maxAttendees: number;
}

export interface BaseItem {
  id?: Guid;
  title: string;
  description: string;

  openingDateTime: string;
  openingTime: string;
  closingDateTime: string;
  closingTime: string;

  startDateTime: string;
  startTime: string;
  endDateTime: string;
  endTime: string;

  publishDateTime: string;
  archiveDateTime: string;

  socialSettingsId: string | undefined;
  socialSettings: SocialSettings;

  uitnodigingUrl: string;
  attachments: Bijlage[];
  isArchived: boolean;
}

export interface SocialSettings {
  allowReaction: boolean;
  allowPhoto: boolean;
  allowAttendance: boolean;
  allowShare: boolean;
  allowLike: boolean;
  showCalendar: boolean;
}

export interface Event {
  id: any;
  status: number;
  companyName: string;
  maxAttendees: number;
  isArchived: boolean;

  item: BaseItem;
  itemId: string;

  address?: Address;
  addressId: string;

  // aanvangstTijdstip: string;
  // eindTijd: string;
  // omschrijving: string,
  // maximumAantalPersonenSponsor: number;
  // isGearchiveerd: boolean;

  eventSponsorDealTypes: EventSponsorDealType[];
  eventContactpersonen: EventContactpersoon[];
  eventLeads: EventLead[];
}

export interface EventSponsorDealType {
  id: string | Guid | undefined | null;
  eventId: string | Guid | undefined | null;
  sponsorDealTypeId: string | Guid | undefined | null;
  aantal: number;
  isDeleted: boolean;
}

export interface EventContactpersoon {
  id: any;
  eventId: any;
  contactpersoonId: any;
}

export interface EventLead {
  id: number;
  eventId: number;
  leadId: number;
  titel: string;
  datum: string;
  voornaam: string;
  achternaam: string;
  bedrijfsnaam: string;
  emailadres: string;
  isDeleted: boolean;
}

export interface Actie {
  id: string;
  item: BaseItem;
  status: number;
  deliveryType: number;
  actionProducts: ActieProduct[];
  isArchived: boolean;
}

export interface ActieProduct {
  product: Product;
  isDeleted: boolean;
}

export interface Product {
  id: number;
  name: string;
  unitPrice: number;
  vatRate: number;
  stock: number;
  isArchived: boolean;
}

export interface Bestelling {
  id: number;
  contactpersoonnaam: string;
  productnaam: string;
  aantal: number;
  totaalprijs: number;
}

export interface UserBestelling {
  id: number;
  actieProductId: number;
  aantal: number;
  afleverStraat?: string;
  afleverHuisnummer?: string;
  afleverPostcode?: string;
  afleverPlaats?: string;
}

export interface Nieuwsbericht {
  id: any;
  date: string;
  author: string;
  possibilityToRespond: boolean;
  topBericht: boolean;
  gesponsordBericht: boolean;
  item: BaseItem;
  itemId: any;
}

export interface CustomPage {
  id: Guid | undefined;
  title: string;
  description: string;
}

export interface AgendaItem {
  itemId: Guid | undefined;
  title: string;
  startDatetime: string;
  endDatetime: string;
  startTime: string;
  endTime: string;
  isFootballMatch: boolean;
  tag: string;
}

export interface Settings {
  id: number;
  socialMediaVerantwoordelijkeId: number;
  reclamebordVerantwoordelijkeId: number;
  primaireKleur: string;
  secundaireKleur: string;
  logoBlobUrl: string;
  logo: Bijlage;
}

export interface Bijlage {
  id?: string;
  title: string;
  content: string;
  overwrite: boolean;
  url: string;
  isArchived: boolean;
  type?: number;
}

export interface BijlageUrl {
  id: number;
  url: string;
  isContract?: boolean;
}

export interface Gridview {
  id: string;
  sorting: Sorting[];
  filters: Filter[];
}

export interface Filter {
  id: string;
  value: string;
}

export interface Sorting {
  id: string;
  desc: boolean;
}

export interface Verantwoordelijke {
  id: string;
  fullName: string;
}

export interface Person {
  id: string;
  firstname: string;
  surname: string;
  emailAddress: string;
  type: string;
  jobTitle: string;
  isSystemUser: boolean;
  isResponsible: boolean;
  roleName: string;

  // companyId?: string | undefined;
  // birthDate: string;
  // phoneNumber: string;
}

export enum Rol {
  Unknown = "",
  Beheerder = "Beheerder",
  Bestuurslid = "Bestuurslid",
}

export interface ChartModel {
  labels: string[];
  data: number[];
}

export interface CsvViewModel {
  viewNames: string[];
}

export interface SocialNotificationSettings {
  personId: string;
  eventEmail: boolean;
  eventPushNotification: boolean;
  actionsEmail: boolean;
  actionsPushNotification: boolean;
  newsEmail: boolean;
  newsPushNotification: boolean;
  networkEmail: boolean;
  networkPushNotification: boolean;
}

export interface InvoiceValidation {
  company: string;
  invoiceNumber: string;
  errors: string[];
}

export interface InvoiceValidation {
  company: string;
  invoiceNumber: string;
  errors: string[];
}

export enum Permissions {
  "ReadDashboard" = 1,
  "ManageDashboard" = 2,
  "ReadTasks" = 3,
  "ManageTasks" = 4,
  "ReadRecords" = 5,
  "ManageRecords" = 6,
  "ReadCompanies" = 7,
  "ManageCompanies" = 8,
  "ReadContactPersons" = 9,
  "ManageContactPersons" = 10,
  "ReadLeads" = 11,
  "ManageLeads" = 12,
  "ReadCustomPages" = 13,
  "ManageCustomPages" = 14,
  "ReadInstructions" = 15,
  "ManageInstructions" = 16,
  "ReadSponsorDeals" = 17,
  "ManageSponsorDeals" = 18,
  "ReadInvoices" = 19,
  "ManageInvoices" = 20,
  "ReadEvents" = 21,
  "ManageEvents" = 22,
  "ReadNews" = 23,
  "ManageNews" = 24,
  "ReadAgenda" = 25,
  "ManageAgenda" = 26,
  "ReadSponsorDealKinds" = 27,
  "ManageSponsorDealKinds" = 28,
  "ReadProducts" = 29,
  "ManageProducts" = 30,
  "ReadUsers" = 31,
  "ManageUsers" = 32,
  "ReadRoles" = 33,
  "ManageRoles" = 34,
}
