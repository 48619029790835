import ApiClient from './ApiClient';
import { Lead } from "../types/model";

const endpoint = 'api/leads';

export default class LeadApi {
    public static async getLeads(): Promise<Lead[]> {
        var leads = await ApiClient.doHttpRequest<Lead[]>('get', `${endpoint}`);
        return leads;
    }

    public static async getLead(id: string): Promise<Lead> {
        var lead = await ApiClient.doHttpRequest<Lead>('get', `${endpoint}/${id}`);
        return lead;
    }

    public static async createLead(lead: Lead): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}`, lead);
    }

    public static async updateLead(lead: Lead): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}`, lead);
    }

    public static async archiveSelectedLeads(selectedLeads: string[]): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/archiveSelectedLeads`, selectedLeads);
    }

    public static async deleteLead(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('patch', `${endpoint}/${id}/delete`);
    }
}