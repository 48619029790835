import RolComponent from "../components/RolComponent/RolComponent";
import React from "react";
import { Role } from "../types/model";
import { connect } from "react-redux";
import GebruikerApi from '../api/GebruikerApi';
import { AnyAction } from "redux";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import history from "../components/history";
import { toast } from "react-toastify";
import { getContactPersoonByEmail, getGebruiker, getRollen, getRol } from "../actions/gebruikerActions";
import { showLoading, hideLoading } from "../actions/loadingActions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { change } from "redux-form";

interface DispatchProps {
    getRollen: () => any;
    getRol: (id: string) => any;
    updateFormValue: (field: string, value: number[]) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    rol: Role;
}

interface State {
    rol: Role;
}

interface MatchParams {
    id: string
}

class RolContainer extends React.Component<DispatchProps & StateProps & RouteComponentProps<MatchParams>, State> {

    componentDidMount() {
        if (this.props.match.params.id)
            this.props.getRol(this.props.match.params.id);
    }

    updateFormValue = (field: string, value: number[]) => {
        this.props.updateFormValue(field, value);
    }

    updateRole = async (formValues: any) => {
        const role = {
            id : formValues.id,
            name : formValues.rolName,
            permissions: formValues.permissions
        } as Role
        this.props.showLoading();

        await GebruikerApi.upsertRole(role)
            .then(() => {
                this.props.getRollen();
                history.goBack();
                toast.success("Het opslaan is succesvol");
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => this.props.hideLoading());
    }

    onCancel = () => {
        history.goBack();
    }

    render() {
        const { rol } = this.props;

        return (
            <RolComponent updateFormValue={this.updateFormValue} rol={rol} isEdit={true} onCancel={this.onCancel} onSubmit={this.updateRole} />
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => {
    return ({
        rol: state.rol
    })
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getRollen: () => dispatch(getRollen()),
    getRol: (id: string) => dispatch(getRol(id)),
    updateFormValue: (field: string, value: number[]) => dispatch(change("RolForm", field, value)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RolContainer));
