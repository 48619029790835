import * as types from "../constants/taakActionTypes";
import { Taak } from "../types/model";

const initialTakenState: Array<Taak> = [];
const initialTaakState = {} as Taak;

type TaakState = typeof initialTaakState;
type TakenState = typeof initialTakenState;

export const takenReducer = (
    state: TakenState = initialTakenState,
    action: types.GetTakenAction
) => {
    if (action.type === types.GET_TAKEN)
        return action.taken;

    return state;
};

export const taakReducer= (
    state: TaakState = initialTaakState,
    action: types.GetTaakAction
) => {
    if (action.type === types.GET_TAAK)
        return action.taak;

    return state;
};
