import { AgendaItem, Permissions } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import React, { ChangeEvent } from "react";
import { formValues, submit } from "redux-form";
import { getAgendaItem, getAgendaItems } from "../actions/agendaActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import AgendaApi from "../api/AgendaApi";
import AgendaItemComponent from "../components/AgendaItemComponent/AgendaItemComponent";
import AgendaItemsComponent from "../components/AgendaItemsComponent/AgendaItemsComponent";
import { AnyAction } from "redux";
import { IsReadOnly } from "../helpers/PermissionHelper";
import { Modal } from "../components/Modal";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import matchSorter from "match-sorter";
import moment from "moment";
import { toast } from "react-toastify";

interface DispatchProps {
    getAgendaItems: () => any;
    getAgendaItem: (id: string) => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    agendaItems: AgendaItem[];
    agendaItem: AgendaItem;
}

interface State {
    showCreateModal: boolean;
    showEditModal: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageAgenda);
class AgendaItemsContainer extends React.Component<DispatchProps & StateProps, State> {
    state: State = {
        showCreateModal: false,
        showEditModal: false
    }

    componentDidMount = () => {
        this.props.getAgendaItems();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        this.props.getAgendaItem(rowInfo.original.itemId);
        this.setState({ showEditModal: true });
    }

    createAgendaItem = (formValues: AgendaItem) => {
        formValues.startDatetime = moment(formValues.startDatetime).format("MM-DD-YYYY");

        this.props.showLoading();
        AgendaApi.createAgendaItem(formValues)
            .then(() => {
                this.props.getAgendaItems();
                toast.success("Het opslaan is succesvol");
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => this.props.hideLoading());
        this.setState({ showCreateModal: false });
    }

    updateAgendaItem = (formValues: AgendaItem) => {
        formValues.startDatetime = moment(formValues.startDatetime).format("MM-DD-YYYY");

        this.props.showLoading();
        AgendaApi.updateAgendaItem(formValues)
            .then(() => {
                this.props.getAgendaItems();
                toast.success("Het opslaan is succesvol");
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => this.props.hideLoading());
        this.setState({ showEditModal: false });
    }

    archiveAgendaItem = () => {

        if (this.props.agendaItem && this.props.agendaItem.itemId) {
            this.props.showLoading();
            AgendaApi.archiveAgendaItem(this.props.agendaItem.itemId.toString())
                .then(() => {
                    this.props.getAgendaItems();
                    toast.success("Het verwijderen is succesvol");
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => this.props.hideLoading());
            this.setState({ showEditModal: false });
        }
    }

    onCreateClick = () => {
        this.setState({ showCreateModal: true });
    }

    onCreateClose = () => {
        this.setState({ showCreateModal: false });
    }

    onEditClose = () => {
        this.setState({ showEditModal: false });
    }

    render() {
        const { agendaItems, agendaItem } = this.props;

        let columns: Array<Column> = [
            {
                Header: "Datum",
                accessor: "startDatetime",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => props && props.value ? moment(props.value).format("DD-MM-YYYY") : "",
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true,
            },
            {
                Header: "Starttijd",
                accessor: "startDatetime",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => props && props.value ? moment(props.value).format("HH:mm") : "",
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Eindtijd",
                accessor: "endDatetime",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => props && props.value ? moment(props.value).format("HH:mm") : "",
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Titel",
                accessor: "title",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Tag",
                accessor: "tag",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]

        return (
            <>
                <AgendaItemsComponent columns={columns} onRowClick={this.onRowClick} openModal={this.onCreateClick} agendaItems={agendaItems} />
                {
                    this.state.showCreateModal
                        ? (<Modal
                            title="Nieuw agenda item"
                            removeScroll={true}
                            content={
                                <AgendaItemComponent onSubmit={this.createAgendaItem} />
                            }
                            onSubmit={() => this.props.submitForm("AgendaItemForm")}
                            onClose={this.onCreateClose} />
                        )
                        : (null)
                }
                {
                    this.state.showEditModal
                        ? (<Modal
                            title="Wijzigen agenda item"
                            removeScroll={true}
                            content={
                                <AgendaItemComponent isEdit={true} agendaItem={agendaItem} onSubmit={this.updateAgendaItem} />
                            }
                            readOnly={isReadOnly}
                            onSubmit={() => this.props.submitForm("AgendaItemForm")}
                            onClose={this.onEditClose}
                            onArchive={this.archiveAgendaItem} />
                        )
                        : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    agendaItems: state.agendaItems,
    agendaItem: state.agendaItem
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getAgendaItems: () => dispatch(getAgendaItems()),
    getAgendaItem: (id: string) => dispatch(getAgendaItem(id)),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(AgendaItemsContainer);
