import * as customPagesTypes from "../constants/customPagesActionTypes";
import { AsyncAction } from ".";
import { GetCustomPageAction, GetCustomPagesAction } from "../constants/customPagesActionTypes";
import CustomPageApi from "../api/CustomPagesApi";

export const getCustomPages = (): AsyncAction<GetCustomPagesAction> => {
    return async (dispatch) => {
        const customPages = await CustomPageApi.getCustomPages();

        return dispatch({ type: customPagesTypes.GET_CUSTOMPAGES, customPages });
    };
}

export const getCustomPage = (id : string): AsyncAction<GetCustomPageAction> => {
    return async (dispatch) => {
        const customPage = await CustomPageApi.getCustomPage(id);
        return dispatch({ type: customPagesTypes.GET_CUSTOMPAGE, customPage });
    };
}