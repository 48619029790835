import * as types from "../constants/productActionTypes";
import { Product } from "../types/model";

const initialProductenState: Array<Product> = [];
const initialProductState = {} as Product

type ProductenState = typeof initialProductenState
type ProductState = typeof initialProductState

export const productenReducer = (
    state: ProductenState = initialProductenState,
    action: types.GetProductenAction
) => {
    if (action.type === types.GET_PRODUCTEN)
        return action.producten;

    return state;
};

export const productReducer = (
    state: ProductState = initialProductState,
    action: types.GetProductAction
) => {
    if (action.type === types.GET_PRODUCT)
        return action.product;

    return state;
};
