import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { Permissions, Product } from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import * as BtwTarief from "../../constants/BtwTarief";
import { FormDropdown } from "../FormDropdown";
import integer from "../../validators/integer";
import decimal from "../../validators/decimal";
import { IsReadOnly } from "../../helpers/PermissionHelper";

export interface OwnProps {
  product?: Product;
  formValues: Product;
  onCancel: () => void;
  onSubmit: (formValues: Product) => void;
  isEdit?: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageProducts);
class ProductComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>
> {
  constructor(props: OwnProps & InjectedFormProps<{} & any, OwnProps>) {
    super(props);
  }

  render() {
    const {
      handleSubmit,
      product,
      isEdit = false,
      submitting,
      onCancel,
      onSubmit,
    } = this.props;

    const selectBtwTarieven = [
      { value: BtwTarief.HOOG_TARIEF, label: "Hoog tarief (21%)" },
      { value: BtwTarief.LAAG_TARIEF, label: "Laag tarief (9%)" },
      { value: BtwTarief.NUL_TARIEF, label: "Nul tarief (0%)" },
    ];

    return (
      <div className="content">
        <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
          <div className="columns contentPanel is-multiline is-mobile">
            <Field
              component={FormTextInput}
              label="Naam"
              name="name"
              showSideBySide={true}
              required={true}
              readOnly={isReadOnly}
              validate={required}
            />

            <Field
              component={FormTextInput}
              label="Stuksprijs"
              name="unitPrice"
              showSideBySide={true}
              readOnly={isReadOnly}
              required={true}
              validate={[required, decimal]}
            />

            <Field
              component={FormDropdown}
              label="BTW-tarief"
              name="vatRate"
              showSideBySide={true}
              options={selectBtwTarieven}
              readOnly={isReadOnly}
              required={true}
              validate={required}
            />

            <Field
              component={FormTextInput}
              label="Voorraad"
              name="stock"
              showSideBySide={true}
              readOnly={isReadOnly}
              required={true}
              validate={[required, integer]}
            />
          </div>
        </Form>
      </div>
    );
  }
}

const ProductReduxForm = reduxForm<{}, OwnProps>({
  form: "ProductForm",
  enableReinitialize: true,
})(ProductComponent);

export default connect((state: any, props: any) => {
  return {
    initialValues: {
      id: props.isEdit ? state.product.id : "",
      name: props.isEdit ? state.product.name : "",
      unitPrice: props.isEdit ? state.product.unitPrice : 0,
      vatRate: props.isEdit ? state.product.vatRate : 0.21,
      stock: props.isEdit ? state.product.stock : 0,
    },
    formValues: getFormValues("ProductForm")(state) as Product,
  };
})(ProductReduxForm);
