import ApiClient from './ApiClient';
import { Person, Contactpersoon, Verantwoordelijke, Role } from '../types/model';

const endpoint = 'person';

export default class GebruikerApi {
    public static async getGebruikers(): Promise<Person[]> {
        const gebruikers = await ApiClient.doHttpRequest<Person[]>('get', `api/${endpoint}/systeemgebruikers`);

        return gebruikers;
    }

    public static async getVerantwoordelijken(): Promise<Verantwoordelijke[]> {
        const verantwoordelijken = await ApiClient.doHttpRequest<Verantwoordelijke[]>('get', `api/${endpoint}/portal/verantwoordelijken`);
        return verantwoordelijken;
    }

    public static async getGebruiker(id: string): Promise<Person> {
        const gebruiker = await ApiClient.doHttpRequest<Person>('get', `api/${endpoint}/${id}`);
        return gebruiker;
    }

    public static async getContactPersoon(id: string): Promise<Person> {
        const gebruiker = await ApiClient.doHttpRequest<Person>('get', `api/${endpoint}/portal/contactpersoon/${id}`);
        return gebruiker;
    }

    public static async getContactPersoonByEmail(emailAdres: string): Promise<Person> {
        const gebruiker = await ApiClient.doHttpRequest<Person>('get', `api/${endpoint}/gebruikeremail/${emailAdres}`);
        return gebruiker;
    }

    public static async createGebruiker(gebruiker: Person): Promise<void> {
        return ApiClient.doHttpRequest<void>('post', `api/${endpoint}`, gebruiker);
    }

    public static async updateGebruiker(gebruiker: Person): Promise<void> {
        return ApiClient.doHttpRequest<void>('put', `api/${endpoint}/`+gebruiker.id, gebruiker);
    }

    public static async wachtwoordVergeten(emailadres: string): Promise<void> {
        return ApiClient.doHttpRequest<void>('post', `api/${endpoint}/forgotpassword`, { emailadres, callbackUrl: `${window.location.protocol}//${getHost()}/wachtwoordvergetenbevestigen` })
    }

    public static async resetWachtwoord(userId: string, token: string, wachtwoord: string) : Promise<void> {
        return ApiClient.doHttpRequest<void>('post', `api/${endpoint}/resetpassword`, { userId, token, wachtwoord });
    }

    public static async changeWachtwoord(oudwachtwoord: string, wachtwoord: string): Promise<void> {
        return ApiClient.doHttpRequest<void>('post', `api/${endpoint}/changepassword`, { oudwachtwoord, wachtwoord })
    }

    public static async generateLogin(): Promise<void> {
        return ApiClient.doHttpRequest<void>('get', `api/${endpoint}/GenerateLogins?generateForSponsorDealStatusAangemaakt=true`)
    }

    public static async getAllRoles(): Promise<Role[]> {
        const roles = await ApiClient.doHttpRequest<Role[]>('get', `api/${endpoint}/allRoles`);
        return roles;
    }
    public static async getRole(id : string): Promise<Role> {
        const role = await ApiClient.doHttpRequest<Role>('get', `api/${endpoint}/role/${id}`);
        return role;
    }

    public static async upsertRole(role : Role): Promise<void> {
        await ApiClient.doHttpRequest<Role>('post', `api/${endpoint}/role`, role);
    }
}

export const getHost = () => {
    return window.location.hostname === "localhost"
        ? window.location.host
        : window.location.hostname;
}
