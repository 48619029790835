import * as types from "../constants/socialSettingsActionTypes";
import {  SocialNotificationSettings } from "../types/model";

const initialSocialSettingsState = {} as SocialNotificationSettings

type SocialSettingsState = typeof initialSocialSettingsState

export const socialSettingsReducer = (
    state: SocialSettingsState = initialSocialSettingsState,
    action: types.GetSocialSettingsAction
) => {
    if (action.type === types.GET_SOCIAL_SETTINGS)
        return action.socialSettings;

    return state;
};
