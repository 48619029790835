import ApiClient from './ApiClient';
import { AgendaItem } from "../types/model";

const endpoint = 'api/agenda';

export default class AgendaApi {
    public static async getAgendaItems(): Promise<AgendaItem[]> {
        var agendaItems = await ApiClient.doHttpRequest<AgendaItem[]>('get', `${endpoint}/portal`);
        return agendaItems;
    }

    public static async getAgendaItem(id: string): Promise<AgendaItem> {
        var agendaItem = await ApiClient.doHttpRequest<AgendaItem>('get', `${endpoint}/${id}`);
        return agendaItem;
    }

    public static async createAgendaItem(agendaItem: AgendaItem): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}`, agendaItem);
    }

    public static async updateAgendaItem(agendaItem: AgendaItem): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}`, agendaItem);
    }

    public static async archiveAgendaItem(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('patch', `${endpoint}/${id}/portal/archive`);
    }
}