import * as types from "../constants/bedrijfActionTypes";
import { Bedrijf } from "../types/model";

const initialBedrijvenState: Array<Bedrijf> = [];
const initialBedrijvenArchiefState: Array<Bedrijf> = [];
const initialBedrijfState = {} as Bedrijf;
const initialBedrijfTabIndexState: number = 0;

const initialBedrijvenStatussen = [
    { label: "Lead", value: "0", },
    { label: "Sponsor", value: "1" },
    { label: "Oud sponsor", value: "2" },
    { label: "Afgewezen", value: "3" }
];

type BedrijvenState = typeof initialBedrijvenState
type BedrijvenArchiefState = typeof initialBedrijvenArchiefState
type BedrijfState = typeof initialBedrijfState
type BedrijfTabIndexState = typeof initialBedrijfTabIndexState

export const bedrijvenReducer = (
    state: BedrijvenState = initialBedrijvenState,
    action: types.GetBedrijvenAction
) => {
    if (action.type === types.GET_BEDRIJVEN)
        return action.bedrijven;

    return state;
};

export const bedrijvenArchiefReducer = (
    state: BedrijvenArchiefState = initialBedrijvenArchiefState,
    action: types.GetBedrijvenArchiefAction
) => {
    if (action.type === types.GET_BEDRIJVEN_ARCHIEF)
        return action.bedrijvenarchief;

    return state;
};

export const bedrijfReducer = (
    state: BedrijfState = initialBedrijfState,
    action: types.GetBedrijfAction
) => {
    if (action.type === types.GET_BEDRIJF){
        action.company.hasInvoiceAddress = action.company.companyInvoiceSettings != null;
        return action.company;
    }

    return state;
};

export const bedrijfTabIndexReducer = (
    state: BedrijfTabIndexState = initialBedrijfTabIndexState,
    action: types.SetBedrijfTabIndexAction
) => {
    if (action.type === types.SET_BEDRIJF_TAB_INDEX)
        return action.bedrijfTabIndex;

    return state;
};

export function bedrijfStatussenReducer(state = initialBedrijvenStatussen, action = null) {
    return state;
}
