import * as React from "react";

import { ListEvent, Permissions } from "../../types/model";

import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openArchief: () => void;
  openModal: () => void;
  archiveSelectedEvents: () => void;
  exportSelectedEvents: () => void;
  sendCalendarInviteSelectedEvents: () => void;
  sendReminderSelectedEvents: () => void;
  sendInvitationSelectedEvents: () => void;
  hideExport: boolean;
  columns: Array<Column>;
  events: ListEvent[];
  archiefButtonText: string;
  archiverenButtonText: string;
  bedrijfId?: number;
}

const isReadOnly = IsReadOnly(Permissions.ManageEvents);
export default class EventsComponent extends React.Component<OwnProps> {
  render() {
    const {
      onRowClick,
      openModal,
      openArchief,
      archiveSelectedEvents,
      exportSelectedEvents,
      sendCalendarInviteSelectedEvents,
      sendReminderSelectedEvents,
      sendInvitationSelectedEvents,
      archiefButtonText,
      archiverenButtonText,
      hideExport,
      columns,
      events,
    } = this.props;

    return (
      <>
        <div className="content">
          <div className="contentPanel">
            <div className="columns contentPanel">
              <div className="column is-full">
                {!isReadOnly && (
                  <>
                    <div className="buttons is-pulled-left">
                      {/* <button className="button is-success archive-button" onClick={openArchief}>
                                                {archiefButtonText}
                                            </button> */}
                    </div>
                    <div className="buttons is-pulled-right facturenButtons">
                      {/* {hideExport && <button className="button is-success" onClick={exportSelectedEvents}>
                                                Export
                                            </button>}
                                            <button className="button is-success" onClick={sendCalendarInviteSelectedEvents}>
                                                Definitief maken
                                            </button> */}
                      {/* <button className="button is-success" onClick={sendReminderSelectedEvents}>
                                                Herinnering sturen
                                            </button>
                                            <button className="button is-success" onClick={sendInvitationSelectedEvents}>
                                                Uitnodiging sturen
                                            </button> */}
                      {/* <button className="button is-success" onClick={archiveSelectedEvents}>
                                                {archiverenButtonText}
                                            </button> */}
                      <button className="button is-success add-button" onClick={openModal}>
                        Toevoegen
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <Gridview id="EventsList" onRowClick={onRowClick} columns={columns} data={events} />
          </div>
        </div>
      </>
    );
  }
}
