import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { AgendaItem, Permissions } from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import FormDatePicker from "../FormDatePicker";
import { BcwTimePicker } from "../BcwTimePicker";
import moment from "moment";
import { RootState } from "../../types/state";
import datetimePast from "../../validators/datetimePast";
import { IsReadOnly } from "../../helpers/PermissionHelper";

export interface OwnProps {
  agendaItem?: AgendaItem;
  formValues: AgendaItem;
  onSubmit: (formValues: AgendaItem) => void;
  isEdit?: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageAgenda);
class AgendaItemComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>
> {
  render() {
    const { handleSubmit, isEdit = false, onSubmit, agendaItem } = this.props;
    return (
      <div className="content notulen" style={{ height: "320px" }}>
        <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
          <div className="columns contentPanel is-multiline is-mobile">
            <Field
              component={FormDatePicker}
              label="Datum"
              name="startDatetime"
              showSideBySide={true}
              required={true}
              readOnly={isReadOnly}
              validate={[required, datetimePast]}
            />

            <Field
              component={BcwTimePicker}
              label="Starttijd"
              name="startTime"
              showSideBySide={true}
              readOnly={isReadOnly}
              required={true}
              validate={required}
            />

            {agendaItem && !agendaItem.isFootballMatch && (
              <Field
                component={BcwTimePicker}
                label="Eindtijd"
                name="endTime"
                showSideBySide={true}
                readOnly={isReadOnly}
                required={true}
                validate={required}
              />
            )}

            <Field
              component={FormTextInput}
              label="Titel"
              name="title"
              showSideBySide={true}
              readOnly={isReadOnly}
              required={true}
              validate={required}
            />

            <Field
              component={FormTextInput}
              label="Tag"
              name="tag"
              showSideBySide={true}
              readOnly={isReadOnly}
              required={true}
              validate={required}
            />
          </div>
        </Form>
      </div>
    );
  }
}

const validateForm = (values: AgendaItem) => {
  const errors: any = { item: {} }; // had to use any, see no possibility for this validation to work with typescript & redux-form nested field validation

  if (values.isFootballMatch) return errors;

  const startTimeMoment = moment(values.startTime, [moment.ISO_8601, "HH:mm"]);
  let startTimeInMinutes = startTimeMoment.minutes() + startTimeMoment.hours();

  const endTimeMoment = moment(values.endTime, [moment.ISO_8601, "HH:mm"]);
  let endTimeInMinutes = endTimeMoment.minutes() + endTimeMoment.hours();

  if (startTimeInMinutes > endTimeInMinutes) {
    errors.endTime = "eindtijd mag niet voor starttijd liggen";
  }

  return errors;
};

const AgendaItemReduxForm = reduxForm<{}, OwnProps>({
  form: "AgendaItemForm",
  validate: validateForm,
  enableReinitialize: true,
})(AgendaItemComponent);

export default connect((state: RootState, props: any) => {
  const rounded = Math.round(moment().minute() / 15) * 15;
  let initialTime = moment();
  initialTime.minutes(rounded);

  return {
    initialValues: {
      itemId: props.isEdit ? state.agendaItem.itemId : undefined,
      startDatetime: props.isEdit
        ? state.agendaItem.startDatetime
        : new Date().toDateString(),
      startTime: props.isEdit
        ? state.agendaItem.startDatetime
          ? moment(state.agendaItem.startDatetime).format("HH:mm")
          : ""
        : initialTime.format("HH:mm"),
      endTime: props.isEdit
        ? state.agendaItem.isFootballMatch
          ? null
          : state.agendaItem.endDatetime
          ? moment(state.agendaItem.endDatetime).format("HH:mm")
          : ""
        : initialTime.format("HH:mm"),
      title: props.isEdit ? state.agendaItem.title : "",
      tag: props.isEdit ? state.agendaItem.tag : "",
    },
    formValues: getFormValues("AgendaItemForm")(state) as AgendaItem,
  };
})(AgendaItemReduxForm);
