import * as actionTypes from "../constants/loadingActionTypes";
import { LoadingAction } from "../constants/loadingActionTypes";
import { AsyncAction } from ".";

export const showLoading = (): AsyncAction<LoadingAction> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_LOADING, isLoading: true });
    };
}

export const hideLoading = (): AsyncAction<LoadingAction> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_LOADING, isLoading: false });
    };
}
