import {
  AttachmentDocumentListHelper,
  DocumentListHelper,
} from "../../helpers/DocumentListHelper";
import {
  Bedrijf,
  Bijlage,
  BijlageUrl,
  Contactpersoon,
  Permissions,
  Person,
  SeasonAgreement,
  Seizoensafspraak,
  Settings,
  SponsorDealType,
  Sponsordeal,
  SponsordealAttachment,
  StandaardTegenprestatie,
  Tegenprestatie,
  Verantwoordelijke,
} from "../../types/model";
import {
  Field,
  Form,
  InjectedFormProps,
  getFormValues,
  reduxForm,
  isValid,
} from "redux-form";
import {
  OpzeggenSponsordealReden,
  SponsordealAttachmentEnum,
  SponsordealStatus,
  UserRol,
} from "../../constants/enums";

import { AddSponsordealFields } from "./AddSponsordealFields";
import { FormCheckbox } from "../FormCheckbox";
import FormDatePicker from "../FormDatePicker";
import { FormDropdown } from "../FormDropdown";
import FileUpload from "../FileUpload";
import { HiddenInput } from "../HiddenInput";
import { IsReadOnly } from "../../helpers/PermissionHelper";
import { LabelInput } from "../LabelInput";
import React from "react";
import { RootState } from "../../types/state";
import { TextArea } from "../TextArea";
import { FormTextInput } from "../FormTextInput";
import classnames from "classnames";
import { connect } from "react-redux";
import decimal from "../../validators/decimal";
import history from "../history";
import moment from "moment";
import required from "../../validators/required";
import notEmpty from "../../validators/notEmpty";

import addImage from "../../images/add.svg";
import minusImage from "../../images/minus.svg";

export interface OwnProps {
  sponsordeal?: Sponsordeal;
  bedrijven: Bedrijf[];
  sponsorDealTypes: SponsorDealType[];
  verantwoordelijken?: Verantwoordelijke[];
  settings: Settings;
  onSubmit: (formValues: any) => void;
  onCancel: () => void;
  onGenerate?: () => void;
  onDownload?: () => void;
  onFactureren?: (index: number) => void;
  onExtend?: () => void;
  onDelete?: () => void;
  openSignatureModal?: () => void;
  openTerminateModal?: () => void;
  openExtendContractModal?: () => void;
  onDeleteAttachment: (id: string) => void;
  openEmailContract: () => void;
  isEdit?: boolean;
  isModal?: boolean;
  formValues: any;
  companyId?: number;
}

const isReadOnly = IsReadOnly(Permissions.ManageSponsorDeals);
class SponsordealComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>
> {
  componentDidUpdate(prevProps: any) {
    if (
      this.props.initialValues.seasonAgreements !==
        prevProps.initialValues.seasonAgreements &&
      this.props.isEdit
    )
      this.props.initialize(this.props.initialValues);
  }

  onChangeBestaandBedrijf = (event: any) => {
    this.props.formValues.bestaandBedrijf = event.target.checked;
  };

  onClickShowHideSeizoensafspraak = (index: number) => {
    var seizoensafspraak = this.props.formValues.seasonAgreements[index];
    seizoensafspraak.hidden = seizoensafspraak.hidden ? false : true;
    this.props.initialize(this.props.formValues);
  };

  addTegenprestatie = (season: number) => {
    var sponsordealafspraak = this.props.formValues.seasonAgreements[season];
    sponsordealafspraak.seasonAgreementCompensations.push({
      id: "",
      omschrijving: "",
      SponsorDealTypeCompensationId: "",
    });
    this.props.initialize(this.props.formValues);
  };

  deleteTegenprestatie = (season: number, index: number) => {
    if (
      this.props.formValues.seasonAgreements[season]
        .seasonAgreementCompensations.length > 1
    ) {
      this.props.initialize(this.props.formValues);
    } else {
      var tegenprestatie =
        this.props.formValues.seasonAgreements[0]
          .seasonAgreementCompensations[0];
      tegenprestatie.description = "";
      tegenprestatie.SponsorDealTypeCompensationId = "";
      this.props.initialize(this.props.formValues);
    }
  };

  createSeizoensafspraakElement = (
    index: number,
    title?: string
  ): JSX.Element => {
    var sponsordealSoort = this.props.sponsorDealTypes.find(
      (s) => s.id == this.props.formValues.sponsorDealTypeId
    );
    var seasonAgreement = this.props.formValues.seasonAgreements[index];

    if (
      sponsordealSoort &&
      sponsordealSoort.sponsorDealTypeCompensations &&
      sponsordealSoort.sponsorDealTypeCompensations.length > 0 &&
      seasonAgreement.seasonAgreementCompensations &&
      !(seasonAgreement.seasonAgreementCompensations.length > 0) &&
      !this.props.isEdit
    ) {
      seasonAgreement.seasonAgreementCompensations =
        sponsordealSoort!.sponsorDealTypeCompensations.map(function (
          SponsorDealTypeCompensation: StandaardTegenprestatie
        ) {
          return {
            description: SponsorDealTypeCompensation.description,
            SponsorDealTypeCompensationId: SponsorDealTypeCompensation.id,
          };
        });
      seasonAgreement.price = sponsordealSoort.defaultPrice;
    }

    let tegenprestaties = <></>;

    if (
      seasonAgreement &&
      seasonAgreement.seasonAgreementCompensations &&
      seasonAgreement.seasonAgreementCompensations.length < 1
    ) {
      var sponsordealafspraak = this.props.formValues.seasonAgreements[index];
      sponsordealafspraak.seasonAgreementCompensations.push({
        id: "",
        omschrijving: "",
        SponsorDealTypeCompensation: "",
      });
    }

    if (
      seasonAgreement &&
      seasonAgreement.seasonAgreementCompensations &&
      seasonAgreement.seasonAgreementCompensations.length > 0
    ) {
      var that = this;

      tegenprestaties = seasonAgreement.seasonAgreementCompensations.map(
        function (tegenprestatie: any, tegenprestatieIndex: number) {
          return (
            <React.Fragment
              key={`seasonAgreements[${index}].seasonAgreementCompensations[${tegenprestatieIndex}].id`}
            >
              <Field
                component={HiddenInput}
                name={`seasonAgreements[${index}].seasonAgreementCompensations[${tegenprestatieIndex}].id`}
              />

              <Field
                component={HiddenInput}
                name={`seasonAgreements[${index}].seasonAgreementCompensations[${tegenprestatieIndex}].SponsorDealTypeCompensationId`}
              />

              <Field
                component={FormTextInput}
                name={`seasonAgreements[${index}].seasonAgreementCompensations[${tegenprestatieIndex}].description`}
                readOnly={true}
                showSideBySide={true}
              />

              {!that.isReadOnly() && (
                <a
                  className="button is-success delete-btn"
                  onClick={() =>
                    that.deleteTegenprestatie(index, tegenprestatieIndex)
                  }
                >
                  Delete
                </a>
              )}
            </React.Fragment>
          );
        }
      );
    }

    return (
      <div key={index}>
        {title != null && (
          <>
            <span
              className="fold-icon"
              onClick={() => this.onClickShowHideSeizoensafspraak(index)}
            >
              {seasonAgreement.hidden ? (
                <img src={addImage} alt="Logo" />
              ) : (
                <img src={minusImage} alt="Logo" />
              )}
            </span>
            <h3 className="spacer-title top-title first-title">{title}</h3>
          </>
        )}
        {!seasonAgreement.hidden && (
          <>
            <Field
              component={FormTextInput}
              label="Prijs (€)"
              name={`seasonAgreements[${index}].price`}
              showSideBySide={true}
              required={true}
              readOnly={this.isReadOnly()}
              validate={[notEmpty, decimal]}
            />

            <div className="min-height-textarea">
              <Field
                component={TextArea}
                label="In natura"
                name={`seasonAgreements[${index}].inKind`}
                readOnly={this.isReadOnly()}
                showSideBySide={true}
              />
            </div>

            <div className="field-custom-list field-custom-list-settings">
              <div className="field-label">
                <label className="label bcwLabel is-pulled-left">
                  Tegenprestaties
                </label>
              </div>
              {tegenprestaties}
              <div className="field column buttons is-pulled-left">
                {!this.isReadOnly() && (
                  <a
                    className="button is-success"
                    onClick={() => this.addTegenprestatie(index)}
                  >
                    Toevoegen
                  </a>
                )}
                {/* {this.props.isEdit && !this.isReadOnly() && !this.isHoofdcontactpersoon() && <button type="button" className="button is-success" onClick={() => this.props.onFactureren!(seasonAgreement.season)}>
                                    Factureren
                                </button>} */}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  getSeizoenTitel = (startJaar: number, index: number): string => {
    return `${startJaar + index}/${startJaar + index + 1}`;
  };

  getSeizoenStartJaar = (): number => {
    const date = new Date(this.props.formValues.from);
    const year = date.getFullYear();

    return date.getMonth() + 1 < 6 ? year - 1 : year;
  };

  onChangeDoorlooptijd = (e: any) => {
    const doorlooptijd = parseFloat(e);
    const aantalSeizoenen = Math.ceil(doorlooptijd);

    var sponsorDealType = this.props.sponsorDealTypes.find(
      (s) => s.id == this.props.formValues.sponsorDealTypeId
    );
    const nieuweAfspraken: SeasonAgreement[] =
      this.props.formValues.seasonAgreements;

    nieuweAfspraken.sort((a, b) => a.season - b.season);

    if (nieuweAfspraken && nieuweAfspraken.length > aantalSeizoenen) {
      nieuweAfspraken.splice(aantalSeizoenen);
    }

    if (nieuweAfspraken) {
      for (let i = nieuweAfspraken.length; i < aantalSeizoenen; i++) {
        nieuweAfspraken.push({
          inKind: "",
          price: nieuweAfspraken[0].price,
          season: i + 1,
          seasonAgreementCompensations:
            sponsorDealType &&
            sponsorDealType.sponsorDealTypeCompensations &&
            sponsorDealType.sponsorDealTypeCompensations.length > 0
              ? sponsorDealType.sponsorDealTypeCompensations.map((x) => {
                  return {
                    id: "",
                    description: x.description,
                    SponsorDealTypeCompensationId: x.id,
                  };
                })
              : [{ description: "", SponsorDealTypeCompensationId: "" }],
          hidden: false,
        });
      }
    }

    this.props.formValues.seasonAgreements = nieuweAfspraken;
    this.props.initialize(this.props.formValues);
  };

  onChangeSponsorSoort = (id: any) => {
    var sponsordealSoort = this.props.sponsorDealTypes.find((s) => s.id == id);

    if (
      sponsordealSoort &&
      sponsordealSoort.sponsorDealTypeCompensations &&
      sponsordealSoort.sponsorDealTypeCompensations.length > 0
    ) {
      this.props.formValues.seasonAgreements.forEach(
        (afspraak: SeasonAgreement, index: number) => {
          afspraak.seasonAgreementCompensations =
            sponsordealSoort!.sponsorDealTypeCompensations.map(function (
              SponsorDealTypeCompensation: StandaardTegenprestatie
            ) {
              return {
                description: SponsorDealTypeCompensation.description,
                SponsorDealTypeCompensationId: SponsorDealTypeCompensation.id,
              };
            });
          afspraak.price = sponsordealSoort ? sponsordealSoort.defaultPrice : 0;
        }
      );
    } else {
      this.props.formValues.seasonAgreements.forEach(
        (afspraak: SeasonAgreement, index: number) => {
          afspraak.seasonAgreementCompensations = [
            { description: "", SponsorDealTypeCompensationId: "" },
          ];
          afspraak.price = sponsordealSoort ? sponsordealSoort.defaultPrice : 0;
        }
      );
    }
    this.props.initialize(this.props.formValues);
  };

  onChangeHeeftSeizoensafspraken = (id: any) => {
    if (
      this.props.formValues &&
      this.props.formValues.seasonAgreements &&
      this.props.formValues.from
    ) {
      if (!this.props.formValues.seperateSeasonalAgreements) {
        var sponsordealSoort = this.props.sponsorDealTypes.find(
          (s) => s.id == this.props.formValues.sponsorDealTypeId
        );
        this.props.formValues.seasonAgreements[0].prijs = sponsordealSoort
          ? sponsordealSoort.defaultPrice
          : 0;
      }
      if (this.props.formValues.seasonAgreements[0].hidden)
        this.onClickShowHideSeizoensafspraak(0);

      const nieuweAfspraken: SeasonAgreement[] =
        this.props.formValues.seasonAgreements;
      for (let i = 0; i < nieuweAfspraken.length; i++) {
        nieuweAfspraken[i].price =
          this.props.formValues.seasonAgreements[0].price;
      }
    }
  };

  onChangeUploadSponsordealAttachment = (fileName: string, file: string) => {
    this.onChangeUploadAttachment(
      fileName,
      file,
      SponsordealAttachmentEnum.Default
    );
  };

  onChangeUploadSponsordealContractBijlage = (
    fileName: string,
    file: string
  ) => {
    this.onChangeUploadAttachment(
      fileName,
      file,
      SponsordealAttachmentEnum.Contract
    );
  };

  onChangeUploadAttachment = (
    fileName: string,
    file: string,
    attachmentType: SponsordealAttachmentEnum
  ) => {
    var existsInSponsordealAttachments = this.props.formValues.attachments.find(
      (sponsordealAttachment: SponsordealAttachment) =>
        sponsordealAttachment.fileName === fileName
    );
    let attachment = {
      fileName: fileName,
      content: file,
      attachmentType: attachmentType,
    };
    if (existsInSponsordealAttachments) {
      if (
        window.confirm(
          "Er bestaat al een bijlage met deze bestandsnaam, weet u zeker dat u deze wilt overschrijven?"
        )
      ) {
        this.props.formValues.attachments.push(attachment);
      }
    } else this.props.formValues.attachments.push(attachment);
  };

  isReadOnly = () => {
    if (
      (!isReadOnly && !this.props.isEdit) ||
      (this.props.sponsordeal &&
        this.props.sponsordeal.status != SponsordealStatus.Afgerond &&
        this.props.sponsordeal &&
        this.props.sponsordeal.status != SponsordealStatus.Opgezegd)
    )
      return false;
    else return true;
  };

  render() {
    const {
      handleSubmit,
      onCancel,
      onGenerate,
      onDownload,
      onExtend,
      onDelete,
      openSignatureModal,
      openTerminateModal,
      openEmailContract,
      sponsordeal,
      bedrijven,
      isEdit = false,
      isModal = false,
      sponsorDealTypes,
      verantwoordelijken,
      settings,
      onSubmit,
      onDeleteAttachment,
      openExtendContractModal,
      submitting,
      formValues,
    } = this.props;

    let title: any = "";
    let buttons: any = "";
    let fields: any = "";
    let sponsorDealAttachments: any = "";
    let wrapperClass = "";

    let selectBedrijven =
      bedrijven &&
      bedrijven.map(function (bedrijf) {
        return { label: bedrijf.name, value: bedrijf.id.toString() };
      });

    let selectSponsordealSoorten =
      sponsorDealTypes &&
      sponsorDealTypes.map(function (sponsordealSoort) {
        return {
          label: sponsordealSoort.name,
          value: sponsordealSoort.id.toString(),
        };
      });

    const selectDoorlooptijd = [
      1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8,
    ].map((value) => ({
      label: value.toLocaleString("nl"),
      value,
    }));

    let selectVerantwoordelijken =
      verantwoordelijken &&
      verantwoordelijken.map(function (verantwoordelijke) {
        return {
          label: verantwoordelijke.fullName,
          value: verantwoordelijke.id.toString(),
        };
      });

    const seizoensafspraakElements: JSX.Element[] = [];
    if (formValues && formValues.seasonAgreements && formValues.from) {
      if (formValues.seperateSeasonalAgreements) {
        const startJaar = this.getSeizoenStartJaar();
        formValues.seasonAgreements
          .sort((n1: any, n2: any) => n1.season - n2.season)
          .forEach((afspraak: SeasonAgreement, index: number) => {
            const titel = this.getSeizoenTitel(startJaar, index);
            const element = this.createSeizoensafspraakElement(index, titel);
            seizoensafspraakElements.push(element);
          });
      } else {
        const element = this.createSeizoensafspraakElement(0);
        seizoensafspraakElements.push(element);
      }
    }

    if (isEdit) {
      title = (
        <div className="column subHeader is-full">
          <h1>Sponsordeal</h1>
        </div>
      );

      fields = (
        <>
          <Field
            component={FormDropdown}
            label="Bedrijf"
            name="companyId"
            showSideBySide={true}
            options={selectBedrijven}
            required={true}
            readOnly={this.isReadOnly()}
            validate={required}
          />

          <Field
            component={LabelInput}
            label="Status"
            name="status"
            showSideBySide={true}
            text={
              sponsordeal!.status != SponsordealStatus.Opgezegd
                ? SponsordealStatus[sponsordeal!.status]
                : SponsordealStatus[sponsordeal!.status] +
                  " per " +
                  moment(sponsordeal!.cancelledDate).format("DD-MM-YYYY")
            }
          />

          {sponsordeal!.status == SponsordealStatus.Opgezegd && (
            <>
              <Field
                component={LabelInput}
                label="Opgezegd reden"
                name="cancelledReason"
                showSideBySide={true}
                text={sponsordeal!.cancelledReason}
              />

              <Field
                component={LabelInput}
                label="Opgezegd omschrijving"
                name="cancelledDescription"
                showSideBySide={true}
                text={sponsordeal!.cancelledDescription}
              />
            </>
          )}
        </>
      );

      sponsorDealAttachments =
        sponsordeal &&
        sponsordeal.attachments &&
        sponsordeal.attachments.map((attachment) =>
          AttachmentDocumentListHelper({
            attachment: attachment,
            onDeleteAttachment: onDeleteAttachment,
            showDelete:
              !this.isReadOnly() &&
              attachment.attachmentType != SponsordealAttachmentEnum.Contract,
          })
        );

      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              {/* {sponsordeal && sponsordeal.isAfgerond && <button type="button" className="button is-success" onClick={onDownload}>
                                Download contract
                            </button>} */}
              {sponsordeal &&
                sponsordeal.attachments &&
                sponsordeal.attachments.length > 0 &&
                !isReadOnly && (
                  <button
                    type="button"
                    className="button is-success"
                    onClick={openEmailContract}
                  >
                    Email Contract
                  </button>
                )}
              {sponsordeal &&
                sponsordeal.status != SponsordealStatus.Aangemaakt &&
                !isReadOnly && (
                  <button
                    type="button"
                    className="button is-success"
                    onClick={openExtendContractModal}
                  >
                    Verlengen
                  </button>
                )}
              {sponsordeal &&
                sponsordeal.status == SponsordealStatus.Afgerond &&
                sponsordeal.cancelledDate == null &&
                !isReadOnly &&
                moment(sponsordeal.to).isAfter(moment(new Date(), "day")) && (
                  <button
                    type="button"
                    className="button is-success"
                    onClick={openTerminateModal}
                  >
                    Opzeggen contract
                  </button>
                )}
              {sponsordeal && !this.isReadOnly() && (
                <button type="button" className="button" onClick={onDelete}>
                  Verwijder
                </button>
              )}
              <button type="button" className="button" onClick={onCancel}>
                {this.isReadOnly() ? "Terug" : "Annuleren"}
              </button>
              {
                <button
                  type="submit"
                  disabled={
                    submitting ||
                    (this.props.sponsordeal &&
                      this.props.sponsordeal.isArchived)
                  }
                  className="button is-success"
                >
                  Opslaan
                </button>
              }
            </div>
          </div>
        </div>
      );
    } else if (
      isModal &&
      isEdit == false &&
      this.props.formValues &&
      this.props.formValues.bestaandBedrijf
    ) {
      fields = (
        <>
          <h3 className="spacer-title top-title">Bedrijf</h3>
          <Field
            component={FormCheckbox}
            label="Bestaand bedrijf?"
            name="bestaandBedrijf"
            showSideBySide={true}
            readOnly={isReadOnly}
            onChange={this.onChangeBestaandBedrijf}
          />

          <Field
            component={FormDropdown}
            label="Bedrijf"
            name="companyId"
            showSideBySide={true}
            options={selectBedrijven}
            required={true}
            readOnly={isReadOnly}
            validate={[required]}
          />
        </>
      );

      wrapperClass = "customOverflow";
    } else {
      fields = (
        <AddSponsordealFields
          onChangeBestaandBedrijf={this.onChangeBestaandBedrijf}
          verantwoordelijken={selectVerantwoordelijken}
          hasPartner={this.props.formValues && this.props.formValues.hasPartner}
        />
      );

      wrapperClass = "customOverflow";
    }

    wrapperClass = classnames(wrapperClass, "singleCompany customPadding");

    return (
      <div className="content">
        <Form onSubmit={handleSubmit(onSubmit)} className={wrapperClass}>
          <div className="columns contentPanel is-multiline is-mobile">
            {title}

            {fields}

            <Field
              component={FormDropdown}
              label="Soort"
              name="sponsorDealTypeId"
              showSideBySide={true}
              options={selectSponsordealSoorten}
              onChange={this.onChangeSponsorSoort}
              required={true}
              readOnly={this.isReadOnly()}
              validate={required}
            />
            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop">
              <Field
                component={FormDatePicker}
                label="Van"
                name="from"
                showSideBySide={true}
                required={true}
                readOnly={this.isReadOnly()}
                validate={[required]}
              />
              <br></br>
              {isEdit && (
                <Field
                  component={FormDatePicker}
                  label="Tot"
                  name="to"
                  showSideBySide={true}
                  readOnly={true}
                />
              )}
            </div>
            <Field
              component={FormDropdown}
              label="Doorlooptijd (seizoenen)"
              name="leadTime"
              showSideBySide={true}
              options={selectDoorlooptijd}
              readOnly={this.isReadOnly()}
              onChange={this.onChangeDoorlooptijd}
            />

            {settings && settings.socialMediaVerantwoordelijkeId && (
              <Field
                component={FormCheckbox}
                label="Plaatsing op social media"
                name="socialMediaPlaatsing"
                showSideBySide={true}
              />
            )}

            {isEdit && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop ">
                <div className="field-label">
                  <label className="label bcwLabel is-pulled-left">
                    Bestanden
                  </label>
                </div>
                <div className="custom-docs">{sponsorDealAttachments}</div>
              </div>
            )}

            {!isEdit && formValues && !formValues.bestaandBedrijf && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop ">
                <div className="field-label">
                  <label className="label bcwLabel is-pulled-left"> </label>
                </div>
                <div className="custom-docs">{sponsorDealAttachments}</div>
              </div>
            )}

            {!this.isReadOnly() && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop ">
                <div className="field-label">
                  <label className="label bcwLabel is-pulled-left">
                    Upload bestanden
                  </label>
                </div>
                <Field
                  component={FileUpload}
                  label="Bijlagen"
                  name="attachments"
                  onChangeUpload={this.onChangeUploadSponsordealAttachment}
                />
              </div>
            )}

            {formValues && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop ">
                <div className="field-label">
                  <label className="label bcwLabel is-pulled-left">
                    {!this.isReadOnly()
                      ? "Upload contract"
                      : "Upload nieuw contract"}
                  </label>
                </div>
                <Field
                  component={FileUpload}
                  label="Bijlagen"
                  name="attachments"
                  multiple={false}
                  accept=".pdf"
                  onChangeUpload={this.onChangeUploadSponsordealContractBijlage}
                />
              </div>
            )}

            <div className="field column buttons is-pulled-left">
              {!this.isReadOnly() && (
                <button
                  type="button"
                  className="button is-success"
                  onClick={openSignatureModal}
                >
                  Zet handtekening
                </button>
              )}
              {!this.isReadOnly() && (
                <button
                  type="button"
                  className="button is-success"
                  onClick={onGenerate}
                >
                  Genereer contract
                </button>
              )}
            </div>

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-full-desktop">
              <Field
                component={TextArea}
                label="Opmerking"
                name="remark"
                readOnly={this.isReadOnly()}
                showSideBySide={true}
              />
            </div>

            <div className="field column seizoenAfwijkendeAfspraken is-horizontal is-marginless is-full-mobile is-full-tablet">
              <Field
                component={FormCheckbox}
                label="Per seizoen afwijkende afspraken"
                name="seperateSeasonalAgreements"
                showSideBySide={true}
                readOnly={sponsordeal && this.isReadOnly()}
                onValueChanged={this.onChangeHeeftSeizoensafspraken}
              />
            </div>

            <div className="season column">{seizoensafspraakElements}</div>
          </div>

          <div className="floating-btn-bar">{buttons}</div>
        </Form>
      </div>
    );
  }
}

const SponsordealReduxForm = reduxForm<{}, OwnProps>({
  form: "SponsordealForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SponsordealComponent);

export default connect((state: RootState, props: any) => {
  return {
    initialValues: {
      id: props.isEdit ? state.sponsordeal.id : "",
      bestaandBedrijf: props.bedrijven.length > 0 ? true : false,
      companyId: props.isEdit
        ? state.sponsordeal.companyId
        : props.companyId
        ? props.companyId
        : props.bedrijven.length > 0
        ? props.bedrijven[0].id
        : "",
      sponsorDealTypeId: props.isEdit
        ? state.sponsordeal.sponsorDealTypeId
        : state.sponsorDealTypes.length > 0
        ? state.sponsorDealTypes[0].id
        : 0,
      status: props.isEdit ? state.sponsordeal.status : 0,
      cancelledDate: props.isEdit ? state.sponsordeal.cancelledDate : "",
      cancelledDescription: props.isEdit
        ? state.sponsordeal.cancelledDescription
        : "",
      cancelledReason: props.isEdit ? state.sponsordeal.cancelledReason : "",
      from: props.isEdit ? state.sponsordeal.from : new Date().toDateString(),
      to: props.isEdit ? state.sponsordeal.to : new Date().toDateString(),
      country:
        props.isEdit && state.sponsordeal.company
          ? state.sponsordeal.company.address.country
          : "Nederland",
      remark: props.isEdit ? state.sponsordeal.remark : "",
      leadTime: props.isEdit ? state.sponsordeal.leadTime : 1,
      seperateSeasonalAgreements: props.isEdit
        ? state.sponsordeal.seperateSeasonalAgreements
        : false,
      seasonAgreements: props.isEdit
        ? state.sponsordeal.seasonAgreements
        : [
            {
              prijs:
                state.sponsorDealTypes.length > 0
                  ? state.sponsorDealTypes[0].defaultPrice
                  : 0,
              inKind: "",
              season: 1,
              seasonAgreementCompensations: [],
            },
          ],
      attachments: state.sponsordeal.attachments
        ? state.sponsordeal.attachments
        : [],
      isQualified: props.isEdit ? state.contactpersoon.isQualified : true,
      sponsordealBijlagenUrls: props.isEdit
        ? state.sponsordeal.sponsordealBijlagenUrls
        : [],
      sponsordealBijlagen: [],
      sponsordealContractBijlage: {},
      socialMediaPlaatsing: false,
      soortBedrijf: "0",
      verantwoordelijkeId: props.isEdit
        ? 0
        : props.verantwoordelijken.length > 0
        ? props.verantwoordelijken[0].id
        : 0,
    },
    formValues: getFormValues("SponsordealForm")(state),
  };
})(SponsordealReduxForm);
