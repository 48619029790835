import ApiClient from './ApiClient';
import { Actie } from "../types/model";

const endpoint = 'api/Action';

export default class ActieApi {
    public static async getActies(): Promise<Actie[]> {
        var Acties = await ApiClient.doHttpRequest<Actie[]>('get', `${endpoint}`);
        return Acties;
    }

    public static async getActie(id: string): Promise<Actie> {
        var Actie = await ApiClient.doHttpRequest<Actie>('get', `${endpoint}/${id}`);
        return Actie;
    }

    public static async createActie(Actie: Actie): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}`, Actie);
    }

    public static async updateActie(Actie: Actie): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}`, Actie);
    }

    // public static async archiveSelectedActies(selectedActies: number[]): Promise<void> {
    //     await ApiClient.doHttpRequest<void>('post', `${endpoint}/archiveSelectedActies`, selectedActies);
    // }

    // public static async exportSelectedActies(selectedActies: number[]): Promise<void> {
    //     await ApiClient.doFileRequest<void>('post', `${endpoint}/exportSelectedActies`, selectedActies, false, true, { responseType: 'blob' })
    //     .then(response => {
    //         var blobUrl = window.URL.createObjectURL(response.data);
    //         var tempLink = document.createElement('a');
    //         tempLink.href = blobUrl;
    //         tempLink.setAttribute('download', 'bestellingen.csv');
    //         tempLink.click();
    //     });
    // }

    // public static async sendActieEmailInvitations(selectedActies: number[]): Promise<void> {
    //     await ApiClient.doHttpRequest<void>('post', `${endpoint}/sendActieEmailInvitations`, selectedActies);
    // }

    public static async deleteActieFlyer(id: number): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `${endpoint}/ActieBijlage/${id}`);
    }
}