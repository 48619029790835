import { Bijlage, Nieuwsbericht, Permissions } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import { EditorState, convertToRaw } from "draft-js";
import { GetNieuwsberichtAction, GetNieuwsberichtenAction } from "../constants/nieuwsberichtActionTypes";
import React, { ChangeEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getNieuwsbericht, getNieuwsberichten } from "../actions/nieuwsberichtActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import { IsReadOnly } from "../helpers/PermissionHelper";
import { Modal } from "../components/Modal";
import NieuwsberichtApi from "../api/NieuwsberichtApi";
import NieuwsberichtComponent from "../components/NieuwsberichtComponent/NieuwsberichtComponent";
import NieuwsberichtenComponent from "../components/NieuwsberichtenComponent/NieuwsberichtenComponent";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import draftToHtml from 'draftjs-to-html';
import matchSorter from "match-sorter";
import moment from "moment";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
    getNieuwsberichten: () => Promise<GetNieuwsberichtenAction>;
    getNieuwsbericht: (id: string) => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    nieuwsberichten: Nieuwsbericht[];
    nieuwsbericht: Nieuwsbericht;
}

interface MatchParams {
    id: string
}

type Props = StateProps & DispatchProps & RouteComponentProps<MatchParams>

interface State {
    showCreateModal: boolean;
    showEditModal: boolean;
    selectedNieuwsberichten: string[];
    archief: boolean;
}
const isReadOnly = IsReadOnly(Permissions.ManageNews);
class NieuwsberichtenContainer extends React.Component<Props, State> {
    state: State = {
        showCreateModal: false,
        showEditModal: false,
        selectedNieuwsberichten: [],
        archief: false
    }

    componentDidMount() {
        this.props.getNieuwsberichten();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        this.props.getNieuwsbericht(rowInfo.original.id);
        this.setState({ showEditModal: true });
    }

    openCreateModal = () => {
        this.setState({ showCreateModal: true });
    }

    createNieuwsbericht = (formValues: Nieuwsbericht, editorState: EditorState) => {
        var nieuwsbericht = formValues;

        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const html = draftToHtml(
            rawContentState
        );
        nieuwsbericht.item.description = html;

        NieuwsberichtApi.createNieuwsbericht(nieuwsbericht)
            .then(() => {
                this.props.getNieuwsberichten();
                toast.success("Het opslaan is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.onClose();
            });
    }

    archiveNieuwsbericht = () => {
        if (this.props.nieuwsbericht && this.props.nieuwsbericht.id)
            NieuwsberichtApi.archiveNieuwsbericht(this.props.nieuwsbericht.id)
                .then(() => {
                    this.props.getNieuwsberichten();
                    toast.success("Het verwijderen is succesvol")
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => {
                    this.onClose();
                });
    }

    openArchief = () => {
        this.setState({ archief: this.state.archief ? false : true });
    }

    onClose = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false
        });
    }

    onDeleteNieuwsberichtFotoBijlage = (id: string) => {
        NieuwsberichtApi.deleteNieuwsberichtFotoBijlage(id)
            .then(() => {
                if (this.props.nieuwsbericht.id) {
                    this.props.getNieuwsbericht(this.props.nieuwsbericht.id as string);
                    toast.success("Het verwijderen is succesvol");
                }
            });
    }

    onDeleteHoofdFotoBijlage = (id: string) => {
        NieuwsberichtApi.deleteHoofdFotoBijlage(id)
            .then(() => {
                this.props.getNieuwsbericht(this.props.nieuwsbericht.id as string);
                toast.success("Het verwijderen is succesvol");
            });
    }

    updateNieuwsbericht = (formValues: Nieuwsbericht, editorState: EditorState) => {
        this.props.showLoading();
        var nieuwsbericht = formValues;

        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const html = draftToHtml(
            rawContentState
        );
        nieuwsbericht.item.description = html;

        if (nieuwsbericht && nieuwsbericht.id) {
            NieuwsberichtApi.updateNieuwsbericht(nieuwsbericht.id, nieuwsbericht)
                .then(() => {
                    this.props.getNieuwsberichten();
                    toast.success("Het opslaan is succesvol")
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => {
                    this.props.hideLoading();
                    this.onClose();
                });
        }
    }

    archiveSelectedNieuwsberichten = () => {
        this.props.showLoading();
        NieuwsberichtApi.archiveSelectedNieuwsberichten(this.state.selectedNieuwsberichten)
            .then(() => {
                this.props.getNieuwsberichten();
                this.setState({ selectedNieuwsberichten: [] });
                toast.success("Het archiveren is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.props.hideLoading();
            });
    }

    selectNieuwsbericht = (event: any) => {
        const nieuwsberichtId = event.target.value;

        if (event.target.checked) {
            if (this.state.selectedNieuwsberichten.find(item => item === nieuwsberichtId) == null) {
                const selectedNieuwsberichten = [...this.state.selectedNieuwsberichten, nieuwsberichtId];
                this.setState({ selectedNieuwsberichten: selectedNieuwsberichten });
            }
        }
        else {
            var index = this.state.selectedNieuwsberichten.indexOf(nieuwsberichtId);
            const selectedNieuwsberichten = [
                ...this.state.selectedNieuwsberichten.slice(0, index),
                ...this.state.selectedNieuwsberichten.slice(index + 1)
            ]
            this.setState({ selectedNieuwsberichten: selectedNieuwsberichten });
        }
    }

    selectAlleNieuwsberichten = (event: any) => {
        if (event.target.checked) {
            const selectedNieuwsberichten = this.state.selectedNieuwsberichten;
            this.props.nieuwsberichten.forEach(nieuwsbericht => {
                if (nieuwsbericht.id && this.state.selectedNieuwsberichten.find(item => item === nieuwsbericht.id) == null) {
                    selectedNieuwsberichten.push(nieuwsbericht.id.toString());
                }
            });
            this.setState({ selectedNieuwsberichten: selectedNieuwsberichten });
        }
        else {
            this.setState({ selectedNieuwsberichten: [] });
        }
    }

    render() {
        const { nieuwsberichten, nieuwsbericht } = this.props;

        let columns: Array<Column> = [
            {
                Header: "Titel",
                accessor: "item.title",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Auteur",
                accessor: "author",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Datum",
                accessor: "date",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => props && props.value ? moment(props.value).format("DD-MM-YYYY HH:mm") : "",
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]

        let nieuwsberichtenList = nieuwsberichten;
        if (this.state.archief)
            nieuwsberichtenList = nieuwsberichtenList.filter(p => p.item.isArchived == true);
        else
            nieuwsberichtenList = nieuwsberichtenList.filter(p => p.item.isArchived == false);

        return (
            <>
                <NieuwsberichtenComponent onRowClick={this.onRowClick}
                    openModal={this.openCreateModal} openArchief={this.openArchief}
                    archiefButtonText={this.state.archief ? "Terug" : "Archief"} archiverenButtonText={this.state.archief ? "Dearchiveren" : "Archiveren"}
                    archiveSelectedNieuwsberichten={this.archiveSelectedNieuwsberichten} columns={columns} nieuwsberichten={nieuwsberichtenList} />

                {this.state.showCreateModal
                    ? (<Modal
                        title="Nieuw nieuwsbericht"
                        content={
                            <NieuwsberichtComponent
                                onDeleteHoofdFotoBijlage={this.onDeleteHoofdFotoBijlage}
                                onDeleteNieuwsberichtFotoBijlage={this.onDeleteNieuwsberichtFotoBijlage}
                                onSubmit={this.createNieuwsbericht}
                            />
                        }
                        onSubmit={() => this.props.submitForm("NieuwsberichtForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }

                {this.state.showEditModal
                    ? (<Modal
                        title="Aanpassen nieuwsbericht"
                        content={
                            <NieuwsberichtComponent
                                nieuwsbericht={nieuwsbericht}
                                onDeleteHoofdFotoBijlage={this.onDeleteHoofdFotoBijlage}
                                onDeleteNieuwsberichtFotoBijlage={this.onDeleteNieuwsberichtFotoBijlage}
                                onSubmit={this.updateNieuwsbericht} isEdit={true}
                            />
                        }
                        onSubmit={() => this.props.submitForm("NieuwsberichtForm")}
                        readOnly={isReadOnly}
                        onClose={this.onClose}
                        onArchive={this.archiveNieuwsbericht} />
                    )
                    : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    nieuwsberichten: state.nieuwsberichten,
    nieuwsbericht: state.nieuwsbericht
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getNieuwsberichten: () => dispatch(getNieuwsberichten()),
    getNieuwsbericht: (id: string) => dispatch(getNieuwsbericht(id)),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NieuwsberichtenContainer));
