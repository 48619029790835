import React from "react";
import { InvoiceValidation } from "../types/model";

export const generateInvoiceErrors = (errors: InvoiceValidation[]) => {
  let errorBuilder = [
    <div>
      De volgende foutmeldingen zijn opgetreden, om door te gaan moeten deze opgelost worden: <br />
    </div>,
  ];
  errors.forEach((error: InvoiceValidation) => {
    if (error.errors.length > 0) {
      errorBuilder.push(
        <div>
          <br />
          {error.company}
        </div>
      );
      error.errors.forEach((e) => {
        errorBuilder.push(<div>{e}</div>);
      });
    }
  });
  return errorBuilder;
};
