import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { WrappedFieldProps } from "redux-form";

interface OwnProps {
  onClear: (signatureCanvas: any) => void;
  onChangeSignature: (signatureCanvas: any) => void;
}

export const Signature = (props: WrappedFieldProps & OwnProps) => {
  const { onClear, onChangeSignature } = props;

  let style = {};
  let signatureCanvas: any = {};

  let wrapperClass = "field column is-horizontal is-marginless is-full-mobile is-full-tablet is-full-desktop";

  //style = { paddingTop: '.5rem', paddingRight: '2rem' };

  return (
    <div className={wrapperClass}>
      <div className="field-label">
        <label className="label bcwLabel is-pulled-left" style={style}>
          Handtekening
        </label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <SignatureCanvas
              onEnd={() => onChangeSignature(signatureCanvas)}
              canvasProps={{ name: "signature", width: 500, height: 300, style: { border: "1px solid #dbdbdb" } }}
              ref={(ref: any) => (signatureCanvas = ref)}
            />

            <div className="is-size-7">
              <p>
                <a onClick={() => onClear(signatureCanvas)}>Clear</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
