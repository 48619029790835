import moment from "moment";

export const combineDateAndTime = (date:string, time:string ) => {
    if(date && time){
        var dateMoment = moment(date);
        var momentObj = moment(dateMoment.format('YYYY-MM-DD') + ' ' + time, 'YYYY-MM-DD HH:mm');

        return momentObj.format('YYYY-MM-DD') + 'T' + momentObj.format('HH:mm') + ':00';
    }
    return date;
}