import React from "react";
import NumberFormat from "react-number-format";

interface Props {
  value?: number;
}

export const FinancialValue = (props: Props) => {
  const { value } = props;

  return <NumberFormat value={value} displayType={"text"} decimalSeparator="," fixedDecimalScale={true} thousandSeparator="." decimalScale={2} prefix={"€"} />;
};
