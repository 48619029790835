import * as React from "react";

import { Notation, Permissions } from "../../types/model";

import { CSVLink } from "react-csv";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openArchief: () => void;
  openModal: () => void;
  archiveSelectedNotulen: () => void;
  exportSelectedNotulen: () => void;
  hideExport: boolean;
  columns: Array<Column>;
  notation: Notation[];
  archiefButtonText: string;
  archiverenButtonText: string;
  bedrijfId?: number;
}

const isReadOnly = IsReadOnly(Permissions.ManageRecords);
export default class NotulenComponent extends React.Component<OwnProps> {
  render() {
    const {
      onRowClick,
      openModal,
      openArchief,
      archiveSelectedNotulen,
      exportSelectedNotulen,
      hideExport,
      archiefButtonText,
      archiverenButtonText,
      columns,
      notation,
    } = this.props;

    return (
      <>
        <div className="content notulen">
          <div className="contentPanel">
            <div className="columns contentPanel">
              <div className="column is-full">
                <div className="buttons is-pulled-left">
                  <button className="button is-success archive-button" onClick={openArchief}>
                    {archiefButtonText}
                  </button>
                </div>
                <div className="buttons is-pulled-right facturenButtons">
                  {hideExport && (
                    <button className="button is-success" onClick={exportSelectedNotulen}>
                      Printen (pdf)
                    </button>
                  )}
                  {!isReadOnly && (
                    <>
                      <button className="button is-success" onClick={archiveSelectedNotulen}>
                        {archiverenButtonText}
                      </button>
                      <button className="button is-success add-button" onClick={openModal}>
                        Toevoegen
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Gridview id="NotulenList" onRowClick={onRowClick} columns={columns} data={notation} />
          </div>
        </div>
      </>
    );
  }
}
