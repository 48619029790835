import { Action } from "redux";
import { Person, Role, Verantwoordelijke, CustomPage } from "../types/model";

export const GET_GEBRUIKERS = "GET_GEBRUIKERS";
export const GET_GEBRUIKER = "GET_GEBRUIKER";
export const GET_PERSOON_BY_EMAIL = "GET_PERSOON_BY_EMAIL";
export const GET_VERANTWOORDELIJKEN = "GET_VERANTWOORDELIJKEN";
export const GET_ROLLEN = "GET_ROLLEN";
export const GET_ROL = "GET_ROL";

export interface GetGebruikersAction extends Action<string> {
    gebruikers: Person[];
}

export interface GetGebruikerAction extends Action<string> {
    gebruiker: Person;
}

export interface GetContactpersoonByEmailAction extends Action<string> {
    contactPersoon: Person;
}

export interface GetVerantwoordelijkenAction extends Action<string> {
    verantwoordelijken: Verantwoordelijke[];
}

export interface GetRollenAction extends Action<string> {
    rollen: Role[];
}

export interface GetRolAction extends Action<string> {
    rol: Role;
}
