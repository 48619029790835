import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { CustomPage, Permissions, Person, Role } from "../../types/model";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openModal: () => void;
  columns: Array<Column>;
  customPages: Array<CustomPage>;
}

const isReadOnly = IsReadOnly(Permissions.ManageRoles);
export default class CustomPagesComponent extends React.Component<OwnProps> {
  render() {
    const { onRowClick, openModal, columns, customPages: customPages } = this.props;
    return (
      <>
        <div className="content">
          <div className="columns contentPanel">
            <div className="column is-full">
              <div className="buttons is-pulled-right">
                {!isReadOnly && (
                  <button className="button is-success add-button" onClick={openModal}>
                    Toevoegen
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="contentPanel">
            <Gridview id="RollenList" onRowClick={onRowClick} columns={columns} data={customPages} />
          </div>
        </div>
      </>
    );
  }
}
