import * as React from "react";
import { NavLink } from "react-router-dom";
import { ShowPage } from "../helpers/PermissionHelper";
import { Permissions } from "../types/model";

interface State {
  showMenu: boolean;
}

export default class FoldableSponsorsMenu extends React.Component<{}, State> {
  state = {
    showMenu: false,
  };

  onClickMenu = (event: React.MouseEvent) => {
    this.setState({ showMenu: this.state.showMenu ? false : true });
  };

  render() {
    const isVisibleCompanies = ShowPage([Permissions.ReadCompanies, Permissions.ManageCompanies]);
    const isVisibleContactPersons = ShowPage([Permissions.ReadContactPersons, Permissions.ManageContactPersons]);
    const isVisibleLeads = ShowPage([Permissions.ReadLeads, Permissions.ManageLeads]);

    return (
      (isVisibleCompanies || isVisibleContactPersons || isVisibleLeads) && (
        <div className="menu-list">
          <a onClick={this.onClickMenu}>Sponsoren</a>
          {this.state.showMenu && (
            <ul>
              {isVisibleCompanies && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Bedrijven">
                  Bedrijven
                </NavLink>
              )}

              {isVisibleContactPersons && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Contactpersonen">
                  Contactpersonen
                </NavLink>
              )}

              {isVisibleLeads && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Leads">
                  Leads
                </NavLink>
              )}
            </ul>
          )}
        </div>
      )
    );
  }
}
