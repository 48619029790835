import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  FormErrors,
  getFormValues,
  formValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import {
  Event,
  EventSponsorDealType,
  SponsorDealType,
  Contactpersoon,
  EventLead,
  EventContactpersoon,
  Address,
  BaseItem,
  Bijlage,
  Permissions,
} from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import datetimePast from "../../validators/datetimePast";
import postalcode from "../../validators/postalcode";
import lengthmax6 from "../../validators/lengthmax6";
import FormDatePicker from "../FormDatePicker";
import { BcwTimePicker } from "../BcwTimePicker";
import { EditorState, ContentState, Editor } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import integer from "../../validators/integer";
import { FormDropdown } from "../FormDropdown";
import { EventStatus } from "../../constants/enums";
import history from "../history";
import { LabelInput } from "../LabelInput";
import FileUpload from "../FileUpload";
import moment from "moment";
import { RootState } from "../../types/state";
import { HiddenInput } from "../HiddenInput";
import { DocumentListHelperTwo } from "../../helpers/DocumentListHelper";
import ImageCropper from "../ImageCropper";
import { IsReadOnly } from "../../helpers/PermissionHelper";
import TextEditor from "../TextEditor";
import EventApi from "../../api/EventApi";

import openImage from "../../images/bin.svg";

export interface OwnProps {
  event?: Event;
  formValues: Event;
  sponsorDealTypes: SponsorDealType[];
  contactpersonen?: Contactpersoon[];
  onSubmit: (
    formValues: Event,
    editorState: EditorState,
    selectedContactpersonen: string[]
  ) => void;
  onDeleteAttachment: (id: string) => void;
  onDeleteOverigeFotoBijlage: (id: string) => void;
  onDelete: () => void;
  onCancel: () => void;
  isEdit?: boolean;
  isNew?: boolean;
}

interface State {
  editorState: EditorState;
  selectedContactpersonen: string[];
}

const isReadOnly = IsReadOnly(Permissions.ManageEvents);
class EventComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<{} & any, OwnProps>) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      selectedContactpersonen: [],
    };
  }

  doSubmit = (
    formValues: Event,
    editorState: EditorState,
    selectedContactpersonen: string[]
  ) => {
    this.props.onSubmit(formValues, editorState, selectedContactpersonen);
  };

  componentDidUpdate = (prevProps: any) => {
    if (prevProps != this.props && this.props.formValues && this.props.isEdit) {
      if (
        this.props.formValues &&
        this.props.formValues.item &&
        this.props.formValues.item.description != undefined
      ) {
        const blocksFromHtml = htmlToDraft(
          this.props.formValues.item.description
        );
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }

      if (
        this.props.initialValues.item.uitnodigingUrl !==
          prevProps.initialValues.item.uitnodigingUrl &&
        this.props.isEdit
      )
        this.props.initialize(this.props.initialValues);

      if (
        this.props.initialValues.eventContactpersonen !==
          prevProps.initialValues.eventContactpersonen &&
        this.props.isEdit
      ) {
        this.setState({
          selectedContactpersonen:
            this.props.initialValues.eventContactpersonen.map(
              (c: EventContactpersoon) => c.contactpersoonId
            ),
        });
      }

      if (
        this.props.initialValues.eventSponsorDealTypes !==
        prevProps.initialValues.eventSponsorDealTypes
      ) {
        this.props.initialize(this.props.initialValues);
      }

      if (
        this.props.initialValues.eventLeads !==
          prevProps.initialValues.eventLeads &&
        this.props.initialValues.eventLeads &&
        this.props.initialValues.eventLeads[0].id != 0 &&
        this.props.isEdit
      ) {
        this.props.initialize(this.props.initialValues);
      }
    }
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  onSelectedChanged = (event: any, contactpersoonId: string) => {
    if (event.target.checked) {
      var contactpersonen = this.state.selectedContactpersonen;
      contactpersonen.push(contactpersoonId);
      this.setState({ selectedContactpersonen: contactpersonen });
    } else {
      this.setState({
        selectedContactpersonen: this.state.selectedContactpersonen.filter(
          (c) => c != contactpersoonId
        ),
      });
    }
  };

  createAttachmentList = (bijlagen: Bijlage[]) => {
    return (
      bijlagen &&
      bijlagen.map((bijlage: Bijlage) => {
        return (
          <>
            <a href={bijlage.content} target="_blank">
              {decodeURIComponent(bijlage.title)}
            </a>
            {!isReadOnly && (
              <a
                className="gridIconDelete"
                onClick={() =>
                  this.props.onDeleteAttachment(bijlage.id ? bijlage.id : "")
                }
              >
                <img src={openImage} alt="Delete" />
              </a>
            )}
            <br />
          </>
        );
      })
    );
  };

  createHeaderFoto = (
    url: string,
    id: string | undefined,
    filename?: string
  ) => {
    if (!filename) {
      var filename = url ? url.split("/").pop() : undefined;
      if (filename == undefined) filename = "";
    }

    return (
      <>
        <div className="custom-docs event-docs">
          <a href={url} target="_blank">
            {decodeURIComponent(filename)}
          </a>
          {!isReadOnly && id && (
            <a
              className="gridIconDelete"
              onClick={() => this.props.onDeleteAttachment(id)}
            >
              <img src={openImage} alt="Delete" />
            </a>
          )}
        </div>
      </>
    );
  };

  onHoofdFotoSave = (fileName: string, fileDataURL: string) => {
    let file = fileDataURL.split(",")[1];
    this.props.formValues.item.attachments.push({
      id: undefined,
      type: 1,
      title: fileName,
      content: file,
      overwrite: true,
      url: "",
      isArchived: false,
    });
  };

  onChangeUploadOverigeFotos = (fileName: string, file: string) => {
    var existsInOverigeEventFotos = this.props.formValues.item.attachments.find(
      (attachment: Bijlage) =>
        decodeURI(attachment.content.split("/").pop()!) === fileName
    );
    var existsInOverigeFotos = this.props.formValues.item.attachments.find(
      (bijlage: Bijlage) => bijlage.title === fileName
    );

    if (existsInOverigeEventFotos) {
      if (
        window.confirm(
          "Er bestaat al een bijlage met deze bestandsnaam, weet u zeker dat u deze wilt overschrijven?"
        )
      )
        this.props.formValues.item.attachments.push({
          id: undefined,
          type: 2,
          title: fileName,
          content: file,
          overwrite: true,
          url: "",
          isArchived: false,
        });
    }
    if (!existsInOverigeFotos)
      this.props.formValues.item.attachments.push({
        id: undefined,
        type: 2,
        title: fileName,
        content: file,
        overwrite: false,
        url: "",
        isArchived: false,
      });
  };

  onChangeUploadUitnodiging = (fileName: string, file: string) => {
    if (
      this.props.formValues.item.uitnodigingUrl &&
      this.props.formValues.item.uitnodigingUrl.split("/").pop() === fileName
    ) {
      if (
        window.confirm(
          "Er bestaat al een bijlage met deze bestandsnaam, weet u zeker dat u deze wilt overschrijven?"
        )
      )
        this.props.formValues.item.attachments.push({
          id: undefined,
          type: 4,
          title: fileName,
          content: file,
          overwrite: false,
          url: "",
          isArchived: false,
        });
    } else
      this.props.formValues.item.attachments.push({
        id: undefined,
        type: 4,
        title: fileName,
        content: file,
        overwrite: false,
        url: "",
        isArchived: false,
      });
  };

  createEventLead = (index: number): JSX.Element => {
    return (
      <React.Fragment key={index}>
        <Field
          component={FormTextInput}
          label="Voornaam"
          name={`eventLeads[${index}].voornaam`}
          showSideBySide={true}
          readOnly={
            isReadOnly &&
            this.props.event &&
            (this.props.event.status == EventStatus.Definitief ||
              this.props.event.status == EventStatus.Nieuw)
          }
        />

        <Field
          component={FormTextInput}
          label="Achternaam"
          name={`eventLeads[${index}].achternaam`}
          showSideBySide={true}
          readOnly={
            isReadOnly &&
            this.props.event &&
            (this.props.event.status == EventStatus.Definitief ||
              this.props.event.status == EventStatus.Nieuw)
          }
        />

        <Field
          component={FormTextInput}
          label="Bedrijfsnaam"
          name={`eventLeads[${index}].bedrijfsnaam`}
          showSideBySide={true}
          readOnly={
            isReadOnly &&
            this.props.event &&
            (this.props.event.status == EventStatus.Definitief ||
              this.props.event.status == EventStatus.Nieuw)
          }
        />

        <Field
          component={FormTextInput}
          label="Emailadres"
          name={`eventLeads[${index}].emailadres`}
          showSideBySide={true}
          readOnly={
            isReadOnly &&
            this.props.event &&
            (this.props.event.status == EventStatus.Definitief ||
              this.props.event.status == EventStatus.Nieuw)
          }
        />

        {!isReadOnly &&
          this.props.event &&
          (this.props.event.status == EventStatus.Definitief ||
            this.props.event.status == EventStatus.Nieuw) && (
            <a
              className="button is-success delete-btn"
              onClick={() => this.deleteEventLead(index)}
            >
              Delete
            </a>
          )}

        <div className="border-spacer" />
      </React.Fragment>
    );
  };

  addEventLead = () => {
    var eventLeads = this.props.formValues.eventLeads;
    eventLeads.push({
      id: 0,
      eventId: 0,
      leadId: 0,
      titel: "",
      voornaam: "",
      achternaam: "",
      emailadres: "",
      bedrijfsnaam: "",
      datum: "",
      isDeleted: false,
    });
    this.props.initialize(this.props.formValues);
  };

  deleteEventLead = (index: number) => {
    if (this.props.formValues.eventLeads.length > 1) {
      this.props.formValues.eventLeads[index].isDeleted = true;
      this.props.initialize(this.props.formValues);
    } else {
      var eventLead = this.props.formValues.eventLeads[0];
      eventLead.isDeleted = true;
      this.props.initialize(this.props.formValues);
    }
  };

  createEventSponsordealSoort = (
    index: number,
    type?: EventSponsorDealType
  ): JSX.Element => {
    if (this.props.sponsorDealTypes) {
      let selectSponsorDealTypes = this.props.sponsorDealTypes.map(function (
        sponsorDealType
      ) {
        return {
          label: sponsorDealType.name,
          value: sponsorDealType.id.toString(),
        };
      });

      return (
        <div key={index} className="eventSponsorRow eventSponsors">
          <Field
            component={HiddenInput}
            type="hidden"
            name={`eventSponsorDealTypes[${index}].id`}
          />
          <Field
            component={FormDropdown}
            label="Soort"
            name={`eventSponsorDealTypes[${index}].sponsorDealTypeId`}
            showSideBySide={false}
            options={selectSponsorDealTypes.filter(
              (dealTypes) =>
                (type && dealTypes.value == type.sponsorDealTypeId) ||
                !this.props.formValues.eventSponsorDealTypes
                  .filter((types) => !types.isDeleted)
                  .some((types) => types.sponsorDealTypeId == dealTypes.value)
            )}
            readOnly={isReadOnly}
          />

          <Field
            component={FormTextInput}
            label="Aantal"
            name={`eventSponsorDealTypes[${index}].aantal`}
            showSideBySide={false}
            validate={integer}
            readOnly={isReadOnly}
          />

          {!isReadOnly && (
            <a
              className="button is-success delete-btn"
              onClick={() => this.deleteEventSponsordealSoort(index)}
            >
              Delete
            </a>
          )}
        </div>
      );
    }
    return <></>;
  };

  addEventSponsordealSoort = () => {
    var eventSponsordealSoorten = this.props.formValues.eventSponsorDealTypes;

    if (eventSponsordealSoorten) {
      eventSponsordealSoorten.push({
        id: null,
        eventId: this.props.event ? this.props.event.id : null,
        sponsorDealTypeId: undefined,
        aantal: 0,
        isDeleted: false,
      });

      this.props.initialize(this.props.formValues);
      this.createEventSponsordealSoort(1, undefined);
      this.props.initialize(this.props.formValues);
    }
  };

  deleteEventSponsordealSoort = (index: number) => {
    let eventSponsorDealTypes =
      this.props.formValues && this.props.formValues.eventSponsorDealTypes;

    if (eventSponsorDealTypes.length > 0) {
      this.props.formValues.eventSponsorDealTypes[index].isDeleted = true;
    } else if (eventSponsorDealTypes.length == 1) {
      this.props.formValues.eventSponsorDealTypes = [];
    } else {
    }
    this.props.initialize(this.props.formValues);
  };

  sendEventReminder = async (id: string) => {
    if (
      window.confirm(
        "Dit zal een herinneringsmail en pushnotificatie sturen naar alle leden." +
          " Bent u er zeker van dat u de herinnering wilt versturen?"
      )
    ) {
      await EventApi.sendEventReminder(id);
    }
  };

  render() {
    const {
      handleSubmit,
      event,
      contactpersonen,
      isEdit = false,
      onSubmit,
      onDelete,
      onCancel,
      submitting,
      formValues,
      isNew,
    } = this.props;

    let title: any = "";
    let buttons: any = "";
    let uitnodiging: any = "";
    let eventSponsordealSoorten: JSX.Element[] = [];
    let eventLeads: JSX.Element[] = [];
    let contactpersonenElement: JSX.Element[] = [];
    let headerFotoUrlElement: any;
    let overigeEventFotos: any;

    if (
      event &&
      event.item &&
      event.item.attachments &&
      event.item.attachments.length > 0
    ) {
      let attachments = event.item.attachments;

      let overigeFotos = attachments.filter((value) => value.type === 2);
      if (overigeFotos)
        overigeEventFotos = this.createAttachmentList(overigeFotos);

      let eventInvitation = attachments.find((x) => x.type == 4);
      if (eventInvitation)
        uitnodiging = this.createAttachmentList([eventInvitation]);

      let featuredPhoto =
        attachments.length > 0 &&
        attachments.filter((value) => value.type === 1).length > 0
          ? attachments.filter((value) => value.type === 1)[0]
          : undefined;
      if (featuredPhoto) {
        headerFotoUrlElement =
          featuredPhoto.id &&
          this.createHeaderFoto(
            featuredPhoto.content,
            featuredPhoto.id.toString(),
            featuredPhoto.title
          );
      }
    }

    if (
      contactpersonen &&
      contactpersonen.length > 0 &&
      formValues &&
      formValues.eventContactpersonen
    ) {
      contactpersonenElement = contactpersonen.map((contactpersoon, index) => {
        return (
          <div
            key={contactpersoon.id}
            className="is-half-desktop checkbox-wrapper"
          >
            <div className="field-body">
              <input
                type="checkbox"
                value={contactpersoon.id}
                checked={
                  this.state.selectedContactpersonen.find(
                    (c: string) => c == contactpersoon.id
                  )
                    ? true
                    : false
                }
                disabled={
                  (isReadOnly &&
                    this.props.event &&
                    this.props.event.status == EventStatus.Definitief) ||
                  (!this.state.selectedContactpersonen.includes(
                    contactpersoon.id
                  ) &&
                    this.state.selectedContactpersonen.length ==
                      event!.maxAttendees)
                }
                onChange={(event: any) =>
                  this.onSelectedChanged(event, contactpersoon.id)
                }
              />
            </div>
            <div className="field-label checkbox-label--1">
              <label
                className="label bcwLabel is-pulled-left"
                style={{ paddingTop: ".5rem", paddingRight: "2rem" }}
              >
                {contactpersoon.name}
              </label>
            </div>
          </div>
        );
      });
    }

    if (
      formValues &&
      formValues.eventLeads &&
      formValues.eventLeads.length > 0
    ) {
      formValues.eventLeads.forEach((eventLead: EventLead, index: number) => {
        if (!eventLead.isDeleted) {
          const element = this.createEventLead(index);
          eventLeads.push(element);
        }
      });
    } else {
      if (!isReadOnly) {
        const element = this.createEventLead(0);
        eventLeads.push(element);
      }
    }

    // if(this.props.formValues)
    //     this.props.formValues.eventSponsorDealTypes = [];
    if (
      formValues &&
      formValues.eventSponsorDealTypes &&
      formValues.eventSponsorDealTypes.length > 0
    ) {
      formValues.eventSponsorDealTypes.forEach(
        (eventSponsordealSoort: EventSponsorDealType, index: number) => {
          if (!eventSponsordealSoort.isDeleted) {
            const element = this.createEventSponsordealSoort(
              index,
              eventSponsordealSoort
            );
            eventSponsordealSoorten.push(element);
          }
        }
      );
    } else {
      //const element = this.createEventSponsordealSoort(0);
      //eventSponsordealSoorten.push(element);
    }

    if (isEdit) {
      title = (
        <div className="column subHeader is-full">
          <h1>Event</h1>
        </div>
      );

      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              {!isReadOnly && event && event.status == EventStatus.Nieuw && (
                <button
                  type="button"
                  className="button is-success delete-button"
                  onClick={onDelete}
                >
                  Verwijder
                </button>
              )}
              {!isReadOnly && event && event.status != EventStatus.Nieuw && (
                <button
                  type="button"
                  className="button"
                  onClick={() => this.sendEventReminder(event!.id)}
                >
                  Verstuur herinnering
                </button>
              )}
              <button type="button" className="button" onClick={onCancel}>
                {isReadOnly ? "Terug" : "Annuleren"}
              </button>

              {!isReadOnly &&
                event &&
                event.status != EventStatus.Definitief && (
                  <button
                    type="submit"
                    disabled={submitting}
                    className="button is-success"
                  >
                    Opslaan
                  </button>
                )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="content notulen">
        <Form
          className="singleCompany"
          onSubmit={handleSubmit((values: any) =>
            this.doSubmit(
              values,
              this.state.editorState,
              this.state.selectedContactpersonen
            )
          )}
        >
          <div className="columns contentPanel is-multiline is-mobile">
            {title}
            <Field
              component={FormTextInput}
              label="Titel"
              name="item.title"
              showSideBySide={isNew != undefined ? !isNew.valueOf() : true}
              required={true}
              validate={required}
              readOnly={isReadOnly}
            />

            {isEdit && (
              <Field
                component={LabelInput}
                showSideBySide={false}
                label="Status"
                name="status"
                text={event && EventStatus[event.status]}
                readOnly={isReadOnly}
              />
            )}

            <Field
              component={FormDatePicker}
              label="Openingsdatum"
              name="item.openingDateTime"
              showSideBySide={true}
              required={true}
              validate={
                this.props.formValues &&
                this.props.formValues.status == EventStatus.Nieuw
                  ? [required, datetimePast]
                  : [required]
              }
              readOnly={isReadOnly}
            />

            <Field
              component={FormDatePicker}
              label="Sluitingsdatum"
              name="item.closingDateTime"
              showSideBySide={true}
              required={true}
              validate={
                this.props.formValues &&
                this.props.formValues.status == EventStatus.Nieuw
                  ? [required, datetimePast]
                  : [required]
              }
              readOnly={isReadOnly}
            />

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-invite">
              <div className="field-label">
                <label className="field-label label bcwLabel is-pulled-left">
                  Uitnodiging
                </label>
              </div>
              <div className="field-body omschrijvingEvent">{uitnodiging}</div>

              {event && event.item && event.item.uitnodigingUrl && (
                <div>
                  <img
                    className="eventUitnodigingImage"
                    src={event.item.uitnodigingUrl}
                  ></img>
                </div>
              )}
            </div>

            {!isReadOnly && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop uploaded-files">
                <div className="field-label">
                  <label className="label bcwLabel is-pulled-left">
                    Upload uitnodiging
                  </label>
                </div>
                <Field
                  component={FileUpload}
                  accept={[".pdf"]}
                  label="Uitnodigingen"
                  name="item.eventUitnodiging"
                  onChangeUpload={this.onChangeUploadUitnodiging}
                  multiple={false}
                />
              </div>
            )}

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-uploaded-files">
              <div className="field-label">
                <label className="label bcwLabel">Hoofdfoto</label>
              </div>
              {headerFotoUrlElement}
            </div>
            {!isReadOnly && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-uploaded-files">
                <div className="field-label">
                  <label className="label bcwLabel ">Upload hoofdfoto</label>
                </div>
                <Field
                  component={ImageCropper}
                  label="Upload hoofdfoto"
                  name="item.attachments"
                  onSaveCrop={this.onHoofdFotoSave}
                  aspectRatio={19 / 10}
                  showSideBySide={true}
                  quality={0.2}
                />
              </div>
            )}

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-uploaded-files">
              <div className="field-label">
                <label className="label bcwLabel">Overige foto's</label>
              </div>
              <div className="custom-docs event-docs">{overigeEventFotos}</div>
            </div>
            {!isReadOnly && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop custom-uploaded-files">
                <div className="field-label">
                  <label className="label bcwLabel ">
                    Upload overige foto's
                  </label>
                </div>

                <Field
                  component={FileUpload}
                  label="Bijlagen"
                  name="overigeFotos"
                  onChangeUpload={this.onChangeUploadOverigeFotos}
                  accept="image/*"
                />
              </div>
            )}
            <Field
              component={FormDatePicker}
              label="Datum"
              name="item.startDateTime"
              showSideBySide={true}
              required={true}
              validate={
                this.props.formValues &&
                this.props.formValues.status == EventStatus.Nieuw
                  ? [required, datetimePast]
                  : [required]
              }
              readOnly={isReadOnly}
            />
            <Field
              component={FormDatePicker}
              label="Einddatum"
              name="item.endDateTime"
              showSideBySide={true}
              required={true}
              validate={
                this.props.formValues &&
                this.props.formValues.status == EventStatus.Nieuw
                  ? [required, datetimePast]
                  : [required]
              }
              readOnly={isReadOnly}
            />

            <Field
              component={BcwTimePicker}
              label="Aanvangst tijdstip"
              name="item.startTime"
              showSideBySide={true}
              readOnly={isReadOnly}
            />
            <Field
              component={BcwTimePicker}
              label="Eindtijd"
              name="item.endTime"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Bedrijfsnaam"
              name="companyName"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Straat"
              name="address.street"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Huisnr"
              name="address.houseNumber"
              showSideBySide={true}
              validate={[lengthmax6]}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Postcode"
              name="address.postalCode"
              showSideBySide={true}
              validate={[postalcode]}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Plaats"
              name="address.city"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Maximum aantal personen"
              name="maxAttendees"
              showSideBySide={true}
              validate={[integer]}
              readOnly={isReadOnly}
            />

            {!isReadOnly ? (
              <div className="wysiwyg-edit">
                <Field
                  component={TextEditor}
                  label="Omschrijving"
                  name="item.description"
                  editorState={this.state.editorState}
                  onEditorStateChange={this.onEditorStateChange}
                  readOnly={isReadOnly}
                />
              </div>
            ) : (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop">
                <div className="field-label">
                  <label className="field-label label bcwLabel is-pulled-left">
                    Omschrijving
                  </label>
                </div>
                <div className="field-body omschrijvingEvent">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        (event && event.item && event.item.description) || "",
                    }}
                  />
                </div>
              </div>
            )}

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop">
              <div className="subHeader is-full">
                <h3>Aanmeldingen</h3>
              </div>
              {contactpersonenElement && contactpersonenElement.length > 0 ? (
                contactpersonenElement
              ) : (
                <span>Er zijn geen aanmeldingen</span>
              )}
            </div>

            <>
              <div className="column subHeader is-full eventSponsordealSoorten">
                <h3>Introducees</h3>
              </div>
              <div className="season eventleads column">
                <div className="field-custom-list">
                  {eventLeads && eventLeads.length > 0 ? (
                    eventLeads
                  ) : (
                    <span>Er zijn geen introducees</span>
                  )}
                  {!isReadOnly &&
                    event &&
                    event.status != EventStatus.Definitief &&
                    event.status != EventStatus.Nieuw && (
                      <a
                        className="button is-success"
                        onClick={() => this.addEventLead()}
                      >
                        Toevoegen
                      </a>
                    )}
                </div>
              </div>
            </>

            <div className="eventSponsors">
              <div className="column subHeader is-full sponsorsHeader">
                <h3>Sponsors</h3>
                {eventSponsordealSoorten &&
                eventSponsordealSoorten.length > 0 ? (
                  eventSponsordealSoorten
                ) : (
                  <span>Er zijn geen sponsoren</span>
                )}
              </div>
              {!isReadOnly && (
                <a
                  className="button is-success"
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.addEventSponsordealSoort()}
                >
                  Toevoegen
                </a>
              )}
            </div>
          </div>

          <div className="floating-btn-bar">{buttons}</div>
        </Form>
      </div>
    );
  }
}

const validateForm = (values: Event) => {
  const errors: any = { item: {} }; // had to use any, see no possibility for this validation to work with typescript & redux-form nested field validation

  if (values.item) {
    if (
      values &&
      moment(values.item.closingDateTime).isBefore(
        moment(values.item.openingDateTime)
      )
    ) {
      errors.item.closingDateTime =
        "Sluitdatum mag niet voor openingsdatum liggen";
    }
    if (
      values &&
      moment(values.item.endDateTime).isBefore(
        moment(values.item.startDateTime)
      )
    ) {
      errors.item.endDateTime = "Einddatum mag niet voor startdatum liggen";
    }
    if (
      values &&
      moment(values.item.startDateTime).isBefore(
        moment(values.item.closingDateTime),
        "day"
      )
    ) {
      errors.item.startDateTime = "Mag niet voor de sluitingsdatum liggen";
    }
  }
  return errors;
};

const EventReduxForm = reduxForm<{}, OwnProps>({
  form: "EventForm",
  validate: validateForm,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventComponent);

export default connect((state: RootState, props: any) => {
  const rounded = Math.round(moment().minute() / 15) * 15;
  let initialTime = moment();
  initialTime.minutes(rounded);

  let event = state.event && (state.event as Event);
  let address = event && (event.address as Address | undefined);
  let item = event && (event.item as BaseItem | undefined);

  return {
    initialValues: {
      id: props.isEdit ? state.event.id : "",
      companyName: props.isEdit ? event.companyName : "",
      maxAttendees: props.isEdit ? event.maxAttendees : 0,
      addressId: props.isEdit && event ? event.addressId : "",
      status: props.isEdit ? state.event.status : 0,
      address: {
        id: props.isEdit && event ? event.addressId : "",
        street: props.isEdit && address ? address.street : "",
        houseNumber: props.isEdit && address ? address.houseNumber : "",
        postalCode: props.isEdit && address ? address.postalCode : "",
        city: props.isEdit && address ? address.city : "",
        country: "Nederland",
      },
      itemId: props.isEdit && event ? event.itemId : "",
      item: {
        id: props.isEdit && item ? event.item.id : "",
        title: props.isEdit && item ? event.item.title : "",
        description: props.isEdit && item ? event.item.description : "",
        openingDateTime:
          props.isEdit && item && event.item.openingDateTime
            ? event.item.openingDateTime
            : new Date().toDateString(),
        openingTime:
          props.isEdit && item && event.item.openingDateTime
            ? moment(event.item.openingDateTime).format("HH:mm")
            : initialTime.format("HH:mm"),
        closingDateTime:
          props.isEdit && item && event.item.closingDateTime
            ? event.item.closingDateTime
            : new Date().toDateString(),
        closingTime:
          props.isEdit && item && event.item.closingDateTime
            ? moment(event.item.closingDateTime).format("HH:mm")
            : initialTime.format("HH:mm"),
        startDateTime:
          props.isEdit && item && event.item.startDateTime
            ? event.item.startDateTime
            : new Date().toDateString(),
        startTime:
          props.isEdit && item && event.item.startDateTime
            ? moment(event.item.startDateTime).format("HH:mm")
            : initialTime.format("HH:mm"),
        endDateTime:
          props.isEdit && item && event.item.endDateTime
            ? event.item.endDateTime
            : new Date().toDateString(),
        endTime:
          props.isEdit && item && event.item.endDateTime
            ? moment(event.item.endDateTime).format("HH:mm")
            : initialTime.format("HH:mm"),
        publishDateTime:
          props.isEdit && item
            ? event.item.publishDateTime
            : new Date().toDateString(),
        archiveDateTime:
          props.isEdit && item
            ? event.item.archiveDateTime
            : new Date().toDateString(),
        socialSettingsId:
          props.isEdit && item ? event.item.socialSettingsId : undefined,
        socialSettings: {
          id: props.isEdit && item ? event.item.socialSettingsId : undefined,
        },
        //uitnodigingUrl: props.isEdit ? state.event.uitnodigingUrl : "",
        attachments: props.isEdit && item ? item.attachments : [],
      },
      eventSponsorDealTypes: props.isEdit ? event.eventSponsorDealTypes : [],
      eventLeads:
        props.isEdit &&
        state.event.eventLeads &&
        state.event.eventLeads.length > 0
          ? state.event.eventLeads
          : isReadOnly
          ? undefined
          : [
              {
                id: 0,
                eventId: 0,
                leadId: 0,
                titel: "",
                voornaam: "",
                achternaam: "",
                emailadres: "",
                bedrijfsnaam: "",
                datum: "",
                isDeleted: false,
              },
            ],
      eventContactpersonen: props.isEdit
        ? state.event.eventContactpersonen
        : [{ id: 0, eventId: 0, contactpersoonId: 0, checked: false }],
    },
    formValues: getFormValues("EventForm")(state) as Event,
  };
})(EventReduxForm);
