import { Action } from "redux";
import { Taak } from "../types/model";

export const GET_TAKEN = "GET_TAKEN";
export const GET_TAAK = "GET_TAAK";

export interface GetTakenAction extends Action<string> {
    taken: Taak[];
}

export interface GetTaakAction extends Action<string> {
    taak: Taak;
}
