import * as React from 'react';

import { Column, Filter, RowInfo } from 'react-table';
import { Person, Role } from '../types/model';
import { change, submit } from 'redux-form';
import { getGebruikers, getRollen } from '../actions/gebruikerActions';
import { hideLoading, showLoading } from '../actions/loadingActions';

import { AnyAction } from 'redux';
import GebruikerApi from '../api/GebruikerApi';
import GebruikerComponent from '../components/GebruikerComponent/GebruikerComponent';
import GebruikersComponent from '../components/GebruikersComponent/GebruikersComponent';
import { Modal } from '../components/Modal';
import { PersonType } from '../constants/enums';
import { RootState } from "../types/state";
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import history from '../components/history';
import matchSorter from 'match-sorter';
import { toast } from 'react-toastify';

interface State {
    showEditModal: boolean;
}

export type Props = OwnProps & StateProps & DispatchProps;

export interface OwnProps {
}

export interface StateProps {
    gebruikers: Array<Person>;
    rollen: Role[];
}

export interface DispatchProps {
    updateFormValue: (field: string, value: string) => any;
    submitForm: (form: string) => any;
    getGebruikers: () => any;
    getRollen: () => any;
    showLoading: () => any;
    hideLoading: () => any;
}

class GebruikersContainer extends React.Component<Props, State> {
    state: State = {
        showEditModal: false
    };

    componentDidMount() {
        this.props.getGebruikers();
        this.props.getRollen();
    }

    onRowClick(event: React.ChangeEvent, rowInfo: RowInfo) {
        history.push("/Gebruikers/" + rowInfo.original.id);
    }

    createGebruiker = async (formValues: any) => {
        let gebruiker = formValues.gebruiker as Person;

        this.props.showLoading();
        await GebruikerApi.createGebruiker(gebruiker)
            .then(() => {
                this.props.getGebruikers();
                this.closeModal();
                toast.success("Het opslaan is succesvol");
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => this.props.hideLoading());
    }

    searchPersoonByEmail = async (emailAddress: string) => {
        var returnValue = false;
        await GebruikerApi.getContactPersoonByEmail(emailAddress)
            .then((person) => {
                this.props.updateFormValue("gebruiker.firstname", person.firstname);
                this.props.updateFormValue("gebruiker.surname", person.surname);
                this.props.updateFormValue("gebruiker.jobTitle", person.jobTitle);
                this.props.updateFormValue("gebruiker.type", person.type);
                returnValue = true;
            })
            .catch(err => { });
        return returnValue;
    }

    openModal = () => {
        this.setState({ showEditModal: true });
    }

    closeModal = () => {
        this.setState({ showEditModal: false });
    }

    generateIdentity = async () => {
        await GebruikerApi.generateLogin().then(() => {
            toast.success("Het genereren is succesvol");
        })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => this.props.hideLoading());
    }

    gebruikers = () => ([
        {
            Header: "Voornaam",
            accessor: "firstname",
            headerStyle: {
                textAlign: "left"
            },
            filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
            filterAll: true
        },
        {
            Header: "Achternaam",
            accessor: "surname",
            headerStyle: {
                textAlign: "left"
            },
            filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
            filterAll: true
        },
        {
            Header: "Email-adres",
            accessor: "emailAddress",
            headerStyle: {
                textAlign: "left"
            },
            filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
            filterAll: true,
        },
        {
            Header: "Gebruiker type",
            accessor: "type",
            headerStyle: {
                textAlign: "left"
            },
            filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
            filterAll: true,
            Cell: (props: any) => {
                return props != null ? PersonType[props.value] : "";
            }
        }
    ] as Array<Column>)

    render() {
        const { gebruikers, rollen, submitForm } = this.props;

        return (
            <>
                <GebruikersComponent onRowClick={this.onRowClick} columns={this.gebruikers()} openModal={this.openModal} gebruikers={gebruikers} generateIdentity={this.generateIdentity} />
                {
                    this.state.showEditModal &&
                    <Modal
                        title={"Gebruiker toevoegen"}
                        content={<GebruikerComponent rollen={rollen} searchPersonByEmail={this.searchPersoonByEmail} updateFormValue={this.props.updateFormValue} onSubmit={this.createGebruiker} />}
                        onSubmit={() => submitForm("GebruikerForm")}
                        onClose={this.closeModal}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (state: any): StateProps => ({
    gebruikers: [...state.gebruikers],
    rollen: state.rollen
})

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    submitForm: (form: string) => dispatch(submit(form)),
    updateFormValue: (field: string, value: string) => dispatch(change("GebruikerForm", field, value)),
    getGebruikers: () => dispatch(getGebruikers()),
    getRollen: () => dispatch(getRollen()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
})

export default connect(mapStateToProps, mapDispatchToProps)(GebruikersContainer)

