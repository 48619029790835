import React, { useContext, useEffect } from "react";
import StatistiekenComponent from "../components/StatistiekenComponent/StatistiekenComponent";
import { StatisticsContext } from "../contexts/statistics/StatisticsContext";
import { ChartModel } from "../types/model";

export const StatistiekenContainer: React.FC = () => {
  const [
    sponsorDealTotalAmountStatistics,
    setSponsorDealTotalAmountStatistics,
  ] = React.useState<ChartModel>({ labels: [], data: [] });
  const [localStatistics, setLocalStatistics] = React.useState<ChartModel>({
    labels: [],
    data: [],
  });
  const [contactPersonStatistics, setContactPersonStatistics] =
    React.useState<ChartModel>({ labels: [], data: [] });
  const [sponsorDealTypeStatistics, setSponsorDealTypeStatistics] =
    React.useState<ChartModel>({ labels: [], data: [] });

  const {
    getSponsorDealTypeStatistics,
    getSponsorDealTotalAmountStatistics,
    getLocalStatistics,
    getContactPersonStatistics,
  } = useContext(StatisticsContext);

  useEffect(() => {
    getSponsorDealTotalAmountStatistics().then((data) => {
      setSponsorDealTotalAmountStatistics(data);
    });
    getLocalStatistics().then((data) => {
      setLocalStatistics(data);
    });
    getContactPersonStatistics().then((data) => {
      setContactPersonStatistics(data);
    });
    getSponsorDealTypeStatistics().then((data) => {
      setSponsorDealTypeStatistics(data);
    });
  }, []);

  return (
    <StatistiekenComponent
      sponsordealSoortStatistieken={sponsorDealTypeStatistics}
      sponsordealTotaalBedragStatistieken={sponsorDealTotalAmountStatistics}
      genemuidenStatistieken={localStatistics}
      contactpersoonStatistics={contactPersonStatistics}
    />
  );
};
