import ApiClient from './ApiClient';
import { ChartModel, CsvViewModel } from "../types/model";

const endpoint = 'api/statistics';

export default class StatisticsApi {
    public static async getSponsordealSoortStatistieken(): Promise<ChartModel> {
        var sponsordealSoortStatistieken = await ApiClient.doHttpRequest<ChartModel>('get', `${endpoint}/sponsordealtypes`);
        return sponsordealSoortStatistieken;
    }

    public static async getSponsordealTotaalBedragStatistieken(): Promise<ChartModel> {
        var genemuidenStatistieken = await ApiClient.doHttpRequest<ChartModel>('get', `${endpoint}/sponsordealtotalprices`);
        return genemuidenStatistieken;
    }

    public static async getGenemuidenStatistieken(): Promise<ChartModel> {
        var genemuidenStatistieken = await ApiClient.doHttpRequest<ChartModel>('get', `${endpoint}/companylocations`);
        return genemuidenStatistieken;
    }

    public static async getFactuurStatistieken(): Promise<ChartModel> {
        var factuurStatistieken = {} as ChartModel;//await ApiClient.doHttpRequest<ChartModel>('get', `${endpoint}/factuur`);
        return factuurStatistieken;
    }

    public static async getContactpersoonStatistics(): Promise<ChartModel> {
        var contactpersoonStatistics = await ApiClient.doHttpRequest<ChartModel>('get', `${endpoint}/contactpersonen`);
        return contactpersoonStatistics;
    }

    public static async getViewNames(): Promise<CsvViewModel> {
        var csvViewNames = await ApiClient.doHttpRequest<CsvViewModel>('get', `${endpoint}/viewnames`);
        return csvViewNames;
    }

    public static async generateViewCSV(viewName: string): Promise<any> {       
        await ApiClient.doFileRequest('get', `${endpoint}/GenerateViewCSV/${viewName}`, null, false, true, { responseType: 'blob' })
        .then(response => {
            var blobUrl = window.URL.createObjectURL(response.data);
            var tempLink = document.createElement('a');
            tempLink.href = blobUrl;
            tempLink.setAttribute('download', `${viewName}.xlsx`);
            tempLink.click();
        });
    }
}
