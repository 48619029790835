import React from "react";
import { useUpcomingEvents } from "./useUpcomingEvents";
import { ListEvent } from "../../types/model";

export const EventContext = React.createContext({
  getUpcomingEvents: async (force = false) => {
    return [] as ListEvent[];
  },
});

export const EventContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { getUpcomingEvents } = useUpcomingEvents();

  const contextValue = {
    getUpcomingEvents,
  };

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
};
