import * as actionTypes from "../constants/contactpersoonActionTypes";
import ContactpersoonApi from '../api/ContactpersoonApi';
import GebruikerApi from "../api/GebruikerApi"
import { GetContactpersonenAction, GetContactpersoonAction, GetPartnersAction, GetPartnerAction } from "../constants/contactpersoonActionTypes";
import { AsyncAction } from ".";

export const getContactpersonen = (companyId: string | undefined, archived: boolean, inclPartners: boolean): AsyncAction<GetContactpersonenAction> => {
    return async (dispatch) => {
        const contactpersonen = await ContactpersoonApi.getContactpersonen(companyId + '', archived, inclPartners);

        return dispatch({ type: actionTypes.GET_CONTACTPERSONEN, contactpersonen });
    };
}


export const getContactpersoon = (id: string): AsyncAction<GetContactpersoonAction> => {
    return async (dispatch) => {
        const contactpersoon = await ContactpersoonApi.getContactpersoon(id);

        return dispatch({ type: actionTypes.GET_CONTACTPERSOON, contactpersoon });
    };
}


export const getPartners = (companyId:string, archived: boolean): AsyncAction<GetPartnersAction> => {
    return async (dispatch) => {
        const partners = await ContactpersoonApi.getPartners(companyId, archived);

        return dispatch({ type: actionTypes.GET_PARTNERS, partners });
    };
}

export const getPartnersForHoofdcontactpersoon = (): AsyncAction<GetPartnersAction> => {
    return async (dispatch) => {
        const partners = await ContactpersoonApi.getPartnersForHoofdcontactpersoon();

        return dispatch({ type: actionTypes.GET_PARTNERS, partners })
    }
}

export const getPartner = (id: number): AsyncAction<GetPartnerAction> => {
    return async (dispatch) => {
        const partner = await ContactpersoonApi.getPartner(id);

        return dispatch({ type: actionTypes.GET_PARTNER, partner });
    };
}

export const getPartnerForHoofdcontactpersoon = (id: number): AsyncAction<GetPartnerAction> => {
    return async (dispatch) => {
        const partner = await ContactpersoonApi.getPartnerForHoofdcontactpersoon(id);

        return dispatch({ type: actionTypes.GET_PARTNER, partner });
    };
}
