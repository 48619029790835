const MESSAGE_INCORRECT = "Onjuiste postcode.";
const MESSAGE_SPACE = "Postcode mag geen spaties bevatten.";

export default (value: string) => {
    if(value && !/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i.test(value)) {
        return MESSAGE_INCORRECT
    } else if (value && value.includes(" ")) {
        return MESSAGE_SPACE
    }
    return undefined    
}
