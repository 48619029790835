import "react-table/react-table.css";

import { Filter, Sorting } from "../../types/model";
import React, { ChangeEvent, useState } from "react";
import ReactTable, { Column, RowInfo } from "react-table";
import { setFilter, setSorting } from "../../actions/gridviewActions";
import { AnyAction } from "redux";
import { RootState } from "../../types/state";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

import openImage from "../../images/arrow-rectangle-right.svg";

interface OwnProps {
  id: string;
  data: Array<Object>;
  columns: Array<Column>;
  onRowClick: (event: any, rowInfo: any) => void;
  onSetRowColor?: (rowInfo: any) => string;
  onFilterChange?: (array: Array<any>) => void;
  height?: string;
  addEditColumn?: boolean;
  defaultPageSize?: number;
  defaultSorting: { id: string; desc: boolean };
}

interface StateProps {
  id: string;
  filtered: Filter[];
  defaultSorted: Sorting[];
}

interface DispatchProps {
  setFilter: (gridviewId: string, filter: Filter) => void;
  setSorting: (gridviewId: string, sort: Sorting) => void;
}

interface State {
  page: number;
  pageSize: number;
}

class Gridview extends React.Component<
  OwnProps & StateProps & DispatchProps,
  State
> {
  reactTable: any;
  constructor(props: Readonly<OwnProps & StateProps & DispatchProps>) {
    super(props);
    this.reactTable = React.createRef();
  }

  state = {
    page: 0,
    pageSize: this.props.defaultPageSize ? this.props.defaultPageSize : 50,
  };

  private resetScrollInsideTable = () => {
    let tableBody = document.getElementsByClassName("rt-tbody")[0];
    tableBody.scrollTop = 0;
  };

  render() {
    const {
      id,
      data,
      columns,
      onRowClick,
      onSetRowColor,
      onFilterChange,
      height = "84vh",
      addEditColumn = true,
    } = this.props;

    if (addEditColumn) {
      columns.push({
        Header: "",
        accessor: "edit",
        Cell: (row) => (
          <a className="gridIcon" /*onClick={e => onRowClick(e, row)}*/>
            <img src={openImage} alt="Logo" />
          </a>
        ),
        maxWidth: 50,
        filterable: false,
      });
    }

    return (
      <ReactTable
        loadingText="Laden"
        noDataText="Niets gevonden"
        ref={(r) => {
          this.reactTable = r;
        }}
        data={data}
        columns={columns}
        nextText="Volgende"
        previousText="Vorige"
        rowsText="regels"
        pageText="Pagina"
        page={this.state.page}
        onPageChange={(newPage) => this.setState({ page: newPage })}
        getTrProps={(
          state: any,
          rowInfo: RowInfo | undefined,
          column: Column<any> | undefined,
          instance: any
        ) => {
          return {
            style: {
              color:
                onSetRowColor &&
                rowInfo &&
                rowInfo.original &&
                onSetRowColor(rowInfo),
            },
          };
        }}
        getTdProps={(
          state: any,
          rowInfo?: RowInfo,
          column?: Column,
          instance?: any
        ) => {
          if (
            rowInfo &&
            rowInfo.row &&
            rowInfo.row._original &&
            column &&
            column.id !== "checkbox" &&
            column.id !== "afgerond" &&
            column.id !== "actions"
          ) {
            return {
              onClick: (e: ChangeEvent) => {
                if (rowInfo && rowInfo.original) onRowClick(e, rowInfo);
              },
            };
          } else {
            return {};
          }
        }}
        onFilteredChange={(filtered, column) => {
          const filter = filtered.find((f) => f.id === column.id);

          this.resetScrollInsideTable();

          if (filter) {
            this.props.setFilter(id, { id: filter.id, value: filter.value });
            this.state.page = 0;
          } else this.props.setFilter(id, { id: column.id, value: "" });

          if (onFilterChange) {
            onFilterChange(this.reactTable.getResolvedState().sortedData);
          }
        }}
        onSortedChange={(sorted, column) => {
          const sorting = sorted.find((s) => s.id === column.id);
          if (sorting)
            this.props.setSorting(this.props.id, {
              id: sorting.id,
              desc: sorting.desc,
            });
        }}
        filtered={this.props.filtered}
        defaultSorted={this.props.defaultSorted}
        className="-striped -highlight"
        style={{ width: "100%", margin: "0 auto", height: height }}
        filterable
        pageSize={this.state.pageSize}
        onPageSizeChange={(e) => this.setState({ pageSize: e })}
      />
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const gridview = state.gridviews.find((g) => g.id === ownProps.id);

  const defaultSorting = getDefaultSorting(ownProps.id);

  return {
    id: ownProps.id,
    filtered: gridview ? [...gridview.filters] : [],
    defaultSorted: gridview
      ? [...gridview.sorting]
      : defaultSorting
      ? [defaultSorting]
      : [],
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  setFilter: (gridviewId: string, filter: Filter) =>
    dispatch(setFilter(gridviewId, filter)),
  setSorting: (gridviewId: string, sort: Sorting) =>
    dispatch(setSorting(gridviewId, sort)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gridview);

const getDefaultSorting = (
  id: string
): { id: string; desc: boolean } | undefined => {
  if (id === "ContactpersonenList") return { id: "companyName", desc: false };
  if (id === "BedrijvenList") return { id: "name", desc: false };
  if (id === "sponsordealslist") return { id: "companyName", desc: false };
  if (id === "FacturenList") return;
  if (id === "LeadsList") return { id: "companyName", desc: false };
  return undefined;
};
