import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { CustomPage, Permissions } from "../../types/model";
import { connect } from "react-redux";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import TextEditor from "../TextEditor";
import { IsReadOnly } from "../../helpers/PermissionHelper";
import { FormTextInput } from "../FormTextInput";

export interface OwnProps {
  formValues: CustomPage;
  onSubmit: (formValues: CustomPage, editorState: EditorState) => void;
}

interface State {
  editorState: EditorState;
}

const isReadOnly = IsReadOnly(Permissions.ManageCustomPages);
class CustomPageComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<{} & any, OwnProps>) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidUpdate = (prevProps: any) => {
    if (this.props.initialValues.id !== prevProps.initialValues.id)
      this.props.initialize(this.props.initialValues);

    if (prevProps != this.props && this.props.formValues) {
      if (this.props.formValues.description != undefined) {
        const blocksFromHtml = htmlToDraft(this.props.formValues.description);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    }
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  render() {
    const { handleSubmit, onSubmit, submitting } = this.props;

    let buttons: any = "";

    return (
      <div className="content notulen">
        <Form
          className="singleCompany"
          onSubmit={handleSubmit((values: CustomPage) =>
            onSubmit(values, this.state.editorState)
          )}
        >
          <div className="columns contentPanel is-multiline is-mobile">
            <Field
              component={FormTextInput}
              label="Titel"
              name="title"
              showSideBySide={false}
              readOnly={isReadOnly}
            />

            <div className="wysiwyg-edit">
              <Field
                component={TextEditor}
                label="Inhoud"
                name="description"
                editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
                readOnly={isReadOnly}
                showSideBySide={false}
              />
            </div>
            {/* <div className="columns contentPanel">
                            <div className="column is-full">
                                <div className="buttons is-pulled-right">
                                    {!isReadOnly &&
                                        <button type="submit" disabled={submitting} className="button is-success">
                                            Opslaan
                                        </button>
                                    }
                                </div>
                            </div>
                        </div> */}

            <div className="floating-btn-bar">
              <div className="columns contentPanel">
                <div className="column is-full">
                  <div className="buttons is-pulled-right">
                    {/* <button type="button" className="button" onClick={onCancel}>
                                            Annuleren
                                        </button> */}
                    {!isReadOnly && (
                      <button
                        type="submit"
                        disabled={submitting}
                        className="button is-success"
                      >
                        Opslaan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const CustomPageForm = reduxForm<{}, OwnProps>({
  form: "CustomPageForm",
  enableReinitialize: true,
})(CustomPageComponent);

export default connect((state: any, props: any) => {
  return {
    initialValues: {
      id: state.customPage ? state.customPage.id : undefined,
      description: state.customPage ? state.customPage.description : "",
      title: state.customPage ? state.customPage.title : "",
    },
    formValues: getFormValues("CustomPageForm")(state) as CustomPage,
  };
})(CustomPageForm);
