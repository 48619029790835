import { useContext, useEffect, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { CreateInvoice } from "../CreateInvoice";
import { Factuur, FactuurRegel } from "../../../../types/model";
import { FactuurStatus } from "../../../../constants/enums";
import { validateInvoice } from "../validator";
import FactuurApi from "../../../../api/FactuurApi";
import { toast } from "react-toastify";
import { Guid } from "guid-typescript";
import { InvoicesContext } from "../../../../contexts/invoices/InvoicesContext";
import { ApplicationContext } from "../../../../contexts/application/ApplicationContext";

type Props = {
  invoiceId: string | undefined;
  onClose: () => void;
};

export const CreateInvoiceModal: React.FC<Props> = ({ invoiceId, onClose }) => {
  const [updatedInvoice, setUpdatedInvoice] = useState<Factuur>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const { getInvoices } = useContext(InvoicesContext);
  const { setLoading } = useContext(ApplicationContext);

  useEffect(() => {
    if (invoiceId) {
      FactuurApi.getFactuur(invoiceId).then((invoice) => {
        setUpdatedInvoice(invoice);
        setLoading(false);
      });
    } else {
      setUpdatedInvoice({
        id: Guid.create().toString(),
        status: FactuurStatus.Nieuw,
        invoiceLines: [] as FactuurRegel[],
      } as Factuur);
      setLoading(false);
    }
  }, [invoiceId]);

  useEffect(() => {
    setIsValid(!!updatedInvoice && validateInvoice(updatedInvoice));
  }, [updatedInvoice]);

  const submitFactuur = async () => {
    if (!updatedInvoice || !validateInvoice(updatedInvoice)) return;

    try {
      setLoading(true);
      if (invoiceId) {
        await FactuurApi.updateFactuur(updatedInvoice);
        toast.success("Factuur aangepast");
      } else {
        await FactuurApi.createFactuur(updatedInvoice);
        toast.success("Factuur aangemaakt");
      }
      getInvoices(true);
    } catch (error) {
      toast.error("Er is iets misgegaan");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      title={invoiceId ? "Update factuur" : "Nieuwe factuur"}
      content={
        !updatedInvoice ? (
          <div>Loading...</div>
        ) : (
          <CreateInvoice
            invoice={updatedInvoice}
            setInvoice={setUpdatedInvoice}
          />
        )
      }
      saveButtonIsReadOnly={!isValid}
      onSubmit={submitFactuur}
      onClose={onClose}
    />
  );
};
