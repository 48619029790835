import { toast } from "react-toastify";
import FactuurApi from "../../../api/FactuurApi";
import { generateInvoiceErrors } from "../../../helpers/InvoiceHelper";
import { useContext } from "react";
import { ApplicationContext } from "../../../contexts/application/ApplicationContext";

export const useInvoiceActions = (
  updateInvoices: () => void,
  selectedInvoices: string[],
  setSelectedInvoices: (invoices: string[]) => void
) => {
  const { setLoading } = useContext(ApplicationContext);
  var invoiceMailObject = {
    ids: selectedInvoices,
    email: null,
  };
  const amountOfInvoices = selectedInvoices.length;

  const sendInvoices = async () => {
    if (
      window.confirm(
        `Weet u zeker dat u ${amountOfInvoices} facturen wilt versturen?`
      )
    ) {
      setLoading(true);
      FactuurApi.validateInvoices(invoiceMailObject)
        .then((errors) => {
          if (errors.length > 0) {
            const generatedError = generateInvoiceErrors(errors);
            toast.error(<div>{generatedError}</div>, {
              autoClose: false,
              closeOnClick: true,
            });
            return;
          }
          toast.success(
            `${amountOfInvoices} ${
              amountOfInvoices > 1 ? "facturen worden" : "factuur wordt"
            } verwerkt op de achtergrond`
          );
          FactuurApi.sendFactuur(invoiceMailObject);
        })
        .finally(() => setLoading(false));
    }
  };

  const sendInvoiceReminders = () => {
    if (
      window.confirm(
        `Weet u zeker dat u ${amountOfInvoices} factuurherinneringen wilt versturen?`
      )
    ) {
      setLoading(true);
      FactuurApi.validateInvoices(invoiceMailObject)
        .then((errors) => {
          if (errors.length > 0) {
            const generatedError = generateInvoiceErrors(errors);
            toast.error(<div>{generatedError}</div>, {
              autoClose: false,
              closeOnClick: true,
            });
            return;
          }
          toast.success(
            `${amountOfInvoices} ${
              amountOfInvoices > 1
                ? "herinneringen worden"
                : "herinnering wordt"
            } verwerkt op de achtergrond`
          );
          FactuurApi.sendFactuurReminder(invoiceMailObject);
        })
        .finally(() => setLoading(false));
    }
  };

  const archiveInvoices = () => {
    if (
      window.confirm(
        `Weet u zeker dat u ${amountOfInvoices} facturen wilt achriveren?`
      )
    )
      setLoading(true);
    FactuurApi.archiveselectedInvoices(selectedInvoices)
      .then(() => {
        updateInvoices();
        setSelectedInvoices([]);
        toast.success("Het archiveren is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => setLoading(false));
  };

  const exportInvoices = () => {
    setLoading(true);
    FactuurApi.exportselectedInvoices(selectedInvoices)
      .then(() => {
        setSelectedInvoices([]);
        toast.success("Het exporteren is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => setLoading(false));
  };

  const finalizeInvoices = () => {
    setLoading(true);
    FactuurApi.maakDefinitief(selectedInvoices)
      .then((result: any) => {
        updateInvoices();
        setSelectedInvoices([]);
        toast.success("Facturen zijn in wachtrij gezet");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => setLoading(false));
  };

  return {
    sendInvoices,
    sendInvoiceReminders,
    archiveInvoices,
    exportInvoices,
    finalizeInvoices,
  };
};
