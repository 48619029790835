import ApiClient from './ApiClient';
import { CustomPage } from "../types/model";
import { Guid } from 'guid-typescript';

const endpoint = 'api/custompage';

export default class CustomPageApi {
    public static async getCustomPages(): Promise<CustomPage[]> {
        var customPages = await ApiClient.doHttpRequest<CustomPage[]>('get', `${endpoint}`);
        return customPages;
    }

    public static async getCustomPage(id: string): Promise<CustomPage> {
        var customPage = await ApiClient.doHttpRequest<CustomPage>('get', `${endpoint}/${id}`);
        return customPage;
    }

    public static async upsertCustomPage(customPage: CustomPage): Promise<CustomPage> {
        var customPage = await ApiClient.doHttpRequest<CustomPage>('post', `${endpoint}`, customPage);
        return customPage;
    }

    public static async archiveCustomPage(id: Guid): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `${endpoint}/${id}`);
    }
}