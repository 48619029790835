import {
  Bedrijf,
  InvoiceSponsorDeals,
  Permissions,
  Person,
  Settings,
  SponsorDealType,
  Sponsordeal,
  SponsordealList,
  Verantwoordelijke,
} from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import React, { ChangeEvent } from "react";
import { SponsordealStatus, UserRol } from "../constants/enums";
import {
  getSponsordealList,
  getSponsordeals,
} from "../actions/sponsordealActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import FacturenComponent from "../components/FacturenComponent/FacturenComponent";
import { FinancialValue } from "../components/FinancialValue";
import { IsReadOnly } from "../helpers/PermissionHelper";
import { Modal } from "../components/Modal";
import { RootState } from "../types/state";
import SponsordealApi from "../api/SponsordealApi";
import SponsordealComponent from "../components/SponsordealComponent/SponsordealComponent";
import SponsordealsComponent from "../components/SponsordealsComponent/SponsordealsComponent";
import SponsordealsFacturerenComponent from "../components/SponsordealsFacturerenComponent/SponsordealsFacturerenComponent";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getBedrijven } from "../actions/bedrijfActions";
import { getSettings } from "../actions/settingsActions";
import { getSponsordealSoorten } from "../actions/sponsordealSoortActions";
import { getVerantwoordelijken } from "../actions/gebruikerActions";
import history from "../components/history";
import matchSorter from "match-sorter";
import moment from "moment";
import { submit } from "redux-form";
import { toast } from "react-toastify";
import { formatCurrency } from "../helpers/currencyHelper";

interface DispatchProps {
  getSponsordeallist: () => any;
  submitForm: (form: string) => any;
  getSponsordealSoorten: () => any;
  getBedrijven: () => any;
  getVerantwoordelijken: () => any;
  getSettings: () => any;
  showLoading: () => any;
  hideLoading: () => any;
}

interface StateProps {
  sponsordeallist: SponsordealList[];
  bedrijven: Bedrijf[];
  sponsorDealTypes: SponsorDealType[];
  verantwoordelijken: Verantwoordelijke[];
  settings: Settings;
  sponsordealStatussen: [{ label: string; value: string }];
}

interface OwnProps {
  bedrijfId?: string;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  showModal: boolean;
  showInvoiceModal: boolean;
  archive: boolean;
  selectedDeals: string[];
}

const isReadOnly = IsReadOnly(Permissions.ManageSponsorDeals);
class SponsordealsContainer extends React.Component<Props, State> {
  filteredSponsordeals: SponsordealList[];

  constructor(props: Readonly<Props>) {
    super(props);
    this.filteredSponsordeals = [];
  }

  state: State = {
    showModal: false,
    showInvoiceModal: false,
    archive: false,
    selectedDeals: [],
  };

  componentDidMount() {
    this.props.getSponsordealSoorten();
    this.props.getSponsordeallist();
    this.props.getVerantwoordelijken();
    this.props.getSettings();
    this.props.getBedrijven();
  }

  onRowClick(event: ChangeEvent, rowInfo: RowInfo) {
    history.push("/Sponsordeals/" + rowInfo.original.id);
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  openInvoiceModal = () => {
    if (this.state.selectedDeals.length > 0) {
      this.setState({ showInvoiceModal: true });
    }
  };

  createSponsordeal = (formValues: Sponsordeal) => {
    var sponsordeal = formValues;

    this.props.showLoading();
    SponsordealApi.createSponsordeal(sponsordeal)
      .then(
        () =>
          this.props.getSponsordeallist() &&
          toast.success("Het opslaan is succesvol")
      )
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());
    this.setState({ showModal: false });
  };

  onClose = () => {
    this.setState({ showInvoiceModal: false });
    this.setState({ showModal: false });
  };

  archiveToggleSelectedDeals = () => {
    if (this.state.selectedDeals.length > 0) {
      let successMessage = this.state.archive
        ? "Het dearchiveren is succesvol"
        : "Het archiveren is succesvol";

      this.props.showLoading();
      SponsordealApi.archiveSelectedSponsorDeals(this.state.selectedDeals)
        .then(() => {
          this.props.getSponsordeallist();
          this.setState({ selectedDeals: [] });
          toast.success(successMessage);
        })
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => {
          this.props.hideLoading();
        });
    }
  };

  toggleArchive = () => {
    this.setState({ selectedDeals: [] });
    this.setState({ archive: this.state.archive ? false : true });
  };

  generateInvoices = (formValues: InvoiceSponsorDeals) => {
    if (this.state.selectedDeals.length > 0) {
      this.props.showLoading();
      formValues.ids = this.state.selectedDeals;
      SponsordealApi.invoiceSelectedSponsorDeals(formValues)
        .then(() => {
          this.props.getSponsordeallist();
          this.setState({ selectedDeals: [] });
          toast.success("Genereren van facturen gelukt");
          this.setState({ showInvoiceModal: false });
        })
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => {
          this.props.hideLoading();
        });
    }
  };

  getSeizoenenFromSponsorDealList = (sponsordeallist: SponsordealList[]) => {
    let startJaren = [] as number[];
    let seizoenen: { label: string; value: number }[] = [];
    sponsordeallist.forEach((deal) => {
      const fromDate = new Date(deal.from);
      const year = fromDate.getFullYear();

      let startjaar = fromDate.getMonth() + 1 < 7 ? year - 1 : year;
      for (
        var seasonNumber = 0;
        seasonNumber < deal.amountOfSeasons;
        seasonNumber++
      ) {
        startJaren.push(startjaar + seasonNumber);
      }
    });
    startJaren.sort();

    // filter hieronder is om jaar duplicates eruit te halen
    startJaren
      .filter((a, b) => startJaren.indexOf(a) === b)
      .forEach((jaar) => {
        seizoenen.push({ label: `${jaar}/${jaar + 1}`, value: jaar });
      });

    return seizoenen.reverse();
  };

  selectDeal = (event: any) => {
    const dealId = event.target.value;

    if (event.target.checked) {
      if (this.state.selectedDeals.find((item) => item === dealId) == null) {
        const selectedDeals = [...this.state.selectedDeals, dealId];
        this.setState({ selectedDeals: selectedDeals });
      }
    } else {
      var index = this.state.selectedDeals.indexOf(dealId);
      const selectedDeals = [
        ...this.state.selectedDeals.slice(0, index),
        ...this.state.selectedDeals.slice(index + 1),
      ];
      this.setState({ selectedDeals: selectedDeals });
    }
  };

  selectAllDeals = (event: any) => {
    if (event.target.checked) {
      const selectedDeals = this.state.selectedDeals;
      this.props.sponsordeallist.forEach((deal) => {
        if (
          this.filteredSponsordeals.find((item) => item.id == deal.id) ||
          this.filteredSponsordeals.length == 0
        ) {
          if (
            this.state.selectedDeals.find((item) => item === deal.id) == null &&
            deal.isArchived == this.state.archive
          ) {
            selectedDeals.push(deal.id);
          }
        }
      });
      this.setState({ selectedDeals: selectedDeals });
    } else {
      this.setState({ selectedDeals: [] });
    }
  };

  onFilterChanged = (array: any) => {
    this.filteredSponsordeals = [];
    array.forEach((element: { _original: SponsordealList }) => {
      this.filteredSponsordeals.push(element._original);
    });
  };

  render() {
    const {
      sponsordeallist,
      bedrijven,
      sponsorDealTypes,
      verantwoordelijken,
      settings,
      bedrijfId,
    } = this.props;

    let columns: Array<Column> = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectedDeals.some(
                (item) => item.toString() === original.id.toString()
              )}
              value={original.id}
              onChange={this.selectDeal}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectedDeals.length > 0}
              onChange={this.selectAllDeals}
            />
          );
        },
        sortable: false,
        filterable: false,
        width: 55,
      },
      {
        Header: "Bedrijf",
        accessor: "companyName",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Van",
        accessor: "from",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) => moment(props.value).format("DD-MM-YYYY"),
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Tot",
        accessor: "to",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) => moment(props.value).format("DD-MM-YYYY"),
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Soort",
        accessor: "sponsorDealTypeName",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) => {
          const sponsordealSoort = sponsorDealTypes.find(
            (sponsordealSoort) => sponsordealSoort.name === rows[filter.id]
          );
          return rows[filter.id] !== undefined && sponsordealSoort !== undefined
            ? sponsordealSoort.name
                .toLowerCase()
                .startsWith(filter.value.toLowerCase())
            : true;
        },
      },
      {
        Header: "Laatst gefactureerd",
        accessor: "lastSeasonInvoiced",
        headerStyle: {
          textAlign: "left",
        },
      },
      {
        Header: "Contract status",
        accessor: "status",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) => {
          var status = SponsordealStatus[props.value];
          return status;
        },
        filterMethod: (filter: Filter, rows: any) => {
          const id = filter.id;
          return rows[id] !== undefined
            ? String(SponsordealStatus[rows[id]].toLowerCase()).startsWith(
                filter.value.toLowerCase()
              )
            : true;
        },
      },
      {
        Header: "Huidig seizoen sponsorbedrag",
        accessor: "sponsorDealTypeDefaultPrice",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) => formatCurrency(props.value),
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Waarde deal",
        accessor: "totalValue",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) => formatCurrency(props.value),
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
    ];

    let sponsordealslist = sponsordeallist;
    var seizoenen = this.getSeizoenenFromSponsorDealList(sponsordeallist);

    if (bedrijfId) {
      sponsordealslist = sponsordealslist.filter(
        (c) => c.companyId == bedrijfId
      );
    }

    if (this.state.archive) {
      sponsordealslist = sponsordealslist.filter((c) => c.isArchived == true);
    } else {
      sponsordealslist = sponsordealslist.filter((c) => c.isArchived == false);
    }

    return (
      <>
        <SponsordealsComponent
          columns={columns}
          openModal={this.openModal}
          openInvoiceModal={this.openInvoiceModal}
          onFilterChange={this.onFilterChanged}
          onRowClick={this.onRowClick}
          bedrijfId={bedrijfId}
          archiefButtonText={this.state.archive ? "Terug" : "Archief"}
          archiverenButtonText={
            this.state.archive ? "Dearchiveren" : "Archiveren"
          }
          showingArchive={this.state.archive}
          archiveToggleSelectedNotulen={this.archiveToggleSelectedDeals}
          toggleArchive={this.toggleArchive}
          sponsordeallist={sponsordealslist}
        />

        {this.state.showModal ? (
          <Modal
            title="Nieuwe sponsordeal"
            content={
              <SponsordealComponent
                sponsorDealTypes={sponsorDealTypes}
                bedrijven={bedrijven}
                verantwoordelijken={verantwoordelijken}
                settings={settings}
                companyId={bedrijfId}
                isModal={true}
                onSubmit={this.createSponsordeal}
              />
            }
            onSubmit={() => this.props.submitForm("SponsordealForm")}
            onClose={this.onClose}
          />
        ) : null}

        {this.state.showInvoiceModal ? (
          <Modal
            title={
              this.state.selectedDeals.length.toString() +
              " sponsordeal(s) factureren"
            }
            content={
              <SponsordealsFacturerenComponent
                ids={this.state.selectedDeals}
                seizoenen={seizoenen}
                isModal={true}
                onSubmit={this.generateInvoices}
              />
            }
            onSubmit={() => this.props.submitForm("SponsordealsFacturerenForm")}
            onClose={this.onClose}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  sponsordeallist: state.sponsordeallist,
  bedrijven: state.bedrijven,
  sponsorDealTypes: state.sponsorDealTypes,
  verantwoordelijken: state.verantwoordelijken,
  settings: state.settings,
  sponsordealStatussen: state.sponsordealStatussen,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getSponsordeallist: () => dispatch(getSponsordealList()),
  submitForm: (form: string) => dispatch(submit(form)),
  getSponsordealSoorten: () => dispatch(getSponsordealSoorten()),
  getBedrijven: () => dispatch(getBedrijven()),
  getVerantwoordelijken: () => dispatch(getVerantwoordelijken()),
  getSettings: () => dispatch(getSettings()),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsordealsContainer);
