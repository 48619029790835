import * as React from "react";
import {
  Form,
  Field,
  reduxForm,
  InjectedFormProps,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { FormDropdown } from "../FormDropdown";
import { Permissions, Person } from "../../types/model";
import required from "../../validators/required";
import email from "../../validators/email";
import confirmpassword from "../../validators/confirmpassword";
import { connect } from "react-redux";
import length6 from "../../validators/length6";
import { Role } from "../../types/model";
import containsnumber from "../../validators/containsnumber";
import { UserRol, PersonType } from "../../constants/enums";
import { RootState } from "../../types/state";
import { FormCheckbox } from "../FormCheckbox";
import { HiddenInput } from "../HiddenInput";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  gebruiker?: Person;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  searchPersonByEmail: (emailAddress: string) => Promise<boolean>;
  updateFormValue: (field: string, value: any) => any;
  isEdit?: boolean;
  rollen: Array<Role>;
  formValues: Person;
}

interface State {
  isReadonlySystemUser: boolean;
  disableFields: boolean;
}
const isReadOnly = IsReadOnly(Permissions.ManageUsers);
class GebruikerComponent extends React.Component<
  OwnProps & InjectedFormProps<{}, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<{}, OwnProps>) {
    super(props);

    this.state = { isReadonlySystemUser: true, disableFields: false };
  }

  componentDidUpdate = (
    prevProps: OwnProps & InjectedFormProps<{}, OwnProps>
  ) => {
    // Check if the gebruiker is sent via Props
    if (this.props.gebruiker !== prevProps.gebruiker) {
      // When we have the gebruiker we determine if isSystemUser field should be readonly
      let isReadonlySystemUser =
        this.props.gebruiker &&
        this.props.gebruiker.type ==
          PersonType.SysteemGebruiker.valueOf().toString();

      // if the value could be retrieved properly
      if (isReadonlySystemUser) {
        // the state is adjusted and component wil rerender, this function will trigger again
        // but gebruiker will not be different to its previous gebruiker at that time
        this.setState({ isReadonlySystemUser: isReadonlySystemUser });
      }
    }
  };

  onGebruikerTypeChanged = (value: any) => {
    if (value == PersonType.SysteemGebruiker) {
      this.props.updateFormValue("gebruiker.isSystemUser", true);
      this.setState({ isReadonlySystemUser: true });
    } else {
      this.props.updateFormValue("gebruiker.isSystemUser", false);
      this.setState({ isReadonlySystemUser: false });
    }
  };

  onEmailChanged = (emailAddres: string) => {
    this.props.searchPersonByEmail(emailAddres).then((result) => {
      this.setState({ disableFields: result });
    });
  };

  render() {
    const {
      submitting,
      onSubmit,
      handleSubmit,
      isEdit,
      onCancel,
      gebruiker,
      rollen,
    } = this.props;

    let title: any = "";
    let buttons: any = "";
    let wachtwoordFields: any = "";

    if (isEdit) {
      title = (
        <div className="column subHeader is-full">
          <h1>Gebruiker</h1>
        </div>
      );

      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              <button type="button" className="button " onClick={onCancel}>
                Annuleren
              </button>
              {!isReadOnly && (
                <button
                  type="submit"
                  disabled={submitting}
                  className="button is-success"
                >
                  Opslaan
                </button>
              )}
            </div>
          </div>
        </div>
      );

      wachtwoordFields = <></>;
    } else {
      wachtwoordFields = <></>;
    }

    return (
      <div className="content">
        <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
          <div className="columns contentPanel is-multiline is-mobile">
            {title}

            <Field
              component={FormTextInput}
              label="Email"
              name="gebruiker.emailAddress"
              showSideBySide={true}
              validate={[required, email]}
              required={true}
              onBlur={(e: any) => {
                this.onEmailChanged(e.target.value);
              }}
              readOnly={isReadOnly}
            />
            <Field
              style={{ overflow: "visible" }}
              component={FormDropdown}
              label="Type gebruiker"
              name="gebruiker.type"
              options={Object.keys(PersonType)
                .filter((k) => typeof PersonType[k as any] === "number")
                .map((value, index) => {
                  return { value: index, label: value };
                })}
              //.filter(k => k.label == "SysteemGebruiker")}
              showSideBySide={true}
              required={true}
              onValueChanged={(value: any) => {
                this.onGebruikerTypeChanged(value);
              }}
              readOnly={this.state.disableFields || isReadOnly || gebruiker}
            />
            <Field
              component={FormTextInput}
              label="Voornaam"
              name="gebruiker.firstname"
              showSideBySide={true}
              validate={required}
              required={true}
              readOnly={this.state.disableFields || isReadOnly || gebruiker}
            />

            <Field
              component={FormTextInput}
              label="Achternaam"
              name="gebruiker.surname"
              showSideBySide={true}
              validate={required}
              required={true}
              readOnly={this.state.disableFields || isReadOnly || gebruiker}
            />
            <Field
              component={FormCheckbox}
              label="Gebruiker heeft toegang tot Portaal"
              name="gebruiker.isSystemUser"
              readOnly={this.state && this.state.isReadonlySystemUser}
              customWrapperClass={
                "field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
              }
            />
            <Field
              component={FormCheckbox}
              label="Gebruiker heeft toegang tot de App"
              name="gebruiker.isAppUser"
              readOnly={this.state && this.state.isReadonlySystemUser}
              customWrapperClass={
                "field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
              }
            />
            <Field
              component={FormTextInput}
              label="Functie"
              name="gebruiker.jobTitle"
              showSideBySide={true}
              validate={[required]}
              required={true}
              readOnly={isReadOnly}
            />
            <Field
              style={{ overflow: "visible" }}
              component={FormDropdown}
              label="Gebruiker rol"
              name="gebruiker.roleName"
              options={rollen.map((value) => {
                return { value: value.name, label: value.name };
              })}
              showSideBySide={true}
              required={true}
              // onValueChanged={(value: any) => { this.onGebruikerTypeChanged(value); }}
              readOnly={isReadOnly}
            />
            <Field
              component={FormCheckbox}
              label="Gebruiker is een verantwoordelijke"
              name="gebruiker.isResponsible"
              readOnly={isReadOnly}
              customWrapperClass={
                "field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
              }
            />

            {wachtwoordFields}
          </div>

          <div className="floating-btn-bar">{buttons}</div>
        </Form>
      </div>
    );
  }
}

const GebruikerReduxForm = reduxForm<{}, OwnProps>({
  form: "GebruikerForm",
  enableReinitialize: true,
})(GebruikerComponent);

export default connect((state: RootState & State, props: any) => {
  let gebruiker = state.gebruiker ? (state.gebruiker as Person) : undefined;

  return {
    initialValues: {
      gebruiker: {
        id: props.isEdit ? state.gebruiker.id : "",
        firstname: props.isEdit ? state.gebruiker.firstname : "",
        surname: props.isEdit ? state.gebruiker.surname : "",
        emailAddress: props.isEdit ? state.gebruiker.emailAddress : "",
        jobTitle: props.isEdit && gebruiker ? gebruiker.jobTitle : "",
        isSystemUser:
          props.isEdit && gebruiker
            ? gebruiker.isSystemUser
              ? gebruiker.isSystemUser
              : false
            : true,
        isResponsible:
          props.isEdit && gebruiker
            ? gebruiker.isResponsible
              ? gebruiker.isResponsible
              : false
            : false,
        type: props.isEdit ? state.gebruiker.type : "SystemUser",
        roleName: props.isEdit ? state.gebruiker.roleName : "",
        wachtwoord: "",
        wachtwoord2: "",
        oudwachtwoord: "",
      },
    },
    formValues: getFormValues("GebruikerForm")(state) as Person,
  };
})(GebruikerReduxForm);
