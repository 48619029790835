import * as types from "../constants/notuleActionTypes";
import { Notation } from "../types/model";

const initialNotulenState: Array<Notation> = [];
const initialNotuleState = {} as Notation

type NotulenState = typeof initialNotulenState
type NotuleState = typeof initialNotuleState

export const notulenReducer = (
    state: NotulenState = initialNotulenState,
    action: types.GetNotulenAction
) => {
    if (action.type === types.GET_NOTULEN)
        return action.notulen;

    return state;
};

export const notuleReducer = (
    state: NotuleState = initialNotuleState,
    action: types.GetNotuleAction
) => {
    if (action.type === types.GET_NOTATION)
        return action.notule;

    return state;
};
