import React, { ChangeEvent } from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { FormDropdown } from "../FormDropdown";
import {
  Taak,
  Bedrijf,
  Person,
  Verantwoordelijke,
  Permissions,
} from "../../types/model";
import { connect } from "react-redux";
import { TaakSoort } from "../../constants/enums";
import FormDatePicker from "../FormDatePicker";
import { HiddenInput } from "../HiddenInput";
import { FormCheckbox } from "../FormCheckbox";
import { IsReadOnly } from "../../helpers/PermissionHelper";

export interface OwnProps {
  taak?: Taak;
  bedrijven: Bedrijf[];
  verantwoordelijken: Verantwoordelijke[];
  contactpersoonSoorten: Array<{ label: string; value: string }>;
  formValues: any;
  onCancel: () => void;
  onSubmit: (formValues: any) => void;
  isEdit?: boolean;
  isModal?: boolean;
  companyId?: number;
}

export const TaakSoorten = [
  { label: "Afspraak contractverlenging", value: 0 },
  { label: "Afspraak voor event", value: 1 },
  { label: "Contact opnemen over reclame uitingen", value: 2 },
  { label: "Handmatige herinnering factuur (bellen / mailen)", value: 3 },
  { label: "Hoofdcontactpersoon wijzigen", value: 4 },
  { label: "Contactpersoon wijzigen", value: 5 },
  { label: "Contact- of adresgegevens wijzigen", value: 6 },
  { label: "Anders", value: 7 },
];

const isReadOnly = IsReadOnly(Permissions.ManageTasks);

class TaakComponent extends React.Component<
  OwnProps & InjectedFormProps<{}, OwnProps>
> {
  constructor(props: OwnProps & InjectedFormProps<{}, OwnProps>) {
    super(props);
  }

  onBedrijfChange = (event: any) => {
    //bedrijfId wordt doorgegeven door react-select ipv een changeevent;
    var bedrijfId = event as string;
    var bedrijf = this.props.bedrijven.find((b) => b.id == bedrijfId);

    if (
      bedrijf &&
      bedrijf.responsibleId &&
      !bedrijf.responsibleId.startsWith("0") &&
      this.props.verantwoordelijken.find(
        (b) => bedrijf != undefined && b.id == bedrijf.responsibleId
      )
    ) {
      this.props.formValues.taak.responsibleId = bedrijf.responsibleId;
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      bedrijven,
      verantwoordelijken,
      taak,
      isEdit = false,
      isModal = false,
      onSubmit,
      onCancel,
    } = this.props;

    let buttons: any = "";
    let title: any = "";

    if (isEdit && isModal == false) {
      title = (
        <div className="column subHeader is-full">
          <h1>Taak</h1>
        </div>
      );

      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              <button type="button" className="button " onClick={onCancel}>
                Annulerennn
              </button>
              {!isReadOnly && (
                <button
                  type="submit"
                  disabled={submitting}
                  className="button is-success"
                >
                  Opslaan
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }

    let selectBedrijven = bedrijven.map(function (bedrijf) {
      return { label: bedrijf.name, value: bedrijf.id.toString() };
    });

    let selectVerantwoordelijken = verantwoordelijken.map(function (
      verantwoordelijke
    ) {
      return {
        label: verantwoordelijke.fullName,
        value: verantwoordelijke.id.toString(),
      };
    });

    return (
      <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="columns contentPanel is-multiline is-mobile">
            {title}

            <Field component={HiddenInput} name="taak.id" />

            <Field
              component={FormDropdown}
              label="Soort"
              name="taak.taskType"
              showSideBySide={true}
              options={TaakSoorten}
              readOnly={isReadOnly}
            />

            <Field
              component={FormDropdown}
              label="Bedrijf"
              name="taak.companyId"
              showSideBySide={true}
              options={selectBedrijven}
              onChange={this.onBedrijfChange}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Omschrijving"
              name="taak.description"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormDropdown}
              label="Verantwoordelijke"
              name="taak.responsibleId"
              showSideBySide={true}
              options={selectVerantwoordelijken}
              readOnly={isReadOnly}
            />

            <Field
              component={FormDatePicker}
              label="Vervaldatum"
              name="taak.expirationDate"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            {taak && taak.id && (
              <span className="checkboxWrapper">
                <Field
                  component={FormCheckbox}
                  label="Voltooid"
                  name="taak.completed"
                  showSideBySide={true}
                  readOnly={isReadOnly}
                  customWrapperClass={
                    "field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop"
                  }
                />
              </span>
            )}
          </div>

          <div className="floating-btn-bar">{buttons}</div>
        </div>
      </Form>
    );
  }
}

const TaakReduxForm = reduxForm<{}, OwnProps>({
  form: "TaakForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TaakComponent);

export default connect((state: any, props: any) => ({
  initialValues: {
    taak: {
      id: props.isEdit ? state.taak.id : "",
      companyId:
        props.isEdit && state.taak
          ? state.taak.companyId
          : props.bedrijven[0].id,
      description: props.isEdit ? state.taak.description : "",
      taskType: props.isEdit
        ? state.taak.taskType
        : TaakSoort["Afspraak contractverlenging"],
      responsibleId: props.isEdit
        ? state.taak.responsibleId
        : props.companyId
        ? props.bedrijven.find((b: { id: number }) => b.id == props.companyId)
            .responsibleId
        : props.bedrijven[0].responsibleId,
      expirationDate: props.isEdit
        ? state.taak.expirationDate
        : new Date().toDateString(),
      completed: props.isEdit ? state.taak.completed : false,
    },
  },
  formValues: getFormValues("TaakForm")(state),
}))(TaakReduxForm);
