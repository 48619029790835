import { InvoiceSponsorDeals, Sponsordeal, SponsordealList, SponsordealSignature } from "../types/model";

import ApiClient from './ApiClient';

const endpoint = 'api/sponsordeal';

export default class SponsordealApi {
    public static async getSponsordeals(): Promise<Sponsordeal[]> {
        var sponsordeals = await ApiClient.doHttpRequest<Sponsordeal[]>('get', `${endpoint}`);

        return sponsordeals;
    }

    public static async getSponsordealList(): Promise<SponsordealList[]> {
        var sponsordeals = await ApiClient.doHttpRequest<SponsordealList[]>('get', `${endpoint}/list?includeArchived=true`);

        return sponsordeals;
    }

    public static async archiveSelectedSponsorDeals(ids: string[]): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/archive`, ids);
    }

    public static async invoiceSelectedSponsorDeals(sponsordeals: InvoiceSponsorDeals): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/invoice`, sponsordeals);
    }    

    public static async getSponsordeal(id: number): Promise<Sponsordeal> {
        var sponsordeal = await ApiClient.doHttpRequest<Sponsordeal>('get', `${endpoint}/${id}`);
        return sponsordeal;
    }

    public static async createSponsordeal(sponsordeal: Sponsordeal): Promise<void> { 
        await ApiClient.doHttpRequest<void>('post', `${endpoint}`, sponsordeal);
    }

    public static async updateSponsordeal(sponsordeal: Sponsordeal): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}/${sponsordeal.id}`, sponsordeal);
    }

    public static async updateSponsordealSignature(sponsordealsignature: SponsordealSignature): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}/UpdateSponsordealSignature`, sponsordealsignature);
    }

    public static async deleteSponsordeal(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `${endpoint}/${id}`);
    }

    public static async extendSponsordeal(id: string, seasonAmount: number): Promise<string> {
        var newSponsorDealId = await ApiClient.doHttpRequest<string>('post', `${endpoint}/ExtendSponsordeal/${id}/${seasonAmount}`);
        return newSponsorDealId;
    }

    public static async createFactuurForSponsordeal(id: number, season: number): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/CreateFactuurForSponsordeal`, { id: id, season: season });
    }

    public static async terminateSponsordealContract(id: string, cancelledDate: string, cancelledDescription: string, cancelledReason: number): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/TerminateSponsordeal`, { id, cancelledDate, cancelledDescription, cancelledReason });
    }

    public static async deleteSponsorDealAttachment(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `${endpoint}/attachment/${id}`);
    }

    public static async generateSponsordealContract(id: string): Promise<any> {
        await ApiClient.doFileRequest('get', `${endpoint}/GenerateSponsordeal/${id}`, null, false, true, { responseType: 'application/pdf' })
    }

    public static async downloadSponsordealContract(id: string): Promise<any> {
        await ApiClient.doFileRequest('get', `${endpoint}/DownloadSponsordeal/${id}`, null, false, true, { responseType: 'application/pdf' })
    }

    public static async emailSponsordealContract(id: string): Promise<any>{
        await ApiClient.doFileRequest('post', `${endpoint}/SendEmailContract/${id}`);
    }
}



export const getHost = () => {
    return window.location.hostname === "localhost"
        ? window.location.host
        : window.location.hostname;
}