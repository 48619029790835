import ApiClient from "./ApiClient";
import { Factuur, InvoiceValidation, ListFactuur } from "../types/model";
import { Guid } from "guid-typescript";
import moment from "moment";

const endpoint = "api/invoice";

export default class FactuurApi {
  public static async getFacturen(): Promise<ListFactuur[]> {
    var facturen = await ApiClient.doHttpRequest<ListFactuur[]>(
      "get",
      `${endpoint}`
    );
    return facturen;
  }

  public static async getFactuur(id: string): Promise<Factuur> {
    var factuur = await ApiClient.doHttpRequest<Factuur>(
      "get",
      `${endpoint}/${id}`
    );

    return factuur;
  }

  public static async createFactuur(factuur: Factuur): Promise<void> {
    await ApiClient.doHttpRequest<void>("post", `${endpoint}`, factuur);
  }

  public static async updateFactuur(factuur: Factuur): Promise<void> {
    await ApiClient.doHttpRequest<void>("put", `${endpoint}`, factuur);
  }

  public static async deleteFactuur(id: string): Promise<void> {
    await ApiClient.doHttpRequest<void>("patch", `${endpoint}/${id}/delete`);
  }

  public static async generateFactuurPDF(factuurId: string): Promise<any> {
    await ApiClient.doFileRequest<Blob>(
      "get",
      `${endpoint}/generateInvoicePDF/${factuurId}`,
      null,
      false,
      true,
      { responseType: "blob" }
    ).then((response) => {
      var blobUrl = window.URL.createObjectURL(response.data);
      var tempLink = document.createElement("a");
      tempLink.href = blobUrl;
      tempLink.setAttribute("download", "export_factuur.pdf");
      tempLink.click();
    });
  }

  public static async maakDefinitief(facturenIds: string[]): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "put",
      `${endpoint}/makeFinal`,
      facturenIds
    );
  }

  public static async sendselectedInvoicesToUnit4(
    selectedInvoices: string[]
  ): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `${endpoint}/sendSelectedFacturenToUnit4`,
      selectedInvoices
    );
  }

  public static async archiveselectedInvoices(
    selectedInvoices: string[]
  ): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `${endpoint}/archive`,
      selectedInvoices
    );
  }

  public static async validateInvoices(invoiceMailModel: {
    ids: string[];
    email: string | null;
  }): Promise<InvoiceValidation[]> {
    return await ApiClient.doHttpRequest<InvoiceValidation[]>(
      "post",
      `${endpoint}/validateInvoices`,
      invoiceMailModel
    );
  }

  public static async sendFactuurReminder(invoiceMailModel: {
    ids: string[];
    email: string | null;
  }): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `${endpoint}/sendreminder`,
      invoiceMailModel
    );
  }

  public static async sendFactuur(invoiceMailModel: {
    ids: string[];
    email: string | null;
  }): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `${endpoint}/sendinvoice`,
      invoiceMailModel
    );
  }

  public static async exportselectedInvoices(
    selectedInvoices: string[]
  ): Promise<any> {
    await ApiClient.doFileRequest<Blob>(
      "post",
      `${endpoint}/export`,
      selectedInvoices,
      false,
      true,
      { responseType: "blob" }
    ).then((response) => {
      var blobUrl = window.URL.createObjectURL(response.data);
      var tempLink = document.createElement("a");
      tempLink.href = blobUrl;
      tempLink.setAttribute(
        "download",
        `facturen_${moment().format("YYYY-MM-DDTHH-mm-ss")}.xlsx`
      );
      tempLink.click();
    });
  }
}
