import ApiClient from './ApiClient';
import { Product } from "../types/model";

const endpoint = 'api/product';

export default class ProductApi {
    public static async getProducten(): Promise<Product[]> {
        var producten = await ApiClient.doHttpRequest<Product[]>('get', `${endpoint}`);
        return producten;
    }

    public static async getProductenForUser(): Promise<Product[]> {
        var producten = await ApiClient.doHttpRequest<Product[]>('get', `${endpoint}/getProductenForUser`);
        return producten;
    }

    public static async getProduct(id: number): Promise<Product> {
        var product = await ApiClient.doHttpRequest<Product>('get', `${endpoint}/${id}`);
        return product;
    }

    public static async createProduct(product: Product): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}`, product);
    }

    public static async updateProduct(product: Product): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}`, product);
    }

    public static async archiveSelectedProducten(selectedProducten: number[]): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/archiveSelectedProducten`, selectedProducten);
    }
}