import {
  Bedrijf,
  Contactpersoon,
  EventAttendee,
  EventAttendSubmit,
  EventGuest,
  EventLeadSubmit,
  Permissions,
} from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import { PersonType } from "../constants/enums";
import React, { ChangeEvent } from "react";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import { CSVLink } from "react-csv";
import EventApi from "../api/EventApi";
import EventAttendeeComponent from "../components/EventAttendeeComponent/EventAttendeeComponent";
import EventAttendeesComponent from "../components/EventAttendeesComponent/EventAttendeesComponent";
import { IsReadOnly } from "../helpers/PermissionHelper";
import { Modal } from "../components/Modal";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getAttendees } from "../actions/eventActions";
import { getBedrijven } from "../actions/bedrijfActions";
import history from "../components/history";
import matchSorter from "match-sorter";
import { submit } from "redux-form";
import { toast } from "react-toastify";
import { getContactpersonen } from "../actions/contactpersoonActions";

interface DispatchProps {
  getAttendees: (eventId: string) => any;
  submitForm: (form: string) => any;
  getBedrijven: () => any;
  getContactpersonen: () => any;
  showLoading: () => any;
  hideLoading: () => any;
}

interface StateProps {
  attendees: Contactpersoon[];
  contactpersonen: Contactpersoon[];
  bedrijven: Bedrijf[];
  contactpersoonSoorten: [{ label: string; value: string }];
}

interface OwnProps {
  eventId?: string;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  showModal: boolean;
  selectedAttendee: Contactpersoon | undefined;
  selectedAttendees: string[];
  isArchive: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageEvents);
class EventAttendeesContainer extends React.Component<Props, State> {
  state: State = {
    showModal: false,
    selectedAttendee: undefined,
    selectedAttendees: [],
    isArchive: false,
  };

  componentDidMount() {
    if (this.props.eventId) this.props.getAttendees(this.props.eventId);
    this.props.getBedrijven();
    this.props.getContactpersonen();
  }

  onRowClick(event: ChangeEvent, rowInfo: RowInfo) {
    history.push("/Attendees/" + rowInfo.original.id);
  }

  openModal = () => {
    this.setState({ selectedAttendee: undefined, showModal: true });
  };

  onClose = () => {
    this.setState({ showModal: false });
  };

  onClick = (id: string, formValues: any) => {
    if (formValues.original.IsTeWijzigen)
      this.setState({
        selectedAttendee: this.props.attendees.find(
          (e) => e.id === formValues.original.Id
        ),
        showModal: true,
      });
  };

  submitForm = (formValues: any) => {
    const attendee = formValues.attendee;

    let attendeeObject = {
      firstname: attendee.firstname,
      surname: attendee.surname,
      eventId: this.props.eventId,
      companyName: attendee.companyName,
      emailAddress: attendee.email,
      phoneNumber: attendee.phoneNumber,
    } as EventGuest;

    if (attendee.id !== null) attendeeObject.id = attendee.id;

    let successMessage =
      formValues.attendee.id === null
        ? "Toevoegen is gelukt"
        : "Wijzigen is gelukt";

    if (formValues.isGuest) {
      if (formValues.attendee.isLead) {
        var eventLead = attendeeObject as EventLeadSubmit;
        eventLead.invitedById = attendee.invitedById;
        EventApi.upsertEventLead(eventLead)
          .then((e) => {
            toast.success(successMessage);
            if (this.props.eventId) this.props.getAttendees(this.props.eventId);
          })
          .catch(() => toast.error("Er is een fout opgetreden"));
      } else {
        EventApi.upsertEventGuest(attendeeObject)
          .then((e) => {
            toast.success(successMessage);
            if (this.props.eventId) this.props.getAttendees(this.props.eventId);
          })
          .catch(() => toast.error("Er is een fout opgetreden"));
      }
    } else {
      let attendeeObj = {
        personId: formValues.contactpersoonId,
      } as EventAttendSubmit;
      if (this.props.eventId) {
        EventApi.upsertEventContactpersoon(this.props.eventId, attendeeObj)
          .then((e) => {
            toast.success(successMessage);
            if (this.props.eventId) this.props.getAttendees(this.props.eventId);
          })
          .catch(() => toast.error("Er is een fout opgetreden"));
      }
    }

    this.setState({ showModal: false });
  };

  selectAttendee = (event: React.ChangeEvent<HTMLInputElement>) => {
    const attendeeId = event.target.value;

    if (event.target.checked) {
      if (
        this.state.selectedAttendees.find((item) => item === attendeeId) == null
      ) {
        const selectedAttendees = [...this.state.selectedAttendees, attendeeId];
        this.setState({ selectedAttendees: selectedAttendees });
      }
    } else {
      var index = this.state.selectedAttendees.indexOf(attendeeId);
      const selectedAttendees = [
        ...this.state.selectedAttendees.slice(0, index),
        ...this.state.selectedAttendees.slice(index + 1),
      ];
      this.setState({ selectedAttendees: selectedAttendees });
    }
  };

  selectAllAttendees = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const selectedAttendees = this.state.selectedAttendees;
      this.props.attendees.forEach((attendee) => {
        if (
          this.state.selectedAttendees.find((item) => item === attendee.id) ==
          null
        ) {
          selectedAttendees.push(attendee.id);
        }
      });
      this.setState({ selectedAttendees: selectedAttendees });
    } else {
      this.setState({ selectedAttendees: [] });
    }
  };

  archiveToggleSelectedCompanies = () => {
    if (this.state.selectedAttendees.length > 0) {
      let successMessage = this.state.isArchive
        ? "Het dearchiveren is succesvol"
        : "Het archiveren is succesvol";

      this.props.showLoading();
      EventApi.archiveEventAttendees(
        this.props.eventId!,
        this.state.selectedAttendees
      )
        .then(() => {
          this.props.getAttendees(this.props.eventId as string);
          this.setState({ selectedAttendees: [] });
          toast.success(successMessage);
        })
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => {
          this.props.hideLoading();
        });
    }
  };

  render() {
    const { attendees, contactpersoonSoorten, bedrijven, contactpersonen } =
      this.props;
    let columns: Array<Column> = [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectedAttendees.some(
                (fact) => fact === original.Id
              )}
              value={original.Id}
              onChange={this.selectAttendee}
            />
          );
        },
        Header: (x) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={
                this.state.selectedAttendees.length ===
                this.props.attendees.length
              }
              onChange={this.selectAllAttendees}
            />
          );
        },
        sortable: false,
        filterable: false,
        width: 55,
      },
      {
        Header: "Voornaam",
        accessor: "Voornaam",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Achternaam",
        accessor: "Achternaam",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Bedrijf",
        accessor: "Bedrijfsnaam",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Soort",
        accessor: "Type",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) => {
          const id = filter.id;
          return rows[id] !== undefined
            ? rows[id].toLowerCase().startsWith(filter.value.toLowerCase())
            : true;
        },
      },
      {
        Header: "Email-adres",
        accessor: "Emailadres",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
      {
        Header: "Telefoonnummer",
        accessor: "Telefoonnummer",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, {
            keys: [
              { threshold: matchSorter.rankings.CONTAINS, key: filter.id },
            ],
          }),
        filterAll: true,
      },
    ];

    var attendeesView: EventAttendee[] = [];
    if (
      bedrijven &&
      bedrijven.length > 0 &&
      contactpersoonSoorten &&
      contactpersoonSoorten.length > 0
    ) {
      var attendeesView: EventAttendee[] = attendees
        .filter((e) => e.isArchived === this.state.isArchive)
        .map((attendee) => {
          var company = bedrijven.find(
            (b: Bedrijf) => b.id === attendee.companyId
          );
          var type = contactpersoonSoorten.find(
            (c: { value: string; label: string }) => c.value == attendee.type
          );
          return {
            Id: attendee.id,
            Voornaam: attendee.firstname,
            Achternaam: attendee.surname,
            Bedrijfsnaam: company ? company.name : attendee.companyName,
            Type: type ? type.label : String(PersonType[attendee.type]),
            Emailadres: attendee.emailAddress,
            Telefoonnummer: attendee.phoneNumber,
            Geboortedatum: attendee.birthDate,
            IsTeWijzigen: attendee.isEditable,
          };
        });
    }

    return (
      <>
        <div className="content">
          <div className="contentPanel">
            <div className="columns contentPanel">
              <div className="column is-full">
                <div className="buttons is-pulled-left">
                  <button
                    className="button is-success archive-button"
                    onClick={() => {
                      this.setState({ isArchive: !this.state.isArchive });
                    }}
                  >
                    {this.state.isArchive ? "Terug" : "Archief"}
                  </button>
                </div>
                <div className="buttons is-pulled-right">
                  {!isReadOnly && (
                    <>
                      <button
                        onClick={this.archiveToggleSelectedCompanies}
                        className="button is-success"
                      >
                        {this.state.isArchive ? "Dearchiveren" : "Archiveren"}
                      </button>
                      <button
                        onClick={this.openModal}
                        className="button is-success"
                      >
                        Deelnemer toevoegen
                      </button>
                    </>
                  )}
                  <CSVLink
                    filename="Deelnemers.csv"
                    className="button is-success"
                    data={attendees}
                  >
                    Exporteer Excel
                  </CSVLink>
                </div>
              </div>
            </div>
            <EventAttendeesComponent
              onClick={this.onClick}
              bedrijven={bedrijven}
              columns={columns}
              attendees={attendeesView}
            />
          </div>
        </div>
        {this.state.showModal ? (
          <Modal
            title={
              this.state.selectedAttendee
                ? "Deelnemer wijzigen"
                : "Deelnemer toevoegen"
            }
            content={
              <EventAttendeeComponent
                onClick={this.onClick}
                bedrijven={bedrijven}
                contactpersonen={contactpersonen}
                selectedAttendee={this.state.selectedAttendee}
                onSubmit={this.submitForm}
                attendees={attendees}
              />
            }
            onSubmit={() => this.props.submitForm("EventAttendeeForm")}
            onClose={this.onClose}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  attendees: state.attendees,
  bedrijven: state.bedrijven,
  contactpersoonSoorten: state.contactpersoonSoorten,
  contactpersonen: state.contactpersonen,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getAttendees: (eventId: string) => dispatch(getAttendees(eventId)),
  getBedrijven: () => dispatch(getBedrijven()),
  getContactpersonen: () =>
    dispatch(getContactpersonen(undefined, false, true)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  submitForm: (form: string) => dispatch(submit(form)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventAttendeesContainer);
