export const arraysHaveSameEntries = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

export const mergeArrays = <T>(arr1: T[], arr2: T[]): T[] => {
  const mergedSet = new Set([...arr1, ...arr2]);
  const mergedArray = Array.from(mergedSet);

  return mergedArray;
};
