import { Bedrijf, Settings, SponsorDealType, Sponsordeal, SponsordealSignature, Tegenprestatie } from "../types/model";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { change, submit } from "redux-form";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import DocumentApi from "../api/DocumentApi";
import ExtendSponsordealForm from "../components/ExtendSponsordealForm";
import { Modal } from "../components/Modal";
import React from "react";
import { RootState } from "../types/state";
import SignatureForm from "../components/SignatureForm";
import SponsordealApi from "../api/SponsordealApi";
import { SponsordealAttachmentEnum } from "../constants/enums";
import SponsordealComponent from "../components/SponsordealComponent/SponsordealComponent";
import TerminateForm from "../components/TerminateForm";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getBedrijven } from "../actions/bedrijfActions";
import { getSettings } from "../actions/settingsActions";
import { getSponsordeal } from "../actions/sponsordealActions";
import { getSponsordealSoorten } from "../actions/sponsordealSoortActions";
import history from "../components/history";
import { mdiConsoleNetworkOutline } from "@mdi/js";
import { toast } from "react-toastify";

interface DispatchProps {
  getSponsordeal: (id: string) => any;
  getSponsordealSoorten: () => any;
  getBedrijven: () => any;
  getSettings: () => any;
  submitForm: (form: string) => any;
  updateFormValueForSignatureForm: (field: string, value: any) => any;
  updateFormValueForUploadForm: (field: string, value: any) => any;
  showLoading: () => any;
  hideLoading: () => any;
}

interface StateProps {
  sponsordeal: Sponsordeal;
  sponsorDealTypes: SponsorDealType[];
  bedrijven: Bedrijf[];
  settings: Settings;
}

interface MatchParams {
  id: string;
}

interface State {
  showSignatureModal: boolean;
  showUploadModal: boolean;
  showTerminateModal: boolean;
  showExtendContractModal: boolean;
}

class SponsordealContainer extends React.Component<DispatchProps & StateProps & RouteComponentProps<MatchParams>, State> {
  state: State = {
    showSignatureModal: false,
    showUploadModal: false,
    showTerminateModal: false,
    showExtendContractModal: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getSponsordeal(this.props.match.params.id);
      this.props.getSponsordealSoorten();
      this.props.getBedrijven();
    }
  }

  updateSponsordeal = async (formValues: any) => {
    var sponsordeal = formValues as Sponsordeal;

    if (!sponsordeal.seperateSeasonalAgreements) {
      let price: number, seasonCompensations: Tegenprestatie[], inKind: string;

      sponsordeal.seasonAgreements.forEach((element, index) => {
        if (index == 0) {
          price = element.price;
          inKind = element.inKind;
          seasonCompensations = element.seasonAgreementCompensations.map(function (tegenprestatie) {
            return { description: tegenprestatie.description, SponsorDealTypeCompensationId: tegenprestatie.SponsorDealTypeCompensationId };
          });
        } else {
          element.price = price;
          element.inKind = inKind;
          element.seasonAgreementCompensations = seasonCompensations;
        }
      });
    }

    this.props.showLoading();
    await SponsordealApi.updateSponsordeal(sponsordeal)
      .then(() => toast.success("Het opslaan is succesvol"))
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());

    history.goBack();
  };

  saveSponsordealSignature = async (formValues: any) => {
    var sponsordealsignature = formValues as SponsordealSignature;
    sponsordealsignature.id = sponsordealsignature.id;
    sponsordealsignature.sponsorDealId = this.props.sponsordeal.id;

    this.props.showLoading();
    await SponsordealApi.updateSponsordealSignature(sponsordealsignature)
      .then(() => {
        this.closeSignatureModal();
        this.props.getSponsordeal(this.props.match.params.id);
        toast.success("Het opslaan is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());
  };

  deleteSponsordeal = async () => {
    this.props.showLoading();
    await SponsordealApi.deleteSponsordeal(this.props.match.params.id)
      .then(() => {
        toast.success("Het verwijderen is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());

    history.goBack();
  };

  onChangeSignature = (signatureCanvas: any) => {
    this.props.updateFormValueForSignatureForm("signature", signatureCanvas.getTrimmedCanvas().toDataURL());
  };

  openSignatureModal = () => {
    this.setState({ showSignatureModal: true });
  };

  closeSignatureModal = () => {
    this.setState({ showSignatureModal: false });
  };

  openTerminateModal = () => {
    this.setState({ showTerminateModal: true });
  };

  closeTerminateModal = () => {
    this.setState({ showTerminateModal: false });
  };

  openExtendContractModal = () => {
    this.setState({ showExtendContractModal: true });
  };

  closeExtendContractModal = () => {
    this.setState({ showExtendContractModal: false });
  };

  generateSponsordealContract = async () => {
    var sponsordealId = this.props.sponsordeal.id;

    this.props.showLoading();
    await SponsordealApi.generateSponsordealContract(sponsordealId)
      .then(() => {
        this.props.getSponsordeal(this.props.sponsordeal.id);
        toast.success("Het genereren is succesvol");
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => this.props.hideLoading());
  };

  downloadSponsordealContract = async () => {
    var sponsordeal = this.props.sponsordeal;

    this.props.showLoading();

    if (sponsordeal && sponsordeal.attachments && sponsordeal.attachments.length > 0) {
      var contract = sponsordeal.attachments.find((s) => s.attachmentType == SponsordealAttachmentEnum.Contract);
      if (contract != undefined) {
        window.open(contract.fileUrl, "_blank");
      }
    }
    this.props.hideLoading();
  };

  terminateSponsordealContract = async (formValues: any) => {
    var sponsordealId = this.props.sponsordeal.id;
    var cancelledDate = formValues.cancelledDate;
    var cancelledDescription = formValues.cancelledDescription;
    var cancelledReason = formValues.cancelledReason;

    this.props.showLoading();
    await SponsordealApi.terminateSponsordealContract(sponsordealId, cancelledDate, cancelledDescription, cancelledReason)
      .then(() => {
        this.closeTerminateModal();
        this.props.getSponsordeal(this.props.sponsordeal.id);
        toast.success("Het opzeggen is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());
  };

  extendSponsordealContract = async (formvalues: any) => {
    var sponsordealId = this.props.match.params.id;

    this.props.showLoading();
    await SponsordealApi.extendSponsordeal(sponsordealId, formvalues.seasonAmount)
      .then((newDealId) => {
        this.closeExtendContractModal();
        toast.success("Het verlengen is succesvol");

        this.props.getSponsordeal(newDealId);
        history.replace("/Sponsordeals/" + newDealId);
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());
  };

  createFactuurForSponsordealContract = async (season: number) => {
    var sponsordealId = parseInt(this.props.match.params.id);

    this.props.showLoading();
    await SponsordealApi.createFactuurForSponsordeal(sponsordealId, season)
      .then(() => {
        toast.success("Het aanmaken van de factuur is succesvol");
        history.goBack();
      })
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());
  };

  onDeleteAttachment = (id: string) => {
    SponsordealApi.deleteSponsorDealAttachment(id).then(() => {
      this.props.getSponsordeal(this.props.sponsordeal.id);
      toast.success("Het verwijderen is succesvol");
    });
  };

  openEmailContract = () => {
    SponsordealApi.emailSponsordealContract(this.props.sponsordeal.id)
      .then(() => {
        this.props.getSponsordeal(this.props.sponsordeal.id);
        toast.success("Het sturen van de email is succesvol");
      })
      .catch(() => toast.error("Er is een fout opgetreden"));
  };

  onCancel = () => {
    history.goBack();
  };

  render() {
    const { sponsordeal, sponsorDealTypes, bedrijven, settings } = this.props;

    return (
      <>
        <SponsordealComponent
          sponsordeal={sponsordeal}
          sponsorDealTypes={sponsorDealTypes}
          bedrijven={bedrijven}
          settings={settings}
          isEdit={true}
          onCancel={this.onCancel}
          onGenerate={this.generateSponsordealContract}
          onDownload={this.downloadSponsordealContract}
          onExtend={this.extendSponsordealContract}
          onFactureren={this.createFactuurForSponsordealContract}
          openSignatureModal={this.openSignatureModal}
          closeExtendContractModal={this.closeExtendContractModal}
          openExtendContractModal={this.openExtendContractModal}
          openTerminateModal={this.openTerminateModal}
          onSubmit={this.updateSponsordeal}
          onDeleteAttachment={this.onDeleteAttachment}
          onDelete={this.deleteSponsordeal}
          openEmailContract={this.openEmailContract}
        />
        {this.state.showSignatureModal ? (
          <Modal
            title="Handtekening"
            content={<SignatureForm onSubmit={this.saveSponsordealSignature} onChangeSignature={this.onChangeSignature} />}
            onSubmit={() => this.props.submitForm("SignatureForm")}
            onClose={this.closeSignatureModal}
          />
        ) : null}
        ,
        {this.state.showTerminateModal ? (
          <Modal
            title="Opzeggen contract"
            content={<TerminateForm onSubmit={this.terminateSponsordealContract} />}
            onSubmit={() => this.props.submitForm("TerminateForm")}
            onClose={this.closeTerminateModal}
          />
        ) : null}
        ,
        {this.state.showExtendContractModal ? (
          <Modal
            title="Contract verlengen"
            content={<ExtendSponsordealForm onSubmit={this.extendSponsordealContract} />}
            onSubmit={() => this.props.submitForm("ExtendSponsordealForm")}
            onClose={this.closeExtendContractModal}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  sponsordeal: state.sponsordeal,
  sponsorDealTypes: state.sponsorDealTypes,
  bedrijven: state.bedrijven,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
  getSponsordeal: (id: string) => dispatch(getSponsordeal(id as unknown as number)),
  getSponsordealSoorten: () => dispatch(getSponsordealSoorten()),
  getBedrijven: () => dispatch(getBedrijven()),
  getSettings: () => dispatch(getSettings()),
  submitForm: (form: string) => dispatch(submit(form)),
  updateFormValueForSignatureForm: (field: string, value: any) => dispatch(change("SignatureForm", field, value)),
  updateFormValueForUploadForm: (field: string, value: any) => dispatch(change("FileUploadForm", field, value)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SponsordealContainer));
