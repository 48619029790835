import * as React from "react";
import {
  Form,
  Field,
  reduxForm,
  InjectedFormProps,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { FormDropdown } from "../FormDropdown";
import { Permissions, Role } from "../../types/model";
import required from "../../validators/required";
import email from "../../validators/email";
import confirmpassword from "../../validators/confirmpassword";
import { connect } from "react-redux";
import length6 from "../../validators/length6";
import containsnumber from "../../validators/containsnumber";
import { UserRol, PersonType } from "../../constants/enums";
import { RootState } from "../../types/state";
import { FormCheckbox } from "../FormCheckbox";
import {
  permissionList,
  SubMenuItem,
} from "../../constants/permissions/permissions";
import { HiddenInput } from "../HiddenInput";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  rol?: Role;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  updateFormValue: (field: string, value: any) => any;
  selectPermission: (value: any) => any;
  isEdit?: boolean;
  formValues: any;
}

interface State {
  isReadonlySystemUser: boolean;
  disableFields: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageRoles);
class RolComponent extends React.Component<
  OwnProps & InjectedFormProps<{}, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<{}, OwnProps>) {
    super(props);

    this.state = { isReadonlySystemUser: true, disableFields: false };
  }

  updatePermissions = (e: any) => {
    var permissions = this.props.formValues.permissions;
    var isChecked = e.target.checked;
    var permission = parseInt(e.target.value);

    if (isChecked) permissions.push(permission);
    else {
      var index = permissions.indexOf(permission);
      if (index !== -1) {
        permissions.splice(index, 1);
      }
    }
    this.props.updateFormValue("permissions", permissions);
  };

  createPermissionBody = () => {
    const rol = this.props.formValues && this.props.formValues;

    var permissionBody = permissionList.map((e) => {
      return (
        <>
          <tr style={{ width: "100%", backgroundColor: "#f5f5f5" }}>
            <td style={{ width: "50%", fontWeight: "bold" }}>{e.menuItem}</td>
            <td></td>
            <td></td>
          </tr>
          {e.subMenuItems.map((smi: SubMenuItem) => {
            return (
              <tr style={{ width: "100%" }}>
                <td style={{ width: "50%" }}>»&nbsp;{smi.subMenuItem}</td>
                <td style={{ width: "25%" }}>
                  <input
                    type="checkbox"
                    disabled={isReadOnly}
                    onChange={(e: any) => this.updatePermissions(e)}
                    value={smi.readId}
                    checked={
                      rol && rol.permissions
                        ? rol.permissions.includes(smi.readId)
                        : false
                    }
                  />
                </td>
                <td style={{ width: "25%" }}>
                  <input
                    type="checkbox"
                    disabled={isReadOnly}
                    onChange={(e: any) => this.updatePermissions(e)}
                    value={smi.manageId}
                    checked={
                      rol && rol.permissions
                        ? rol.permissions.includes(smi.manageId)
                        : false
                    }
                  />
                </td>
              </tr>
            );
          })}
        </>
      );
    });
    return (
      <table>
        <thead>
          <tr style={{ width: "100%" }}>
            <td style={{ width: "50%" }}></td>
            <td style={{ width: "25%", fontWeight: "bold" }}>Alleen lezen</td>
            <td style={{ width: "25%", fontWeight: "bold" }}>Bewerken</td>
          </tr>
        </thead>
        <tbody>{permissionBody}</tbody>
      </table>
    );
  };

  render() {
    const { submitting, onSubmit, handleSubmit, isEdit, onCancel, rol } =
      this.props;

    let buttons: any = "";

    if (isEdit) {
      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              <button type="button" className="button" onClick={onCancel}>
                Annuleren
              </button>
              {!isReadOnly && (
                <button
                  type="submit"
                  disabled={submitting}
                  className="button is-success"
                >
                  Opslaan
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="content">
        <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
          <div className="columns contentPanel is-multiline is-mobile">
            <div className="column subHeader is-full">
              <h1>Rol</h1>
              <Field
                component={FormTextInput}
                label="Rol naam"
                name="rolName"
                showSideBySide={true}
                required={true}
                readOnly={isReadOnly}
                validate={required}
              />
            </div>
            {this.createPermissionBody()}
          </div>
          <div className="floating-btn-bar">{buttons}</div>
        </Form>
      </div>
    );
  }
}

const RolReduxForm = reduxForm<{}, OwnProps>({
  form: "RolForm",
  enableReinitialize: true,
})(RolComponent);

export default connect((state: RootState & State, props: any) => {
  return {
    initialValues: {
      permissions: props.isEdit ? state.rol.permissions : [],
      rolName: props.isEdit ? state.rol.name : "",
      id: props.isEdit ? state.rol.id : "",
    },
    formValues: getFormValues("RolForm")(state) as any,
  };
})(RolReduxForm);
