import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Nieuwsbericht, Permissions } from "../../types/model";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openArchief: () => void;
  openModal: () => void;
  archiveSelectedNieuwsberichten: () => void;
  columns: Array<Column>;
  nieuwsberichten: Nieuwsbericht[];
  archiefButtonText: string;
  archiverenButtonText: string;
}

const isReadOnly = IsReadOnly(Permissions.ManageNews);
export default class NieuwsberichtenComponent extends React.Component<OwnProps> {
  render() {
    const { onRowClick, openModal, openArchief, archiveSelectedNieuwsberichten, archiefButtonText, archiverenButtonText, columns, nieuwsberichten } =
      this.props;

    return (
      <>
        <div className="content">
          <div className="contentPanel">
            <div className="columns contentPanel">
              <div className="column is-full">
                <div className="buttons is-pulled-left">
                  {/* <button className="button is-success archive-button" onClick={openArchief}>
                                        {archiefButtonText}
                                    </button> */}
                </div>
                <div className="buttons is-pulled-right facturenButtons">
                  {/* <button className="button is-success" onClick={archiveSelectedNieuwsberichten}>
                                        {archiverenButtonText}
                                    </button> */}
                  {!isReadOnly && (
                    <button className="button is-success add-button" onClick={openModal}>
                      Toevoegen
                    </button>
                  )}
                </div>
              </div>
            </div>
            <Gridview id="NieuwsberichtenList" onRowClick={onRowClick} columns={columns} data={nieuwsberichten} />
          </div>
        </div>
      </>
    );
  }
}
