import * as actionTypes from "../constants/leadActionTypes";
import LeadApi from '../api/LeadApi';
import { GetLeadsAction, GetLeadAction } from "../constants/leadActionTypes";
import { AsyncAction } from ".";

export const getLeads = (): AsyncAction<GetLeadsAction> => {
    return async (dispatch) => {
        const leads = await LeadApi.getLeads();

        return dispatch({ type: actionTypes.GET_LEADS, leads });
    };
}

export const getLead = (id: string): AsyncAction<GetLeadAction> => {
    return async (dispatch) => {
        const lead = await LeadApi.getLead(id);

        return dispatch({ type: actionTypes.GET_LEAD, lead });
    };
}
