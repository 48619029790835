import { Action } from "redux";
import { Product } from "../types/model";

export const GET_PRODUCTEN = "GET_PRODUCTEN";
export const GET_PRODUCT = "GET_PRODUCT";

export interface GetProductenAction extends Action<string> {
    producten: Product[];
}

export interface GetProductAction extends Action<string> {
    product: Product;
}
