import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";
import Select from "react-select";
import { OptionsType } from "react-select/lib/types";

interface Props {
  options: OptionsType<{ label: string; value: string }>;
  label?: string;
  readOnly?: boolean;
  showSideBySide?: boolean;
  clearable: boolean;
  onValueChanged?: (value: any) => void;
  required: boolean;
}

export type DropdownProps = WrappedFieldProps & Props;

export const FormDropdown = (props: DropdownProps) => {
  const {
    options,
    input,
    label,
    meta: { touched, error },
    required,
    clearable = false,
    showSideBySide,
    readOnly,
    onValueChanged,
  } = props;

  let style = {};

  let wrapperClass =
    "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

  if (showSideBySide)
    wrapperClass = classnames(wrapperClass, "is-half-desktop");
  else wrapperClass = classnames(wrapperClass, "is-full-desktop");

  //style = { paddingTop: '.5rem', paddingRight: '2rem' };
  var value =
    options &&
    options.find(
      (o: { value: string; label: string }) =>
        o.value.toString() == input.value.toString()
    );

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label is-normal">
          <label
            className="label is-pulled-left"
            htmlFor={input.name}
            style={style}
          >
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </label>
        </div>
      )}
      <div className="field-body">
        <div className="field">
          <div className="control">
            <Select
              className="field"
              options={options}
              isDisabled={readOnly}
              isSearchable={true}
              value={value}
              defaultValue={
                options && options.length == 1
                  ? options[0]
                  : { label: "", value: "" }
              }
              isClearable={clearable}
              onChange={(option: any) => {
                if (onValueChanged) onValueChanged(option ? option.value : "");
                input.onChange(option ? option.value : "");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
