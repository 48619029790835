import * as React from "react";
import { Dashboard, Error } from "../pages";

import { Switch } from "react-router-dom";
import PrintMarkdown from "../components/PrintMarkdown";
import PrivateRoute from "./PrivateRoute";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import { initialize } from "../actions/applicationActions";
import { AnyAction } from "redux";
import { WaitScreen } from "./WaitScreen";
import { connect } from "react-redux";
import { Permissions } from "../types/model";
import { ShowPage } from "../helpers/PermissionHelper";
import { CustomPages } from "../pages/CustomPages";
import { CustomPage } from "../pages/CustomPage";
import { StatistiekenContainer } from "../containers/StatistiekenContainer";
import { InvoicesOverview } from "../pages/Invoices/Overview/InvoicesOverview";

const mapStateToProps = ({ initialized, company }: RootState) => {
  return {
    initialized: initialized,
    company: company,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
) => ({
  initialize: () => dispatch(initialize()),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const RouterWrapper: React.FC<Props> = ({
  initialized,
  initialize,
  company,
}) => {
  React.useEffect(() => {
    initialize();
  }, [initialize]);
  if (!initialized) {
    return <WaitScreen />;
  }

  const Bedrijven = require("../pages/Bedrijven").Bedrijven;
  const Bedrijf = require("../pages/Bedrijf").default;
  const Contactpersonen = require("../pages/Contactpersonen").Contactpersonen;
  const Contactpersoon = require("../pages/Contactpersoon").Contactpersoon;
  const Partner = require("../pages/Partner").Partner;
  const Taken = require("../pages/Taken").Taken;
  const Sponsordeals = require("../pages/Sponsordeals").Sponsordeals;
  const Sponsordeal = require("../pages/Sponsordeal").Sponsordeal;
  const SponsordealSoorten =
    require("../pages/SponsordealSoorten").SponsordealSoorten;
  const Gebruikers = require("../pages/Gebruikers").Gebruikers;
  const Gebruiker = require("../pages/Gebruiker").Gebruiker;
  const Leads = require("../pages/Leads").Leads;
  const Notulen = require("../pages/Notulen").Notulen;
  const Events = require("../pages/Events").Events;
  const Event = require("../pages/Event").default;
  const Acties = require("../pages/Acties").Acties;
  const Actie = require("../pages/Actie").Actie;
  const Producten = require("../pages/Producten").Producten;
  const Nieuwsberichten = require("../pages/Nieuwsberichten").Nieuwsberichten;
  const AgendaItems = require("../pages/AgendaItems").AgendaItems;
  const Settings = require("../pages/Settings").Settings;
  const WachtwoordWijzigen =
    require("../pages/WachtwoordWijzigen").WachtwoordWijzigen;
  const Rollen = require("../pages/Rollen").Rollen;
  const Rol = require("../pages/Rol").Rol;

  return (
    <Switch>
      <PrivateRoute exact={true} path="/Error" component={Error} />
      {ShowPage([Permissions.ReadDashboard, Permissions.ManageDashboard]) && (
        <PrivateRoute exact={true} path="/" component={Dashboard} />
      )}

      {ShowPage([Permissions.ReadDashboard, Permissions.ManageDashboard]) && (
        <PrivateRoute
          exact={true}
          path="/Statistieken"
          component={StatistiekenContainer}
        />
      )}

      {ShowPage([Permissions.ReadCompanies, Permissions.ManageCompanies]) && (
        <PrivateRoute exact={true} path="/Bedrijven" component={Bedrijven} />
      )}
      {ShowPage([Permissions.ReadCompanies, Permissions.ManageCompanies]) && (
        <PrivateRoute exact={true} path="/Bedrijven/:id" component={Bedrijf} />
      )}
      {ShowPage([Permissions.ReadCompanies, Permissions.ManageCompanies]) && (
        <PrivateRoute
          exact={true}
          path="/Bedrijven/:id/:bedrijfTabIndex"
          component={Bedrijf}
        />
      )}
      {ShowPage([Permissions.ReadCompanies, Permissions.ManageCompanies]) && (
        <PrivateRoute exact={true} path="/Bedrijf" component={Bedrijf} />
      )}
      {ShowPage([
        Permissions.ReadContactPersons,
        Permissions.ManageContactPersons,
      ]) && (
        <PrivateRoute
          exact={true}
          path="/Contactpersonen"
          component={Contactpersonen}
        />
      )}
      {ShowPage([
        Permissions.ReadContactPersons,
        Permissions.ManageContactPersons,
      ]) && (
        <PrivateRoute
          exact={true}
          path="/Contactpersonen/:id"
          component={Contactpersoon}
        />
      )}
      {ShowPage([
        Permissions.ReadContactPersons,
        Permissions.ManageContactPersons,
      ]) && (
        <PrivateRoute
          exact={true}
          path="/Contactpersoon"
          component={Contactpersoon}
        />
      )}
      {ShowPage([Permissions.ReadCompanies, Permissions.ManageCompanies]) && (
        <PrivateRoute exact={true} path="/Partners/:id" component={Partner} />
      )}
      {ShowPage([Permissions.ReadTasks, Permissions.ManageTasks]) && (
        <PrivateRoute exact={true} path="/Taken" component={Taken} />
      )}
      {ShowPage([
        Permissions.ReadSponsorDeals,
        Permissions.ManageSponsorDeals,
      ]) && (
        <PrivateRoute
          exact={true}
          path="/Sponsordeals"
          component={Sponsordeals}
        />
      )}
      {ShowPage([
        Permissions.ReadSponsorDeals,
        Permissions.ManageSponsorDeals,
      ]) && (
        <PrivateRoute
          exact={true}
          path="/Sponsordeals/:id"
          component={Sponsordeal}
        />
      )}
      {ShowPage([
        Permissions.ReadSponsorDealKinds,
        Permissions.ManageSponsorDealKinds,
      ]) && (
        <PrivateRoute
          exact={true}
          path="/Sponsordealsoorten"
          component={SponsordealSoorten}
        />
      )}
      {ShowPage([Permissions.ReadUsers, Permissions.ManageUsers]) && (
        <PrivateRoute exact={true} path="/Gebruikers" component={Gebruikers} />
      )}
      {ShowPage([Permissions.ReadUsers, Permissions.ManageUsers]) && (
        <PrivateRoute
          exact={true}
          path="/Gebruikers/:id"
          component={Gebruiker}
        />
      )}
      {ShowPage([Permissions.ReadInvoices, Permissions.ManageInvoices]) && (
        <PrivateRoute
          exact={true}
          path="/Facturen"
          component={InvoicesOverview}
        />
      )}
      {ShowPage([Permissions.ReadLeads, Permissions.ManageLeads]) && (
        <PrivateRoute exact={true} path="/Leads" component={Leads} />
      )}
      {ShowPage([Permissions.ReadRecords, Permissions.ManageRecords]) && (
        <PrivateRoute exact={true} path="/Notulen" component={Notulen} />
      )}
      <PrivateRoute
        exact={true}
        path="/PrintMarkdown"
        component={PrintMarkdown}
        navigation={false}
      />
      {ShowPage([Permissions.ReadEvents, Permissions.ManageEvents]) && (
        <PrivateRoute exact={true} path="/Events" component={Events} />
      )}
      {ShowPage([Permissions.ReadEvents, Permissions.ManageEvents]) && (
        <PrivateRoute exact={true} path="/Events/:id" component={Event} />
      )}
      <PrivateRoute exact={true} path="/Acties" component={Acties} />
      <PrivateRoute exact={true} path="/Acties/:id" component={Actie} />
      {ShowPage([Permissions.ReadProducts, Permissions.ManageProducts]) && (
        <PrivateRoute exact={true} path="/Producten" component={Producten} />
      )}
      {ShowPage([Permissions.ReadNews, Permissions.ManageNews]) && (
        <PrivateRoute exact={true} path="/Nieuws" component={Nieuwsberichten} />
      )}
      {ShowPage([Permissions.ReadAgenda, Permissions.ManageAgenda]) && (
        <PrivateRoute exact={true} path="/Agenda" component={AgendaItems} />
      )}
      <PrivateRoute exact={true} path="/Settings" component={Settings} />
      <PrivateRoute
        exact={true}
        path="/WachtwoordWijzigen"
        component={WachtwoordWijzigen}
      />
      {ShowPage([Permissions.ReadRoles, Permissions.ManageRoles]) && (
        <PrivateRoute exact={true} path="/Rollen" component={Rollen} />
      )}
      {ShowPage([Permissions.ReadRoles, Permissions.ManageRoles]) && (
        <PrivateRoute exact={true} path="/Rol/:id" component={Rol} />
      )}
      {ShowPage([
        Permissions.ReadCustomPages,
        Permissions.ManageCustomPages,
      ]) && (
        <PrivateRoute
          exact={true}
          path="/CustomPages"
          component={CustomPages}
        />
      )}
      {ShowPage([
        Permissions.ReadCustomPages,
        Permissions.ManageCustomPages,
      ]) && (
        <PrivateRoute
          exact={true}
          path="/CustomPage/:id"
          component={CustomPage}
        />
      )}
    </Switch>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterWrapper);
