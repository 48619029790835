import { Action } from "redux";
import { Factuur, ListFactuur } from "../types/model";

export const GET_FACTUREN = "GET_FACTUREN";
export const GET_FACTUUR = "GET_FACTUUR";

export interface GetFacturenAction extends Action<string> {
    facturen: ListFactuur[];
}

export interface GetFactuurAction extends Action<string> {
    factuur: Factuur;
}
