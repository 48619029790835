import { UserManager } from 'oidc-client';
let identityServerUrl = localStorage.getItem("identityServerUrl");
const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
const userManager = new UserManager({
    authority: identityServerUrl!,
    client_id: 'js',
    response_type: 'code',
    scope: 'openid profile api',
    redirect_uri: `${url}/callback`,
    silent_redirect_uri: `${url}/silent-renew.html`,
    post_logout_redirect_uri: `${url}`,
    response_mode: 'query',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true
});
export default userManager;