export const formatCurrency = (amount: number | undefined): string => {
  if (!amount) return "€ - ";

  if (typeof amount === "string") amount = parseFloat(amount);

  if (amount < 0.01) return "€ - ";
  const options = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  };

  return amount.toLocaleString("nl-NL", options);
};
