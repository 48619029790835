import { Bedrijf, Permissions, Person, Taak, Verantwoordelijke } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import React, { ChangeEvent } from "react";
import { TaakSoort, UserRol } from "../constants/enums";
import { getBedrijven, setBedrijfTabIndex } from "../actions/bedrijfActions";
import { getTaak, getTaken } from "../actions/taakActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import { IsReadOnly } from '../helpers/PermissionHelper';
import { Modal } from "../components/Modal";
import { RootState } from "../types/state";
import TaakComponent from "../components/TaakComponent/TaakComponent";
import TakenComponent from "../components/TakenComponent/TakenComponent";
import TaskApi from "../api/TaakApi";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { eventTabIndexReducer } from "../reducers/eventReducer";
import { getVerantwoordelijken } from "../actions/gebruikerActions";
import history from "../components/history";
import matchSorter from "match-sorter";
import moment from "moment";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
    getTaken: () => any;
    getTaak: (id: string) => any;
    getBedrijven: () => any;
    getVerantwoordelijken: () => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
    setBedrijfTabIndex: (bedrijfTabIndex: number) => any;
}

interface StateProps {
    taak: Taak;
    taken: Taak[];
    bedrijven: Bedrijf[];
    verantwoordelijken: Verantwoordelijke[];
}

interface OwnProps {
    companyId?: string;
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
    showCreateModal: boolean;
    showEditModal: boolean;
    selectedTasks: string[],
    archief: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageTasks);
class TakenContainer extends React.Component<Props, State> {
    state: State = {
        showCreateModal: false,
        showEditModal: false,
        archief: false,
        selectedTasks: []
    }

    componentDidMount() {
        this.props.getTaken();
        this.props.getBedrijven();
        this.props.getVerantwoordelijken();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        if (event.target.getAttribute('type') != 'checkbox') {
            this.props.getTaak(rowInfo.original.id);
            this.setState({ showEditModal: true });
        }
    }

    openModal = () => {
        this.setState({ showCreateModal: true });
    }

    createTaak = (formValues: any) => {
        var taak = formValues.taak as Taak;
        taak.expirationDate = moment(taak.expirationDate).format("YYYY-MM-DD");

        TaskApi.createTask(taak)
            .then(() => {
                this.props.getTaken();
                toast.success("Het opslaan is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.onClose();
            });

        this.setState({ showCreateModal: false });
    }

    updateTaak = (formValues: any) => {
        var taak = formValues.taak as Taak;

        TaskApi.updateTask(taak)
            .then(() => {
                this.props.getTaken();
                toast.success("Taak succesvol opgeslagen");
            })
            .catch(() => toast.error("Er is een fout opgetreden"));

        this.setState({ showEditModal: false });
    }

    openArchief = () => {
        this.setState({ archief: this.state.archief ? false : true });
    }

    onClose = () => {
        this.setState({ showCreateModal: false });
    }

    onCloseEdit = () => {
        this.setState({ showEditModal: false });
    }

    toggleTaak = (event: any) => {
        const taakId = event.target.value;

        TaskApi.toggleTask(taakId)
            .then(() => {
                this.props.getTaken();
                toast.success("Taak afgerond");
            })
            .catch(() => toast.error("Er is een fout opgetreden"));

        return false;
    }

    archiveToggleSelectedTasks = () => {
        if (this.state.selectedTasks.length > 0) {
            let successMessage = this.state.archief ? "Het dearchiveren is succesvol" : "Het archiveren is succesvol";

            this.props.showLoading();
            TaskApi.archiveSelectedTaks(this.state.selectedTasks)
                .then(() => {
                    this.props.getTaken();
                    this.setState({ selectedTasks: [] });
                    toast.success(successMessage)
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => {
                    this.props.hideLoading();
                });
        }
    }

    onSetRowColor = (rowInfo: RowInfo) => {
        if (rowInfo && rowInfo.original) {
            let vervaldatum = new Date(rowInfo.original.expirationDate);
            let today = new Date();
            var temp = new Date();
            let lastweek = new Date(temp.setDate(vervaldatum.getDate() - 7));

            if (vervaldatum < today)
                return "red";
            else if (lastweek < today && vervaldatum > today)
                return "orange";
        }

        return "";
    }

    onOpenCompany = () => {
        if (this.props.taak && this.props.taak.companyId)
            history.push("Bedrijven/" + this.props.taak.companyId);

        this.props.setBedrijfTabIndex(0);
    }

    selectTask = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dealId = event.target.value;

        if (event.target.checked) {
            if (this.state.selectedTasks.find(item => item === dealId) == null) {
                const selectedTasks = [...this.state.selectedTasks, dealId];
                this.setState({ selectedTasks: selectedTasks });
            }
        }
        else {
            var index = this.state.selectedTasks.indexOf(dealId);
            const selectedTasks = [
                ...this.state.selectedTasks.slice(0, index),
                ...this.state.selectedTasks.slice(index + 1)
            ]
            this.setState({ selectedTasks: selectedTasks });
        }
    }

    selectAllTasks = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { archief } = this.state;
        if (event.target.checked) {
            const selectedTasks = this.state.selectedTasks;
            this.props.taken.filter(e => e.isArchived === archief).forEach(task => {
                if (this.state.selectedTasks.find(item => item === task.id) == null) {
                    selectedTasks.push(task.id);
                }
            });
            this.setState({ selectedTasks: selectedTasks });
        }
        else {
            this.setState({ selectedTasks: [] });
        }
    }


    render() {
        const { taak, taken, bedrijven, verantwoordelijken, companyId } = this.props;

        let selectVerantwoordelijken = verantwoordelijken.map(function (verantwoordelijke) {
            return { label: verantwoordelijke.fullName, value: verantwoordelijke.id.toString() };
        });

        let columns: Array<Column> = [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedTasks.some(item => item.toString() === original.id.toString())}
                            value={original.id}
                            onChange={this.selectTask}
                        />
                    );
                },
                Header: x => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedTasks.length === this.props.taken.filter(t => t.isArchived === this.state.archief).length}
                            onChange={this.selectAllTasks}
                        />
                    );
                },
                sortable: false,
                filterable: false,
                width: 55
            },
            {
                Header: "Omschrijving",
                accessor: "description",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true,
            },
            {
                Header: "Soort",
                accessor: "taskType",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => {
                    var status = TaakSoort[props.value];
                    return status;
                },
                filterMethod: (filter: Filter, rows: any) => {
                    const id = filter.id;
                    return rows[id] !== undefined ? String((TaakSoort[rows[id]]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
                }
            },
            {
                Header: "Verantwoordelijke",
                accessor: "responsibleId",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => {
                    var status = selectVerantwoordelijken && selectVerantwoordelijken.find(f => f.value == props.value);
                    return status ? status.label : '';
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Vervaldatum",
                accessor: "expirationDate",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => moment(props.value).format("DD-MM-YYYY"),
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true,
            },

        ];
        if (!isReadOnly) {
            columns.push({
                Header: "Afgerond",
                accessor: "completed",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={original.completed}
                            value={original.id}
                            onChange={this.toggleTaak}
                        />
                    );
                },
                sortable: false,
                filterable: false
            })
        }
        let takenList = taken;

        let modelButtons =
            <button type="button" style={{ float: "left" }} onClick={this.onOpenCompany} className="button">
                Bedrijf openen
            </button>

        //const userRol = localStorage.getItem("userRol");
        //if (userRol && (userRol != UserRol.Admin && userRol != UserRol.Beheerder)) {
        //    takenList = takenList.filter(t => t.responsibleId.toString() === localStorage.getItem("userId"))
        //}

        if (companyId) {
            takenList = takenList.filter(t => t.companyId == companyId);
        }

        if (this.state.archief) {
            takenList = takenList.filter(t => t.isArchived);
        }
        else
            takenList = takenList.filter(t => !t.isArchived);

        return (
            <>
                <TakenComponent
                    onRowClick={this.onRowClick}
                    onSetRowColor={this.onSetRowColor}
                    openModal={this.openModal}
                    openArchief={this.openArchief}
                    archiveToggleSelectedTasks={this.archiveToggleSelectedTasks}
                    archiverenButtonText={this.state.archief ? "Dearchiveren" : "Archiveren"}
                    archiefButtonText={this.state.archief ? "Terug" : "Archief"}
                    columns={columns}
                    taken={takenList}
                />

                {this.state.showCreateModal
                    ? (<Modal
                        title="Nieuwe taak"
                        content={
                            <TaakComponent bedrijven={bedrijven} verantwoordelijken={verantwoordelijken} companyId={companyId} onSubmit={this.createTaak} />
                        }
                        onSubmit={() => this.props.submitForm("TaakForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
                {this.state.showEditModal
                    ? (<Modal
                        title="Taak aanpassen"
                        readOnly={isReadOnly}
                        content={
                            <TaakComponent isEdit={true} taak={taak} bedrijven={bedrijven} verantwoordelijken={verantwoordelijken} companyId={companyId} onSubmit={this.updateTaak} />
                        }
                        onSubmit={(formValues) => this.props.submitForm("TaakForm")}
                        onClose={this.onCloseEdit}
                        buttons={modelButtons} />
                    )
                    : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    taak: state.taak,
    taken: state.taken,
    bedrijven: state.bedrijven,
    verantwoordelijken: state.verantwoordelijken
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getTaak: (id: string) => dispatch(getTaak(id)),
    getTaken: () => dispatch(getTaken()),
    getBedrijven: () => dispatch(getBedrijven()),
    getVerantwoordelijken: () => dispatch(getVerantwoordelijken()),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    setBedrijfTabIndex: (bedrijfTabIndex: number) => dispatch(setBedrijfTabIndex(bedrijfTabIndex))
});

export default connect(mapStateToProps, mapDispatchToProps)(TakenContainer);
