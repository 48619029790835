import { Action } from "redux";
import { ChartModel, CsvViewModel } from "../types/model";

export const GET_GENEMUIDEN_STATISTIEKEN = "GET_GENEMUIDEN_STATISTIEKEN";
export const GET_SPONSORDEALTOTAALBEDRAG_STATISTIEKEN = "GET_SPONSORDEALTOTAALBEDRAG_STATISTIEKEN";
export const GET_SPONSORDEALSOORT_STATISTIEKEN = "GET_SPONSORDEALSOORT_STATISTIEKEN";
export const GET_FACTUUR_STATISTIEKEN = "GET_FACTUUR_STATISTIEKEN";
export const GET_CONTACTPERSOON_STATISTICS = "GET_CONTACTPERSOON_STATISTIEKEN";
export const GET_CSV_VIEWNAMES = "GET_CSV_VIEWNAMES";

export interface GetSponsordealSoortStatistiekenAction extends Action<string> {
    sponsordealSoortStatistieken: ChartModel;
}

export interface GetSponsordealTotaalBedragStatistiekenAction extends Action<string> {
    sponsordealTotaalBedragStatistieken: ChartModel;
}

export interface GetGenemuidenStatistiekenAction extends Action<string> {
    genemuidenStatistieken: ChartModel;
}

export interface GetFactuurStatistiekenAction extends Action<string> {
    factuurStatistieken: ChartModel;
}

export interface GetContactpersoonStatisticsAction extends Action<string> {
    contactpersoonStatistics: ChartModel;
}

export interface GetViewNamesAction extends Action<string> {
    viewNames: CsvViewModel;
}
