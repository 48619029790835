import {
  Field,
  Form,
  InjectedFormProps,
  getFormValues,
  reduxForm,
} from "redux-form";

import FormDatePicker from "./FormDatePicker";
import { FormDropdown } from "./FormDropdown";
import { OpzeggenSponsordealReden } from "../constants/enums";
import React from "react";
import { FormTextInput } from "./FormTextInput";
import { connect } from "react-redux";

const OpzeggenSponsordealRedenen = [
  { label: "1", value: 0 },
  { label: "2", value: 1 },
  { label: "Prijs te hoog", value: 2 },
  { label: "Andere focus mbt sponsoring", value: 3 },
  { label: "Door BCW opgezegd", value: 4 },
  { label: "Anders", value: 5 },
];

interface OwnProps {
  onSubmit: (formValues: any) => void;
  formValues: any;
}

class ExtendSponsordealForm extends React.Component<
  OwnProps & InjectedFormProps<{}, OwnProps>
> {
  render() {
    const { handleSubmit, onSubmit } = this.props;

    return (
      <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={FormTextInput}
          type={"number"}
          label="Aantal seizoenen"
          name="seasonAmount"
          showSideBySide={true}
        />
      </Form>
    );
  }
}

const ExtendSponsordealReduxForm = reduxForm<{}, OwnProps>({
  form: "ExtendSponsordealForm",
  enableReinitialize: true,
})(ExtendSponsordealForm);

export default connect((state: any) => ({
  initialValues: {
    seasonAmount: 1,
  },
  formValues: getFormValues("ExtendSponsordealForm")(state),
}))(ExtendSponsordealReduxForm);
