import { Gridview } from "../types/model";
import * as types from '../constants/gridviewActionTypes';

export default (state : Gridview[] = [], action : any) => {
    let gridview = state.find(g => g.id === action.gridviewId);
    if (!gridview && action.gridviewId) {
        gridview = { id: action.gridviewId, sorting: [], filters: [] };
        state = [...state, gridview];
    }

    switch (action.type) {
        case types.SET_SORTING:
            gridview!.sorting = [{ id: action.sorting.id, desc: action.sorting.desc }];
            return [...state];
        case types.SET_FILTER:
            const filter = gridview!.filters.find(s => s.id === action.filter.id);

            if (!filter)
                gridview!.filters.push({ id: action.filter.id, value: action.filter.value })
            else
                filter.value = action.filter.value;

            return [...state];
        default:
            return state;
    }
};
