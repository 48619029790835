import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";

interface Props {
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  text: string;
  name: string;
  showSideBySide?: boolean;
}

export const LabelInput = (props: WrappedFieldProps & Props) => {
  const { name, label, text, showSideBySide } = props;

  let wrapperClass = "field column is-horizontal is-marginless is-full-mobile is-full-tablet ";

  if (showSideBySide) wrapperClass = classnames(wrapperClass, "is-half-desktop");
  else wrapperClass = classnames(wrapperClass, "is-half-desktop");

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label ">
          <label className="field-label label bcwLabel is-pulled-left" htmlFor={name}>
            {label}
          </label>
        </div>
      )}
      <div className="field-body omschrijvingEvent">
        <p>{text}</p>
      </div>
    </div>
  );
};
