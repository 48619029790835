import * as actionTypes from "../constants/productActionTypes";
import ProductApi from '../api/ProductApi';
import { GetProductenAction, GetProductAction } from "../constants/productActionTypes";
import { AsyncAction } from ".";

export const getProducten = (): AsyncAction<GetProductenAction> => {
    return async (dispatch) => {
        const producten = await ProductApi.getProducten();

        return dispatch({ type: actionTypes.GET_PRODUCTEN, producten });
    };
}

export const getProductenForUser = (): AsyncAction<GetProductenAction> => {
    return async (dispatch) => {
        const producten = await ProductApi.getProductenForUser();

        return dispatch({ type: actionTypes.GET_PRODUCTEN, producten });
    };
}

export const getProduct = (id: number): AsyncAction<GetProductAction> => {
    return async (dispatch) => {
        const product = await ProductApi.getProduct(id);

        return dispatch({ type: actionTypes.GET_PRODUCT, product });
    };
}
