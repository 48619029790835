import * as actionTypes from "../constants/gridviewActionTypes";
import { AsyncAction } from ".";
import { SetFilterAction, SetSortingAction } from "../constants/gridviewActionTypes";
import { Sorting, Filter } from "../types/model";

export const setFilter = (gridviewId: string, filter: Filter): AsyncAction<SetFilterAction> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_FILTER, gridviewId, filter });
    };
}

export const setSorting = (gridviewId: string, sorting: Sorting): AsyncAction<SetSortingAction> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_SORTING, gridviewId, sorting });
    };
}
