import { Action } from "redux";
import { Nieuwsbericht } from "../types/model";

export const GET_NIEUWSBERICHTEN = "GET_NIEUWSBERICHTEN";
export const GET_NIEUWSBERICHT = "GET_NIEUWSBERICHT";

export interface GetNieuwsberichtenAction extends Action<string> {
    nieuwsberichten: Nieuwsbericht[];
}

export interface GetNieuwsberichtAction extends Action<string> {
    nieuwsbericht: Nieuwsbericht;
}
