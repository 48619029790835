import React, { useEffect, useRef } from "react";
import { Redirect, Route, RouteProps, withRouter } from "react-router-dom";
import { setFilter, setSorting } from "../actions/gridviewActions";

import { AnyAction } from "redux";
import { Filter } from "react-table";
import HeaderContainer from "../containers/HeaderContainer";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

import sycadeLogo from "../images/sycade_logo.png";

export interface OwnProps {
  navigation?: boolean;
  onlyRead?: boolean;
}

interface DispatchProps {
  setFilter: (gridviewId: string, filter: Filter) => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    gridviews: state.gridviews,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  setFilter: (gridviewId: string, filter: Filter) =>
    dispatch(setFilter(gridviewId, filter)),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps &
  OwnProps;

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const PrivateRoute = ({
  component,
  navigation = true,
  setFilter,
  gridviews,
  ...rest
}: Props) => {
  const prevPath = usePrevious(rest.path);

  useEffect(() => {
    if (prevPath !== rest.path) {
      for (var i = 0; i < gridviews.length; i++) {
        for (var k = 0; k < gridviews[i].filters.length; k++) {
          setFilter(gridviews[i].id, {
            id: gridviews[i].filters[k].id,
            value: "",
          });
        }
      }
    }
  });

  //
  // if (userRol == null || !allowedRoles.includes(userRol)) {
  //     return <Redirect to="/Error" />;
  // }

  return (
    <div className="main-content">
      {navigation && <HeaderContainer />}
      <Route {...rest} component={component} />

      {navigation && (
        <div className="footer">
          <span>Powered by:</span>
          <a href="https://sycade.com" target="_blank">
            <img src={sycadeLogo} />
          </a>
        </div>
      )}
    </div>
  );
};

// interface DispatchProps {
//     setReturnUrl: (returnUrl: string) => any;
// }

// const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
//     setReturnUrl: (returnUrl: string) => dispatch(setReturnUrl(returnUrl))
// });

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
