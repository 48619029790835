import { Column, Filter } from "react-table";
import matchSorter from "match-sorter";
import moment from "moment";
import { FinancialValue } from "../../../../../components/FinancialValue";
import { FactuurStatus } from "../../../../../constants/enums";

export const getInvoiceOverviewTableColumns = (
  selected: string[],
  selectAll: (() => void) | undefined,
  toggleSelected: (event: any) => void,
  fiscalYears: string[]
): Column[] => {
  return [
    {
      id: "checkbox",
      accessor: "",
      Cell: ({ original }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={selected.some((fact) => fact === original.id)}
            value={original.id}
            onChange={toggleSelected}
          />
        );
      },
      Header: (x) => {
        return (
          !!selectAll && (
            <input
              type="checkbox"
              className="checkbox"
              checked={selected.length > 0}
              onChange={selectAll}
            />
          )
        );
      },
      sortable: false,
      filterable: false,
      width: 55,
    },
    {
      Header: "Bedrijfsnaam",
      accessor: "companyName",
      headerStyle: {
        textAlign: "left",
      },
      filterMethod: (filter: Filter, rows: any) => {
        return matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        });
      },
      filterAll: true,
    },
    {
      Header: "Factuurnummer",
      accessor: "invoiceNumber",
      headerStyle: {
        textAlign: "left",
      },
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
    {
      Header: "Kenmerk",
      accessor: "distinction",
      headerStyle: {
        textAlign: "left",
      },
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
    {
      Header: "Status",
      accessor: "status",
      headerStyle: {
        textAlign: "left",
      },
      Cell: (props) => {
        var status = FactuurStatus[props.value];
        return status;
      },
      filterMethod: (filter: Filter, rows: any) => {
        const id = filter.id;
        return rows[id] !== undefined
          ? String(FactuurStatus[rows[id]].toLowerCase()).startsWith(
              filter.value.toLowerCase()
            )
          : true;
      },
    },
    {
      Header: "Totaalbedrag incl BTW",
      accessor: "totalAmountIncVAT",
      headerStyle: {
        textAlign: "left",
      },
      Cell: (props) =>
        props.value ? <FinancialValue value={props.value} /> : "€0,00",
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
    {
      Header: "Reeds betaald incl BTW",
      accessor: "payedAmountIncVAT",
      headerStyle: {
        textAlign: "left",
      },
      Cell: (props) =>
        props.value ? <FinancialValue value={props.value} /> : "€0,00",
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
    {
      Header: "Openstaand bedrag incl BTW",
      accessor: "remainingAmountIncVAT",
      headerStyle: {
        textAlign: "left",
      },
      Cell: (props) =>
        props.value ? <FinancialValue value={props.value} /> : "€0,00",
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
    {
      Header: "Reminder verstuurd",
      accessor: "lastReminder",
      headerStyle: {
        textAlign: "left",
      },
      Cell: (props) => (props.value ? moment(props.value).format("LLLL") : ""),
      filterAll: true,
    },
    {
      Header: "Unit4 Meldingen",
      accessor: "unit4Message",
      headerStyle: {
        textAlign: "left",
      },
      filterAll: true,
    },
    {
      Header: "Boekjaar",
      accessor: "fiscalYear",
      headerStyle: {
        textAlign: "left",
      },
      style: {
        textAlign: "right",
      },
      Cell: (props) => (props.value ? props.value : ""),
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="">Alles</option>
            {fiscalYears.map(function (value) {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        );
      },
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
  ] as Column[];
};
