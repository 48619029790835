import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { AgendaItem, Permissions } from "../../types/model";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openModal: () => void;
  columns: Array<Column>;
  agendaItems: AgendaItem[];
  bedrijfId?: number;
}
const isReadOnly = IsReadOnly(Permissions.ManageAgenda);
export default class LeadsComponent extends React.Component<OwnProps> {
  render() {
    const { onRowClick, openModal, columns, agendaItems } = this.props;

    const userRol = localStorage.getItem("userRol");

    return (
      <>
        <div className="content">
          <div className="columns contentPanel">
            <div className="column is-full">
              <div className="buttons is-pulled-right">
                {!isReadOnly && (
                  <button className="button is-success add-button" onClick={openModal}>
                    Toevoegen
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="contentPanel">
            <Gridview id="AgendaItemsList" onRowClick={onRowClick} columns={columns} data={agendaItems} />
          </div>
        </div>
      </>
    );
  }
}
