import React from "react";

import waitIndicator from "../images/loadingBcw.gif";

export const WaitScreen = () => {
  const style: React.CSSProperties = {
    width: "100vw",
    height: "100vh",
    background: "black",
    zIndex: 10000,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={style}>
      <img
        src={waitIndicator}
        width="240px"
        height="240px"
        alt="Waiting indicator"
      />
    </div>
  );
};
