
import WachtwoordWijzigenComponent from "../components/WachtwoordWijzigenComponent/WachtwoordWijzigenComponent";
import React from "react";
import { connect } from "react-redux";
import GebruikerApi from "../api/GebruikerApi";
import history from "../components/history";
import { toast } from "react-toastify";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { showLoading, hideLoading } from "../actions/loadingActions";
import { RootState } from "../types/state";

interface DispatchProps {
    showLoading: () => any;
    hideLoading: () => any;
}

export interface State {
    userId: string,
    resetpasswordtoken: string
}

class WachtwoordWijzigenContainer extends React.Component<DispatchProps, State> {
    onSubmit = (formValues: any) => {
        this.props.showLoading();
        GebruikerApi.changeWachtwoord(formValues.gebruiker.oudwachtwoord, formValues.gebruiker.wachtwoord)
            .then(() => {
                toast.success("Het wachtwoord aanpassen is succesvol");
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.props.hideLoading();
            });
    }

    onCancel = () => {
        history.goBack();
    }

    render() {
        return (
            <WachtwoordWijzigenComponent onSubmit={this.onSubmit} />
        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(null, mapDispatchToProps)(WachtwoordWijzigenContainer);