import { combineReducers } from "redux";
import { initializedReducer } from "./applicationReducer";
import { bedrijvenReducer, bedrijfReducer, bedrijfStatussenReducer, bedrijvenArchiefReducer, bedrijfTabIndexReducer } from "./bedrijfReducer";
import { contactpersonenReducer, contactpersoonReducer, contactpersoonSoortenReducer, partnersReducer, partnerReducer } from "./contactpersoonReducer";
import { sponsordealsReducer, sponsordealReducer, sponsordeallistReducer } from "./sponsordealReducer";
import { sponsordealSoortenReducer, sponsordealSoortReducer } from "./sponsordealSoortReducer";
import { takenReducer, taakReducer } from "./taakReducer";
import { gebruikersReducer, verantwoordelijkenReducer, gebruikerReducer, rollenReducer, contactpersoonByEmailReducer, rolReducer } from './gebruikerReducer';
import { sponsordealSoortStatistiekenReducer, sponsordealTotaalBedragStatistiekenReducer, genemuidenStatistiekenReducer, factuurStatistiekenReducer, contactpersoonStatisticsReducer, csvViewNamesReducer } from "./statistiekenReducer";
import { facturenReducer, factuurReducer } from "./factuurReducer";
import { leadsReducer, leadReducer } from "./leadReducer";
import { notulenReducer, notuleReducer } from "./notuleReducer";
import { eventsReducer, eventReducer, attendeesReducer } from "./eventReducer";
import { actiesReducer, actieReducer } from "./actieReducer";
import { productenReducer, productReducer } from "./productReducer";
import { nieuwsberichtenReducer, nieuwsberichtReducer } from "./nieuwsberichtReducer";
import { agendaItemsReducer, agendaItemReducer } from "./agendaReducer";
import { settingsReducer } from "./settingsReducer";
import gridviewReducer from "./gridviewReducer";
import { loadingReducer } from "./loadingReducer";
import { reducer as formReducer } from 'redux-form';
import { reducer as oidcReducer } from 'redux-oidc';
import { socialSettingsReducer } from "./socialSettingsReducer";
import { customPageReducer, customPagesReducer } from "./customPagesReducer";

export const rootReducer = combineReducers({
    initialized: initializedReducer,
    bedrijven: bedrijvenReducer,
    bedrijvenarchief: bedrijvenArchiefReducer,
    company: bedrijfReducer,
    bedrijfTabIndex: bedrijfTabIndexReducer,
    bedrijfStatussen: bedrijfStatussenReducer,
    contactpersonen: contactpersonenReducer,
    contactpersoon: contactpersoonReducer,
    partners: partnersReducer,
    partner: partnerReducer,
    contactpersoonSoorten: contactpersoonSoortenReducer,
    sponsordeals: sponsordealsReducer,
    sponsordeallist: sponsordeallistReducer,
    sponsordeal: sponsordealReducer,
    sponsorDealTypes: sponsordealSoortenReducer,
    sponsorDealType: sponsordealSoortReducer,
    taken: takenReducer,
    taak: taakReducer,
    gebruikers: gebruikersReducer,
    rol: rolReducer,
    rollen: rollenReducer,
    verantwoordelijken: verantwoordelijkenReducer,
    gebruiker: gebruikerReducer,
    sponsordealSoortStatistieken: sponsordealSoortStatistiekenReducer,
    sponsordealTotaalBedragStatistieken: sponsordealTotaalBedragStatistiekenReducer,
    genemuidenStatistieken: genemuidenStatistiekenReducer,
    factuurStatistieken: factuurStatistiekenReducer,
    contactpersoonStatistics: contactpersoonStatisticsReducer,
    facturen: facturenReducer,
    factuur: factuurReducer,
    leads: leadsReducer,
    lead: leadReducer,
    notulen: notulenReducer,
    notule: notuleReducer,
    events: eventsReducer,
    event: eventReducer,
    attendees: attendeesReducer,
    acties: actiesReducer,
    actie: actieReducer,
    producten: productenReducer,
    product: productReducer,
    nieuwsberichten: nieuwsberichtenReducer,
    nieuwsbericht: nieuwsberichtReducer,
    agendaItems: agendaItemsReducer,
    agendaItem: agendaItemReducer,
    settings: settingsReducer,
    gridviews: gridviewReducer,
    isLoading: loadingReducer,
    form: formReducer, 
    oidc: oidcReducer,
    viewNames: csvViewNamesReducer,
    contactpersoonByEmail: contactpersoonByEmailReducer,
    socialSettings: socialSettingsReducer,
    customPages: customPagesReducer,
    customPage: customPageReducer
});