import { Action } from "redux";
import { Actie } from "../types/model";

export const GET_ACTIES = "GET_ACTIES";
export const GET_ACTIE = "GET_ACTIE";

export interface GetActiesAction extends Action<string> {
    acties: Actie[];
}

export interface GetActieAction extends Action<string> {
    actie: Actie;
}
