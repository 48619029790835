import React from "react";
import { Field } from "redux-form";
import required from "../../validators/required";
import email from "../../validators/email";
import { FormTextInput } from "../FormTextInput";
import { FormCheckbox } from "../FormCheckbox";
import { FormDropdown } from "../FormDropdown";
import FormDatePicker from "../FormDatePicker";
import postalcode from "../../validators/postalcode";

export interface OwnProps {
  onChangeBestaandBedrijf: (event: any) => void;
  verantwoordelijken: { label: string; value: string }[] | undefined;
  hasPartner: boolean;
}

export const AddSponsordealFields = (props: OwnProps) => {
  return (
    <>
      <h3 className="spacer-title top-title">Bedrijf</h3>
      <Field
        component={FormCheckbox}
        label="Bestaand bedrijf?"
        name="bestaandBedrijf"
        showSideBySide={true}
        onChange={props.onChangeBestaandBedrijf}
      />

      <Field
        component={FormTextInput}
        label="Bedrijf"
        name="company.name"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop">
        <div className="field-label">
          <label className="label bcwLabel is-pulled-left">Soort bedrijf</label>
        </div>

        <div className="control">
          <label className="radio">
            <Field
              name="company.type"
              component="input"
              type="radio"
              value="0"
            />
            B.V.
          </label>
          <label className="radio">
            <Field
              name="company.type"
              component="input"
              type="radio"
              value="1"
            />
            V.OF
          </label>
          <label className="radio">
            <Field
              name="company.type"
              component="input"
              type="radio"
              value="2"
            />
            Eenmanszaak
          </label>
        </div>
      </div>

      <Field
        component={FormTextInput}
        label="Emailadres"
        name="company.emailAddress"
        showSideBySide={true}
        required={true}
        validate={[required, email]}
      />

      <Field
        component={FormTextInput}
        label="Straat"
        name="company.address.street"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <Field
        component={FormTextInput}
        label="Huisnr"
        name="company.address.houseNumber"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <Field
        component={FormTextInput}
        label="Postcode"
        name="company.address.postalCode"
        showSideBySide={true}
        required={true}
        validate={[required, postalcode]}
      />

      <Field
        component={FormTextInput}
        label="Plaats"
        name="company.address.city"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <Field
        component={FormTextInput}
        label="Land"
        name="country"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <Field
        component={FormTextInput}
        label="Telefoon"
        name="company.phoneNumber"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <Field
        component={FormTextInput}
        label="Website"
        name="company.website"
        showSideBySide={true}
      />

      <Field
        component={FormDropdown}
        label="Verantwoordelijke"
        name="company.responsibleId"
        options={props.verantwoordelijken}
        showSideBySide={true}
        required={true}
        validate={required}
      />

      <h3 className="spacer-title">Hoofdcontactpersoon</h3>

      <Field
        component={FormTextInput}
        label="Voornaam"
        name="contactPerson.firstName"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <Field
        component={FormTextInput}
        label="Achternaam"
        name="contactPerson.surName"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <Field
        component={FormTextInput}
        label="Emailadres"
        name="contactPerson.emailAddress"
        showSideBySide={true}
        required={true}
        validate={[required, email]}
      />

      <Field
        component={FormDatePicker}
        label="Geboortedatum"
        name="contactPerson.birthDate"
        showSideBySide={true}
      />

      <Field
        component={FormTextInput}
        label="Mobiel"
        name="contactPerson.phoneNumber"
        showSideBySide={true}
        required={true}
        validate={[required]}
      />

      <Field
        component={FormCheckbox}
        label="Is tekenbevoegd"
        name="isQualified"
        showSideBySide={true}
      />

      <div className="grey-block">
        <h3 className="spacer-title">Partner</h3>

        <span className="checkboxWrapper">
          <Field
            component={FormCheckbox}
            label="Partner toevoegen"
            name="hasPartner"
            className="checkbox"
          />
        </span>

        {props.hasPartner && (
          <>
            <Field
              component={FormTextInput}
              label="Voornaam"
              name="partnerContactPerson.firstname"
              showSideBySide={true}
              required={true}
              validate={[required]}
            />

            <Field
              component={FormTextInput}
              label="Achternaam"
              name="partnerContactPerson.surname"
              showSideBySide={true}
              required={true}
              validate={[required]}
            />

            <Field
              component={FormDatePicker}
              label="Geboortedatum"
              name="partnerContactPerson.birthDate"
              showSideBySide={true}
              required={true}
              validate={[required]}
              dateFormat="dd-MM"
            />

            <Field
              component={FormTextInput}
              label="Straat"
              name="partnerContactPerson.address.street"
              showSideBySide={true}
              required={true}
              validate={[required]}
            />

            <Field
              component={FormTextInput}
              label="Huisnr"
              name="partnerContactPerson.address.houseNumber"
              showSideBySide={true}
              required={true}
              validate={[required]}
            />

            <Field
              component={FormTextInput}
              label="Postcode"
              name="partnerContactPerson.address.postalCode"
              showSideBySide={true}
              required={true}
              validate={[required, postalcode]}
            />

            <Field
              component={FormTextInput}
              label="Plaats"
              name="partnerContactPerson.address.city"
              showSideBySide={true}
              required={true}
              validate={[required]}
            />
          </>
        )}
      </div>
      <h3 className="spacer-title">Deal</h3>
    </>
  );
};
