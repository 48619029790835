import { ListEvent } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import React, { ChangeEvent, useEffect } from "react";
import { SponsordealStatus } from "../constants/enums";
import DashboardComponent from "../components/DashboardComponent/DashboardComponent";
import StatisticsApi from "../api/StatistiekenApi";
import history from "../components/history";
import matchSorter from "match-sorter";
import moment from "moment";
import { EventContext } from "../contexts/events/EventContext";
import { SponsorContext } from "../contexts/sponsor/SponsorContext";
import { StatisticsContext } from "../contexts/statistics/StatisticsContext";

export const DashboardContainer: React.FC = () => {
  const { getUpcomingEvents } = React.useContext(EventContext);
  const { sponsorDealTypes, sponsorDealList } =
    React.useContext(SponsorContext);
  const [eventsList, setEventsList] = React.useState<ListEvent[]>([]);
  const { viewNames } = React.useContext(StatisticsContext);

  useEffect(() => {
    getUpcomingEvents().then((events) => setEventsList(events));
  }, []);

  const onOverzichtRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
    StatisticsApi.generateViewCSV(rowInfo.original.naam);
  };

  const onEventRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
    history.push("/Events/" + rowInfo.original.id);
  };

  const onSponsordealRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
    history.push("/Sponsordeals/" + rowInfo.original.id);
  };

  const aflopendeContractenColumns: Array<Column> = [
    {
      Header: "Bedrijf",
      accessor: "companyName",
      headerStyle: {
        textAlign: "left",
      },
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
    {
      Header: "Soort",
      accessor: "sponsorDealTypeId",
      headerStyle: {
        textAlign: "left",
      },
      Cell: (props) => {
        var sponsordealSoort = sponsorDealTypes.find(
          (sponsordealSoort) => sponsordealSoort.id === props.value
        );
        return sponsordealSoort ? sponsordealSoort.name : "Onbekend";
      },
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
  ];

  const aankomendeEventsColumns: Array<Column> = [
    {
      Header: "Titel",
      accessor: "eventName",
      headerStyle: {
        textAlign: "left",
      },
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
    {
      Header: "Datum",
      accessor: "startDate",
      headerStyle: {
        textAlign: "left",
      },
      Cell: (props) =>
        props && props.value
          ? moment(props.value).format("DD-MM-YYYY HH:mm")
          : "",
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
  ];

  let overzichtColumns: Array<Column> = [
    {
      Header: "Namen",
      accessor: "naam",
      headerStyle: {
        textAlign: "left",
      },
      filterMethod: (filter: Filter, rows: any) =>
        matchSorter(rows, filter.value, {
          keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }],
        }),
      filterAll: true,
    },
  ];

  const getSeizoenEind = (): Date => {
    const date = new Date();
    const year = date.getFullYear();

    return date.getMonth() + 1 < 7
      ? new Date(year, 5, 30, 23, 59, 59)
      : new Date(year + 1, 5, 30, 23, 59, 59);
  };

  const sponsordealslistFilter = sponsorDealList.filter(
    (sponsordeal) =>
      moment(sponsordeal.to) <= moment(getSeizoenEind()) &&
      sponsordeal.status != SponsordealStatus.Opgezegd &&
      sponsordeal.isArchived == false
  );

  const overzichtList = viewNames.map((x) => {
    return { naam: x };
  });

  return (
    <DashboardComponent
      aflopendeContractenColumns={aflopendeContractenColumns}
      aankomendeEventsColumns={aankomendeEventsColumns}
      sponsordeallist={sponsordealslistFilter}
      events={eventsList}
      onSponsordealRowClick={onSponsordealRowClick}
      onEventRowClick={onEventRowClick}
      onOverzichtRowClick={onOverzichtRowClick}
      overzichtViewNames={overzichtList}
      overzichtColumns={overzichtColumns}
    />
  );
};
