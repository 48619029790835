import { Action } from "redux";
import { Bedrijf } from "../types/model";

export const GET_BEDRIJVEN = "GET_BEDRIJVEN";
export const GET_BEDRIJVEN_ARCHIEF = "GET_BEDRIJVEN_ARCHIEF";
export const GET_BEDRIJF = "GET_BEDRIJF"
export const SET_BEDRIJF_TAB_INDEX = "SET_BEDRIJF_TAB_INDEX";

export interface GetBedrijvenAction extends Action<string> {
    bedrijven: Bedrijf[];
}

export interface GetBedrijvenArchiefAction extends Action<string> {
    bedrijvenarchief: Bedrijf[];
}

export interface GetBedrijfAction extends Action<string> {
    company: Bedrijf;
}

export interface SetBedrijfTabIndexAction extends Action<string> {
    bedrijfTabIndex: number;
}
