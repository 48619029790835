import React, { useState } from "react";
import { ListFactuur } from "../../types/model";
import FactuurApi from "../../api/FactuurApi";

export const InvoicesContext = React.createContext({
  invoices: [] as ListFactuur[],
  getInvoices: (force = false) => {},
});

export const InvoicesContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [invoices, setInvoices] = useState<ListFactuur[]>([]);

  const getInvoices = async (force = false) => {
    if (force || invoices.length === 0) {
      const invoices = await FactuurApi.getFacturen();
      setInvoices(invoices);
    }
  };

  const contextValue = {
    invoices,
    getInvoices,
  };

  return (
    <InvoicesContext.Provider value={contextValue}>
      {children}
    </InvoicesContext.Provider>
  );
};
