import { Column, Filter, RowInfo } from "react-table";
import { EditorState, convertToRaw } from "draft-js";
import { Permissions, SponsorDealType, StandaardTegenprestatie } from "../types/model";
import React, { ChangeEvent } from "react";
import { getSponsordealSoort, getSponsordealSoorten } from "../actions/sponsordealSoortActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import { IsReadOnly } from "../helpers/PermissionHelper";
import { Modal } from "../components/Modal";
import { RootState } from '../types/state';
import SponsordealSoortApi from "../api/SponsordealSoortApi";
import SponsordealSoortComponent from "../components/SponsordealSoortComponent/SponsordealSoortComponent";
import SponsordealSoortenComponent from "../components/SponsordealSoortenComponent/SponsordealSoortenComponent";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import draftToHtml from 'draftjs-to-html';
import matchSorter from "match-sorter";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
    getSponsorDealTypes: () => any;
    getSponsorDealType: (id: number) => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    sponsorDealTypes: SponsorDealType[];
    sponsorDealType: SponsorDealType;
}

interface State {
    showCreateModal: boolean;
    showEditModal: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageSponsorDealKinds);
class SponsordealTypesContainer extends React.Component<DispatchProps & StateProps, State> {
    state: State = {
        showCreateModal: false,
        showEditModal: false
    }

    componentDidMount = () => {
        this.props.getSponsorDealTypes();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        this.props.getSponsorDealType(rowInfo.original.id);

        setTimeout(() => {
            this.setState({ showEditModal: true });
        }, 300)
    }

    createSponsorDealType = (formValues: any, deletedStandaardTegenprestaties: StandaardTegenprestatie[], editorState: EditorState) => {
        var sponsorDealType = formValues.sponsorDealType as SponsorDealType;

        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const html = draftToHtml(
            rawContentState
        );
        sponsorDealType.defaultProviderSpecifications = html;

        this.props.showLoading();
        SponsordealSoortApi.createSponsorDealType(sponsorDealType)
            .then(() => {
                this.props.getSponsorDealTypes();
                toast.success("Het opslaan is succesvol");
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => this.props.hideLoading());
        this.setState({ showCreateModal: false });
    }

    updateSponsorDealType = (formValues: any, deletedStandaardTegenprestaties: StandaardTegenprestatie[], editorState: EditorState) => {
        var sponsorDealType = formValues.sponsorDealType as SponsorDealType;
        sponsorDealType.deletedStandaardTegenprestaties = deletedStandaardTegenprestaties;

        sponsorDealType.sponsorDealTypeCompensations.filter(sponsorDealTypeCompensation => sponsorDealTypeCompensation.id !== "" && sponsorDealTypeCompensation.description !== "")

        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const html = draftToHtml(
            rawContentState
        );
        sponsorDealType.defaultProviderSpecifications = html;

        this.props.showLoading();
        SponsordealSoortApi.updateSponsorDealType(sponsorDealType)
            .then(() => {
                this.props.getSponsorDealTypes();
                toast.success("Het opslaan is succesvol");
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => this.props.hideLoading());
        this.setState({ showEditModal: false });
    }

    onCreateClick = () => {
        this.setState({ showCreateModal: true });
    }

    onCreateClose = () => {
        this.setState({ showCreateModal: false });
    }

    onEditClose = () => {
        this.setState({ showEditModal: false });
    }

    render() {
        const { sponsorDealTypes: sponsorDealTypes, sponsorDealType: sponsordealSoort } = this.props;

        let columns: Array<Column> = [
            {
                Header: "Soort",
                accessor: "name",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Standaard prijs",
                accessor: "defaultPrice",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => props.value ? "€ " + props.value.toLocaleString() : "",
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Gebruikt",
                accessor: "numUsed",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Tegenprestaties",
                accessor: "numCompensations",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]

        return (
            <>
                <SponsordealSoortenComponent columns={columns} onRowClick={this.onRowClick} openModal={this.onCreateClick} sponsorDealTypes={sponsorDealTypes} />
                {
                    this.state.showCreateModal
                        ? (<Modal
                            title="Nieuwe sponsordealsoort"
                            content={
                                <SponsordealSoortComponent isModal={true} onSubmit={this.createSponsorDealType} />
                            }
                            onSubmit={() => this.props.submitForm("SponsordealSoortForm")}
                            onClose={this.onCreateClose} />
                        )
                        : (null)
                }
                {
                    this.state.showEditModal
                        ? (<Modal
                            title="Wijzigen sponsordealsoort"
                            content={
                                <SponsordealSoortComponent isModal={true} isEdit={true} sponsordealSoort={sponsordealSoort} onSubmit={this.updateSponsorDealType} />
                            }
                            readOnly={isReadOnly}
                            onSubmit={() => this.props.submitForm("SponsordealSoortForm")}
                            onClose={this.onEditClose} />
                        )
                        : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    sponsorDealTypes: state.sponsorDealTypes,
    sponsorDealType: state.sponsorDealType
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getSponsorDealTypes: () => dispatch(getSponsordealSoorten()),
    getSponsorDealType: (id: number) => dispatch(getSponsordealSoort(id)),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(SponsordealTypesContainer);
