import { Action } from "redux";
import { Contactpersoon } from "../types/model";

export const GET_CONTACTPERSONEN = "GET_CONTACTPERSONEN";
export const GET_CONTACTPERSOON = "GET_CONTACTPERSOON"
export const GET_PARTNERS = "GET_PARTNERS";
export const GET_PARTNER = "GET_PARTNER"

export interface GetContactpersonenAction extends Action<string> {
    contactpersonen: Contactpersoon[];
}

export interface GetContactpersoonAction extends Action<string> {
    contactpersoon: Contactpersoon;
}

export interface GetPartnersAction extends Action<string> {
    partners: Contactpersoon[];
}

export interface GetPartnerAction extends Action<string> {
    partner: Contactpersoon;
}
