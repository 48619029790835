export const ShowPage = (allowedPermissions: number[]) => {
  var permissions = ParseLocalStoragePermissionsToArray();

  if (!permissions) return false;

  var includesPermission = allowedPermissions.map((e) => {
    if (permissions.includes(e)) return true;
  });
  return includesPermission.includes(true);
};

export const IsReadOnly = (managePermission: number) => {
  var permissions = ParseLocalStoragePermissionsToArray();
  if (!permissions) return false;

  return !permissions.includes(managePermission);
};

export const ParseLocalStoragePermissionsToArray = () => {
  var permissions = localStorage.getItem("permissions");

  if (!permissions) return null;
  return JSON.parse(permissions);
};
