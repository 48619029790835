import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Contactpersoon } from "../../types/model";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openModal: () => void;
  columns: Array<Column>;
  partners: Contactpersoon[];
}

export default class PartnersComponent extends React.Component<OwnProps> {
  render() {
    const { onRowClick, openModal, columns, partners } = this.props;

    const userRol = localStorage.getItem("userRol");

    return (
      <div className="content">
        <div className="contentPanel">
          <div className="columns contentPanel">
            <div className="column is-full">
              <div className="buttons is-pulled-right">
                {/* {userRol 
                                    && (userRol == UserRol.Hoofdcontactpersoon && partners.length == 0
                                    || UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]))
                                    && <button className="button is-success add-button" onClick={openModal}>
                                            Toevoegen
                                        </button>
                                } */}
              </div>
            </div>
          </div>
          <Gridview id="PartnersList" onRowClick={onRowClick} columns={columns} data={partners} />
        </div>
      </div>
    );
  }
}
