import React from "react";
import moment, { Moment } from "moment";
import TimePicker from "rc-time-picker";
import classnames from "classnames";
import { WrappedFieldProps } from "redux-form";
import "rc-time-picker/assets/index.css";

interface Props {
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  showSideBySide?: boolean;
  required?: boolean;
}

export const BcwTimePicker = (props: WrappedFieldProps & Props) => {
  const {
    input,
    label,
    required,
    meta: { touched, error },
    placeholder,
    showSideBySide,
    readOnly,
  } = props;

  function onChange(value: Moment) {
    input.onChange(value != null ? value.format("HH:mm") : "");
  }

  let style = {};

  let wrapperClass = "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

  if (showSideBySide) wrapperClass = classnames(wrapperClass, "is-half-desktop");
  else wrapperClass = classnames(wrapperClass, "is-full-desktop");

  //style = { paddingTop: '.5rem', paddingRight: '2rem' };

  const rounded = Math.round(moment().minute() / 15) * 15;
  let initialTime = moment();
  initialTime.minutes(rounded);

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label">
          <label className="label bcwLabel is-pulled-left timepickerLabel" htmlFor={input.name} style={style}>
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </label>
        </div>
      )}
      <div className="field-body">
        <TimePicker
          showSecond={false}
          onChange={onChange}
          minuteStep={15}
          allowEmpty={true}
          value={input.value ? moment(input.value, "HH:mm") : initialTime}
          disabled={readOnly}
        />
        {touched && error && <p className="help is-danger">{error}</p>}
      </div>
    </div>
  );
};
