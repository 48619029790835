import ApiClient from './ApiClient';

import { Bedrijf } from "../types/model";
import moment from 'moment';

const endpoint = 'company/portal';

export default class BedrijfApi {
    public static async getBedrijven(): Promise<Bedrijf[]> {
        var bedrijven = await ApiClient.doHttpRequest<Bedrijf[]>('get', `api/${endpoint}`);
        return bedrijven;
    }

    public static async getBedrijvenArchief(): Promise<Bedrijf[]> {
        var bedrijven = await ApiClient.doHttpRequest<Bedrijf[]>('get', `api/${endpoint}?includeArchived=true`);
        return bedrijven;
    }

    public static async getBedrijf(id: number): Promise<Bedrijf> {
        var bedrijf = await ApiClient.doHttpRequest<Bedrijf>('get', `api/${endpoint}/${id}`);

        return bedrijf;
    }

    public static async getBedrijfForHoofdcontactpersoon(): Promise<Bedrijf> {
        var bedrijf = await ApiClient.doHttpRequest<Bedrijf>('get', `api/${endpoint}/GetBedrijfForHoofdcontactpersoon`);

        return bedrijf;
    }

    public static async createBedrijf(bedrijf: Bedrijf): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `api/company`, bedrijf);
    }

    public static async updateBedrijf(bedrijf: Bedrijf): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `api/${endpoint}/`+bedrijf.id, bedrijf);
    }

    public static async updateBedrijfForHoofdcontactpersoon(bedrijf: Bedrijf): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `api/${endpoint}/UpdateBedrijfForHoofdcontactpersoon`, bedrijf);
    }

    public static async UploadBedrijfDocumenten(document: Document): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `api/${endpoint}/UploadBedrijfDocumenten`, document)
    }

    public static async deleteBedrijfLogoBijlage(id: number): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `api/${endpoint}/BedrijfLogoBijlage/${id}`);
    }

    public static async deleteBedrijfAdvertentieBijlage(id: number): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `api/${endpoint}/BedrijfAdvertentieBijlage/${id}`);
    }

    public static async deleteBedrijfReclamebordBijlage(id: number): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `api/${endpoint}/BedrijfReclamebordBijlage/${id}`);
    }

    public static async deleteBedrijf(id: number): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `api/${endpoint}/{${id}}`);
    }

    public static async archiveBedrijf(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('patch', `api/company/{${id}}/archive`);
    }

    public static async archiveSelectedBedrijven(ids: string[]): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `api/company/archive`, ids);
    }

    public static async exportSelectedBedrijven(ids: string[], archived: boolean): Promise<any> {
        var query = new URLSearchParams();
        if (archived) query.append("includeArchived", "true");
        var queryString = query.toString();
        await ApiClient.doFileRequest<Blob>('post', `api/company/export${queryString.length > 0 ? "?" + queryString : ""}`, ids, false, true, { responseType: 'blob' })
            .then(response => {
                var blobUrl = window.URL.createObjectURL(response.data);
                var tempLink = document.createElement('a');
                tempLink.href = blobUrl;
                tempLink.setAttribute('download', `bedrijven_${moment().format('YYYY-MM-DDTHH-mm-ss')}.xlsx`);
                tempLink.click();
            });
    }
}
