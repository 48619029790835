
const MESSAGE = "Wachtwoorden zijn niet gelijk";

export default (value: string, allValues: any, props: any = null, name: string) => {
    if (allValues.gebruiker) {
        if (name === "gebruiker.wachtwoord") {
            if (allValues.gebruiker.wachtwoord2)
                return (value === allValues.gebruiker.wachtwoord2 ? undefined : MESSAGE)
        };
        if (name === "gebruiker.wachtwoord2") {
            if (allValues.gebruiker.wachtwoord)
                return (value === allValues.gebruiker.wachtwoord ? undefined : MESSAGE);
        }
    }
    return undefined;
}