import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Actie } from "../../types/model";
import UserRolHelper from "../../helpers/UserRolHelper";
import { UserRol } from "../../constants/enums";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openArchief: () => void;
  openModal: () => void;
  // archiveSelectedactions: () => void;
  // exportSelectedactions: () => void;
  // sendActieEmailInvitations: () => void;
  hideExport: boolean;
  columns: Array<Column>;
  acties: Actie[];
  archiefButtonText: string;
  archiverenButtonText: string;
  bedrijfId?: number;
}

export default class ActionsComponent extends React.Component<OwnProps> {
  render() {
    const { onRowClick, openModal, openArchief, hideExport, archiefButtonText, archiverenButtonText, columns, acties } = this.props;

    const userRol = localStorage.getItem("userRol");

    return (
      <>
        <div className="content">
          <div className="contentPanel">
            <div className="columns contentPanel">
              <div className="column is-full">
                {userRol && UserRolHelper(userRol, [UserRol.Admin, UserRol.Beheerder, UserRol.Bestuurslid]) && (
                  <>
                    {/* <div className="buttons is-pulled-left">
                                            <button className="button is-success archive-button" onClick={openArchief}>
                                                {archiefButtonText}
                                            </button>
                                        </div> */}
                    <div className="buttons is-pulled-right facturenButtons">
                      {/* <button className="button is-success" onClick={sendActieEmailInvitations}>
                                                Definitief maken
                                            </button> */}
                      {/* {hideExport && <button className="button is-success" onClick={exportSelectedactions}>
                                                Export
                                            </button>} */}
                      {/* <button className="button is-success" onClick={archiveSelectedactions}>
                                                {archiverenButtonText}
                                            </button> */}
                      <button className="button is-success add-button" onClick={openModal}>
                        Toevoegen
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <Gridview id="actionsList" onRowClick={onRowClick} columns={columns} data={acties} />
          </div>
        </div>
      </>
    );
  }
}
