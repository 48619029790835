import { Bedrijf, Notation, Person, Verantwoordelijke } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import React, { ChangeEvent, OptionHTMLAttributes } from "react";
import { getNotation, getNotations } from "../actions/notuleActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import { EditorState } from "draft-js"
import { IsReadOnly } from '../helpers/PermissionHelper';
import { Modal } from "../components/Modal";
import NotuleApi from "../api/NotuleApi";
import NotuleComponent from "../components/NotuleComponent/NotuleComponent";
import NotulenComponent from "../components/NotulenComponent/NotulenComponent";
import { Permissions } from "../types/model";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { getBedrijven } from "../actions/bedrijfActions";
import { getVerantwoordelijken } from "../actions/gebruikerActions";
import matchSorter from "match-sorter";
import moment from "moment";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
    getNotations: () => any;
    getNotation: (id: string) => any;
    getBedrijven: () => any;
    getVerantwoordelijken: () => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    notulen: Notation[];
    notule: Notation;
    bedrijven: Bedrijf[];
    verantwoordelijken: Verantwoordelijke[];
}

type Props = StateProps & DispatchProps

interface State {
    showCreateModal: boolean;
    showEditModal: boolean;
    selectedNotulen: string[];
    archief: boolean;
    currentNotationId?: string;
}
const isReadOnly = IsReadOnly(Permissions.ManageRecords);
class NotulenContainer extends React.Component<Props, State> {
    state: State = {
        showCreateModal: false,
        showEditModal: false,
        selectedNotulen: [],
        archief: false,
        currentNotationId: undefined
    }

    componentDidMount() {
        this.props.getNotations();
        this.props.getBedrijven();
        this.props.getVerantwoordelijken();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        this.props.getNotation(rowInfo.original.id);
        this.setState({
            showEditModal: true,
            currentNotationId: rowInfo.original.id
        });
    }

    openCreateNotuleModal = () => {
        this.setState({ showCreateModal: true });
    }

    createNotule = (formValues: Notation, agendaEditorState: EditorState, notulenEditorState: EditorState) => {
        var notule = formValues as Notation;

        const agendaRawContentState = convertToRaw(agendaEditorState.getCurrentContent());
        const agendaHtml = draftToHtml(
            agendaRawContentState
        );
        notule.schedule = agendaHtml;

        const notulenRawContentState = convertToRaw(notulenEditorState.getCurrentContent());
        const notulenHtml = draftToHtml(
            notulenRawContentState
        );
        notule.notations = notulenHtml;

        NotuleApi.createNotule(notule)
            .then(() => {
                this.props.getNotations();
                toast.success("Het opslaan is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.onClose();
            });
    }

    openArchief = () => {
        this.setState({ archief: this.state.archief ? false : true });
    }

    onClose = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false
        });
    }

    updateNotule = (formValues: Notation, agendaEditorState: EditorState, notulenEditorState: EditorState) => {
        var notation = formValues as Notation;

        const agendaRawContentState = convertToRaw(agendaEditorState.getCurrentContent());
        const agendaHtml = draftToHtml(
            agendaRawContentState
        );
        notation.schedule = agendaHtml;

        const notulenRawContentState = convertToRaw(notulenEditorState.getCurrentContent());
        const notulenHtml = draftToHtml(
            notulenRawContentState
        );
        notation.notations = notulenHtml;

        NotuleApi.updateNotation(notation)
            .then(() => {
                this.props.getNotations();
                toast.success("Het opslaan is succesvol")
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.onClose();
            });
    }

    exportSelectedNotulen = () => {
        var notation = this.props.notulen.find(notule => notule.id == this.state.selectedNotulen[0])

        if (notation) {
            var attending = notation.attending.replace(new RegExp("\n", "g"), " <br/> ");
            var markdown = "<h1 align='center'>" + notation.title + "</h1>";
            markdown = markdown + "<p align='center'><strong>" + moment(notation.date).format('DD-MM-YYYY') + " </strong></p><br/><br/>";
            markdown = markdown + "<strong>Aanwezig: </strong><br/>"
            markdown = markdown + attending + " <br/><br/>";
            markdown = markdown + "<hr><strong>Agenda: </strong><br/> \n\n"
            markdown = markdown + notation.schedule;
            markdown = markdown + "<hr><strong>Notulen: </strong><br/> \n\n"
            markdown = markdown + notation.notations;

            var base64 = btoa(markdown);
            window.open("/PrintMarkdown?md=" + encodeURIComponent(base64));
        }
    }

    archiveSelectedNotulen = () => {
        if (this.state.selectedNotulen.length > 0) {
            let successMessage = this.state.archief ? "Het dearchiveren is succesvol" : "Het archiveren is succesvol";

            this.props.showLoading();
            NotuleApi.archiveSelectedNotulen(this.state.selectedNotulen)
                .then(() => {
                    this.props.getNotations();
                    this.setState({ selectedNotulen: [] });
                    toast.success(successMessage)
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => {
                    this.props.hideLoading();
                });
        }
    }

    selectNotule = (event: any) => {
        const notuleId = event.target.value;

        if (event.target.checked) {
            if (this.state.selectedNotulen.find(item => item === notuleId) == null) {
                const selectedNotulen = [...this.state.selectedNotulen, notuleId];
                this.setState({ selectedNotulen: selectedNotulen });
            }
        }
        else {
            var index = this.state.selectedNotulen.indexOf(notuleId);
            const selectedNotulen = [
                ...this.state.selectedNotulen.slice(0, index),
                ...this.state.selectedNotulen.slice(index + 1)
            ]
            this.setState({ selectedNotulen: selectedNotulen });
        }
    }

    selectAlleNotulen = (event: any) => {
        if (event.target.checked) {
            const selectedNotulen = this.state.selectedNotulen;
            this.props.notulen.forEach(notule => {
                if (this.state.selectedNotulen.find(item => item === notule.id) == null) {
                    selectedNotulen.push(notule.id);
                }
            });
            this.setState({ selectedNotulen: selectedNotulen });
        }
        else {
            this.setState({ selectedNotulen: [] });
        }
    }

    onDeleteAttachment = (id: string) => {
        NotuleApi.deleteAttachment(id)
            .then(() => {
                if (this.state.currentNotationId) {
                    toast.success("Het verwijderen is succesvol");
                    this.props.getNotation(this.state.currentNotationId);
                }
            });
    }

    render() {
        const { notulen, notule, bedrijven, verantwoordelijken } = this.props;

        let columns: Array<Column> = [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedNotulen.some(item => item.toString() === original.id.toString())}
                            value={original.id}
                            onChange={this.selectNotule}
                        />
                    );
                },
                Header: x => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedNotulen.length === this.props.notulen.length}
                            onChange={this.selectAlleNotulen}
                        />
                    );
                },
                sortable: false,
                filterable: false,
                width: 55
            },
            {
                Header: "Titel",
                accessor: "title",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Datum vergadering",
                accessor: "date",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => props && props.value ? moment(props.value).format("DD-MM-YYYY") : "",
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]

        let notulenList = notulen;

        if (this.state.archief)
            notulenList = notulenList.filter(n => n.isArchived == true);
        else
            notulenList = notulenList.filter(n => n.isArchived == false);

        return (
            <>
                <NotulenComponent
                    onRowClick={this.onRowClick}
                    openModal={this.openCreateNotuleModal}
                    openArchief={this.openArchief}
                    archiefButtonText={this.state.archief ? "Terug" : "Archief"}
                    archiverenButtonText={this.state.archief ? "Dearchiveren" : "Archiveren"}
                    archiveSelectedNotulen={this.archiveSelectedNotulen}
                    exportSelectedNotulen={this.exportSelectedNotulen}
                    hideExport={this.state.selectedNotulen.length <= 1}
                    columns={columns}
                    notation={notulenList} />

                {this.state.showCreateModal
                    ? (<Modal
                        title="Nieuwe notulen"
                        content={
                            <NotuleComponent
                                onSubmit={this.createNotule}
                                bedrijven={bedrijven}
                                verantwoordelijken={verantwoordelijken}
                                onDeleteBijlage={this.onDeleteAttachment} />
                        }
                        onSubmit={() => this.props.submitForm("NotuleForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
                {this.state.showEditModal
                    ? (<Modal
                        title="Aanpassen notulen"
                        readOnly={isReadOnly}
                        content={
                            <NotuleComponent
                                notule={notule}
                                bedrijven={bedrijven}
                                verantwoordelijken={verantwoordelijken}
                                onSubmit={this.updateNotule}
                                onDeleteBijlage={this.onDeleteAttachment}
                                isEdit={true} />
                        }
                        onSubmit={() => this.props.submitForm("NotuleForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    notulen: state.notulen,
    notule: state.notule,
    bedrijven: state.bedrijven,
    verantwoordelijken: state.verantwoordelijken
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getNotations: () => dispatch(getNotations()),
    getNotation: (id: string) => dispatch(getNotation(id)),
    getBedrijven: () => dispatch(getBedrijven()),
    getVerantwoordelijken: () => dispatch(getVerantwoordelijken()),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(NotulenContainer);
