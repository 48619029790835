import "react-datepicker/dist/react-datepicker.css";

import ReactDatePicker, { registerLocale } from "react-datepicker";

import React, { useState } from "react";
import classnames from "classnames";
import moment from "moment";
import nl from "date-fns/locale/nl";
import { InputErrorMessage } from "../ErrorMessage";

registerLocale("nl", nl);

interface Props {
  name: string;
  value: Date;
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  type?: string;
  showSideBySide?: boolean;
  required?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  showTimeFormat?: boolean;
  onChange: (newDate: Date | null) => void;
}

export const DatePicker: React.FC<Props> = ({
  name,
  value,
  onChange,
  label,
  required,
  showSideBySide,
  dateFormat,
  timeFormat,
  showTimeFormat,
  readOnly,
}) => {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState("");

  var format = dateFormat || "dd-MM-yyyy";
  const timeformat = timeFormat || "HH:mm";

  if (showTimeFormat) format += " " + timeformat;

  let style = {};

  let wrapperClass =
    "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

  if (showSideBySide)
    wrapperClass = classnames(wrapperClass, "is-half-desktop");
  else wrapperClass = classnames(wrapperClass, "is-full-desktop");

  const onBlur = () => {
    setTouched(true);
    setError(!value && required ? "Verplicht" : "");
  };

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label">
          <label
            className="label bcwLabel is-pulled-left"
            htmlFor={name}
            style={style}
          >
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </label>
        </div>
      )}
      <div className="field-body">
        <ReactDatePicker
          selected={value ? new Date(value) : null}
          name={name}
          className={classnames("input", touched && error && "is-danger")}
          dateFormat={format}
          dateFormatCalendar={format}
          showTimeSelect={showTimeFormat}
          timeFormat={timeformat}
          timeCaption="Tijdstip"
          timeIntervals={15}
          onChange={onChange}
          onBlur={onBlur}
          strictParsing={true}
          disabled={readOnly}
          todayButton={"Vandaag"}
        />
        <InputErrorMessage error={error} touched={touched} />
      </div>
    </div>
  );
};
