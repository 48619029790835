import { Checkbox, Dropdown, TextInput } from "../../../../components/Input";
import { VATRates } from "../../../../constants/BtwTarief";
import { FactuurRegel } from "../../../../types/model";
import decimal from "../../../../validators/decimal";
import integer from "../../../../validators/integer";
import required from "../../../../validators/required";

type Props = {
  index: number;
  invoiceLine: FactuurRegel;
  setInvoiceLine: (invoiceLine: FactuurRegel) => void;
  isReadOnly: boolean;
};

const vatRateOptions = [
  { value: VATRates.High.toString(), label: "Hoog tarief (21%)" },
  { value: VATRates.Low.toString(), label: "Laag tarief (9%)" },
  { value: VATRates.Zero.toString(), label: "Nul tarief (0%)" },
];

export const CreateInvoiceLine: React.FC<Props> = ({
  index,
  invoiceLine,
  setInvoiceLine,
  isReadOnly,
}) => {
  if (!invoiceLine || invoiceLine.isDeleted) return <></>;

  return (
    <div key={index} className="facturenRow">
      <TextInput
        name={`${index}.amount`}
        label="Aantal"
        showSideBySide={true}
        required={true}
        readOnly={isReadOnly}
        validators={[integer, required]}
        value={invoiceLine.amount}
        setValue={(value: string) =>
          setInvoiceLine({ ...invoiceLine, amount: value })
        }
      />
      <TextInput
        label="Omschrijving"
        name={`${index}.description`}
        readOnly={isReadOnly}
        showSideBySide={true}
        value={invoiceLine.description}
        setValue={(value: string) =>
          setInvoiceLine({ ...invoiceLine, description: value })
        }
      />

      <Dropdown
        label="BTW-tarief"
        name={`${index}.vatRate`}
        readOnly={isReadOnly}
        showSideBySide={true}
        options={vatRateOptions}
        clearable={false}
        required={false}
        value={invoiceLine.vatRate ? invoiceLine.vatRate.toString() : ""}
        onValueChanged={(value: string) =>
          setInvoiceLine({ ...invoiceLine, vatRate: Number.parseFloat(value) })
        }
      />
      <div className="column is-mobile">
        <div className="columns">
          <TextInput
            label="Bedrag (€)"
            name={`${index}.price`}
            showSideBySide={true}
            readOnly={isReadOnly}
            validators={[decimal]}
            value={invoiceLine.price}
            setValue={(value: string) =>
              setInvoiceLine({ ...invoiceLine, price: value })
            }
          />
          {/* <Checkbox
            displayInline={true}
            label="Bedrag is Inclusief BTW"
            name={`${index}.isIncludingVat`}
            showSideBySide={true}
            readOnly={isReadOnly}
            onValueChanged={(value: string) =>
              setInvoiceLine({ ...invoiceLine, isIncludingVat: !!value })
            }
            value={invoiceLine.isIncludingVat}
          /> */}
        </div>
      </div>
      {!isReadOnly && (
        <a
          className="button is-success delete-btn"
          onClick={() => setInvoiceLine({ ...invoiceLine, isDeleted: true })}
        >
          Delete
        </a>
      )}
    </div>
  );
};
