import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import {
  Notation,
  NotuleTaak,
  Bedrijf,
  Person,
  Verantwoordelijke,
  Actie,
  Taak,
  Permissions,
} from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import { TextArea } from "../TextArea";
import FormDatePicker from "../FormDatePicker";
import TextEditor from "../TextEditor";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import FileUpload from "../FileUpload";
import {
  DocumentListHelper,
  AttachmentDocumentListHelper,
} from "../../helpers/DocumentListHelper";
import { FormDropdown } from "../FormDropdown";
import { SponsordealAttachmentEnum } from "../../constants/enums";
import { IsReadOnly } from "../../helpers/PermissionHelper";
import { TaakSoorten } from "../TaakComponent/TaakComponent";

export interface OwnProps {
  notule?: Notation;
  bedrijven: Bedrijf[];
  verantwoordelijken: Verantwoordelijke[];
  formValues: Notation;
  onSubmit: (
    formValues: Notation,
    agendaEditorState: EditorState,
    notulenEditorState: EditorState
  ) => void;
  onDeleteBijlage: (id: string) => void;
  isEdit?: boolean;
}

interface State {
  agendaEditorState: EditorState;
  notulenEditorState: EditorState;
}

const isReadOnly = IsReadOnly(Permissions.ManageRecords);
class NotuleComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<{} & any, OwnProps>) {
    super(props);
    this.state = {
      notulenEditorState: EditorState.createEmpty(),
      agendaEditorState: EditorState.createEmpty(),
    };
  }

  componentDidUpdate = (prevProps: any) => {
    if (
      this.props.initialValues.tasks !== prevProps.initialValues.tasks &&
      this.props.initialValues.tasks &&
      this.props.initialValues.tasks[0] &&
      this.props.initialValues.tasks[0].id != 0 &&
      this.props.isEdit
    )
      this.props.initialize(this.props.initialValues);

    if (prevProps != this.props && this.props.formValues && this.props.isEdit) {
      //agenda
      if (this.props.formValues.schedule != undefined) {
        const blocksFromHtml = htmlToDraft(this.props.formValues.schedule);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const agendaEditorState = EditorState.createWithContent(contentState);
        this.setState({ agendaEditorState });
      }
      //notulen
      if (this.props.formValues.notations != undefined) {
        const blocksFromHtml = htmlToDraft(this.props.formValues.notations);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const notulenEditorState = EditorState.createWithContent(contentState);
        this.setState({ notulenEditorState });
      }
    }
  };

  onAgendaEditorStateChange = (agendaEditorState: EditorState) => {
    this.setState({ agendaEditorState });
  };

  onNotulenEditorStateChange = (notulenEditorState: EditorState) => {
    this.setState({ notulenEditorState });
  };

  onChangeUpload = (fileName: string, file: string) => {
    //var existsInNotuleBijlagenUrls = this.props.formValues.notuleBijlagenUrls.find(notuleBijlageUrl => decodeURI(notuleBijlageUrl.url.split("/").pop()!) === fileName);
    if (!this.props.formValues.attachments) {
      this.props.formValues.attachments = [];
    }
    var existsInNotuleBijlagen = this.props.formValues.attachments.find(
      (bijlage) => bijlage.fileName === fileName
    );

    if (!existsInNotuleBijlagen)
      this.props.formValues.attachments.push({
        id: "",
        fileName: fileName,
        content: file,
        attachmentType: SponsordealAttachmentEnum.Signature,
        fileUrl: "",
      });
  };

  createNotuleTaak = (index: number): JSX.Element => {
    let selectBedrijven = this.props.bedrijven.map(function (bedrijf) {
      return { label: bedrijf.name, value: bedrijf.id.toString() };
    });

    let selectVerantwoordelijken = this.props.verantwoordelijken.map(function (
      verantwoordelijke
    ) {
      return {
        label: verantwoordelijke.fullName,
        value: verantwoordelijke.id.toString(),
      };
    });

    return (
      <div className="notulenRow" key={index}>
        <Field
          component={FormDropdown}
          label="Soort"
          name={`tasks[${index}].taskType`}
          showSideBySide={true}
          options={TaakSoorten}
        />

        <Field
          component={FormDropdown}
          label="Bedrijf"
          name={`tasks[${index}].companyId`}
          showSideBySide={true}
          options={selectBedrijven}
          clearable={true}
        />

        <Field
          component={FormDropdown}
          label="Verantwoordelijke"
          name={`tasks[${index}].responsibleId`}
          showSideBySide={true}
          options={selectVerantwoordelijken}
        />

        <Field
          component={FormTextInput}
          label="Omschrijving"
          name={`tasks[${index}].description`}
          showSideBySide={true}
        />

        <Field
          component={FormDatePicker}
          label="Vervaldatum"
          name={`tasks[${index}].expirationDate`}
          showSideBySide={true}
        />

        <a
          className="button is-success delete-btn"
          onClick={() => this.deleteNotationTask(index)}
        >
          Delete
        </a>
      </div>
    );
  };

  addNotationTask = () => {
    var tasks = this.props.formValues.tasks;
    tasks.push({} as Taak);
    this.props.initialize(this.props.formValues);
  };

  deleteNotationTask = (index: number) => {
    if (this.props.formValues.tasks.length > 1) {
      this.props.formValues.tasks[index].isArchived = true;
      this.props.initialize(this.props.formValues);
    } else {
      var eventLead = this.props.formValues.tasks[0];
      if (eventLead) {
        eventLead.isArchived = true;
        this.props.initialize(this.props.formValues);
      }
    }
  };

  render() {
    const {
      handleSubmit,
      notule,
      formValues,
      isEdit = false,
      onSubmit,
      onDeleteBijlage,
    } = this.props;

    let tasks: JSX.Element[] = [];
    let attachmentUrls: any = "";
    attachmentUrls =
      notule &&
      notule.attachments &&
      notule.attachments.map((attachment) =>
        AttachmentDocumentListHelper({
          attachment: attachment,
          showDelete: !isReadOnly,
          onDeleteAttachment: onDeleteBijlage,
        })
      );

    if (formValues && formValues.tasks && formValues.tasks.length > 0) {
      formValues.tasks.forEach((notuleTaak: Taak, index: number) => {
        if (!notuleTaak.isArchived) {
          const element = this.createNotuleTaak(index);
          tasks.push(element);
        }
      });
    } else {
      const element = this.createNotuleTaak(0);
      tasks.push(element);
    }

    return (
      <Form
        className="singleCompany"
        onSubmit={handleSubmit((values: Notation) =>
          onSubmit(
            values,
            this.state.agendaEditorState,
            this.state.notulenEditorState
          )
        )}
      >
        <div className="content notulen">
          <div className="columns contentPanel is-multiline is-mobile">
            <Field
              component={FormTextInput}
              label="Titel"
              name="title"
              showSideBySide={true}
              required={true}
              readOnly={isReadOnly}
              validate={required}
            />

            <Field
              component={FormDatePicker}
              label="Datum vergadering"
              name="date"
              readOnly={isReadOnly}
              showSideBySide={true}
              required={true}
              validate={required}
            />

            {isEdit && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet custom-uploaded-files">
                <div className="field-label">
                  <label className="label bcwLabel">Bijlagen</label>
                </div>
                <div className="custom-docs">{attachmentUrls}</div>
              </div>
            )}
            {!isReadOnly && (
              <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet custom-uploaded-files">
                <div className="field-label">
                  <label className="label bcwLabel">Upload bijlagen</label>
                </div>
                <Field
                  component={FileUpload}
                  label="Bijlagen"
                  name="notuleBijlagen"
                  onChangeUpload={this.onChangeUpload}
                />
              </div>
            )}
          </div>
        </div>
      </Form>
    );
  }
}

const NotuleReduxForm = reduxForm<{}, OwnProps>({
  form: "NotuleForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(NotuleComponent);

export default connect((state: any, props: any) => ({
  initialValues: {
    id: props.isEdit ? state.notule.id : "",
    title: props.isEdit ? state.notule.title : "",
    date: props.isEdit ? state.notule.date : new Date().toDateString(),
    schedule: props.isEdit ? state.notule.schedule : "",
    notations: props.isEdit ? state.notule.notations : "",
    attending: props.isEdit ? state.notule.attending : "",
    notationTasks: props.isEdit ? state.notule.notationTasks : [],
    tasks: props.isEdit ? state.notule.tasks : [],
  },
  formValues: getFormValues("NotuleForm")(state) as Notation,
}))(NotuleReduxForm);
