import { Bedrijf, Permissions, Person, Verantwoordelijke } from "../types/model";
import { Column, Filter, RowInfo } from "react-table";
import React, { ChangeEvent } from "react";
import { getBedrijven, getBedrijvenArchief } from "../actions/bedrijfActions";
import { hideLoading, showLoading } from "../actions/loadingActions";

import { AnyAction } from "redux";
import BedrijfApi from "../api/BedrijfApi";
import BedrijfComponent from "../components/BedrijfComponent/BedrijfComponent";
import { BedrijfStatus } from "../constants/enums";
import BedrijvenComponent from "../components/BedrijvenComponent/BedrijvenComponent";
import { Guid } from "guid-typescript";
import { IsReadOnly } from '../helpers/PermissionHelper';
import { Modal } from "../components/Modal";
import { RootState } from '../types/state';
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getVerantwoordelijken } from "../actions/gebruikerActions";
import history from '../components/history';
import matchSorter from "match-sorter";
import { submit } from "redux-form";
import { toast } from "react-toastify";

interface DispatchProps {
    getBedrijven: () => any;
    getBedrijvenArchief: () => any;
    getVerantwoordelijken: () => any;
    submitForm: (form: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

interface StateProps {
    bedrijven: Bedrijf[];
    bedrijvenarchief: Bedrijf[];
    verantwoordelijken: Verantwoordelijke[];
    bedrijfStatussen: [{ label: string; value: string }];
}

interface State {
    showModal: boolean;
    archief: boolean;
    selectedCompanies: string[];
}

var isReadOnly = IsReadOnly(Permissions.ManageCompanies);
class BedrijvenContainer extends React.Component<DispatchProps & StateProps, State> {
    state: State = {
        showModal: false,
        archief: false,
        selectedCompanies: []
    }

    componentDidMount() {
        this.props.getBedrijven();
        this.props.getBedrijvenArchief();
        this.props.getVerantwoordelijken();
    }

    onRowClick(event: ChangeEvent, rowInfo: RowInfo) {
        history.push("/Bedrijven/" + rowInfo.original.id + (rowInfo.original.isArchived ? "?isArchived=true" : ""));
    }

    openModal = () => {
        this.setState({ showModal: true });
    }

    openArchief = () => {
        this.props.getBedrijvenArchief();
        this.setState({ selectedCompanies: []});
        this.setState({ archief: this.state.archief ? false : true });
    }

    createBedrijf = (formValues: any) => {
        var bedrijf = formValues as Bedrijf;

        if (!bedrijf.addressId) {
            bedrijf.addressId = Guid.createEmpty().toString();
        }

        if (!bedrijf.hasInvoiceAddress) {
            bedrijf.invoiceAddress = null;
            bedrijf.invoiceId = null;
            bedrijf.companyInvoiceSettings = null;
            bedrijf.companyInvoiceSettingsId = null;
        }
        else if (bedrijf.invoiceAddress) {
            bedrijf.invoiceAddress.country = "Nederland";
        }
        if (bedrijf.companyInvoiceSettings && bedrijf.companyInvoiceSettings.id == null) {
            bedrijf.companyInvoiceSettings.id = Guid.createEmpty().toString();
        }

        this.props.showLoading();
        BedrijfApi.createBedrijf(bedrijf)
            .then(() => this.props.getBedrijven() && toast.success("Het opslaan is succesvol"))
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => this.props.hideLoading());
        this.setState({ showModal: false });
    }

    onClose = () => {
        this.setState({ showModal: false });
    }

    selectCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyId = event.target.value;

        if (event.target.checked) {
            if (this.state.selectedCompanies.find(item => item === companyId) == null) {
                const selectedCompanies = [...this.state.selectedCompanies, companyId];
                this.setState({ selectedCompanies: selectedCompanies });
            }
        }
        else {
            var index = this.state.selectedCompanies.indexOf(companyId);
            const selectedCompanies = [
                ...this.state.selectedCompanies.slice(0, index),
                ...this.state.selectedCompanies.slice(index + 1)
            ]
            this.setState({ selectedCompanies: selectedCompanies });
        }
    }

    selectAllCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { archief } = this.state;
        const { bedrijven, bedrijvenarchief } = this.props;
        if (event.target.checked) {
            const selectedCompanies = this.state.selectedCompanies;
            var companies = archief ? bedrijvenarchief : bedrijven;
            companies.filter(e => e.isArchived === archief).forEach(task => {
                if (this.state.selectedCompanies.find(item => item === task.id) == null) {
                    selectedCompanies.push(task.id);
                }
            });
            this.setState({ selectedCompanies: selectedCompanies });
        }
        else {
            this.setState({ selectedCompanies: [] });
        }
    }

    archiveToggleSelectedCompanies = () => {
        if (this.state.selectedCompanies.length > 0) {
            let successMessage = this.state.archief ? "Het dearchiveren is succesvol" : "Het archiveren is succesvol";

            this.props.showLoading();
            BedrijfApi.archiveSelectedBedrijven(this.state.selectedCompanies)
                .then(() => {
                    this.props.getBedrijven();
                    this.props.getBedrijvenArchief();
                    this.setState({ selectedCompanies: [] });
                    toast.success(successMessage)
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => {
                    this.props.hideLoading();
                });
        }
    }

    exportSelectedCompanies = () => {
        this.props.showLoading();
        BedrijfApi.exportSelectedBedrijven(this.state.selectedCompanies, this.state.archief)
            .then(() => {
                this.setState({ selectedCompanies: [] });
                toast.success("Het exporteren is succesvol");
            })
            .catch(() => toast.error("Er is een fout opgetreden"))
            .finally(() => {
                this.props.hideLoading();
            });
    }

    render() {
        const { bedrijven, bedrijvenarchief, verantwoordelijken, bedrijfStatussen } = this.props;

        let columns: Array<Column> = [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectedCompanies.some(item => item.toString() === original.id.toString())}
                            value={original.id}
                            onChange={this.selectCompany}
                        />
                    );
                },
                Header: x => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.archief ? bedrijvenarchief.length === this.state.selectedCompanies.length : bedrijven.length === this.state.selectedCompanies.length}
                            onChange={this.selectAllCompanies}
                        />
                    );
                },
                sortable: false,
                filterable: false,
                width: 55
            },
            {
                Header: "Naam",
                accessor: "name",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id], threshold: matchSorter.rankings.CONTAINS }),
                filterAll: true
            },
            {
                Header: "Status",
                accessor: "status",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => {
                    const bedrijfstatus = bedrijfStatussen.find((b: { value: string, label: string }) => b.value === props.value.toString());
                    return bedrijfstatus ? bedrijfstatus.label : null;
                },
                filterMethod: (filter: Filter, rows: any) => {
                    const id = filter.id;
                    return rows[id] !== undefined ? String((BedrijfStatus[rows[id]]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
                }
            },
            {
                Header: "ACTIEVE SPONSORDEALS",
                accessor: "numberOfActiveSponsorDeals",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => props.original.numberOfActiveSponsorDeals === 0 ? "geen" : props.original.numberOfActiveSponsorDeals,
                filterMethod: (filter: Filter, rows: any) => {
                    const id = filter.id;
                    return rows[id] != undefined ? rows[id] === 0 ? "geen".startsWith(filter.value) : String(rows[id]).toLowerCase().startsWith(filter.value) : true;
                }
            },
            {
                Header: "Email-adres",
                accessor: "emailAddress",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Telefoonnummer",
                accessor: "phoneNumber",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Plaats",
                accessor: "place",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            },
            {
                Header: "Verantwoordelijke",
                accessor: "responsibleFullName",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
                filterAll: true
            }
        ]

        return (
            <>
                <BedrijvenComponent
                    onRowClick={this.onRowClick}
                    openModal={this.openModal}
                    openArchief={this.openArchief}
                    columns={columns}
                    archiefButtonText={this.state.archief ? "Terug" : "Archief"}
                    archiverenButtonText={this.state.archief ? "Dearchiveren" : "Archiveren"}
                    archiveToggleSelectedCompanies={this.archiveToggleSelectedCompanies}
                    exportSelectedCompanies={this.exportSelectedCompanies}
                    bedrijven={this.state.archief ? bedrijvenarchief : bedrijven} />
                {this.state.showModal
                    ? (<Modal
                        title="Nieuw bedrijf"
                        content={
                            <BedrijfComponent bedrijfStatussen={bedrijfStatussen} bedrijf={undefined} verantwoordelijken={verantwoordelijken} onSubmit={this.createBedrijf} />
                        }
                        onSubmit={() => this.props.submitForm("BedrijfForm")}
                        onClose={this.onClose} />
                    )
                    : (null)
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    bedrijven: state.bedrijven,
    bedrijvenarchief: state.bedrijvenarchief,
    verantwoordelijken: state.verantwoordelijken,
    bedrijfStatussen: state.bedrijfStatussen
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    getBedrijven: () => dispatch(getBedrijven()),
    getBedrijvenArchief: () => dispatch(getBedrijvenArchief()),
    getVerantwoordelijken: () => dispatch(getVerantwoordelijken()),
    submitForm: (form: string) => dispatch(submit(form)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(BedrijvenContainer);
