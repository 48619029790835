import { Action } from "redux";
import { AgendaItem } from "../types/model";

export const GET_AGENDA_ITEMS = "GET_AGENDA_ITEMS";
export const GET_AGENDA_ITEM = "GET_AGENDA_ITEM";

export interface GetAgendaItemsAction extends Action<string> {
    agendaItems: AgendaItem[];
}

export interface GetAgendaItemAction extends Action<string> {
    agendaItem: AgendaItem;
}
