import * as types from "../constants/leadActionTypes";
import { Lead } from "../types/model";

const initialLeadsState: Array<Lead> = [];
const initialLeadState = {} as Lead

type LeadsState = typeof initialLeadsState
type LeadState = typeof initialLeadState

export const leadsReducer = (
    state: LeadsState = initialLeadsState,
    action: types.GetLeadsAction
) => {
    if (action.type === types.GET_LEADS)
        return action.leads;

    return state;
};

export const leadReducer = (
    state: LeadState = initialLeadState,
    action: types.GetLeadAction
) => {
    if (action.type === types.GET_LEAD)
        return action.lead;

    return state;
};
