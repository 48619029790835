import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Permissions, Sponsordeal, SponsordealList } from "../../types/model";
import { FormDropdown } from "../FormDropdown";
import { Field, reduxForm, InjectedFormProps, getFormValues } from "redux-form";
import { connect } from "react-redux";
import classNames from "classnames";
import { RootState } from "../../types/state";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openModal: () => void;
  openInvoiceModal: () => void;
  toggleArchive: () => void;
  onFilterChange: (array: Array<Sponsordeal>) => void;
  archiveToggleSelectedNotulen: () => void;
  columns: Array<Column>;
  sponsordeallist: SponsordealList[];
  archiefButtonText: string;
  archiverenButtonText: string;
  formValues: any;
  bedrijfId: number;
  showingArchive: boolean;
}
interface State {
  seizoenen: [{ label: string; value: number }];
}

const isReadOnly = IsReadOnly(Permissions.ManageSponsorDeals);
class SponsordealsComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<{}, OwnProps>) {
    super(props);

    this.state = {
      seizoenen: this.createLocalState(),
    };
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.initialValues.sponsordeals !==
      prevProps.initialValues.sponsordeals
    ) {
      this.createLocalState();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  createLocalState = () => {
    let startjaar = this.getSeizoenStartJaar();
    let seizoenen: [{ label: string; value: number }] = [
      { label: "Alle seizoenen", value: 0 },
    ];

    for (let i = 0; i < 5; i++) {
      seizoenen.push({
        label: `${startjaar + i}/${startjaar + i + 1}`,
        value: startjaar + i,
      });
    }

    this.filterSponsordeals(
      this.props.formValues
        ? this.props.formValues.seizoen
        : this.props.initialValues.seizoen
    );
    return seizoenen;
  };

  filterSponsordeals = (value: any) => {
    var sponsordeals = this.props.sponsordeallist;
    if (value !== 0 && this.props.formValues) {
      var jaar = value as number;
      var seizoenstart = new Date(jaar, 6, 1); // 1 juli
      var seizoeneind = new Date(jaar + 1, 5, 30); //30 juni

      this.props.formValues.sponsordeals = sponsordeals;

      this.props.formValues.sponsordeals =
        this.props.formValues.sponsordeals.filter(
          (sponsordeal: { van: string; tot: string }) => {
            const sponsordealStart = new Date(sponsordeal.van);
            const sponsordealEind = new Date(sponsordeal.tot);

            return (
              (seizoenstart >= sponsordealStart &&
                seizoenstart < sponsordealEind) || // Start van seizoen valt in periode van sponsordeal
              (seizoeneind > sponsordealStart &&
                seizoeneind < sponsordealEind) || // Eind van seizoen valt in periode van sponsordeal
              (sponsordealStart <= seizoenstart &&
                sponsordealEind >= seizoeneind)
            ); // Seizoen valt volledig in periode van sponsordeal
          }
        );
      this.props.initialize(this.props.formValues);
    } else if (this.props.formValues && value === 0) {
      this.props.formValues.sponsordeals = sponsordeals;
      this.props.initialize(this.props.formValues);
    }
  };

  onChangeSeizoensjaar = (value: any) => {
    this.filterSponsordeals(value);
  };

  getSeizoenStartJaar = (): number => {
    const date = new Date();
    const year = date.getFullYear();

    return date.getMonth() + 1 < 7 ? year - 1 : year;
  };

  render() {
    const {
      onRowClick,
      openInvoiceModal,
      openModal,
      onFilterChange,
      columns,
      sponsordeallist,
      bedrijfId,
      toggleArchive,
      archiefButtonText,
      archiveToggleSelectedNotulen,
      archiverenButtonText,
      showingArchive,
    } = this.props;
    const userRol = localStorage.getItem("userRol");

    return (
      <>
        <div className="content">
          <div className="contentPanel">
            <div className="columns contentPanel">
              <div className="column is-full">
                <div className="seasonDropdown">
                  <div className="buttons is-pulled-left">
                    {/* <Field
                                            component={Dropdown}
                                            label="Seizoen"
                                            name="seizoen"
                                            options={this.state.seizoenen}
                                            onChange={this.onChangeSeizoensjaar}
                                        /> */}
                  </div>
                </div>
                <div className="buttons is-pulled-left">
                  <button
                    className="button is-success archive-button"
                    onClick={toggleArchive}
                  >
                    {archiefButtonText}
                  </button>
                </div>

                <div className="buttons is-pulled-right">
                  {!isReadOnly && !showingArchive && (
                    <>
                      <button
                        className="button is-success"
                        onClick={openInvoiceModal}
                      >
                        Factureren
                      </button>
                    </>
                  )}
                  <button
                    className="button is-success"
                    onClick={archiveToggleSelectedNotulen}
                  >
                    {archiverenButtonText}
                  </button>
                  <button
                    className="button is-success add-button"
                    onClick={openModal}
                  >
                    Toevoegen
                  </button>
                </div>
              </div>
            </div>
            <Gridview
              id="sponsordealslist"
              onRowClick={onRowClick}
              columns={columns}
              height={"80vh"}
              data={sponsordeallist}
              onFilterChange={onFilterChange}
            />
          </div>
        </div>
      </>
    );
  }
}

const SponsordealsReduxForm = reduxForm<{}, OwnProps>({
  form: "SponsordealsForm",
  enableReinitialize: true,
})(SponsordealsComponent);

export default connect((state: RootState, props: any) => ({
  initialValues: {
    sponsordeallist: state.sponsordeallist ? state.sponsordeallist : [],
    seizoen:
      new Date().getMonth() + 1 < 7
        ? new Date().getFullYear() - 1
        : new Date().getFullYear(),
  },
  formValues: getFormValues("SponsordealsForm")(state),
}))(SponsordealsReduxForm);
