import ApiClient from './ApiClient';
import { Notation } from "../types/model";

const endpoint = 'api/notation';

export default class NotuleApi {
    public static async getNotulen(): Promise<Notation[]> {
        var notulen = await ApiClient.doHttpRequest<Notation[]>('get', `${endpoint}`);
        return notulen;
    }

    public static async getNotation(id: string): Promise<Notation> {
        var notation = await ApiClient.doHttpRequest<Notation>('get', `${endpoint}/${id}`);
        return notation;
    }

    public static async createNotule(notule: Notation): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}`, notule);
    }

    public static async updateNotation(notule: Notation): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `${endpoint}`, notule);
    }

    public static async archiveSelectedNotulen(selectedNotulen: string[]): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/archive`, selectedNotulen);
    }

    public static async deleteAttachment(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>("delete", `${endpoint}/${id}/attachment`)
    }
}