import ErrorComponent from "../components/ErrorComponent/ErrorComponent";

import React from "react";


class ErrorContainer extends React.Component {
    render() {
        const { } = this.props;

        return (
            <ErrorComponent />
        );
    }
}

export default ErrorContainer;
