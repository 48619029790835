import * as types from "../constants/applicationActionTypes";

const initialState = false as Boolean;
type initialState = typeof initialState;

export const initializedReducer = (
    state: initialState = initialState,
    action: types.InitializeAction
) => {
    if (action.type === types.SET_INITIALIZED) {
        return action.initialized;
    }

    return state;
};
