import * as actionTypes from "../constants/agendaActionTypes";
import AgendaApi from '../api/AgendaApi';
import { GetAgendaItemsAction, GetAgendaItemAction } from "../constants/agendaActionTypes";
import { AsyncAction } from ".";

export const getAgendaItems = (): AsyncAction<GetAgendaItemsAction> => {
    return async (dispatch) => {
        const agendaItems = await AgendaApi.getAgendaItems();

        return dispatch({ type: actionTypes.GET_AGENDA_ITEMS, agendaItems });
    };
}

export const getAgendaItem = (id: string): AsyncAction<GetAgendaItemAction> => {
    return async (dispatch) => {
        const agendaItem = await AgendaApi.getAgendaItem(id);

        return dispatch({ type: actionTypes.GET_AGENDA_ITEM, agendaItem });
    };
}
