import * as React from "react";
import { NavLink } from "react-router-dom";
import { ShowPage } from "../helpers/PermissionHelper";
import { Permissions } from "../types/model";

interface State {
  showMenu: boolean;
}

export default class FoldableVoorMijMenu extends React.Component<{}, State> {
  state = {
    showMenu: false,
  };

  onClickMenu = (event: React.MouseEvent) => {
    this.setState({ showMenu: this.state.showMenu ? false : true });
  };

  render() {
    const isVisibleTasks = ShowPage([Permissions.ReadTasks, Permissions.ManageTasks]);
    const isVisibleRecords = ShowPage([Permissions.ReadRecords, Permissions.ManageRecords]);

    return (
      (isVisibleTasks || isVisibleRecords) && (
        <div className="menu-list">
          <a onClick={this.onClickMenu}>Voor mij</a>
          {this.state.showMenu && (
            <ul>
              {isVisibleTasks && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Taken">
                  Taken
                </NavLink>
              )}

              {isVisibleRecords && (
                <NavLink className="navbar-item" activeClassName="is-active" to="/Notulen">
                  Notulen
                </NavLink>
              )}
            </ul>
          )}
        </div>
      )
    );
  }
}
