import React from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EventStatus } from "../constants/enums";

interface Props {
  label?: string;
  editorState: EditorState;
  formValues: Event;
  onEditorStateChange: (editorState: EditorState) => void;
  readOnly: boolean;
  input: HTMLInputElement;
}

// const rawContentState = convertToRaw(editorState.getCurrentContent());
//         const html = draftToHtml(
//             rawContentState
//         );

class TextEditor extends React.Component<Props> {
  componentDidUpdate = (prevProps: any) => {
    if (prevProps != this.props) {
    }
  };

  onEditorChanged = (e: EditorState) => {
    this.onEditorChanged(e);
  };

  render() {
    const { label, editorState, onEditorStateChange, readOnly } = this.props;

    return (
      <>
        <label className="label bcwLabel ">{label}</label>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(e: EditorState) => onEditorStateChange(e)}
          readOnly={readOnly}
        />
      </>
    );
  }
}

export default TextEditor;
