import ApiClient from './ApiClient';
import { Settings } from "../types/model";

const endpoint = 'settings';

export default class SettingsApi {
    public static async getSettings(): Promise<Settings> {
        var settings = await ApiClient.doHttpRequest<Settings>('get', `api/${endpoint}`);
        return settings;
    }

    public static async updateSettings(settings: Settings): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `api/${endpoint}`, settings)
    }

    public static async deleteLogo(): Promise<void> {
        await ApiClient.doHttpRequest<void>('delete', `api/${endpoint}/Logo`)
    }

    public static async ResendActionEmails(): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `api/${endpoint}/ResendActivationEmails`)
    }
}