import * as React from "react";

export default class HeaderComponent extends React.Component {
  render() {
    return (
      <div className="content">
        <div className="singleCompany">
          <div className="columns contentPanel is-multiline is-mobile">
            <div className="column subHeader is-full">
              <h1>404</h1>
            </div>
            <p>U staat buitenspel.</p>
          </div>
        </div>
      </div>
    );
  }
}
