import * as types from "../constants/sponsordealActionTypes";
import { Sponsordeal, SponsordealList } from "../types/model";

const initialSponsordealsState: Array<Sponsordeal> = [];
const initialSponsordeallistState: Array<SponsordealList> = [];
const initialSponsordealState = {} as Sponsordeal;

type SponsordealsState = typeof initialSponsordealsState
type SponsordealListState = typeof initialSponsordeallistState
type SponsordealState = typeof initialSponsordealState

export const sponsordealsReducer = (
    state: SponsordealsState = initialSponsordealsState,
    action: types.GetSponsordealsAction
) => {
    if (action.type === types.GET_SPONSORDEALS)
        return action.sponsordeals;

    return state;
};

export const sponsordeallistReducer = (
    state: SponsordealListState = initialSponsordeallistState,
    action: types.GetSponsordealListAction
) => {
    if (action.type === types.GET_SPONSORDEALLIST)
        return action.sponsordeals;

    return state;
};

export const sponsordealReducer = (
    state: SponsordealState = initialSponsordealState,
    action: types.GetSponsordealAction
) => {
    if (action.type === types.GET_SPONSORDEAL) {
        return action.sponsordeal;
    }

    return state;
};
