import { Action } from "redux";
import { Sponsordeal, SponsordealList } from "../types/model";

export const GET_SPONSORDEALS = "GET_SPONSORDEALS";
export const GET_SPONSORDEAL = "GET_SPONSORDEAL"
export const GET_SPONSORDEALLIST = "GET_SPONSORDEALLIST"

export interface GetSponsordealsAction extends Action<string> {
    sponsordeals: Sponsordeal[];
}

export interface GetSponsordealAction extends Action<string> {
    sponsordeal: Sponsordeal;
}

export interface GetSponsordealListAction extends Action<string> {
    sponsordeals: SponsordealList[];
}
