import * as types from '../constants/loadingActionTypes';

const initialLoadingState = false as boolean;

type LoadingState = typeof initialLoadingState;

export const loadingReducer = (
    state: LoadingState = initialLoadingState,
    action: types.LoadingAction
) => {
    if (action.type === types.SET_LOADING)
        return action.isLoading;

    return state;
};
