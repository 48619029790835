import * as types from "../constants/actieActionTypes";
import { Actie } from "../types/model";

const initialActiesState: Array<Actie> = [];
const initialActieState = {} as Actie

type ActiesState = typeof initialActiesState
type ActieState = typeof initialActieState

export const actiesReducer = (
    state: ActiesState = initialActiesState,
    action: types.GetActiesAction
) => {
    if (action.type === types.GET_ACTIES)
        return action.acties;

    return state;
};

export const actieReducer = (
    state: ActieState = initialActieState,
    action: types.GetActieAction
) => {
    if (action.type === types.GET_ACTIE)
        return action.actie;

    return state;
};
