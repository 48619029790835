import * as types from "../constants/contactpersoonActionTypes";
import { Contactpersoon } from "../types/model";

const initialContactpersonenState: Array<Contactpersoon> = [];
const initialContactpersoonState = {} as Contactpersoon;
const initialPartnersState: Array<Contactpersoon> = [];
const initialPartnerState = {} as Contactpersoon;

const initialContactpersoonSoorten = [
    { label: "Hoofdcontactpersoon", value: "0", },
    { label: "Contactpersoon", value: "1" },
    { label: "Partner", value: "2" },
    { label: "Gast", value: "3" }
];

type ContactpersonenState = typeof initialContactpersonenState
type ContactpersoonState = typeof initialContactpersoonState
type PartnersState = typeof initialPartnersState
type PartnerState = typeof initialPartnerState

export const contactpersonenReducer = (
    state: ContactpersonenState = initialContactpersonenState,
    action: types.GetContactpersonenAction
) => {
    if (action.type === types.GET_CONTACTPERSONEN)
        return action.contactpersonen;

    return state;
};

export const contactpersoonReducer = (
    state: ContactpersoonState = initialContactpersoonState,
    action: types.GetContactpersoonAction
) => {
    if (action.type === types.GET_CONTACTPERSOON)
        return action.contactpersoon;

    return state;
};

export const partnersReducer = (
    state: PartnersState = initialPartnersState,
    action: types.GetPartnersAction
) => {
    if (action.type === types.GET_PARTNERS)
        return action.partners;

    return state;
};

export const partnerReducer = (
    state: PartnerState = initialPartnerState,
    action: types.GetPartnerAction
) => {
    if (action.type === types.GET_PARTNER)
        return action.partner;

    return state;
};

export function contactpersoonSoortenReducer(state = initialContactpersoonSoorten, action = null) {
    return state;
}
