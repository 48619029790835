import * as actionTypes from "../constants/bedrijfActionTypes";
import BedrijfApi from '../api/BedrijfApi';
import { GetBedrijvenAction, GetBedrijvenArchiefAction, GetBedrijfAction, SetBedrijfTabIndexAction } from "../constants/bedrijfActionTypes";
import { AsyncAction } from ".";

export const getBedrijven = (): AsyncAction<GetBedrijvenAction> => {
    return async (dispatch) => {
        const bedrijven = await BedrijfApi.getBedrijven();

        return dispatch({ type: actionTypes.GET_BEDRIJVEN, bedrijven });
    };
}

export const getBedrijvenArchief = (): AsyncAction<GetBedrijvenArchiefAction> => {
    return async (dispatch) => {
        const bedrijvenarchief = await BedrijfApi.getBedrijvenArchief();

        return dispatch({ type: actionTypes.GET_BEDRIJVEN_ARCHIEF, bedrijvenarchief });
    };
}

export const getBedrijf = (id: number): AsyncAction<GetBedrijfAction> => {
    return async (dispatch) => {
        const company = await BedrijfApi.getBedrijf(id);

        return dispatch({ type: actionTypes.GET_BEDRIJF, company });
    };
}

export const getBedrijfForHoofdcontactpersoon = (): AsyncAction<GetBedrijfAction> => {
    return async (dispatch) => {
        const company = await BedrijfApi.getBedrijfForHoofdcontactpersoon();

        return dispatch({ type: actionTypes.GET_BEDRIJF, company });
    };
}

export const setBedrijfTabIndex = (bedrijfTabIndex: number): AsyncAction<SetBedrijfTabIndexAction> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_BEDRIJF_TAB_INDEX, bedrijfTabIndex });
    };
}
