import { ChangeEvent, useEffect, useRef, useState } from "react";
import ReactTable, { Column, Filter, RowInfo, SortingRule } from "react-table";

type Props = {
  data: Array<Object>;
  columns: Array<Column>;
  defaultSorting: SortingRule[];
  onSetRowColor?: (rowInfo: any) => string;
  onRowClick: (event: any, rowInfo: any) => void;
  filteredItemsChanged?: (showingItems: any[]) => void;
};

export const Table: React.FC<Props> = ({
  columns,
  data,
  onSetRowColor,
  onRowClick,
  filteredItemsChanged,
  defaultSorting,
}) => {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<Filter[]>([]);
  const [pageSize, setPageSize] = useState(50);
  let reactTable = useRef<any>(null);

  const resetScrollInsideTable = () => {
    let tableBody = document.getElementsByClassName("rt-tbody")[0];
    tableBody.scrollTop = 0;
  };

  const handleShowingItemsChanged = () => {
    var filteredData: any[] = reactTable.current
      .getResolvedState()
      .sortedData.map((x: any) => x._original);

    filteredItemsChanged && filteredItemsChanged(filteredData);
  };

  return (
    <ReactTable
      loadingText="Laden"
      noDataText="Niets gevonden"
      ref={reactTable}
      data={data}
      columns={columns}
      nextText="Volgende"
      previousText="Vorige"
      rowsText="regels"
      pageText="Pagina"
      page={page}
      onPageChange={(pageNumber) => {
        setPage(pageNumber);
        handleShowingItemsChanged();
      }}
      defaultSorted={defaultSorting}
      getTrProps={(
        state: any,
        rowInfo: RowInfo | undefined,
        column: Column<any> | undefined,
        instance: any
      ) => {
        return {
          style: {
            color:
              onSetRowColor &&
              rowInfo &&
              rowInfo.original &&
              onSetRowColor(rowInfo),
          },
        };
      }}
      getTdProps={(
        state: any,
        rowInfo?: RowInfo,
        column?: Column,
        instance?: any
      ) => {
        if (
          rowInfo &&
          rowInfo.row &&
          rowInfo.row._original &&
          column &&
          column.id !== "checkbox" &&
          column.id !== "afgerond" &&
          column.id !== "actions"
        ) {
          return {
            onClick: (e: ChangeEvent) => {
              if (rowInfo && rowInfo.original) onRowClick(e, rowInfo);
            },
          };
        } else {
          return {};
        }
      }}
      onFilteredChange={(filter, column) => {
        resetScrollInsideTable();
        setPage(0);
        handleShowingItemsChanged();
      }}
      className="-striped -highlight"
      style={{ width: "100%", margin: "0 auto", height: "84vh" }}
      filterable
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
    ></ReactTable>
  );
};
