import * as types from "../constants/settingsActionTypes";
import { Settings } from "../types/model";

const initialSettingsState = {} as Settings

type SettingsState = typeof initialSettingsState

export const settingsReducer = (
    state: SettingsState = initialSettingsState,
    action: types.GetSettingsAction
) => {
    if (action.type === types.GET_SETTINGS)
        return action.settings;

    return state;
};
