import ApiClient from './ApiClient';
import { Contactpersoon } from "../types/model";
import moment from 'moment';

const endpoint = 'person/contactpersonen';

export default class ContactpersoonApi {
    public static async getContactpersonen(companyId: string, archived: boolean, inclPartners: boolean): Promise<Contactpersoon[]> {
        var query = new URLSearchParams();
        if (archived) query.append("includeArchived", "true");
        if (inclPartners) query.append("includePartners", "true");
        if (companyId !== 'undefined' && companyId.length > 0) query.append("companyId", companyId);
        var queryString = query.toString();
        var contactpersonen = await ApiClient.doHttpRequest<Contactpersoon[]>('get', `api/${endpoint}${queryString.length > 0 ? "?" + queryString : ""}`);

        return contactpersonen;
    }

    public static async getPartners(companyId: string, archived: boolean): Promise<Contactpersoon[]> {
        var query = new URLSearchParams();
        if (archived) query.append("includeArchived", "true");
        var queryString = query.toString();
        var partners = await ApiClient.doHttpRequest<Contactpersoon[]>('get', `api/person/GetPartnersForCompany/${companyId}${queryString.length > 0 ? "?" + queryString : ""}`);

        return partners;
    }

    public static async getPartnersForHoofdcontactpersoon(): Promise<Contactpersoon[]> {
        var partners = await ApiClient.doHttpRequest<Contactpersoon[]>('get', `api/${endpoint}/GetPartnersForHoofdcontactpersoon`);

        return partners;
    }

    public static async getContactpersoon(id: string): Promise<Contactpersoon> {
        var contactpersoon = await ApiClient.doHttpRequest<Contactpersoon>('get', `api/${endpoint}/${id}`);

        return contactpersoon;
    }

    public static async getPartner(id: number): Promise<Contactpersoon> {
        var partner = await ApiClient.doHttpRequest<Contactpersoon>('get', `api/person/PortalGetPartner/${id}`);

        return partner;
    }

    public static async getPartnerForHoofdcontactpersoon(id: number): Promise<Contactpersoon> {
        var partner = await ApiClient.doHttpRequest<Contactpersoon>('get', `api/${endpoint}/GetPartnerForHoofdcontactpersoon/${id}`);

        return partner;
    }

    public static async createContactpersoon(contactpersoon: Contactpersoon): Promise<Contactpersoon> {
        var person = await ApiClient.doHttpRequest<Contactpersoon>("post", `api/person/contactpersoon`, contactpersoon);
        return person;
    }

    public static async createPartner(contactpersoon: Contactpersoon): Promise<void> {
        await ApiClient.doHttpRequest<void>("post", `api/${endpoint}/CreatePartner`, contactpersoon);
    }

    public static async createPartnerForHoofdcontactpersoon(contactpersoon: Contactpersoon): Promise<void> {
        await ApiClient.doHttpRequest<void>("post", `api/${endpoint}/CreatePartnerForHoofdcontactpersoon`, contactpersoon);
    }

    public static async updateContactpersoon(contactpersoon: Contactpersoon): Promise<void> {
        await ApiClient.doHttpRequest<void>("put", `api/person/contactpersoon/${contactpersoon.id}`, contactpersoon);
    }

    public static async archiveContactpersoon(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('patch', `api/person/${id}/archive`);
    }

    public static async resendActivationMail(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>('patch', `api/person/${id}/resendactivation`);
    }

    public static async updatePartner(partner: Contactpersoon): Promise<void> {
        await ApiClient.doHttpRequest<void>("put", `api/person/UpdatePartner/`+partner.id, partner);
    }

    public static async updatePartnerForHoofdcontactpersoon(partner: Contactpersoon): Promise<void> {
        await ApiClient.doHttpRequest<void>("put", `api/${endpoint}/updatePartnerForHoofdcontactpersoon`, partner);
    }

    public static async updateContactpersoonForHoofdcontactpersoon(contactpersoon: Contactpersoon): Promise<void> {
        await ApiClient.doHttpRequest<void>("put", `api/${endpoint}/UpdateContactpersoonForHoofdcontactpersoon`, contactpersoon);
    }

    public static async updateContactpersoonForUser(contactpersoon: Contactpersoon): Promise<void> {
        await ApiClient.doHttpRequest<void>("put", `api/${endpoint}/UpdateContactpersoonForUser`, contactpersoon);
    }

    public static async deleteContactpersoon(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>("delete", `api/${endpoint}/${id}`);
    }

    public static async deletePartner(id: string): Promise<void> {
        await ApiClient.doHttpRequest<void>("delete", `api/person/DeletePartner/${id}`);
    }

    public static async exportContactpersonen(ids: string[], archived: boolean, inclPartners: boolean): Promise<any> {
        var query = new URLSearchParams();
        if (archived) query.append("includeArchived", "true");
        if (inclPartners) query.append("includePartners", "true");
        var queryString = query.toString();
        await ApiClient.doFileRequest<Blob>('post', `api/Person/export${queryString.length > 0 ? "?" + queryString : ""}`, ids, false, true, { responseType: 'blob' })
            .then(response => {
                var blobUrl = window.URL.createObjectURL(response.data);
                var tempLink = document.createElement('a');
                tempLink.href = blobUrl;
                tempLink.setAttribute('download', `contactpersonen_${moment().format('YYYY-MM-DDTHH-mm-ss')}.xlsx`);
                tempLink.click();
            });
    }
}

export const getHost = () => {
    return window.location.hostname === "localhost"
        ? window.location.host
        : window.location.hostname;
}