import {
  Page,
  PageHeader,
  PageHeaderLeft,
  PageHeaderRight,
} from "../../../components/Page";
import { Button, ButtonType } from "../../../components/Button/Button";
import { useContext, useEffect, useState } from "react";
import { CreateInvoiceModal } from "../Create/Modal/CreateInvoiceModal";
import { InvoicesOverviewTable } from "./Table/InvoicesOverviewTable";
import { IsReadOnly } from "../../../helpers/PermissionHelper";
import { Permissions } from "../../../types/model";
import { useInvoiceActions } from "./useInvoiceActions";
import { InvoicesContext } from "../../../contexts/invoices/InvoicesContext";

export const InvoicesOverview: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false);
  const [showOnlyUnpaid, setShowOnlyUnpaid] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [invoiceToEdit, setInvoiceToEdit] = useState<string>();
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const isReadOnly = IsReadOnly(Permissions.ManageInvoices);
  const { getInvoices } = useContext(InvoicesContext);

  const {
    sendInvoiceReminders,
    archiveInvoices,
    exportInvoices,
    sendInvoices,
    finalizeInvoices,
  } = useInvoiceActions(
    () => getInvoices(true),
    selectedInvoices,
    setSelectedInvoices
  );

  useEffect(() => {
    if (!showCreateModal) setInvoiceToEdit(undefined);
  }, [showCreateModal]);

  useEffect(() => {
    if (invoiceToEdit !== undefined) setShowCreateModal(true);
  }, [invoiceToEdit]);

  const addNewInvoice = () => {
    setShowCreateModal(true);
  };

  return (
    <Page>
      <PageHeader>
        <PageHeaderLeft>
          <Button
            title={showArchived ? "Terug" : "Archief"}
            onClick={() => setShowArchived(!showArchived)}
            type={ButtonType.success}
          />
        </PageHeaderLeft>
        <PageHeaderRight>
          {!isReadOnly && !showArchived && (
            <>
              <Button
                title={"Versturen"}
                onClick={sendInvoices}
                type={ButtonType.success}
                disabled={selectedInvoices.length < 1}
              />
              <Button
                title={"Herinneren"}
                onClick={sendInvoiceReminders}
                type={ButtonType.success}
                disabled={selectedInvoices.length < 1}
              />
              <Button
                title={
                  showOnlyUnpaid ? "Alle weergeven" : "Openstaande weergeven"
                }
                onClick={() => setShowOnlyUnpaid(!showOnlyUnpaid)}
                type={ButtonType.success}
              />
              <Button
                title={"Definitief maken"}
                onClick={finalizeInvoices}
                type={ButtonType.success}
                disabled={selectedInvoices.length < 1}
              />
            </>
          )}
          <Button
            title={showArchived ? "Dearchiveren" : "Archiveren"}
            onClick={archiveInvoices}
            type={ButtonType.success}
            disabled={selectedInvoices.length < 1}
          />
          <Button
            title={"Exporteren"}
            onClick={exportInvoices}
            type={ButtonType.success}
            disabled={selectedInvoices.length < 1}
          />
          {!isReadOnly && (
            <Button
              title={"Toevoegen"}
              onClick={addNewInvoice}
              type={ButtonType.success}
            />
          )}
        </PageHeaderRight>
      </PageHeader>
      <InvoicesOverviewTable
        showArchived={showArchived}
        showOnlyUnpaid={showOnlyUnpaid}
        selectedInvoices={selectedInvoices}
        setSelectedInvoices={setSelectedInvoices}
        setInvoiceToEdit={setInvoiceToEdit}
      />
      {showCreateModal && (
        <CreateInvoiceModal
          invoiceId={invoiceToEdit}
          onClose={() => setShowCreateModal(false)}
        />
      )}
    </Page>
  );
};
