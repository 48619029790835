import { useEffect, useState } from "react";
import { Bedrijf, Factuur, FactuurRegel } from "../../../types/model";
import { FactuurStatus } from "../../../constants/enums";
import BedrijfApi from "../../../api/BedrijfApi";
import required from "../../../validators/required";
import email from "../../../validators/email";
import { DatePicker, Dropdown, TextInput } from "../../../components/Input";
import { CreateInvoiceLine } from "../Line/Create/CreateInvoiceLine";
import { Guid } from "guid-typescript";
import { toast } from "react-toastify";
import { IsReadOnly } from "../../../helpers/PermissionHelper";
import { Permissions } from "../../../types/model";
import { CreateInvoiceSummary } from "./Summary/CreateInvoiceSummary";

type Props = {
  invoice: Factuur;
  setInvoice: (invoice: Factuur) => void;
};

const isReadOnly = IsReadOnly(Permissions.ManageInvoices);
export const CreateInvoice: React.FC<Props> = ({ invoice, setInvoice }) => {
  const [companies, setCompanies] = useState<Bedrijf[]>([]);

  useEffect(() => {
    BedrijfApi.getBedrijven().then((res) => {
      setCompanies(res);
    });
  }, []);

  const changeCompany = (companyId: string) => {
    var company = companies.find((c) => c.id === companyId);

    setInvoice({
      ...invoice,
      companyId: company!.id,
      email: company!.emailAddress || "",
      toTheAttentionOf: company!.invoiceToTheAttentionOf,
    });
  };

  const updateInvoiceLine = (invoiceLine: FactuurRegel) => {
    var index = invoice.invoiceLines.findIndex((x) => x.id === invoiceLine.id);
    var newInvoiceLines = [...invoice.invoiceLines];
    newInvoiceLines[index] = invoiceLine;

    setInvoice({ ...invoice, invoiceLines: newInvoiceLines });
  };

  const addInvoiceLine = () => {
    if (invoice.invoiceLines.filter((x) => x.isDeleted).length > 2) {
      toast.info("Maximum aantal factuurregels bereikt");
    } else {
      var newInvoiceLine = {
        id: Guid.create().toString(),
      } as FactuurRegel;

      setInvoice({
        ...invoice,
        invoiceLines: [...invoice.invoiceLines, newInvoiceLine],
      });
    }
  };

  return (
    <div className="content">
      <div className="columns contentPanel is-multiline is-mobile">
        {invoice ? (
          <>
            <TextInput
              label="Factuurnummer"
              name="factuur.invoiceNumber"
              readOnly={true}
              value={invoice.invoiceNumber?.toString() || ""}
              showSideBySide={true}
              setValue={() => {}}
            />
            <TextInput
              label="Status"
              name="factuur.status"
              value={FactuurStatus[invoice.status]}
              readOnly={true}
              showSideBySide={true}
              setValue={() => {}}
            />
          </>
        ) : null}

        <Dropdown
          label="Bedrijf"
          name="factuur.companyId"
          showSideBySide={true}
          required={true}
          readOnly={isReadOnly}
          onValueChanged={(e: string) => changeCompany(e)}
          options={companies.map((company) => {
            return { label: company.name, value: company.id.toString() };
          })}
          clearable={false}
          value={invoice.companyId?.toString() || ""}
        />
        <TextInput
          name="invoice.distinction"
          label="Kenmerk"
          readOnly={isReadOnly}
          showSideBySide={true}
          value={invoice.distinction || ""}
          setValue={(value) => setInvoice({ ...invoice, distinction: value })}
        />
        <TextInput
          label="T.a.v."
          name="invoice.toTheAttentionOf"
          showSideBySide={true}
          readOnly={isReadOnly}
          value={invoice.toTheAttentionOf || ""}
          setValue={(value) =>
            setInvoice({ ...invoice, toTheAttentionOf: value })
          }
        />
        <TextInput
          label="Emailadres"
          name="factuur.email"
          validators={[required, email]}
          showSideBySide={true}
          readOnly={isReadOnly}
          value={invoice.email || ""}
          setValue={(value) => setInvoice({ ...invoice, email: value })}
        />
        <DatePicker
          label="Orderdatum"
          name="factuur.orderDate"
          readOnly={isReadOnly}
          showSideBySide={true}
          value={invoice.orderDate || ""}
          onChange={(value) =>
            setInvoice({
              ...invoice,
              orderDate: value || new Date(),
            })
          }
        />
        {invoice.invoiceLines.map((invoiceLine, index) => (
          <CreateInvoiceLine
            key={index}
            index={index}
            invoiceLine={invoiceLine}
            isReadOnly={isReadOnly}
            setInvoiceLine={updateInvoiceLine}
          />
        ))}
        {!isReadOnly && (
          <div className="buttons is-pulled-right">
            <button
              type="button"
              className="button is-success"
              onClick={addInvoiceLine}
            >
              Regel toevoegen
            </button>
          </div>
        )}
        <CreateInvoiceSummary invoice={invoice} />
      </div>
    </div>
  );
};
