import * as actionTypes from "../constants/taakActionTypes";
import TaskApi from "../api/TaakApi";
import { GetTakenAction, GetTaakAction } from "../constants/taakActionTypes";
import { AsyncAction } from ".";

export const getTaken = (): AsyncAction<GetTakenAction> => {
    return async (dispatch) => {
        const taken = await TaskApi.getTasks();

        return dispatch({ type: actionTypes.GET_TAKEN, taken });
    };
}


export const getTaak = (id:string): AsyncAction<GetTaakAction> => {
    return async (dispatch) => {
        const taak = await TaskApi.getTask(id);

        return dispatch({ type: actionTypes.GET_TAAK, taak});
    };
}
