import { HorizontalBar } from "react-chartjs-2";
import { Permissions } from '../../types/model'

export const permissionList = [
    {
        menuItem: "Dashboard", subMenuItems: [
            { readId: Permissions.ReadDashboard, manageId: Permissions.ManageDashboard, subMenuItem: "Dashboard" }
        ]
    },
    {
        menuItem: "Voor mij", subMenuItems: [
            { readId: Permissions.ReadTasks, manageId: Permissions.ManageTasks, subMenuItem: "Taken" },
            { readId: Permissions.ReadRecords, manageId: Permissions.ManageRecords, subMenuItem: "Notulen" }
        ]
    },
    {
        menuItem: "Sponsoren", subMenuItems: [
            { readId: Permissions.ReadCompanies, manageId: Permissions.ManageCompanies, subMenuItem: "Bedrijven" },
            { readId: Permissions.ReadContactPersons, manageId: Permissions.ManageContactPersons, subMenuItem: "Contactpersonen" },
            { readId: Permissions.ReadLeads, manageId: Permissions.ManageLeads, subMenuItem: "Leads" }
        ]
    },
    {
        menuItem: "Informatie", subMenuItems: [
            { readId: Permissions.ReadCustomPages, manageId: Permissions.ManageCustomPages, subMenuItem: "Informatie" }
        ]
    },
    {
        menuItem: "Sponsordeals", subMenuItems: [
            { readId: Permissions.ReadSponsorDeals, manageId: Permissions.ManageSponsorDeals, subMenuItem: "Sponsordeals" }
        ]
    },
    {
        menuItem: "Facturen", subMenuItems: [
            { readId: Permissions.ReadInvoices, manageId: Permissions.ManageInvoices, subMenuItem: "Facturen" }
        ]
    },
    {
        menuItem: "Events", subMenuItems: [
            { readId: Permissions.ReadEvents, manageId: Permissions.ManageEvents, subMenuItem: "Events" }
        ]
    },
    {
        menuItem: "Nieuws", subMenuItems: [
            { readId: Permissions.ReadNews, manageId: Permissions.ManageNews, subMenuItem: "Nieuws" }
        ]
    },
    {
        menuItem: "Agenda", subMenuItems: [
            { readId: Permissions.ReadAgenda, manageId: Permissions.ManageAgenda, subMenuItem: "Agenda" }
        ]
    },
    {
        menuItem: "Voor mij", subMenuItems: [
            { readId: Permissions.ReadSponsorDealKinds, manageId: Permissions.ManageSponsorDealKinds, subMenuItem: "Beheer sponsordealsoorten" },
            { readId: Permissions.ReadProducts, manageId: Permissions.ManageProducts, subMenuItem: "Beheer producten" },
            { readId: Permissions.ReadUsers, manageId: Permissions.ManageUsers, subMenuItem: "Beheer gebruikers" },
            { readId: Permissions.ReadRoles, manageId: Permissions.ManageRoles, subMenuItem: "Beheer rollen" }
        ]
    }
] as MenuItem[];


export interface SubMenuItem {
    readId: number;
    manageId: number;
    subMenuItem: string;
}

export interface MenuItem {
    menuItem: string;
    subMenuItems: SubMenuItem[];
}