import * as types from "../constants/nieuwsberichtActionTypes";
import { Nieuwsbericht } from "../types/model";

const initialNieuwsberichtenState: Array<Nieuwsbericht> = [];
const initiialNieuwsberichtState = {} as Nieuwsbericht

type NieuwsberichtenState = typeof initialNieuwsberichtenState
type NieuwsberichtState = typeof initiialNieuwsberichtState

export const nieuwsberichtenReducer = (
    state: NieuwsberichtenState = initialNieuwsberichtenState,
    action: types.GetNieuwsberichtenAction
) => {
    if (action.type === types.GET_NIEUWSBERICHTEN)
    {
        return action.nieuwsberichten;
    }

    return state;
};

export const nieuwsberichtReducer = (
    state: NieuwsberichtState = initiialNieuwsberichtState,
    action: types.GetNieuwsberichtAction
) => {
    if (action.type === types.GET_NIEUWSBERICHT)
        return action.nieuwsbericht;

    return state;
};
