import { Action } from "redux";
import { SponsorDealType } from "../types/model";

export const GET_SPONSORDEALSOORTEN = "GET_SPONSORDEALSOORTEN"
export const GET_SPONSORDEALSOORT = "GET_SPONSORDEALSOORT"

export interface GetSponsordealSoortenAction extends Action<string> {
    sponsorDealTypes: SponsorDealType[];
}

export interface GetSponsordealSoortAction extends Action<string> {
    sponsorDealType: SponsorDealType;
}
