import * as actionTypes from "../constants/settingsActionTypes";
import SettingsApi from '../api/SettingsApi';
import { GetSettingsAction } from "../constants/settingsActionTypes";
import { AsyncAction } from ".";

export const getSettings = (): AsyncAction<GetSettingsAction> => {
    return async (dispatch) => {
        const settings = await SettingsApi.getSettings();

        return dispatch({ type: actionTypes.GET_SETTINGS, settings });
    };
}
