import * as actionTypes from "../constants/sponsordealSoortenActionTypes";
import SponsordealSoortApi from '../api/SponsordealSoortApi';
import { GetSponsordealSoortenAction, GetSponsordealSoortAction } from "../constants/sponsordealSoortenActionTypes";
import { AsyncAction } from ".";

export const getSponsordealSoorten = (): AsyncAction<GetSponsordealSoortenAction> => {
    return async (dispatch) => {
        const sponsorDealTypes = await SponsordealSoortApi.getSponsorDealTypes();

        return dispatch({ type: actionTypes.GET_SPONSORDEALSOORTEN, sponsorDealTypes });
    };
}

export const getSponsordealSoort = (id: number): AsyncAction<GetSponsordealSoortAction> => {
    return async (dispatch) => {
        const sponsorDealType = await SponsordealSoortApi.getSponsorDealType(id);

        return dispatch({ type: actionTypes.GET_SPONSORDEALSOORT, sponsorDealType });
    };
}
