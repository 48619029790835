import React from "react";
import { EventContextProvider } from "./events/EventContext";
import { SponsorContextProvider } from "./sponsor/SponsorContext";
import { StatisticsContextProvider } from "./statistics/StatisticsContext";
import { InvoicesContextProvider } from "./invoices/InvoicesContext";
import { ApplicationContextProvider } from "./application/ApplicationContext";

export const ContextProviders: React.FC = ({ children }) => {
  return (
    <ApplicationContextProvider>
      <InvoicesContextProvider>
        <SponsorContextProvider>
          <EventContextProvider>
            <StatisticsContextProvider>{children}</StatisticsContextProvider>
          </EventContextProvider>
        </SponsorContextProvider>
      </InvoicesContextProvider>
    </ApplicationContextProvider>
  );
};
