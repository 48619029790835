import { AnyAction, compose } from "redux";
import {
  Bedrijf,
  Contactpersoon,
  Permissions,
  SocialNotificationSettings,
} from "../types/model";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { change, getFormValues, reset } from "redux-form";
import { hideLoading, showLoading } from "../actions/loadingActions";

import ContactpersoonApi from "../api/ContactpersoonApi";
import ContactpersoonComponent from "../components/ContactpersoonComponent/ContactpersoonComponent";
import { Guid } from "guid-typescript";
import { IsReadOnly } from "../helpers/PermissionHelper";
import React from "react";
import { RootState } from "../types/state";
import SocialSettingsApi from "../api/SocialSettingsApi";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getBedrijven } from "../actions/bedrijfActions";
import { getContactpersoon } from "../actions/gebruikerActions";
import { getSocialSettings } from "../actions/socialSettingsActions";
import history from "../components/history";
import moment from "moment";
import { toast } from "react-toastify";

interface DispatchProps {
  getContactpersoon: (id: string) => any;
  getBedrijven: () => any;
  updateFormValue: (field: string, value: string) => any;
  showLoading: () => any;
  hideLoading: () => any;
  resetForm: () => void;
  getSocialNotficationSettings: (id: string) => any;
}

interface StateProps {
  gebruiker?: Contactpersoon;
  contactpersoonSoorten: [{ label: string; value: string }];
  bedrijven: Bedrijf[];
  formValues: Contactpersoon;
  socialNotificationSettings: SocialNotificationSettings;
}

interface OwnProps {
  bedrijfId?: number;
}

interface MatchParams {
  id: string;
}

type Props = DispatchProps &
  OwnProps &
  StateProps &
  RouteComponentProps<MatchParams>;
const isReadOnly = IsReadOnly(Permissions.ManageContactPersons);
class ContactpersoonContainer extends React.Component<Props> {
  componentDidMount = () => {
    const userRol = localStorage.getItem("userRol");
    this.props.getContactpersoon(
      this.props.match.params.id as unknown as string
    );
    this.props.getSocialNotficationSettings(
      this.props.match.params.id as unknown as string
    );
    this.props.getBedrijven();
  };

  updateContactpersoon = async (formValues: Contactpersoon) => {
    var contactpersoon = formValues;
    contactpersoon.useCompanyAddress = !formValues.hasSeperatePartnerAddress;
    contactpersoon.birthDate = moment(contactpersoon.birthDate)
      .startOf("day")
      .format("YYYY-MM-DD hh:mm:ss");
    if (contactpersoon.partner) {
      contactpersoon.partner.birthDate = moment(
        contactpersoon.partner.birthDate
      )
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss");
    }
    this.props.showLoading();
    await ContactpersoonApi.updateContactpersoon(contactpersoon)
      .then(() => {
        toast.success("Het opslaan van het account is succesvol");
      })
      .catch((err) => {
        return toast.error(err.response.data);
      });

    var socialValues = formValues.socialSettings as SocialNotificationSettings;
    socialValues.personId = contactpersoon.id;
    if (socialValues) {
      await SocialSettingsApi.updateSocialSettings(socialValues)
        .then(() => {
          toast.success("Het opslaan van de notificaties is succesvol");
        })
        .catch((err) => {
          return toast.error(err.response.data);
        })
        .finally(() => {
          this.props.hideLoading();
          history.goBack();
        });
    }

    this.setState({ gebruiker: undefined });
    this.props.resetForm();
  };

  archiveContactpersoon = (id: string) => {
    if (
      window.confirm("Weet u zeker dat deze contactpersoon wilt archiveren?")
    ) {
      this.props.showLoading();
      ContactpersoonApi.archiveContactpersoon(id)
        .then(() => toast.success("Archiveren is succesvol"))
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => {
          history.goBack();
          this.props.hideLoading();
        });
    }
  };

  resendActivationMail = (id: string) => {
    this.props.showLoading();
    ContactpersoonApi.resendActivationMail(id)
      .then(() => toast.success("Versturen van mail is succesvol"))
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => {
        history.goBack();
        this.props.hideLoading();
      });
  };

  updateFormValue = (field: string, value: string) => {
    this.props.updateFormValue(field, value);
  };

  onCancel = () => {
    history.goBack();
  };

  render() {
    const {
      gebruiker,
      bedrijven,
      formValues,
      contactpersoonSoorten,
      socialNotificationSettings,
    } = this.props;
    return (
      <ContactpersoonComponent
        useCompanyAddress={formValues && formValues.useCompanyAddress}
        hasPartner={formValues && formValues.hasPartner}
        hasSeperatePartnerAddress={
          formValues && formValues.hasSeperatePartnerAddress
        }
        gebruiker={gebruiker}
        archiveContactpersoon={this.archiveContactpersoon}
        contactpersoonSoorten={contactpersoonSoorten}
        bedrijven={bedrijven}
        isEdit={true}
        onCancel={this.onCancel}
        formValues={formValues}
        updateFormValue={this.updateFormValue}
        onSubmit={this.updateContactpersoon}
        socialSettings={socialNotificationSettings}
        resendActivationMail={this.resendActivationMail}
      />
    );
  }
}

const mapStateToProps = (state: RootState, test: any): StateProps => {
  return {
    gebruiker: state.gebruiker as Contactpersoon,
    contactpersoonSoorten: state.contactpersoonSoorten,
    bedrijven: state.bedrijven,
    formValues: getFormValues("ContactpersoonForm")(state) as Contactpersoon,
    socialNotificationSettings: state.socialSettings,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getContactpersoon: (id: string) => dispatch(getContactpersoon(id)),
  getBedrijven: () => dispatch(getBedrijven()),
  updateFormValue: (field: string, value: string) =>
    dispatch(change("ContactpersoonForm", field, value)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  resetForm: () => dispatch(reset("ContactpersoonForm")),
  getSocialNotficationSettings: (id: string) => dispatch(getSocialSettings(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactpersoonContainer)
);
