import { Action } from "redux";
import { CustomPage } from "../types/model";

export const GET_CUSTOMPAGES = "GET_CUSTOMPAGES";
export const GET_CUSTOMPAGE = "GET_CUSTOMPAGE";

export interface GetCustomPagesAction extends Action<string> {
    customPages: CustomPage[];
}

export interface GetCustomPageAction extends Action<string> {
    customPage: CustomPage;
}
