import * as actionTypes from "../constants/actieActionTypes";
import ActieApi from '../api/ActieApi';
import { GetActiesAction, GetActieAction } from "../constants/actieActionTypes";
import { AsyncAction } from ".";

export const getActies = (): AsyncAction<GetActiesAction> => {
    return async (dispatch) => {
        const acties = await ActieApi.getActies();

        return dispatch({ type: actionTypes.GET_ACTIES, acties });
    };
}

export const getActie = (id: string): AsyncAction<GetActieAction> => {
    return async (dispatch) => {
        const actie = await ActieApi.getActie(id);

        return dispatch({ type: actionTypes.GET_ACTIE, actie });
    };
}