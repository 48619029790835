import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Column, RowInfo } from "react-table";
import { getInvoiceOverviewTableColumns } from "./Columns/InvoicesOverviewTableColumns";
import { ListFactuur } from "../../../../types/model";
import { InvoicesContext } from "../../../../contexts/invoices/InvoicesContext";
import { Table } from "../../../../components/Table/Table";
import {
  arraysHaveSameEntries,
  mergeArrays,
} from "../../../../helpers/arrayHelper";

type Props = {
  showArchived: boolean;
  showOnlyUnpaid: boolean;
  selectedInvoices: string[];
  setSelectedInvoices: (invoices: string[]) => void;
  setInvoiceToEdit: (invoiceId: string) => void;
};

export const InvoicesOverviewTable: React.FC<Props> = ({
  showArchived,
  showOnlyUnpaid,
  selectedInvoices,
  setSelectedInvoices,
  setInvoiceToEdit,
}) => {
  const [columns, setColumns] = useState<Column[]>([]);
  const [filteredInvoices, setFilteredInvoices] = useState<ListFactuur[]>([]);
  const [fiscalYears, setFiscalYears] = useState<string[]>([]);
  const { invoices, getInvoices } = useContext(InvoicesContext);

  const toggleSelected = (event: any) => {
    if (event.target.checked) {
      setSelectedInvoices([...selectedInvoices, event.target.value]);
    } else {
      setSelectedInvoices(
        selectedInvoices.filter((inv) => inv !== event.target.value)
      );
    }
  };

  const selectAll = () => {
    var showingIds = filteredInvoices.map((inv) => inv.id.toString());
    if (arraysHaveSameEntries(selectedInvoices, showingIds)) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(mergeArrays(selectedInvoices, showingIds));
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
    setInvoiceToEdit(rowInfo.original.id);
  };

  useEffect(() => {
    const newFiscalYears = (
      filteredInvoices.length ? filteredInvoices : invoices
    )
      .map((item) => item["fiscalYear"])
      .filter((item, i, s) => s.lastIndexOf(item) == i)
      .sort()
      .reverse();
    if (!arraysHaveSameEntries(fiscalYears, newFiscalYears))
      setFiscalYears(newFiscalYears);
  }, [filteredInvoices, invoices]);

  useEffect(() => {
    const columns = getInvoiceOverviewTableColumns(
      selectedInvoices,
      filteredInvoices.length ? selectAll : undefined,
      toggleSelected,
      fiscalYears
    );

    setColumns(columns);
  }, [fiscalYears, filteredInvoices, selectedInvoices]);

  const getData = () => {
    if (showArchived) return invoices.filter((inv) => inv.isArchived === true);

    const remaining = invoices.filter((inv) => inv.isArchived === false);
    if (showOnlyUnpaid)
      return remaining.filter((inv) => inv.remainingAmountIncVAT >= 0.01);
    return remaining;
  };

  return (
    <Table
      onRowClick={onRowClick}
      columns={columns}
      data={getData()}
      defaultSorting={[{ id: "invoiceNumber", desc: false }]}
      filteredItemsChanged={(list: any[]) => setFilteredInvoices(list)}
    />
  );
};
