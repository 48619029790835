import * as React from "react";
import { NavLink } from "react-router-dom";
import FoldableSponsorsMenu from "../FoldableSponsorsMenu";
import FoldableVoorMijMenu from "../FoldableVoorMijMenu";
import FoldableSettingsMenu from "../FoldableSettingsMenu";
import { ShowPage } from "../../helpers/PermissionHelper";
import { Permissions } from "../../types/model";
import exitIcon from "../../images/Exit.svg";

interface OwnProps {
  onLogout: () => void;
  bedrijfId: string;
  aanmeldenEvents: boolean;
  bestellenActies: boolean;
  logoUrl: string;
  primaireKleur: string;
  secundaireKleur: string;
}

export default class HeaderComponent extends React.Component<OwnProps> {
  render() {
    const {
      onLogout,
      bedrijfId,
      aanmeldenEvents,
      bestellenActies,
      logoUrl,
      primaireKleur,
      secundaireKleur,
    } = this.props;

    document.documentElement.style.setProperty(
      "--primaireKleur",
      primaireKleur
    );
    document.documentElement.style.setProperty(
      "--secundaireKleur",
      secundaireKleur
    );

    return (
      <div className="sidebar-menu">
        <div className="sidebar-intro">
          {ShowPage([
            Permissions.ReadDashboard,
            Permissions.ManageDashboard,
          ]) && (
            <NavLink className="logo" to="/">
              <img src={logoUrl} />
            </NavLink>
          )}

          {!ShowPage([
            Permissions.ReadDashboard,
            Permissions.ManageDashboard,
          ]) && (
            <span className="logo">
              <img src={logoUrl} />
            </span>
          )}

          <span className="intro-name">
            Welkom <strong>{localStorage.getItem("naam")}</strong>
          </span>
        </div>
        <aside className="menu" style={{ marginBottom: "30px" }}>
          <div className="menu-list">
            {ShowPage([
              Permissions.ReadDashboard,
              Permissions.ManageDashboard,
            ]) && (
              <NavLink
                className="navbar-item normal-link"
                exact
                activeClassName="is-active"
                to="/"
              >
                Dashboard
              </NavLink>
            )}

            {ShowPage([
              Permissions.ReadDashboard,
              Permissions.ManageDashboard,
            ]) && (
              <NavLink
                className="navbar-item normal-link"
                exact
                activeClassName="is-active"
                to="/Statistieken"
              >
                Statistieken
              </NavLink>
            )}

            <FoldableVoorMijMenu />
            <FoldableSponsorsMenu />
            {ShowPage([
              Permissions.ReadSponsorDeals,
              Permissions.ManageSponsorDeals,
            ]) && (
              <NavLink
                className="navbar-item normal-link"
                activeClassName="is-active"
                to="/CustomPages"
              >
                Informatie
              </NavLink>
            )}
            {/* 
                        {userRol != null && ShowPage([Permissions.ReadCompany, Permissions.ManageCompany], permissions)
                            && <NavLink className="navbar-item normal-link" activeClassName="is-active" to={"/Bedrijven/" + bedrijfId}>
                                Bedrijf
                            </NavLink>
                        }

                        {userRol != null && ShowPage([Permissions.ReadContactPerson, Permissions.ManageContactPerson], permissions)
                            && <NavLink className="navbar-item normal-link" activeClassName="is-active" to="/Contactpersoon">
                                Contactpersoon
                            </NavLink>
                        } */}

            {ShowPage([
              Permissions.ReadSponsorDeals,
              Permissions.ManageSponsorDeals,
            ]) && (
              <NavLink
                className="navbar-item normal-link"
                activeClassName="is-active"
                to="/Sponsordeals"
              >
                Sponsordeals
              </NavLink>
            )}

            {ShowPage([
              Permissions.ReadInvoices,
              Permissions.ManageInvoices,
            ]) && (
              <NavLink
                className="navbar-item normal-link"
                activeClassName="is-active"
                to="/Facturen"
              >
                Facturen
              </NavLink>
            )}

            {ShowPage([Permissions.ReadEvents, Permissions.ManageEvents]) && (
              <NavLink
                className="navbar-item normal-link"
                activeClassName="is-active"
                to="/Events"
              >
                Events
              </NavLink>
            )}

            {/* {bestellenActies && <NavLink className="navbar-item normal-link" activeClassName="is-active" to="/Acties">
                                Acties
                            </NavLink>
                        } */}

            {ShowPage([Permissions.ReadNews, Permissions.ManageNews]) && (
              <NavLink
                className="navbar-item normal-link"
                activeClassName="is-active"
                to="/Nieuws"
              >
                Nieuws
              </NavLink>
            )}

            {ShowPage([Permissions.ReadAgenda, Permissions.ManageAgenda]) && (
              <NavLink
                className="navbar-item normal-link"
                activeClassName="is-active"
                to="/Agenda"
              >
                Agenda
              </NavLink>
            )}
          </div>

          <FoldableSettingsMenu />

          <a className="navbar-item logout-btn is-success" onClick={onLogout}>
            <img src={exitIcon} /> Log uit
          </a>
        </aside>
      </div>
    );
  }
}
