import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

class ApiClient {
    public static async doHttpRequest<TResponse>(
        method: string,
        endpoint: string,
        data: {} | PromiseLike<{}> | null = null,
        asFormValues = false,
        authenticated = true,
        options: {} | null = null
    ): Promise<TResponse> {
        const headers: any = {};

        if (!asFormValues) {
            headers["Content-Type"] = "application/json";
        }
        if (authenticated) {
            headers["Authorization"] = "Bearer " + localStorage.getItem("token");
        }

        const apiUrl = localStorage.getItem('apiUrl');
        const url = `${apiUrl}/${endpoint}`;

        const config: AxiosRequestConfig = { method, url, data, headers, ...options };

        const response = await axios.request<TResponse>(config);

        return response.data;
    }

    public static async doFileRequest<TResponse>(
        method: string,
        endpoint: string,
        data: {} | PromiseLike<{}> | null = null,
        asFormValues = false,
        authenticated = true,
        options: {} | null = null
    ): Promise<AxiosResponse<TResponse>> {
        const headers: any = {};

        if (!asFormValues) {
            headers["Content-Type"] = "application/json";
        }
        if (authenticated) {
            headers["Authorization"] = "Bearer " + localStorage.getItem("token");
        }

        const apiUrl = localStorage.getItem('apiUrl');
        const url = `${apiUrl}/${endpoint}`;

        const config: AxiosRequestConfig = { method, url, data, headers, ...options };

        const response = await axios.request<TResponse>(config);

        return response;
    }
}

export default ApiClient;
