import * as React from 'react';

import { Column, Filter, RowInfo } from 'react-table';
import { CustomPage, Person, Role } from '../types/model';
import { EditorState, convertToRaw } from 'draft-js';
import { change, submit } from 'redux-form';
import { getCustomPage, getCustomPages } from '../actions/customPageActions';
import { hideLoading, showLoading } from '../actions/loadingActions';

import { AnyAction } from 'redux';
import { ChangeEvent } from 'react';
import CustomPageApi from '../api/CustomPagesApi';
import CustomPageComponent from '../components/CustomPageComponent/CustomPageComponent';
import CustomPagesComponent from '../components/CustomPagesComponent/CustomPagesComponent';
import GebruikerApi from '../api/GebruikerApi';
import GebruikersComponent from '../components/GebruikersComponent/GebruikersComponent';
import { Modal } from '../components/Modal';
import { PersonType } from '../constants/enums';
import RolComponent from '../components/RolComponent/RolComponent';
import RollenComponent from '../components/RollenComponent/RollenComponent';
import { RootState } from "../types/state";
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import { getRollen } from '../actions/gebruikerActions';
import history from '../components/history';
import matchSorter from 'match-sorter';
import { toast } from 'react-toastify';

interface State {
    showEditModal: boolean;
    showCreateModal: boolean;
}

export type Props = OwnProps & StateProps & DispatchProps;

export interface OwnProps {
}

export interface StateProps {
    customPages: Array<CustomPage>;
    customPage: CustomPage;
}

export interface DispatchProps {
    updateFormValue: (field: string, value: number[]) => any;
    submitForm: (form: string) => any;
    getCustomPages: () => any;
    getCustomPage: (id: string) => any;
    showLoading: () => any;
    hideLoading: () => any;
}

class CustomPagesContainer extends React.Component<Props, State> {
    state: State = {
        showEditModal: false,
        showCreateModal: false
    };

    componentDidMount() {
        this.props.getCustomPages();
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
        this.props.getCustomPage(rowInfo.original.id);
        this.setState({ showEditModal: true });
    }

    upsertCustomPage = async (formValues: any, editorState: EditorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const html = draftToHtml(
            rawContentState
        );

        const customPage = {
            id: formValues.id,
            title: formValues.title,
            description: html
        } as CustomPage
        this.props.showLoading();

        await CustomPageApi.upsertCustomPage(customPage)
            .then(() => {
                this.props.getCustomPages();
                this.setState({ showEditModal: false, showCreateModal: false });
                toast.success("Het opslaan is succesvol");
            })
            .catch(err => toast.error(err.response.data))
            .finally(() => this.props.hideLoading());
    }

    openCreateModal = () => {
        this.setState({ showCreateModal: true });
    }

    openEditModal = () => {
        this.setState({ showEditModal: true });
    }

    updateFormValue = (field: string, value: number[]) => {
        this.props.updateFormValue(field, value);
    }

    closeModal = () => {
        this.setState({ showCreateModal: false, showEditModal: false });
    }

    archiveCustomPage = () => {
        if (this.props.customPage && this.props.customPage.id)
            CustomPageApi.archiveCustomPage(this.props.customPage.id)
                .then(() => {
                    this.props.getCustomPages();
                    toast.success("Het verwijderen is succesvol")
                })
                .catch(() => toast.error("Er is een fout opgetreden"))
                .finally(() => {
                    this.closeModal();
                });
    }

    rollen = () => ([
        {
            Header: "Titel",
            accessor: "title",
            headerStyle: {
                textAlign: "left"
            },
            filterMethod: (filter: Filter, rows: any) =>
                matchSorter(rows, filter.value, { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: filter.id }] }),
            filterAll: true
        }
    ] as Array<Column>)

    render() {
        const { customPages, customPage, submitForm } = this.props;

        return (
            <>
                <CustomPagesComponent onRowClick={this.onRowClick} columns={this.rollen()} openModal={this.openCreateModal} customPages={customPages} />
                {
                    this.state.showCreateModal &&
                    <Modal
                        title={"Pagina toevoegen"}
                        content={<CustomPageComponent updateFormValue={this.updateFormValue} onSubmit={this.upsertCustomPage} />}
                        onSubmit={() => submitForm("CustomPageForm")}
                        onClose={this.closeModal}
                    />
                }

                {this.state.showEditModal
                    && (<Modal
                        title="Aanpassen pagina"
                        content={
                            <CustomPageComponent
                                customPage={customPage}
                                onSubmit={this.upsertCustomPage} isEdit={true}
                            />
                        }
                        onSubmit={() => this.props.submitForm("CustomPageForm")}
                        onClose={this.closeModal}
                        onArchive={this.archiveCustomPage}
                    />
                    )
                }
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    customPage: state.customPage,
    customPages: state.customPages
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    submitForm: (form: string) => dispatch(submit(form)),
    updateFormValue: (field: string, value: number[]) => dispatch(change("CustomPageForm", field, value)),
    getCustomPages: () => dispatch(getCustomPages()),
    getCustomPage: (id: string) => dispatch(getCustomPage(id)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomPagesContainer)

