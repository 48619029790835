import * as types from "../constants/sponsordealSoortenActionTypes";
import { SponsorDealType } from "../types/model";

const initialSponsordealSoortenState: Array<SponsorDealType> = [];
const initialSponsordealSoortState = {} as SponsorDealType

type SponsordealSoortenState = typeof initialSponsordealSoortenState
type SponsordealSoortState = typeof initialSponsordealSoortState

export const sponsordealSoortenReducer = (
    state: SponsordealSoortenState = initialSponsordealSoortenState,
    action: types.GetSponsordealSoortenAction
) => {
    if (action.type === types.GET_SPONSORDEALSOORTEN)
        return action.sponsorDealTypes;

    return state;
};

export const sponsordealSoortReducer = (
    state: SponsordealSoortState = initialSponsordealSoortState,
    action: types.GetSponsordealSoortAction
) => {
    if (action.type === types.GET_SPONSORDEALSOORT) {
        if(!action.sponsorDealType.sponsorDealTypeCompensations)
            action.sponsorDealType.sponsorDealTypeCompensations = [];
        action.sponsorDealType.sponsorDealTypeCompensations.push({ id: "", description: "" })
        return action.sponsorDealType;
    }

    return state;
};
