import React from "react";
import { WrappedFieldProps } from "redux-form";
import { TwitterPicker, ColorResult } from "react-color";
import classnames from "classnames";

interface Props {
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  type?: string;
  showSideBySide?: boolean;
  required?: boolean;
}

export class ColorPicker extends React.Component<WrappedFieldProps & Props> {
  onChangeComplete = (color: ColorResult) => {
    this.props.input.onChange(color.hex);
  };

  render() {
    const {
      input,
      label,
      required,
      meta: { touched, error },
      placeholder,
      showSideBySide,
      readOnly,
    } = this.props;

    let style = {};

    let wrapperClass = "field column colorPicker is-horizontal is-marginless is-full-mobile is-full-tablet";

    if (showSideBySide) wrapperClass = classnames(wrapperClass, "is-half-desktop");
    else wrapperClass = classnames(wrapperClass, "is-full-desktop");

    //style = { paddingTop: '.5rem', paddingRight: '2rem' };

    return (
      <div className={wrapperClass}>
        {label && (
          <div className="field-label">
            <label className="label bcwLabel is-pulled-left" htmlFor={input.name} style={style}>
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </label>
          </div>
        )}
        <div className="field-body">
          <TwitterPicker triangle="hide" color={input.value} onChangeComplete={this.onChangeComplete} />
          {touched && error && <p className="help is-danger">{error}</p>}
        </div>
      </div>
    );
  }
}
