import React, { useEffect } from "react";
import { SponsorDealType, SponsordealList } from "../../types/model";
import SponsordealSoortApi from "../../api/SponsordealSoortApi";
import SponsordealApi from "../../api/SponsordealApi";

export const SponsorContext = React.createContext({
  sponsorDealTypes: [] as SponsorDealType[],
  sponsorDealList: [] as SponsordealList[],
});

export const SponsorContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [sponsorDealTypes, setSponsorDealTypes] = React.useState<
    SponsorDealType[]
  >([]);
  const [sponsorDealList, setSponsorDealList] = React.useState<
    SponsordealList[]
  >([]);

  useEffect(() => {
    SponsordealSoortApi.getSponsorDealTypes().then((data) => {
      setSponsorDealTypes(data);
    });

    SponsordealApi.getSponsordealList().then((data) => {
      setSponsorDealList(data);
    });
  }, []);

  const contextValue = {
    sponsorDealTypes,
    sponsorDealList,
  };

  return (
    <SponsorContext.Provider value={contextValue}>
      {children}
    </SponsorContext.Provider>
  );
};
