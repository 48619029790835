import * as actionTypes from "../constants/eventActionTypes";
import EventApi from '../api/EventApi';
import { GetEventsAction, GetEventAction, SetEventTabIndexAction, GetAttendeesAction } from "../constants/eventActionTypes";
import { AsyncAction } from ".";

export const getEvents = (): AsyncAction<GetEventsAction> => {
    return async (dispatch) => {
        const events = await EventApi.getEvents();
        events.sort((a,b) => a.startDate < b.startDate ? 1:-1)
        return dispatch({ type: actionTypes.GET_EVENTS, events });
    };
}

export const getEventsForUser = (): AsyncAction<GetEventsAction> => {
    return async (dispatch) => {
        const events = await EventApi.getEventsForUser();
        events.sort((a,b) => a.startDate < b.startDate ? 1:-1)
        return dispatch({ type: actionTypes.GET_EVENTS, events });
    };
}



export const getAankomendeEvents = (): AsyncAction<GetEventsAction> => {
    return async (dispatch) => {
        const events = await EventApi.getAankomendeEvents();
        events.sort((a,b) => a.startDate < b.startDate ? 1:-1)
        return dispatch({ type: actionTypes.GET_EVENTS, events });
    };
}

export const getEvent = (id: string): AsyncAction<GetEventAction> => {
    return async (dispatch) => {
        const event = await EventApi.getEvent(id);

        return dispatch({ type: actionTypes.GET_EVENT, event });
    };
}

export const getAttendees= (eventId:string): AsyncAction<GetAttendeesAction> => {
    return async (dispatch) => {
        const attendees = await EventApi.getAttendees(eventId);

        return dispatch({ type: actionTypes.GET_ATTENDEES, attendees });
    };
}

export const setEventTabIndex = (eventTabIndex: number): AsyncAction<SetEventTabIndexAction> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_EVENTTABINDEX, eventTabIndex });
    };
}
