import ApiClient from './ApiClient';
import { Settings, SocialNotificationSettings } from "../types/model";
import { Guid } from 'guid-typescript';

const endpoint = 'SocialSettings';

export default class SocialSettingsApi {
    public static async updateSocialSettings(settings: SocialNotificationSettings): Promise<void> {
        await ApiClient.doHttpRequest<void>('put', `api/${endpoint}/SocialNotificationSettings/${settings.personId}`, settings);
    }
    
    public static async getSocialNotificatieSettings(id: string): Promise<SocialNotificationSettings> {
        var socialNotifcationSettings = await ApiClient.doHttpRequest<SocialNotificationSettings>('get', `api/${endpoint}/SocialNotificationSettings/${id}`);

        return socialNotifcationSettings;
    }
    
}