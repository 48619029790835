import * as React from "react";

import { Contactpersoon, Permissions } from "../../types/model";

import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { IsReadOnly } from "../../helpers/PermissionHelper";

interface OwnProps {
  onRowClick: (event: any, rowInfo: any) => void;
  openModal: () => void;
  exportSelectedContactpersons: () => void;
  columns: Array<Column>;
  contactpersonen: Contactpersoon[];
}
const isReadOnly = IsReadOnly(Permissions.ManageContactPersons);
export default class ContactpersonenComponent extends React.Component<OwnProps> {
  render() {
    const {
      onRowClick,
      openModal,
      exportSelectedContactpersons,
      columns,
      contactpersonen,
    } = this.props;

    return (
      <div className="content">
        <div className="contentPanel">
          <div className="columns contentPanel">
            <div className="column is-full">
              <div className="buttons is-pulled-right">
                {!isReadOnly && (
                  <>
                    <button
                      className="button is-success"
                      onClick={exportSelectedContactpersons}
                    >
                      Exporteren
                    </button>
                    <button
                      className="button is-success add-button"
                      onClick={openModal}
                    >
                      Toevoegen
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <Gridview
            id="ContactpersonenList"
            onRowClick={onRowClick}
            columns={columns}
            data={contactpersonen}
            defaultSorting={{
              id: "companyName",
              desc: false,
            }}
          />
        </div>
      </div>
    );
  }
}
