import { useState } from "react";
import EventApi from "../../api/EventApi";
import { ListEvent } from "../../types/model";

export const useUpcomingEvents = () => {
  const [upcomingEvents, setUpcomingEvents] = useState<ListEvent[]>([]);

  const getUpcomingEvents = async (force = false) => {
    if (!force && upcomingEvents.length > 0) return upcomingEvents;
    const events = await EventApi.getAankomendeEvents();
    setUpcomingEvents(events);
    return events;
  };

  return { getUpcomingEvents };
};
