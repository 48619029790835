import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";

interface Props {
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  type?: string;
  showSideBySide?: boolean;
  required?: boolean;
}

export const HiddenInput = (props: WrappedFieldProps & Props) => {
  const {
    input,
    label,
    type = "text",
    required,
    meta: { touched, error },
    placeholder,
    showSideBySide,
    readOnly,
  } = props;

  let style = {};

  let wrapperClass = "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

  if (showSideBySide) wrapperClass = classnames(wrapperClass, "is-half-desktop");
  else wrapperClass = classnames(wrapperClass, "is-full-desktop");

  // style = { paddingTop: '.5rem', paddingRight: '2rem' };

  return (
    <div className={wrapperClass} style={{ display: "none" }}>
      {label && (
        <div className="field-label is-normal">
          <label className="label is-pulled-left" htmlFor={input.name} style={style}>
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </label>
        </div>
      )}
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input {...input} type="hidden" className={classnames("input", touched && error && "is-danger")} placeholder={placeholder} readOnly={readOnly} />
          </div>
        </div>
      </div>
    </div>
  );
};
