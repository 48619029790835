import React from "react";
import {
  BijlageUrl,
  Bijlage,
  SponsordealAttachment,
  Attachment,
} from "../types/model";
import openImage from "../images/bin.svg";

interface Props {
  bijlageUrl: BijlageUrl;
  onDeleteBijlage: (id: number) => void;
  showDelete: boolean;
}
export const DocumentListHelper = (props: Props) => {
  const { bijlageUrl, onDeleteBijlage, showDelete } = props;

  var filename = bijlageUrl.url.split("/").pop();
  if (filename == undefined) filename = "";

  return (
    <div key={bijlageUrl.id}>
      <a href={bijlageUrl.url} target="_blank">
        {decodeURIComponent(filename)}
      </a>
      {showDelete && (
        <a className="gridIcon" onClick={() => onDeleteBijlage(bijlageUrl.id)}>
          <img src={openImage} alt="Delete" />
        </a>
      )}
    </div>
  );
};

interface AttachmentProps {
  attachment: Attachment;
  onDeleteAttachment: (id: string) => void;
  showDelete: boolean;
}
export const AttachmentDocumentListHelper = (props: AttachmentProps) => {
  const { attachment, onDeleteAttachment, showDelete } = props;

  if (attachment) {
    return (
      <div key={attachment.id}>
        <a href={attachment.fileUrl} target="_blank">
          {decodeURIComponent(attachment.fileName)}
        </a>
        {showDelete && (
          <a
            className="gridIcon"
            style={{
              marginLeft: "10px",
              verticalAlign: "middle",
              width: "20px",
              display: "inline-block",
            }}
            onClick={() => onDeleteAttachment(attachment.id)}
          >
            <img src={openImage} alt="Delete" />
          </a>
        )}
      </div>
    );
  } else {
    return "Error";
  }
};

interface NewProps {
  bijlage: Bijlage;
  onDeleteBijlage: (id: string) => void;
  showDelete: boolean;
}
export const DocumentListHelperTwo = (props: NewProps) => {
  const { bijlage, onDeleteBijlage, showDelete } = props;

  var filename = bijlage.content.split("/").pop();
  if (filename == undefined) filename = "";

  return (
    <div key={bijlage.id ? bijlage.id.toString() : 0}>
      <a href={bijlage.content} target="_blank">
        {decodeURIComponent(filename)}
      </a>
      {showDelete && (
        <a
          className="gridIcon"
          onClick={() =>
            onDeleteBijlage(bijlage.id ? bijlage.id.toString() : "")
          }
        >
          <img src={openImage} alt="Delete" />
        </a>
      )}
    </div>
  );
};
