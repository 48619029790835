import LoginComponent, { LoginFormProps } from "../components/LoginComponent/LoginComponent";
import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { showLoading, hideLoading } from "../actions/loadingActions";
import { RootState } from "../types/state";

interface DispatchProps {
    showLoading: () => any;
    hideLoading: () => any;
}

class LoginContainer extends React.Component<DispatchProps> {
    onSubmit = (formValues: LoginFormProps) => {
        this.props.showLoading();
    }

    render() {
        return (
            <LoginComponent onSubmit={this.onSubmit} />
        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>): DispatchProps => ({
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
});

export default connect(null, mapDispatchToProps)(LoginContainer);