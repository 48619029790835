import React from "react";
import { Form, InjectedFormProps, reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { FormTextInput } from "./FormTextInput";
import required from "../validators/required";
import { Signature } from "./Signature";

interface OwnProps {
  onClear?: (signatureCanvas: any) => void;
  onChangeSignature: (signatureCanvas: any) => void;
  onSubmit: (formValues: any) => void;
}

class SignatureForm extends React.Component<
  OwnProps & InjectedFormProps<{}, OwnProps>
> {
  onClear(signatureCanvas: any) {
    signatureCanvas.clear();
  }

  render() {
    const { handleSubmit, onSubmit, onChangeSignature } = this.props;

    return (
      <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={FormTextInput}
          label="Locatie"
          name="location"
          required={true}
          validate={required}
        />

        <Field
          component={Signature}
          label="Handtekening"
          name="signature"
          onClear={this.onClear}
          onChangeSignature={onChangeSignature}
        />
      </Form>
    );
  }
}

const SignatureReduxForm = reduxForm<{}, OwnProps>({
  form: "SignatureForm",
  enableReinitialize: true,
})(SignatureForm);

export default connect(() => ({
  initialValues: {
    location: "",
    signature: "",
  },
}))(SignatureReduxForm);
