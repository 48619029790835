import EventComponent from "../components/EventComponent/EventComponent";
import React from "react";
import {
  Event,
  SponsorDealType,
  Contactpersoon,
  EventContactpersoon,
  Permissions,
} from "../types/model";
import { connect } from "react-redux";
import { getEvent } from "../actions/eventActions";
import EventApi from "../api/EventApi";
import { AnyAction } from "redux";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import history from "../components/history";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "../actions/loadingActions";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { change, submit } from "redux-form";
import { getSponsordealSoorten } from "../actions/sponsordealSoortActions";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { combineDateAndTime } from "../helpers/Moment";
import AttachmentApi from "../api/AttachmentApi";
import { IsReadOnly } from "../helpers/PermissionHelper";

interface DispatchProps {
  getEvent: (id: string) => any;
  getSponsordealSoorten: () => any;
  submitForm: (form: string) => any;
  updateFormValueForUploadForm: (field: string, value: any) => any;
  showLoading: () => any;
  hideLoading: () => any;
}

interface StateProps {
  event: Event;
  sponsorDealTypes: SponsorDealType[];
  contactpersonen: Contactpersoon[];
}

interface MatchParams {
  id: string;
}

interface State {
  showUitnodigingModal: boolean;
}

const isReadOnly = IsReadOnly(Permissions.ManageEvents);
class EventContainer extends React.Component<
  DispatchProps & StateProps & RouteComponentProps<MatchParams>,
  State
> {
  state: State = {
    showUitnodigingModal: false,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getEvent(this.props.match.params.id);
      this.props.getSponsordealSoorten();
    }
  }

  updateEvent = async (formValues: Event, editorState: EditorState) => {
    var event = formValues;

    event.item.startDateTime = combineDateAndTime(
      event.item.startDateTime,
      event.item.startTime
    );
    event.item.endDateTime = combineDateAndTime(
      event.item.endDateTime,
      event.item.endTime
    );

    event.eventContactpersonen = [];
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(rawContentState);
    if (event) event.item.description = html || "";

    this.props.showLoading();
    await EventApi.updateEvent(event)
      .then(() => toast.success("Het opslaan is succesvol"))
      .catch(() => toast.error("Er is een fout opgetreden"))
      .finally(() => this.props.hideLoading());
    history.goBack();
  };

  onDeleteAttachment = (id: string) => {
    AttachmentApi.deleteAttachment(id).then(() => {
      this.props.getEvent(this.props.match.params.id);
      toast.success("Het verwijderen is succesvol");
    });
  };

  onDeleteOverigeFotoBijlage = (id: string) => {
    EventApi.deleteEventBijlage(id).then(() => {
      if (this.props.event.id) {
        this.props.getEvent(this.props.event.id as string);
        toast.success("Het verwijderen is succesvol");
      }
    });
  };

  onDelete = async () => {
    if (window.confirm("Weet u zeker dat u dit event wilt verwijderen?")) {
      this.props.showLoading();
      await EventApi.deleteEvent(this.props.match.params.id)
        .then(() => {
          toast.success("Het verwijderen is succesvol");
        })
        .catch(() => toast.error("Er is een fout opgetreden"))
        .finally(() => this.props.hideLoading());

      history.goBack();
    }
  };

  onCancel = () => {
    history.goBack();
  };

  render() {
    const {
      event,
      sponsorDealTypes: sponsorDealTypes,
      contactpersonen,
    } = this.props;

    return (
      <>
        <EventComponent
          isNew={false}
          event={event}
          sponsorDealTypes={sponsorDealTypes}
          contactpersonen={contactpersonen}
          isEdit={true}
          onDeleteAttachment={this.onDeleteAttachment}
          onDelete={this.onDelete}
          onCancel={this.onCancel}
          onSubmit={this.updateEvent}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  event: state.event,
  sponsorDealTypes: state.sponsorDealTypes,
  contactpersonen: state.contactpersonen,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
): DispatchProps => ({
  getEvent: (id: string) => dispatch(getEvent(id)),
  getSponsordealSoorten: () => dispatch(getSponsordealSoorten()),
  submitForm: (form: string) => dispatch(submit(form)),
  updateFormValueForUploadForm: (field: string, value: any) =>
    dispatch(change("FileUploadForm", field, value)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventContainer)
);
