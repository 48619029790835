import React from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import required from "../../validators/required";
import email from "../../validators/email";
import { FormTextInput } from "../FormTextInput";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import question from "../../images/forgot_password.svg";

export interface LoginFormProps {
  userName: string;
  password: string;
}

const LoginFormComponent: React.FunctionComponent<
  InjectedFormProps<LoginFormProps>
> = (props) => {
  const { handleSubmit } = props;

  return (
    <div id="login" className="columns is-centered">
      <div id="panel" className="column is-one-third">
        <form onSubmit={handleSubmit}>
          <div className="columns is-centered is-multiline is-mobile">
            <img src={logo} alt="Logo" />
            <div className="column is-full" id="header">
              <h1>Inloggen</h1>
            </div>
            <Field
              component={FormTextInput}
              type="text"
              name="userName"
              className="input"
              placeholder="Gebruikersnaam"
              showSideBySide={false}
              validate={[required, email]}
            />
            <Field
              component={FormTextInput}
              type="password"
              name="password"
              className="input"
              placeholder="Wachtwoord"
              showSideBySide={false}
              validate={required}
            />

            <div className="column is-full">
              <button type="submit" className="button is-success loginButton">
                Log in
              </button>
              <div className="forgot-password">
                <Link to="/wachtwoordvergeten">
                  <span>Wachtwoord vergeten</span>
                  <img src={question} alt="Logo" />
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm<LoginFormProps>({
  form: "LoginForm",
})(LoginFormComponent);
