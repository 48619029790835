import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { FormDropdown } from "../FormDropdown";
import { Bedrijf, Lead, EventLead, Permissions } from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import { TextArea } from "../TextArea";
import { LabelInput } from "../LabelInput";
import { IsReadOnly } from "../../helpers/PermissionHelper";

export interface OwnProps {
  lead?: Lead;
  bedrijven: Bedrijf[];
  formValues: Lead;
  onCancel: () => void;
  onSubmit: (formValues: Lead) => void;
  isEdit?: boolean;
  companyId?: number;
}

const isReadOnly = IsReadOnly(Permissions.ManageLeads);
class LeadComponent extends React.Component<
  OwnProps & InjectedFormProps<{} & any, OwnProps>
> {
  constructor(props: OwnProps & InjectedFormProps<{} & any, OwnProps>) {
    super(props);
  }

  createEventLead = (index: number): JSX.Element => {
    return (
      <div key={index} className="facturenRow">
        <>
          <Field
            component={LabelInput}
            label="Titel"
            name={`eventLeads[${index}].titel`}
            showSideBySide={true}
            text={
              this.props.formValues &&
              this.props.formValues.eventLeads[index].titel
            }
            readOnly={isReadOnly}
          />

          <Field
            component={LabelInput}
            label="Datum"
            name={`eventLeads[${index}].datum`}
            showSideBySide={true}
            text={
              this.props.formValues &&
              this.props.formValues.eventLeads[index].datum
            }
            readOnly={isReadOnly}
          />
        </>
      </div>
    );
  };

  render() {
    const {
      handleSubmit,
      lead,
      formValues,
      bedrijven,
      isEdit = false,
      onSubmit,
    } = this.props;

    let eventLeads: JSX.Element[] = [];

    let selectBedrijven = bedrijven.map(function (bedrijf) {
      return { label: bedrijf.name, value: bedrijf.id.toString() };
    });

    if (
      formValues &&
      formValues.eventLeads &&
      formValues.eventLeads.length > 0
    ) {
      formValues.eventLeads.forEach((eventLead: EventLead, index: number) => {
        if (!eventLead.isDeleted) {
          const element = this.createEventLead(index);
          eventLeads.push(element);
        }
      });
    }

    return (
      <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="columns contentPanel is-multiline is-mobile">
            <Field
              component={FormTextInput}
              label="Voornaam"
              name="firstName"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Achternaam"
              name="surName"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Bedrijfsnaam"
              name="companyName"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormDropdown}
              label="Aangedragen door"
              name="companyId"
              showSideBySide={true}
              options={selectBedrijven}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Emailadres"
              name="emailAddress"
              showSideBySide={true}
              required={true}
              validate={required}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Telefoonnummer"
              name="phoneNumber"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={TextArea}
              label="Opmerkingen"
              name="remarks"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Functie"
              name="function"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            {this.props.isEdit &&
              formValues &&
              formValues.eventLeads &&
              formValues.eventLeads.length > 0 && (
                <>
                  <div className="column subHeader is-full leadEventTitle">
                    <h1>Was aanwezig bij</h1>
                  </div>
                  <div className="leadEvent">{eventLeads}</div>
                </>
              )}
          </div>
        </div>
      </Form>
    );
  }
}

const LeadReduxForm = reduxForm<{}, OwnProps>({
  form: "LeadForm",
  enableReinitialize: true,
})(LeadComponent);

export default connect((state: any, props: any) => {
  return {
    initialValues: {
      id: props.isEdit ? state.lead.id : "",
      companyName: props.isEdit ? state.lead.companyName : "",
      companyId: props.isEdit
        ? state.lead.companyId
        : props.companyId
        ? props.companyId
        : props.bedrijven.length > 0
        ? props.bedrijven[0].id
        : "",
      firstName: props.isEdit ? state.lead.firstName : "",
      surName: props.isEdit ? state.lead.surName : "",
      emailAddress: props.isEdit ? state.lead.emailAddress : "",
      phoneNumber: props.isEdit ? state.lead.phoneNumber : "",
      remarks: props.isEdit ? state.lead.remarks : "",
      function: props.isEdit ? state.lead.function : "",
      eventLeads: props.isEdit ? state.lead.eventLeads : [],
    },
    formValues: getFormValues("LeadForm")(state) as Lead,
  };
})(LeadReduxForm);
