import React from "react";
import {
  Form,
  InjectedFormProps,
  reduxForm,
  Field,
  getFormValues,
} from "redux-form";
import { connect } from "react-redux";
import { FormDropdown } from "./FormDropdown";
import { FormTextInput } from "./FormTextInput";
import FormDatePicker from "./FormDatePicker";
import { OpzeggenSponsordealReden } from "../constants/enums";

const OpzeggenSponsordealRedenen = [
  { label: "Opheffing bedrijf", value: 0 },
  { label: "Geen resultaat", value: 1 },
  { label: "Prijs te hoog", value: 2 },
  { label: "Andere focus mbt sponsoring", value: 3 },
  { label: "Door BCW opgezegd", value: 4 },
  { label: "Anders", value: 5 },
];

interface OwnProps {
  onSubmit: (formValues: any) => void;
  formValues: any;
}

class TerminateForm extends React.Component<
  OwnProps & InjectedFormProps<{}, OwnProps>
> {
  render() {
    const { handleSubmit, onSubmit } = this.props;

    return (
      <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={FormDropdown}
          label="Reden"
          name="cancelledReason"
          showSideBySide={true}
          options={OpzeggenSponsordealRedenen}
        />

        <Field
          component={FormTextInput}
          label="Omschrijving"
          name="cancelledDescription"
          showSideBySide={true}
        />

        <Field
          component={FormDatePicker}
          label="Opzeggen per"
          name="cancelledDate"
          showSideBySide={true}
        />
      </Form>
    );
  }
}

const TerminateReduxForm = reduxForm<{}, OwnProps>({
  form: "TerminateForm",
  enableReinitialize: true,
})(TerminateForm);

export default connect((state: any) => ({
  initialValues: {
    cancelledReason: 0,
    cancelledDescription: "",
    cancelledDate: state.sponsordeal.to,
  },
  formValues: getFormValues("TerminateForm")(state),
}))(TerminateReduxForm);
