import { Action } from "redux";
import { Event, ListEvent, Contactpersoon } from "../types/model";

export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT = "GET_EVENT";
export const SET_EVENTTABINDEX = "SET_EVENTTABINDEX";
export const GET_ATTENDEES = "GET_ATTENDEES";

export interface GetEventsAction extends Action<string> {
    events: ListEvent[];
}

export interface GetEventAction extends Action<string> {
    event: Event;
}

export interface SetEventTabIndexAction extends Action<string> {
    eventTabIndex: number;
}

export interface GetAttendeesAction extends Action<string> {
    attendees: Contactpersoon[];
}