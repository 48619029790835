import React from "react";
import { Field, reduxForm, InjectedFormProps, Form } from "redux-form";
import { FormTextInput } from "../FormTextInput";
import { FormDropdown } from "../FormDropdown";
import FormDatePicker from "../FormDatePicker";
import { Contactpersoon, Bedrijf, Permissions } from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import email from "../../validators/email";
import { PersonType } from "../../constants/enums";
import postalcode from "../../validators/postalcode";
import { HiddenInput } from "../HiddenInput";
import { FormCheckbox } from "../FormCheckbox";
import { IsReadOnly } from "../../helpers/PermissionHelper";

export interface OwnProps {
  partner?: Contactpersoon;
  contactpersoonSoorten: Array<{ label: string; value: string }>;
  bedrijven: Bedrijf[];
  formValues: Contactpersoon;
  onCancel?: () => void;
  updateFormValue: (field: string, value: any) => void;
  deletePartner?: (id: string) => void;
  onSubmit: (formValues: Contactpersoon) => void;
  isEdit?: boolean;
  isModal?: boolean;
  companyId?: string;
  useCompanyAddress?: boolean;
}

export interface State {
  company?: Bedrijf;
  partner?: Contactpersoon;
}

const isReadOnly = IsReadOnly(Permissions.ManageCompanies);
class PartnerComponent extends React.Component<
  OwnProps & InjectedFormProps<Contactpersoon, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<Contactpersoon, OwnProps>) {
    super(props);

    this.state = {
      company: props.companyId
        ? props.bedrijven.find((b) => b.id == props.companyId)
        : props.bedrijven[0],
      partner: props.partner,
    };
  }

  componentDidUpdate = (prevProps: OwnProps) => {
    if (this.props !== prevProps) {
      this.setState({
        company: this.props.companyId
          ? this.props.bedrijven.find((b) => b.id == this.props.companyId)
          : this.props.bedrijven[0],
      });
    }
    if (
      this.props.partner &&
      prevProps.partner &&
      this.props.partner != prevProps.partner
    ) {
      this.props.updateFormValue(
        "useCompanyAddress",
        this.props.partner.addressId ==
          (this.props.partner.company
            ? this.props.partner.company.addressId
            : "")
          ? true
          : false
      );
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      partner,
      deletePartner,
      bedrijven,
      isEdit = false,
      isModal = false,
      onSubmit,
      onCancel,
      formValues,
      useCompanyAddress,
    } = this.props;

    let buttons: JSX.Element = <></>;
    let title: JSX.Element = <></>;
    let bedrijf: JSX.Element = <></>;

    let selectBedrijven = bedrijven.map(function (bedrijf) {
      return { label: bedrijf.name, value: bedrijf.id.toString() };
    });

    bedrijf = (
      <Field
        component={FormDropdown}
        label="Bedrijf"
        name="companyId"
        showSideBySide={true}
        required={true}
        readOnly={true}
        validate={required}
        options={selectBedrijven}
      />
    );

    if (isEdit && isModal == false) {
      title = (
        <div className="column subHeader is-full">
          <h1>Partner</h1>
        </div>
      );

      buttons = (
        <div className="columns contentPanel">
          <div className="column is-full">
            <div className="buttons is-pulled-right">
              {!isReadOnly && (
                <button
                  type="button"
                  className="button is-success delete-button"
                  onClick={() => deletePartner!(partner!.id)}
                >
                  Partner verwijderen
                </button>
              )}
              <button type="button" className="button" onClick={onCancel}>
                Annuleren
              </button>
              {!isReadOnly && (
                <button
                  type="submit"
                  disabled={submitting}
                  className="button is-success"
                >
                  Opslaan
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="content">
        <Form className="singleCompany" onSubmit={handleSubmit(onSubmit)}>
          <div className="columns contentPanel is-multiline is-mobile">
            {title}

            <Field component={HiddenInput} name="type" />

            <Field
              component={FormTextInput}
              label="Voornaam"
              name="firstname"
              showSideBySide={true}
              readOnly={isReadOnly}
              required={true}
              validate={[required]}
            />

            <Field
              component={FormTextInput}
              label="Achternaam"
              name="surname"
              readOnly={isReadOnly}
              showSideBySide={true}
              required={true}
              validate={[required]}
            />

            <Field
              component={FormTextInput}
              label="Emailadres"
              name="emailAddress"
              showSideBySide={true}
              validate={email}
              readOnly={isReadOnly}
            />

            {bedrijf}

            <Field
              component={FormTextInput}
              label="Telefoon"
              name="phoneNumber"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormDatePicker}
              label="Geboortedatum"
              name="birthdate"
              showSideBySide={true}
              dateFormat={"dd-MM"}
              readOnly={isReadOnly}
            />

            <Field
              component={FormCheckbox}
              showSideBySide={true}
              label="Bedrijf adres gebruiken"
              name="useCompanyAddress"
              className="checkbox"
              readOnly={isReadOnly}
            />
            <Field
              component={FormTextInput}
              label="Straat"
              name="address.street"
              showSideBySide={true}
              readOnly={useCompanyAddress || isReadOnly}
              required={!useCompanyAddress}
              //validate={required}
            />

            <Field
              component={FormTextInput}
              label="Huisnr"
              name="address.houseNumber"
              showSideBySide={true}
              readOnly={useCompanyAddress || isReadOnly}
              required={!useCompanyAddress}
              //validate={required}
            />

            <Field
              component={FormTextInput}
              label="Postcode"
              name="address.postalCode"
              showSideBySide={true}
              readOnly={useCompanyAddress || isReadOnly}
              required={!useCompanyAddress}
              validate={postalcode}
            />

            <Field
              component={FormTextInput}
              label="Plaats"
              name="address.city"
              showSideBySide={true}
              readOnly={useCompanyAddress || isReadOnly}
              required={!useCompanyAddress}
              //validate={required}
            />
          </div>

          <div className="floating-btn-bar">{buttons}</div>
        </Form>
      </div>
    );
  }
}

const PartnerReduxForm = reduxForm<Contactpersoon, OwnProps>({
  form: "PartnerForm",
  enableReinitialize: true,
})(PartnerComponent);

export default connect((state: State, props: OwnProps) => {
  let address =
    state.partner && state.partner.address ? state.partner.address : undefined;
  let company =
    state.partner && state.partner.company ? state.partner.company : undefined;

  return {
    initialValues: {
      id: props.isEdit && state.partner ? state.partner.id : "",
      firstname: props.isEdit && state.partner ? state.partner.firstname : "",
      surname: props.isEdit && state.partner ? state.partner.surname : "",
      emailAddress:
        props.isEdit && state.partner ? state.partner.emailAddress : "",
      phonenumber:
        props.isEdit && state.partner ? state.partner.phoneNumber : "",
      type:
        props.isEdit && state.partner
          ? state.partner.type
          : PersonType.Partner.valueOf().toString(),
      birthdate:
        props.isEdit && state.partner
          ? state.partner.birthDate
          : new Date().toDateString(),
      address: {
        id: address ? address.id : "",
        street: props.isEdit && address ? address.street : "",
        houseNumber: props.isEdit && address ? address.houseNumber : "",
        postalCode: props.isEdit && address ? address.postalCode : "",
        city: props.isEdit && address ? address.city : "",
        country: "Nederland",
      },
      addressId:
        props.isEdit && state.partner ? state.partner.addressId : undefined,
      companyName: props.isEdit && company ? company.name : "",
      companyId:
        props.isEdit && state.partner
          ? state.partner.companyId
          : props.companyId
          ? props.companyId
          : props.bedrijven.length > 0
          ? props.bedrijven[0].id
          : "",
    },
  };
})(PartnerReduxForm);
