import classnames from "classnames";
import { set } from "date-fns";
import { useState } from "react";
import { InputErrorMessage } from "../ErrorMessage";

interface Props {
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  hidden?: boolean;
  readOnly?: boolean;
  showSideBySide?: boolean;
  required?: boolean;
  validators?: ((value: string) => string | undefined)[];
  setValue: (value: string) => void;
}

export const TextInput: React.FC<Props> = ({
  showSideBySide,
  hidden,
  label,
  readOnly,
  name,
  required,
  value,
  validators,
  placeholder,
  setValue,
}) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const onBlur = () => {
    let error = "";
    setTouched(true);
    if (validators) {
      validators.forEach((validator) => {
        const validatorError = validator(value);
        if (validatorError && !error) {
          error = validatorError;
        }
      });
    }
    setError(error);
  };

  let style = {};

  let wrapperClass =
    "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

  if (showSideBySide)
    wrapperClass = classnames(wrapperClass, "is-half-desktop");
  else wrapperClass = classnames(wrapperClass, "is-full-desktop");

  return (
    <div className={wrapperClass}>
      {!hidden && (
        <>
          {label && (
            <div className="field-label is-normal">
              <label
                className="label is-pulled-left"
                htmlFor={name}
                style={style}
              >
                {label} {required && <span style={{ color: "red" }}>*</span>}
              </label>
            </div>
          )}
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name={name}
                  value={value}
                  type={"text"}
                  onBlur={onBlur}
                  className={classnames(
                    "input",
                    touched && error && "is-danger",
                    readOnly && "is-readonly"
                  )}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  onChange={(e) => setValue(e.target.value)}
                />
                <InputErrorMessage error={error} touched={touched} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
