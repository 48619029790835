import * as types from "../constants/factuurActionTypes";
import { Factuur, ListFactuur } from "../types/model";

const initialFacturenState: Array<ListFactuur> = [];
const initialFactuurState = {} as Factuur

type FacturenState = typeof initialFacturenState
type FactuurState = typeof initialFactuurState

export const facturenReducer = (
    state: FacturenState = initialFacturenState,
    action: types.GetFacturenAction
) => {
    if (action.type === types.GET_FACTUREN)
        return action.facturen;

    return state;
};

export const factuurReducer = (
    state: FactuurState = initialFactuurState,
    action: types.GetFactuurAction
) => {
    if (action.type === types.GET_FACTUUR)
        return action.factuur;

    return state;
};
