
import SocialSettingsApi from '../api/SocialSettingsApi';
import { AsyncAction } from ".";
import * as actionTypes from '../constants/socialSettingsActionTypes';
import { Guid } from 'guid-typescript';
import { GetSocialSettingsAction } from '../constants/socialSettingsActionTypes';

export const getSocialSettings = (id: string): AsyncAction<GetSocialSettingsAction> => {
    return async (dispatch) => {
        const socialSettings = await SocialSettingsApi.getSocialNotificatieSettings(id);

        return dispatch({ type: actionTypes.GET_SOCIAL_SETTINGS, socialSettings });
    };
}
