import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  Form,
  getFormValues,
} from "redux-form";
import { FormTextInput } from "../FormTextInput";
import {
  Permissions,
  SponsorDealType,
  StandaardTegenprestatie,
} from "../../types/model";
import { connect } from "react-redux";
import required from "../../validators/required";
import length50 from "../../validators/length50";
import TextEditor from "../TextEditor";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
// import { Sponsordeal } from "../../pages";
import { RootState } from "../../types/state";
import { FormCheckbox } from "../FormCheckbox";
import { IsReadOnly } from "../../helpers/PermissionHelper";

export interface OwnProps {
  sponsorDealType?: SponsorDealType;
  onSubmit: (
    formValues: any,
    deletedStandaardTegenprestaties: StandaardTegenprestatie[],
    editorState: EditorState
  ) => void;
  isEdit?: boolean;
  isModal?: boolean;
  formValues: any;
}

interface State {
  deletedStandaardTegenprestaties: StandaardTegenprestatie[];
  editorState: EditorState;
}

const isReadOnly = IsReadOnly(Permissions.ManageSponsorDealKinds);
class SponsordealSoortComponent extends React.Component<
  OwnProps & InjectedFormProps<{}, OwnProps>,
  State
> {
  constructor(props: OwnProps & InjectedFormProps<{}, OwnProps>) {
    super(props);

    this.state = {
      deletedStandaardTegenprestaties: [],
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidUpdate = (prevProps: any) => {
    if (prevProps != this.props && this.props.formValues && this.props.isEdit) {
      if (
        this.props.formValues.sponsorDealType &&
        this.props.formValues.sponsorDealType.defaultProviderSpecifications !=
          undefined
      ) {
        const blocksFromHtml = htmlToDraft(
          this.props.formValues.sponsorDealType.defaultProviderSpecifications
        );
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    }
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  render() {
    const { handleSubmit, onSubmit } = this.props;

    const addStandaardTegenprestatie = () => {
      this.props.formValues.sponsorDealType.sponsorDealTypeCompensations.push({
        id: "",
        description: "",
      });
      this.props.initialize(this.props.formValues);
    };

    const deleteStandaardTegenprestatie = (index: number) => {
      if (
        this.props.formValues.sponsorDealType.sponsorDealTypeCompensations
          .length > 1
      ) {
        var deletedStandaardTegenprestatie =
          this.props.formValues.sponsorDealType.sponsorDealTypeCompensations.splice(
            index,
            1
          )[0];
        this.props.initialize(this.props.formValues);

        if (deletedStandaardTegenprestatie.id != 0)
          this.setState({
            deletedStandaardTegenprestaties: [
              ...this.state.deletedStandaardTegenprestaties,
              deletedStandaardTegenprestatie,
            ],
          });
      } else {
        var standaardTegenprestatie =
          this.props.formValues.sponsorDealType.sponsorDealTypeCompensations[0];
        standaardTegenprestatie.description = "";
        this.props.initialize(this.props.formValues);
      }
    };

    let sponsorDealTypeCompensations: Array<any> = [<> </>];
    if (
      this.props.formValues &&
      this.props.formValues.sponsorDealType &&
      this.props.formValues.sponsorDealType.sponsorDealTypeCompensations
    ) {
      sponsorDealTypeCompensations =
        this.props.formValues.sponsorDealType.sponsorDealTypeCompensations.map(
          function (standaardTegenprestatie: any, index: number) {
            return (
              <>
                <Field
                  component={FormTextInput}
                  readOnly={isReadOnly}
                  key={`sponsorDealType.sponsorDealTypeCompensations[${index}].description`}
                  name={`sponsorDealType.sponsorDealTypeCompensations[${index}].description`}
                  showSideBySide={true}
                />

                {!isReadOnly && (
                  <a
                    className="button is-success delete-btn"
                    onClick={() => deleteStandaardTegenprestatie(index)}
                  >
                    Delete
                  </a>
                )}
              </>
            );
          }
        );
    }

    return (
      <Form
        className="singleCompany"
        onSubmit={handleSubmit((values: any) =>
          onSubmit(
            values,
            this.state
              .deletedStandaardTegenprestaties as StandaardTegenprestatie[],
            this.state.editorState
          )
        )}
      >
        <div className="content notulen">
          <div className="columns contentPanel is-multiline is-mobile">
            <Field
              component={FormTextInput}
              label="Naam"
              name="sponsorDealType.name"
              showSideBySide={true}
              required={true}
              validate={[required]}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Standaard prijs"
              name="sponsorDealType.defaultPrice"
              showSideBySide={true}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="App beschrijving"
              name="sponsorDealType.displayName"
              showSideBySide={true}
              validate={length50}
              readOnly={isReadOnly}
            />

            <Field
              component={FormTextInput}
              label="Mailchimp tag"
              name="sponsorDealType.mailChimpTag"
              showSideBySide={true}
              validate={[length50, required]}
              readOnly={isReadOnly}
            />

            <Field
              component={FormCheckbox}
              label="App toegang"
              name="sponsorDealType.allowAppAccess"
              showSideBySide={true}
              readOnly={isReadOnly}
              className="checkbox"
            />

            <div className="field-custom-list field-custom-list-settings">
              <div className="field-label">
                <label className="label bcwLabel is-pulled-left">
                  Standaard tegenprestaties
                </label>
              </div>
              {sponsorDealTypeCompensations}
              {!isReadOnly && (
                <a
                  className="button is-success addPrestatie"
                  onClick={addStandaardTegenprestatie}
                >
                  Toevoegen
                </a>
              )}
            </div>

            <Field
              component={FormTextInput}
              label="Maximum aantal personen"
              name="sponsorDealType.defaultMaxAttendees"
              showSideBySide={true}
              required={true}
              validate={[required]}
              readOnly={isReadOnly}
            />

            <div className="wysiwyg-edit">
              <Field
                component={TextEditor}
                label="Aanleverspecificaties"
                name="sponsorDealType.defaultProviderSpecifications"
                editorState={this.state.editorState}
                readOnly={isReadOnly}
                onEditorStateChange={this.onEditorStateChange}
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const SponsordealSoortReduxForm = reduxForm<{}, OwnProps>({
  form: "SponsordealSoortForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SponsordealSoortComponent);

export default connect((state: RootState, props: any) => ({
  initialValues: {
    sponsorDealType: {
      id: props.isEdit && state.sponsorDealType ? state.sponsorDealType.id : "",
      name:
        props.isEdit && state.sponsorDealType ? state.sponsorDealType.name : "",
      allowAppAccess:
        props.isEdit && state.sponsorDealType
          ? state.sponsorDealType.allowAppAccess
          : "",
      defaultPrice:
        props.isEdit && state.sponsorDealType
          ? state.sponsorDealType.defaultPrice
          : "",
      displayName:
        props.isEdit && state.sponsorDealType
          ? state.sponsorDealType.displayName
          : "",
      sponsorDealTypeCompensations:
        props.isEdit && state.sponsorDealType
          ? state.sponsorDealType.sponsorDealTypeCompensations
          : [{ id: "", description: "" }],
      defaultMaxAttendees:
        props.isEdit && state.sponsorDealType
          ? state.sponsorDealType.defaultMaxAttendees
          : "",
      defaultProviderSpecifications:
        props.isEdit && state.sponsorDealType
          ? state.sponsorDealType.defaultProviderSpecifications
          : "",
      mailChimpTag:
        props.isEdit && state.sponsorDealType
          ? state.sponsorDealType.mailChimpTag
          : "",
    },
  },
  formValues: getFormValues("SponsordealSoortForm")(state),
}))(SponsordealSoortReduxForm);
