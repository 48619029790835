import { Action } from "redux";
import { Lead } from "../types/model";

export const GET_LEADS = "GET_LEADS";
export const GET_LEAD = "GET_LEAD";

export interface GetLeadsAction extends Action<string> {
    leads: Lead[];
}

export interface GetLeadAction extends Action<string> {
    lead: Lead;
}
